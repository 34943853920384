import React from 'react';
import PropTypes from 'prop-types';

import MerchantIcon from '@/components/atoms/MerchantIcon';
import { Paragraph } from '@/components/atoms/Typography/styles';
import i18n from '@/translate/i18n';

import { IconWrapper, Wrapper } from './styles';

function TransferDetailsMerchantSummary({ currencyIso, merchant, username }) {
  const merchantList = JSON.parse(localStorage.getItem('merchants'));
  const merchantData = merchantList?.find(
    ({ slug }) => slug === merchant?.slug,
  );

  return (
    <Wrapper>
      <IconWrapper>
        <MerchantIcon imageUrl={merchantData?.image_url} width="50px" />
        <Paragraph
          color="light"
          family="secondary"
          size="medium"
          textTransform="capitalize"
        >
          {merchant?.name ?? merchant?.slug}
        </Paragraph>
      </IconWrapper>
      {username && (
        <p id="transfer-details-affiliation-username">
          {i18n.t('affiliationStatus.login')}
          {username}
        </p>
      )}
      {currencyIso && <p id="transfer-details-currency">{currencyIso}</p>}
    </Wrapper>
  );
}

TransferDetailsMerchantSummary.propTypes = {
  currencyIso: PropTypes.string,
  merchant: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  username: PropTypes.string,
};

export default TransferDetailsMerchantSummary;
