import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Container from '@/components/atoms/Container/styles';
import DeskContainer from '@/components/atoms/DeskContainer/styles';
import FaqTopicList from '@/components/organisms/FaqTopicList';
import NavMenuLeft from '@/components/organisms/NavMenuLeft';
import WalletHeader from '@/components/molecules/WalletHeader';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import useFaq from '@/hooks/useFaq';
import { DeskWrapper } from '@/components/atoms/GenerateTransactionStyle/styles';

function FaqTopic() {
  const history = useHistory();
  const { topic } = useParams();
  const { isMobileLayout } = useDeviceWidth();
  const { data } = useFaq();

  useEffect(() => {
    if (!isMobileLayout) {
      history.replace(`/faq#${topic}`);
    }
  }, [history, isMobileLayout, topic]);

  return (
    <DeskContainer>
      <NavMenuLeft />
      <Container gradientLight>
        <WalletHeader title={i18n.t('faq.title')} returnTo="/faq" />
        <DeskWrapper>
          <FaqTopicList data={data} />
        </DeskWrapper>
      </Container>
    </DeskContainer>
  );
}

export default FaqTopic;
