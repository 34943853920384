import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import DeskContainer from '@/components/atoms/DeskContainer/styles';
import { DeskWrapper } from '@/components/atoms/GenerateTransactionStyle/styles';
import Container from '@/components/atoms/Container/styles';
import NavMenuLeft from '@/components/organisms/NavMenuLeft';
import WalletHeader from '@/components/molecules/WalletHeader';
import Footer from '@/components/organisms/Footer';

import { AppLayout } from './styles';

function MainTemplate({
  children,
  headerProps,
  containerProps,
  hideHeader,
  hideNavMenu,
  wrapperProps,
}) {
  const { pathname } = useLocation();

  const isExternalFlow = pathname.includes('/external');

  return (
    <DeskContainer>
      {!hideNavMenu && !isExternalFlow && <NavMenuLeft />}
      <AppLayout>
        <Container {...containerProps}>
          {!hideHeader && !isExternalFlow && <WalletHeader {...headerProps} />}
          <DeskWrapper {...wrapperProps}>
            {children}
            {!isExternalFlow && <Footer />}
          </DeskWrapper>
        </Container>
      </AppLayout>
    </DeskContainer>
  );
}

MainTemplate.propTypes = {
  children: PropTypes.element,
  containerProps: PropTypes.object,
  headerProps: PropTypes.object,
  wrapperProps: PropTypes.object,
  hideHeader: PropTypes.bool,
  hideNavMenu: PropTypes.bool,
}.isRequired;

MainTemplate.defaultProps = {
  containerProps: { gradient: true },
};

export default MainTemplate;
