import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import HeadModal from '@/components/molecules/HeadModal';
import PlayIcon from '@/assets/playIcon.svg';
import { Container } from '../CashbackPixInfo/style';
import i18n from '@/translate/i18n';
import Subtitle from '@/components/atoms/Subtitle';
import { useWalletData } from '@/context/walletContext';
import InputTransactionValue from '@/components/atoms/Inputs/InputTransactionValue';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { LOCAL_STORAGE_KEY_USERNAME } from '@/helpers/constants';
import SelectDefault from '@/components/atoms/SelectDefault';
import {
  ButtonContainer,
  CashInsertValueTransationBody,
  SubtitleStyled,
  SubmitButtonStyledVT,
  TransactionContainer,
} from './style';

import useTransactionLimits from '@/hooks/useTransactionLimits';
import { formatCurrency } from '@/helpers/stringFormat';
import { Title } from '@/components/atoms/Typography/styles';

const THIRD_STEP = 3;
const ONE = 1;
const SUB_TITLE = '18px';
const ZERO = 0;

export const CashInsertValueTransation = ({
  merchantName,
  handleBackStep,
  handleNextStep,
  merchantSelected,
  setCurrency,
}) => {
  const username = localStorage.getItem(LOCAL_STORAGE_KEY_USERNAME);
  const {
    walletData: { transactionData, selectedWallet },
    walletBalance,
  } = useWalletData();
  const { isMobileLayout } = useDeviceWidth();
  const {
    transactionLimits: { min_amount_per_transaction: minAmountPerTransaction },
  } = useTransactionLimits(
    'transfer-in',
    selectedWallet,
    null,
    merchantSelected?.id,
  );

  return (
    <Container isMobile={isMobileLayout}>
      <HeadModal
        currentStep={THIRD_STEP}
        currentIcon={PlayIcon}
        handleBackStep={handleBackStep}
      />
      <CashInsertValueTransationBody isMobileLayout={isMobileLayout}>
        <Title size="medium" className="text-xl md:text-2xl text-white">
          {i18n.t('cashbackModalTransation.title')}
          {merchantName}
        </Title>
        <SubtitleStyled fontSize={SUB_TITLE}>Login: {username}</SubtitleStyled>
        {merchantSelected?.currencies.length > ONE && (
          <SelectDefault
            options={merchantSelected?.currencies}
            onChange={({ target }) => setCurrency(target.value)}
          />
        )}
        <TransactionContainer>
          <InputTransactionValue
            content={i18n.t('cashbackModalTransation.insertValue')}
            currency="BRL"
            transactionType="deposit"
            valueInvalid={transactionData.amount > walletBalance}
          />
        </TransactionContainer>
        <ButtonContainer>
          <Subtitle>
            <Trans
              i18n={i18n}
              i18nKey="cashbackModalTransation.tip"
              values={{
                minAmount: formatCurrency(
                  minAmountPerTransaction,
                  selectedWallet,
                ),
              }}
            />
          </Subtitle>
          <SubmitButtonStyledVT
            className="w-full md:!w-3/4"
            isValid={
              transactionData.amount <= walletBalance &&
              (minAmountPerTransaction !== ZERO
                ? transactionData.amount >= minAmountPerTransaction
                : transactionData.amount > ZERO)
            }
            submitText={i18n.t('cashbackModalTransation.buttonText')}
            before={
              transactionData.amount <= walletBalance
                ? i18n.t('cashbackModalTransation.buttonText')
                : i18n.t('cashbackModalTransation.valueDisbled')
            }
            after={i18n.t('cashbackModalTransation.buttonText')}
            onClick={handleNextStep}
          />
        </ButtonContainer>
      </CashInsertValueTransationBody>
    </Container>
  );
};

CashInsertValueTransation.propTypes = {
  handleBackStep: PropTypes.func,
  handleNextStep: PropTypes.func,
  merchantName: PropTypes.string,
  merchantURLCreateAccount: PropTypes.string,
  merchantSelected: PropTypes.object,
  setCurrency: PropTypes.func,
}.isRequired;

export default CashInsertValueTransation;
