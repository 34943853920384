import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import Input from '@/components/atoms/Inputs/Input';
import SubmitButton from '@/components/atoms/Buttons/SubmitButton';
import i18n from '@/translate/i18n';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { Title } from '@/components/atoms/Typography/styles';
import { extractErrorSlug } from '@/helpers/functions';
import { UPDATE_PASSWORD_SCHEMA } from '@/helpers/schemas';
import { updatePasswordConfig } from '@/services/api';

import {
  ButtonWrapper,
  ErrorMessage,
  FormStyled,
  SuccessWrapper,
  SuccessIcon,
} from './styles';

const THREE_SECONDS = 3000;

function UpdatePasswordForm({ handleClose, setIsUpdating }) {
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const checkError = (errors, touched, field) =>
    errors[field] && errors[field] !== 'required' && touched[field];

  return (
    <Formik
      validationSchema={UPDATE_PASSWORD_SCHEMA}
      initialValues={{
        currPassword: '',
        newPassword: '',
        confirmPassword: '',
      }}
      initialErrors={{
        currPassword: 'Required',
        newPassword: 'Required',
        confirmPassword: 'Required',
      }}
      onSubmit={async (
        { currPassword, newPassword, confirmPassword },
        { resetForm },
      ) => {
        try {
          setSubmitted(true);
          setIsUpdating(true);

          const payload = {
            user_id: localStorage.getItem('id'),
            old_password: currPassword,
            password: newPassword,
            password_confirmation: confirmPassword,
          };

          await updatePasswordConfig(payload);

          setPasswordUpdated(true);
          setTimeout(() => {
            handleClose();
            resetForm();
            setPasswordUpdated(false);
            setIsUpdating(false);
          }, THREE_SECONDS);
        } catch (err) {
          const messages = extractErrorSlug(err?.response);

          setErrorMessage(
            i18n.t([
              `error.updatePasswordConfig.${messages?.[0]}`,
              'error.updatePasswordConfig.unspecific',
            ]),
          );
        }
        setIsUpdating(false);
      }}
    >
      {({
        values,
        isSubmitting,
        errors,
        touched,
        setFieldTouched,
        isValid,
        submitForm,
      }) => (
        <FormStyled
          onChange={() => {
            setErrorMessage('');
            setSubmitted(false);
          }}
          id="update-password-form"
        >
          {!passwordUpdated ? (
            <>
              <Title hideInMobile size="large">
                {i18n.t('updatePassword.formTitle')}
              </Title>
              <div>
                <Input
                  id="currPassword"
                  name="currPassword"
                  type="password"
                  disableAutoComplete
                  placeholder={i18n.t('updatePassword.currPassword')}
                  value={values.currPassword}
                  togglePassowordButton
                  color="light"
                  width="240px"
                  secondaryTheme
                />
                <Input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  disableAutoComplete
                  placeholder={i18n.t('updatePassword.newPassword')}
                  value={values.newPassword}
                  error={
                    submitted && checkError(errors, touched, 'newPassword')
                  }
                  handleBlur={() => setFieldTouched('newPassword')}
                  togglePassowordButton
                  color="light"
                  width="240px"
                  secondaryTheme
                />
                <Input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  disableAutoComplete
                  placeholder={i18n.t('updatePassword.confirmPassword')}
                  value={values.confirmPassword}
                  error={
                    submitted && checkError(errors, touched, 'confirmPassword')
                  }
                  handleBlur={() => setFieldTouched('confirmPassword')}
                  color="light"
                  width="240px"
                  secondaryTheme
                />
              </div>
              <ButtonWrapper>
                <SubmitButton
                  before={i18n.t('updatePassword.update')}
                  after={i18n.t('updatePassword.update')}
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  handleClick={setSubmitted}
                  width="60%"
                  id="update-password-submit-button"
                />
                <TextButton
                  hideInMobile
                  onClick={handleClose}
                  id="update-password-cancel-button"
                >
                  {i18n.t('updatePassword.cancel')}
                </TextButton>
                {checkError(errors, touched, 'newPassword') ? (
                  <ErrorMessage>{i18n.t('signup.passwordRules')}</ErrorMessage>
                ) : (
                  checkError(errors, touched, 'confirmPassword') && (
                    <ErrorMessage>
                      {i18n.t('signup.passwordDoesMatch')}
                    </ErrorMessage>
                  )
                )}
                {!!errorMessage.length && (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                )}
              </ButtonWrapper>
            </>
          ) : (
            <SuccessWrapper>
              <Title textColor="secondary">
                {i18n.t('updatePassword.successTitle')}
              </Title>
              <SuccessIcon />
              <Title>{i18n.t('updatePassword.successText')}</Title>
            </SuccessWrapper>
          )}
        </FormStyled>
      )}
    </Formik>
  );
}

UpdatePasswordForm.propTypes = {
  handleClose: PropTypes.func,
  setIsUpdating: PropTypes.func,
}.isRequired;

export default UpdatePasswordForm;
