import styled, { css } from 'styled-components';
import { hexToRGBA } from '@/styles/colors';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    display: grid;
    font-size: ${theme.font.size.xSmall};
    grid-template-columns: 0.5fr 1.7fr 1fr;
    max-width: 600px;
    min-height: 50px;
    padding: 15px 5px;
    position: relative;
    width: 100%;

    & > *:nth-child(3) {
      justify-self: end;
    }

    &:not(:last-child) {
      border-bottom: ${`1px solid ${theme.colors.info.gray}`};
    }

    &:hover {
      background: radial-gradient(
        circle,
        ${hexToRGBA(theme.colors.info.gray, 0.05)} 0%,
        ${hexToRGBA(theme.colors.info.gray, 0.5)} 0%,
        ${hexToRGBA(theme.colors.info.gray, 0.05)} 100%
      );
    }

    @media screen and (min-width: ${theme.breakpoints.md}) {
      min-height: 52px;
    }
  `}
`;

export const FlexWrapper = styled.div`
  ${({ direction = 'row', gap = 0 }) => css`
    display: flex;
    flex-direction: ${direction};
    gap: ${gap};
  `}
`;
