import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: 0 0 0 10%;
  }
`;

export const Text = styled.p`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: inline;
    font-size: ${({ theme }) => theme.font.size.xSmall};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.4rem;
`;

export const ButtonSmall = styled.button`
  background-color: ${({ theme, active }) =>
    active ? theme.colors.button.active : theme.colors.button.inactive};
  border: none;
  color: ${({ theme, active }) =>
    active ? theme.colors.primary : theme.colors.button.disabled};
  font: ${({ theme }) =>
    `${theme.font.size.small} ${theme.font.family.secondary}`};
  padding: 0.2rem 0.5rem;
  text-transform: uppercase;
`;

export const ButtonLarge = styled(ButtonSmall)`
  font: ${({ theme }) =>
    `${theme.font.size.medium} ${theme.font.family.secondary}`};
  height: 40px;
  padding-top: 5px;
  text-transform: uppercase;
  width: 90px;
`;
