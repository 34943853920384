import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import CopyInput from '@/assets/copyInput.svg';
import BankGreen from '@/assets/bank-green.svg';
import Cashback from '@/assets/cashback.svg';
import Modal from '@/components/molecules/Modal';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { Paragraph } from '../SignInForm/styles';
import CardInfo from '@/components/atoms/CardInfo';
import TwoSmartphones from '@/assets/two-smartphones.svg';
import Celebracion from '@/assets/celebracion.svg';
import TwTitle from '@/components/atoms/TwTitle';
import {
  CardInfoActive,
  CardInfoContainer,
  ContainerContentTutorial,
  ContainerParagraphMobile,
  Image,
  MarkedText,
  ModalBodyTutorial,
  ModalContainerTutorial,
  ModalHeader,
  TitleTutorialContainer,
} from './style';

const HEIGHT_MODAL_TUTORIAL = '476px';
const HEIGHT_MODAL_TUTORIAL_MOBILE = '100vh';
const WIDTH_MODAL_TUTORIAL = '874px';
const WIDTH_MODAL_TUTORIAL_MOBILE = '90vw';
const IMAGE_BACKGROUND_SIZE = '100%';

const CashbackTutorialModal = ({ showModal, handleClose }) => {
  const { isMobileLayout } = useDeviceWidth();

  return (
    <Modal
      centralized
      handleClose={handleClose}
      showModal={showModal}
      height={
        isMobileLayout ? HEIGHT_MODAL_TUTORIAL_MOBILE : HEIGHT_MODAL_TUTORIAL
      }
      width={
        isMobileLayout ? WIDTH_MODAL_TUTORIAL_MOBILE : WIDTH_MODAL_TUTORIAL
      }
    >
      <ModalContainerTutorial isMobile={isMobileLayout}>
        <ModalHeader isMobile={isMobileLayout}>
          <img
            src={TwoSmartphones}
            alt="background"
            width={IMAGE_BACKGROUND_SIZE}
          />
          <ContainerContentTutorial isMobile={isMobileLayout}>
            <TitleTutorialContainer isMobile={isMobileLayout}>
              <TwTitle
                size="xl"
                font="arboriaBold"
                color="shadow-500"
                classList="text-center"
              >
                <Trans
                  i18n={i18n}
                  i18nKey="cashbackTutorial.title"
                  components={[<MarkedText />]}
                />
              </TwTitle>
            </TitleTutorialContainer>
            <Image
              src={Celebracion}
              alt="Celebracion"
              isMobile={isMobileLayout}
            />

            {isMobileLayout && (
              <ContainerParagraphMobile isMobile={isMobileLayout}>
                <Paragraph>
                  <Trans
                    i18n={i18n}
                    i18nKey="cashbackTutorial.description"
                    components={[<span />]}
                  />
                </Paragraph>
              </ContainerParagraphMobile>
            )}
          </ContainerContentTutorial>
        </ModalHeader>
        <ModalBodyTutorial>
          {!isMobileLayout && (
            <ContainerParagraphMobile isMobile={isMobileLayout}>
              <Paragraph>
                <Trans
                  i18n={i18n}
                  i18nKey="cashbackTutorial.description"
                  components={[<span />]}
                />
              </Paragraph>
            </ContainerParagraphMobile>
          )}
          <CardInfoContainer isMobile={isMobileLayout}>
            <CardInfo
              title={i18n.t('cashbackTutorial.cardOneTitle')}
              text={i18n.t('cashbackTutorial.cardOneText')}
              icon={CopyInput}
              lessBorderBottom={isMobileLayout}
            />
            <CardInfo
              title={i18n.t('cashbackTutorial.cardTwoTitle')}
              text={i18n.t('cashbackTutorial.cardTwoText')}
              icon={BankGreen}
              lessBorderBottom={isMobileLayout}
            />
            {isMobileLayout && (
              <CardInfo
                title={i18n.t('cashbackTutorial.cardThreeTitle')}
                text={i18n.t('cashbackTutorial.cardThreeText')}
                icon={Cashback}
                active
              />
            )}
          </CardInfoContainer>
          {!isMobileLayout && (
            <CardInfoActive>
              <CardInfo
                title={i18n.t('cashbackTutorial.cardThreeTitle')}
                text={i18n.t('cashbackTutorial.cardThreeText')}
                icon={Cashback}
                active
              />
            </CardInfoActive>
          )}
        </ModalBodyTutorial>
      </ModalContainerTutorial>
    </Modal>
  );
};

CashbackTutorialModal.propTypes = {
  showModal: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default CashbackTutorialModal;
