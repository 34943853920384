import styled from 'styled-components';

import { Form } from 'formik';

import { ReactComponent as Success } from '@/assets/sucessFull.svg';

export const FormStyled = styled(Form)`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding: 5px 0 5px;
  position: relative;
  width: 100%;

  h1 {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    border-radius: 20px;
    height: 100%;
    margin-bottom: 10px;
    max-height: 600px;
    max-width: 280px;
    padding: 0 20px 20px;
    width: 325px;
    justify-content: space-between;
  }
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 5vh;
  min-height: 100px;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: 20px;
  }
`;

export const SuccessWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.info.primary};
  font-size: ${({ theme }) => theme.font.size.small};
  text-align: center;
  width: 250px;
`;

export const SuccessIcon = styled(Success)`
  width: 80px;
`;
