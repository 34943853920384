import styled, { css } from 'styled-components';
import { hexToRGBA } from '@/styles/colors';

export const ContentWrapper = styled.div`
  align-items: center;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  height: 90%;
  justify-content: space-between;
  max-height: 850px;
  max-width: 80%;
  min-width: 240px;
  padding: 0 1rem 2rem 1rem;
  position: relative;
  width: 100vw;
`;

export const FlexWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const MessageWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.info.primary};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.info.dark};
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  position: relative;
  width: 100%;

  svg {
    height: 28px;
    position: absolute;
    right: 2px;
    top: 2px;
    width: 28px;
  }

  p {
    max-width: 350px;
  }
`;

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.info.primary};
    display: flex;
    flex-direction: column;
    font-size: ${theme.font.size.base};
    gap: 0.3rem;
    width: 100%;

    input {
      border-radius: 3px;
      border: none;
      color: ${theme.colors.info.primary};
      font: ${theme.font.size.base} ${theme.font.family.secondary};
      height: 30px;
      padding: 0 1rem;
      width: 100%;

      &:disabled {
        background-color: ${hexToRGBA(
          theme.colors.info.primary,
          0.5,
        )} !important;
        opacity: 1;
      }
    }

    p > span {
      font-size: ${theme.font.size.xSmall};
    }
  `}
`;
