import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.colors.light};
    display: flex;
    flex-direction: column;
    gap: 3rem;
    height: 250px;
    justify-content: center;
    overflow: hidden;
    width: 100%;

    h1 {
      font: ${theme.font.size.base} ${theme.font.family.primary};
      max-width: 280px;
      text-align: center;
    }
  `}
`;

export const FlexWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  button {
    margin-top: 0.5rem;
  }
`;
