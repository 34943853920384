import React from 'react';
import { useParams } from 'react-router-dom';

import ResetPasswordForm from '@/components/organisms/ResetPasswordForm';
import RegisterTemplate from '@/components/templates/RegisterTemplate';

function ResetPassword() {
  const { token } = useParams();

  return (
    <RegisterTemplate>
      <ResetPasswordForm token={token} />
    </RegisterTemplate>
  );
}

export default ResetPassword;
