import styled, { css } from 'styled-components';

import { ReactComponent as LogoMinimal } from '@/assets/vpagLogoMinimal.svg';
import { ReactComponent as LogoVPag } from '@/assets/vpagLogoHorizontal.svg';
import { ReactComponent as SimpleArrow } from '@/assets/simpleArrow.svg';

export const LogoStyles = css`
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.primary};
  margin-top: 2vh;
  max-width: 175px;
  min-height: 43px;
  min-width: 100px;
  width: 44%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    margin: 5vh 0 3vh;
  }
`;

export const Container = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.sidebar.bg};
    display: flex;
    flex-direction: column;
    flex: 0.25;
    max-width: ${({ isCollapsed }) => (isCollapsed ? '107px' : '300px')};
    min-width: ${({ isCollapsed }) => (isCollapsed ? '107px' : '250px')};
    position: relative;
    transition: 0.3s all linear;
    width: ${({ isCollapsed }) => (isCollapsed ? '107px' : '23vw')};
  }
`;

export const Logo = styled(LogoVPag)`
  ${LogoStyles}
`;

export const LogoCollapsed = styled(LogoMinimal)`
  ${LogoStyles}
  max-width: 155px;
  min-height: 30px;
  width: 30%;
`;

export const CollapseButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.info.gray};
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  min-height: 26px;
  position: absolute;
  right: -13px;
  top: 12vh;
  transform: ${({ isCollapsed }) => !isCollapsed && `rotate(180deg)`};
  transition: 0.3s transform ease;
  width: 26px;
  z-index: 9;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
  }
`;

export const Arrow = styled(SimpleArrow)`
  fill: ${({ theme }) => theme.colors.light};
`;
