import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Modal from '@/components/molecules/Modal';
import BonusListItem from '@/components/molecules/BonusListItem';
import RegulationTerms from '@/components/molecules/Modal/Messages/RegulationTerms';
import BonusDetails from '@/components/organisms/BonusDetails';
import { useWalletData } from '@/context/walletContext';
import useAllMerchants from '@/hooks/useAllMerchants';
import i18n from '@/translate/i18n';

import { ListWrapper, ItemsWrapper, Wrapper } from './styles';
import TwTitle from '@/components/atoms/TwTitle';
import useDeviceWidth from '@/hooks/useDeviceWidth';

function BonusSelector({ isVisible, data, enableSecondaryAction }) {
  const { setWalletData } = useWalletData();

  const { getAllMerchants } = useAllMerchants();
  const [selectedBonus, setSelectedBonus] = useState();
  const [termsSlug, setTermsSlug] = useState('');
  const { isMobileLayout } = useDeviceWidth();

  useEffect(() => {
    const controller = new AbortController();
    const merchantList = JSON.parse(localStorage.getItem('merchants'));

    if (!merchantList) {
      getAllMerchants();
    }

    return () => controller.abort();
  }, []);

  const handleClick = (bonus) => {
    if (enableSecondaryAction) {
      setSelectedBonus(bonus);
    } else {
      setWalletData((prevState) => ({
        ...prevState,
        selectedBonus: bonus,
      }));
    }
  };

  const defaultOptionHandleClick = () => {
    if (enableSecondaryAction) {
      setWalletData((prevState) => ({
        ...prevState,
        selectedBonus: null,
        selectedMerchant: {},
        filteredMerchantsByBonus: null,
        isMerchantsFilteredByBonus: false,
      }));
    } else {
      setWalletData((prevState) => ({
        ...prevState,
        selectedBonus: 'default',
      }));
    }
  };

  return (
    <Wrapper isVisible={isVisible} id="bonus-selector">
      <TwTitle
        text={i18n.t('bonus.availableBonuses')}
        size={isMobileLayout ? 'm_lg' : 'd_lg'}
        font="arboriaBold"
        classList="text-center"
      />
      <ListWrapper>
        <ItemsWrapper>
          {data?.map((bonus) => (
            <BonusListItem
              key={bonus?.id}
              bonus={bonus}
              minimalVersion
              handleClick={() => handleClick(bonus)}
            />
          ))}
        </ItemsWrapper>
        <BonusListItem
          isDefault
          bonus={{ title: 'default', id: 0 }}
          handleClick={defaultOptionHandleClick}
        />
      </ListWrapper>
      <span />
      <Modal
        showModal={Boolean(selectedBonus) && !termsSlug}
        setShowModal={selectedBonus}
        handleClose={() => setSelectedBonus(null)}
        height="250px"
        width="350px"
      >
        <BonusDetails
          bonus={selectedBonus}
          setTermsSlug={setTermsSlug}
          setSelectedBonus={setSelectedBonus}
          enableSecondaryAction={enableSecondaryAction}
        />
      </Modal>

      <Modal
        showModal={Boolean(termsSlug)}
        setShowModal={setTermsSlug}
        handleClose={() => setTermsSlug('')}
        width="900px"
        height="auto"
      >
        <RegulationTerms termsSlug={termsSlug} />
      </Modal>
    </Wrapper>
  );
}

BonusSelector.propTypes = {
  isVisible: PropTypes.bool,
  setBonus: PropTypes.func,
  setShowBonusList: PropTypes.func,
  skipButtonLabel: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    title: PropTypes.string,
  }),
}.isRequired;

export default BonusSelector;
