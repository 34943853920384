import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import TopicListRecursive from '@/components/molecules/TopicListRecursive';
import {
  ContentWrapper,
  ListWrapper,
  Nav,
  Wrapper,
} from '@/components/atoms/FaqStyles/styles';
import TwTitle from '@/components/atoms/TwTitle';

function FaqTopicList({ hideInMobile, data }) {
  const { topic: topicParam } = useParams();

  const [openedTopics, setOpenedTopics] = useState([]);

  const isTopicOpen = (index) => !!openedTopics.find((el) => el === index);

  const updateCollapseState = (index) => {
    if (isTopicOpen(index)) {
      setOpenedTopics((state) => state.filter((el) => el !== index));
    } else {
      setOpenedTopics((state) => [...state, index]);
    }
  };

  const faqList = topicParam
    ? data?.filter(({ slug }) => slug === topicParam)
    : data;

  return (
    <Wrapper hideInMobile={hideInMobile}>
      {faqList?.map((group) => (
        <ListWrapper>
          <TwTitle
            size="xxs"
            font="arboriaBold"
            classList="text-left ml-3 mb-2 !text-grass-800"
          >
            <div id={group?.slug}>{group?.title}</div>
          </TwTitle>
          <ContentWrapper>
            <Nav>
              <TopicListRecursive
                data={group}
                updateCollapseState={updateCollapseState}
                openedTopics={openedTopics}
                isTopicOpen={isTopicOpen}
              />
            </Nav>
          </ContentWrapper>
        </ListWrapper>
      ))}
    </Wrapper>
  );
}

FaqTopicList.propTypes = {
  hideInMobile: PropTypes.bool,
}.isRequired;

export default FaqTopicList;
