import moment from 'moment';

export const brDateRegex =
  /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i; // dd-mm-yyyy

export const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

export const EMAIL_REGEX = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+\.([a-z]+)?$/i;

export const registrationStatus = {
  validateEmail: 0,
  finalizeRegistration: 1,
  validateRegistration: 2,
  validateId: 3,
  waitingIdValidation: 4,
  validated: 5,
};

export const brazilianStates = [
  { value: '', label: 'Estado' },
  { value: 'AC', label: 'AC' },
  { value: 'AL', label: 'AL' },
  { value: 'AP', label: 'AP' },
  { value: 'AM', label: 'AM' },
  { value: 'BA', label: 'BA' },
  { value: 'CE', label: 'CE' },
  { value: 'DF', label: 'DF' },
  { value: 'ES', label: 'ES' },
  { value: 'GO', label: 'GO' },
  { value: 'MA', label: 'MA' },
  { value: 'MT', label: 'MT' },
  { value: 'MS', label: 'MS' },
  { value: 'MG', label: 'MG' },
  { value: 'PA', label: 'PA' },
  { value: 'PB', label: 'PB' },
  { value: 'PR', label: 'PR' },
  { value: 'PE', label: 'PE' },
  { value: 'PI', label: 'PI' },
  { value: 'RJ', label: 'RJ' },
  { value: 'RN', label: 'RN' },
  { value: 'RS', label: 'RS' },
  { value: 'RO', label: 'RO' },
  { value: 'RR', label: 'RR' },
  { value: 'SC', label: 'SC' },
  { value: 'SP', label: 'SP' },
  { value: 'SE', label: 'SE' },
  { value: 'TO', label: 'TO' },
  { value: 'EX', label: 'EX' },
];

export const keysToRemoveOnLogout = [
  'id',
  'accessToken',
  'transaction',
  'merchants',
  'store_token',
  'campaigns',
];

export const titleColorByStatus = {
  default: 'info.title',
  pending: 'pending',
  'in-analysis': 'pending',
  completed: 'primary',
  processing: 'pending',
  cancelled: 'danger',
};

export const twTitleColorByStatus = {
  default: 'text-shadow-500',
  pending: 'text-blue-secondary',
  'in-analysis': 'text-blue-secondary',
  completed: 'text-grass-800',
  processing: 'text-blue-secondary',
  cancelled: 'text-cherryRed',
};

export const DELAYS_IN_SECONDS = {
  long: 3000,
};

export const BR_PHONE_CODE = '+55';

export const CONTACT_EMAIL = 'help@vpag.com';

export const VPAG_STORE_URL = 'https://loja.vpag.com/LoginExterno?login=';

export const MAX_KYC_LEVEL = 2;

export const MAX_COOKIE_EXPIRE_DATE = new Date(moment().add(400, 'days'));

export const DEFAULT_METHOD_ID = 1;

export const DEFAULT_METHOD_SLUG = 'pix';
export const LOCAL_STORAGE_KEY_USERNAME = 'username';
export const TRANSACTION_INTEREST_SLUGS = [
  'deposit',
  'withdrawal',
  'transfer-in',
  'transfer-out',
];

export const GENERAL_COMMUNICATION_SLUG = 'comunicacao-geral';

export const POPTIN_SCRIPT_SRC =
  'https://cdn.popt.in/pixel.js?id=226d66dd5be91';

export const STATUS_AFFILIATE = 'affiliated';
