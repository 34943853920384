import { Link, useHistory } from 'react-router-dom';
import React, { useContext, useLayoutEffect, useState, useRef } from 'react';
import { Formik } from 'formik';
import { useCookies } from 'react-cookie';
import { Trans } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import { ReactComponent as Ticket } from '@/assets/ticket.svg';
import Loading from '@/components/atoms/Loading';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';
import Modal from '@/components/molecules/Modal';
import TermsAndConditionsAndPrivacyPolicy from '@/components/molecules/Modal/Messages/TermsAndConditionsAndPrivacyPolicy';
import { MAX_COOKIE_EXPIRE_DATE } from '@/helpers/constants';
import { AuthContext } from '@/context/authContext';
import { useUserData } from '@/context/userContext';
import { extractErrorSlug } from '@/helpers/functions';
import { SIGNUP_SCHEMA } from '@/helpers/schemas';
import { createUser, getInterests } from '@/services/api';
import i18n from '@/translate/i18n';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';

import {
  CheckboxWrapper,
  ConsentText,
  ErrorMessage,
  Flex,
  FormStyled,
  Loader,
  ToggleItem,
} from './styles';
import TwSubmitButton from '@/components/atoms/Buttons/TwSubmitButton';
import TwFormField from '@/components/atoms/Inputs/TwFormField';
import TwToggleSwitch from '@/components/v2/atoms/TwToggleSwitch';
import TwTitle from '@/components/atoms/TwTitle';
import PasswordFormCheck from '../PasswordFormCheck';

const SWITCH_BTN_SIZE = 13;

function SignUpForm({ setEmailValid, setStateProgressBar }) {
  const history = useHistory();
  const { search, pathname } = history.location;
  const searchParams = new URLSearchParams(search);
  const promocodeFromUrl = searchParams.get('promocode');
  const promocodeLocalStorage = localStorage.getItem('promocode');
  const promocodeValue = promocodeFromUrl || promocodeLocalStorage || '';

  const { setIsAuthenticated } = useContext(AuthContext);
  const { setUserData } = useUserData();
  const [submitError, setSubmitError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorEmailIsRegistered, setErrorEmailIsRegistered] = useState('');
  const [emailIsRegistered, setEmailIsRegistered] = useState('');
  const [showModal, setShowModal] = useState('');
  const [cookies, setCookie] = useCookies();
  const firstRenderPasswordControl = useRef(true);
  const firstRenderPasswordConfirmControl = useRef(true);
  const isExternalSignup = pathname?.includes('/external');
  const uuid = sessionStorage.getItem('pendingApiTransaction');
  const externalSignInPath = `/transactions/signin/${uuid}`;

  const checkError = (errors, touched, field) =>
    touched[field] && errors[field];

  useLayoutEffect(() => {
    if (!promocodeLocalStorage && promocodeFromUrl) {
      localStorage.setItem('promocode', promocodeFromUrl);
    }
  }, []);

  const handleSubmit = async (values) => {
    try {
      const payload = {
        ...values,
        ...(uuid ? { uuid } : {}),
      };

      if (values?.notifications) {
        const interestList = await getInterests({
          customer_confirmation: 1,
        });
        const interestIds = interestList?.data.map((el) => el?.id);
        payload.customer_interests = interestIds;
      }
      delete payload.notifications;

      if (payload.promocode === '') {
        delete payload.promocode;
      }

      const res = await createUser(payload);
      localStorage.setItem('accessToken', JSON.stringify(res.data.token));
      localStorage.setItem('id', JSON.stringify(res.data.id));
      localStorage.removeItem('promocode');
      setUserData({ ...res.data, status: 0 });
      setIsAuthenticated(true);

      if (isExternalSignup) {
        history.push('/external/validate-email');
      } else {
        history.push('/validate-email');
      }
    } catch (err) {
      const errorSlugs = extractErrorSlug(err?.response);

      const error = errorSlugs?.[0][0];
      if (error === 'email-already-exists') {
        setEmailIsRegistered(values.email);
        setErrorEmailIsRegistered(i18n.t('error.signup.email-already-exists'));
      } else {
        setErrorMessage(
          i18n.t([
            `error.signup.${errorSlugs?.[0]}`,
            'error.signup.unspecific',
          ]),
        );
      }
      setSubmitError(true);
    }
  };

  if (!cookies?.guid) {
    setCookie('guid', uuidv4(), {
      expires: MAX_COOKIE_EXPIRE_DATE,
    });
  }

  const checkEmailisValid = (errors) => {
    if (errors.email === undefined) {
      setEmailValid(true);
      setStateProgressBar('signUpNotEmpty');
    } else {
      setStateProgressBar('signUp');
      setEmailValid(false);
    }
  };

  const checkThatEmailIsSameUsedBefore = (email, buttonEnabled) => {
    if (emailIsRegistered === '') return;
    if (buttonEnabled) {
      setStateProgressBar('createAccountButtonIsValid');
    } else if (email !== '') {
      setStateProgressBar('signUpNotEmpty');
    }
  };

  return (
    <>
      <Formik
        validationSchema={SIGNUP_SCHEMA}
        enableReinitialize
        initialValues={{
          email: '',
          password: '',
          password_confirmation: '',
          policyConsent: false,
          promocode: promocodeValue,
          notifications: false,
        }}
        initialErrors={{
          email: 'required',
          password: 'required',
          password_confirmation: 'required',
          policyConsent: 'required',
        }}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          isSubmitting,
          isValid,
          setFieldTouched,
          setFieldValue,
          values,
          touched,
          handleChange,
        }) =>
          isSubmitting ? (
            <Loader isExternal={isExternalSignup}>
              <Loading />
            </Loader>
          ) : (
            <FormStyled
              onChange={() => {
                setSubmitError(false);
              }}
            >
              <div className="mt-5">
                <section className="w-full max-w-[600px] px-10 md:px-16 flex flex-col gap-6">
                  <TwTitle size="xl" color="white" classList="text-center">
                    <Trans
                      i18nKey="signup.accessVpag"
                      i18n={i18n}
                      components={[<Title className="text-grass-800 inline" />]}
                    />
                  </TwTitle>
                  <TwFormField
                    id="email"
                    name="email"
                    type="email"
                    placeholder={i18n.t('signup.emailPlaceholder')}
                    value={values.email}
                    error={
                      errorEmailIsRegistered !== '' && values.email !== ''
                        ? errorEmailIsRegistered
                        : checkError(errors, touched, 'email')
                    }
                    errorText
                    handleBlur={() => {
                      checkEmailisValid(errors);
                      setFieldTouched('email');
                      if (values.email === emailIsRegistered) {
                        setErrorEmailIsRegistered(
                          i18n.t('error.signup.email-already-exists'),
                        );
                      }
                    }}
                    onFocus={() => {
                      setErrorEmailIsRegistered('');
                      setFieldTouched('email', false);
                    }}
                    dataTestId="signup-input-email"
                    secondaryTheme
                    disableAutoComplete
                    onChange={(e) => {
                      handleChange(e);
                      const buttonEnabled =
                        emailIsRegistered === e.target.value ? false : isValid;
                      checkThatEmailIsSameUsedBefore(
                        e.target.value,
                        buttonEnabled,
                      );
                    }}
                  />
                  <PasswordFormCheck
                    errors={errors}
                    touched={touched}
                    values={values}
                    setFieldTouched={setFieldTouched}
                    firstRenderPasswordControl={firstRenderPasswordControl}
                    firstRenderPasswordConfirmControl={
                      firstRenderPasswordConfirmControl
                    }
                  />
                  <TwFormField
                    id="promocode"
                    name="promocode"
                    type="text"
                    placeholder={i18n.t('signup.promoCodePlaceholder')}
                    value={values.promocode}
                    dataTestId="signup-input-promo-code"
                    error={checkError(errors, touched, 'promocode')}
                    handleBlur={() => setFieldTouched('promocode')}
                    secondaryTheme
                    disableAutoComplete
                    icon={<Ticket />}
                  />
                </section>
                <CheckboxWrapper>
                  <section className="w-full px-10 md:px-16">
                    <ToggleItem>
                      <TwToggleSwitch
                        id="policyConsent"
                        checked={values?.policyConsent}
                        onClick={() => {
                          setFieldValue('policyConsent', !values.policyConsent);
                        }}
                        data-testid="signup-privacy-policy-button"
                      />
                      <ConsentText
                        data-testid="signup-privacy-policy-text"
                        family="primary"
                        size="small"
                        className="!text-[12px]"
                      >
                        <Trans
                          i18n={i18n}
                          i18nKey="signup.privacyPolicy"
                          components={[
                            <TextButton
                              type="button"
                              id="signup-terms-and-conditions-btn"
                              onClick={() => setShowModal('termsAndConditions')}
                              size="small"
                              family="primary"
                              className="!text-[12px]"
                            />,
                            <TextButton
                              type="button"
                              id="signup-privacy-policy-btn"
                              onClick={() => setShowModal('privacyPolicy')}
                              size="small"
                              family="primary"
                              className="!text-[12px]"
                            />,
                          ]}
                        />
                      </ConsentText>
                    </ToggleItem>
                  </section>
                  <section className="w-full px-10 md:px-16">
                    <ToggleItem>
                      <TwToggleSwitch
                        id="notifications"
                        checked={values?.notifications}
                        onClick={() => {
                          setFieldValue('notifications', !values.notifications);
                        }}
                        size={SWITCH_BTN_SIZE}
                      />
                      <Paragraph
                        family="primary"
                        size="small"
                        textAlign="left"
                        whiteSpace="initial"
                        className="!text-[12px]"
                      >
                        <Trans
                          i18n={i18n}
                          i18nKey="signup.generalNotifications"
                          components={[<span className="!text-[12px]" />]}
                        />
                      </Paragraph>
                    </ToggleItem>
                  </section>
                </CheckboxWrapper>
              </div>
              <section className="w-full px-10 md:px-16 flex flex-col gap-6 pb-10 max-w-[600px]">
                {submitError && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <TwSubmitButton
                  id="signup-submit-btn"
                  data-testid="signup-submit-btn"
                  before={i18n.t('signup.btnAfter')}
                  after={i18n.t('signup.btnAfter')}
                  isValid={
                    emailIsRegistered === ''
                      ? isValid
                      : isValid && emailIsRegistered !== values.email
                  }
                  submitError={submitError}
                  isSubmitting={isSubmitting}
                  classList="!text-xl"
                />
                <Flex>
                  <p className="text-white text-base">
                    {i18n.t('signup.alreadyRegistered')}
                  </p>
                  <Link
                    className="font-arboria-regular text-grass-800 underline text-base"
                    to={isExternalSignup ? externalSignInPath : '/signin'}
                    family="primary"
                    data-testid="signin-signup-link"
                  >
                    {i18n.t('signup.signin')}
                  </Link>
                </Flex>
              </section>
            </FormStyled>
          )
        }
      </Formik>
      <Modal
        setShowModal={setShowModal}
        showModal={Boolean(showModal)}
        width="900px"
        height="90vh"
        bgColor="darkSecondary"
      >
        <TermsAndConditionsAndPrivacyPolicy selectedTopic={showModal} />
      </Modal>
    </>
  );
}

SignUpForm.propTypes = {
  setEmailValid: PropTypes.func,
  setStateProgressBar: PropTypes.func,
};
export default SignUpForm;
