import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 0.2;
  flex-direction: column;
  gap: 0.5rem;
`;

export const InputWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 0.25rem;
`;

export const Input = styled.input`
  ${({ theme }) => css`
    &::placeholder {
      letter-spacing: normal !important;
    }
  `}
`;
