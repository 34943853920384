import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { ContainerCashbackPix } from '../CashbackPix/style';
import { Button } from '@/components/atoms/Buttons/Button/styles';
import { Title } from '@/components/atoms/Typography/styles';

export const BannerCashbackBody = styled.div`
  ${({ isMobileLayout }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    gap: 15px;
    padding: ${isMobileLayout ? '0 20px' : '0 15px'};
  `}
`;

export const Container = styled(ContainerCashbackPix)`
  ${({ isMobile }) => css`
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: ${isMobile ? '2rem 0' : 'none'};
    height: ${isMobile ? '100vh' : 'none'};
    width: ${isMobile ? '100vw' : 'none'};

    @media (max-width: 320px) and (max-height: 568px) {
      height: auto;
      padding-bottom: 1rem;
    }
  `};
`;

export const BannerCashbackInfoContainer = styled.div`
  ${({ theme, vpag }) => css`
    border-radius: 5px;
    border: 1px solid ${vpag ? theme.colors.greenVpag : theme.colors.blue};
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-top: 27px;
    width: 18.8125rem;
  `}
`;
export const IconsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 30px;
  justify-content: center;
  width: 100%;
`;

export const ImageContainer = styled.div`
  ${({ theme, active, vpagSelected }) => css`
    cursor: pointer;
    border-bottom: 1px solid
      ${active
        ? vpagSelected
          ? theme.colors.greenVpag
          : theme.colors.blue
        : 'transparent'};
    display: flex;
    justify-content: center;
    width: 70px;
  `}
`;

export const SimpleImage = styled.img`
  width: 36px;
  height: 36px;
  margin-bottom: 5px;
`;

export const VerticalDivider = styled.div`
  height: 45px;
  border: 1px solid white;
`;

export const InfoContainer = styled.div`
  color: white;
  display: flex;
  justify-content: start;

  ul {
    padding-left: 40px;
    list-style-type: disc;
  }
`;

export const ResumeInfo = styled.div`
  ${({ theme, vpag }) => css`
    border-top: 1px solid ${vpag ? theme.colors.greenVpag : theme.colors.blue};
    display: flex;
    justify-content: center;
    padding: 5px;
    text-align: center;

    span:first-of-type {
      color: white;
      font-weight: 800;
    }
  `}
`;

export const ButtonStyled = styled(Button)`
  margin-top: -5px;
  transition: opacity 0.2s;

  :hover {
    opacity: 0.8;
  }
`;

export const TitleWhite = styled(Title)`
  color: white;
`;

export const SpanStyled = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.greenVpag};
    text-decoration: underline;
    transition: opacity 0.2s;

    :hover {
      opacity: 0.8;
    }
  `};
`;
