import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      align-items: center;
      column-gap: 2%;
      display: grid;
      grid-template-columns: 47% 1% 47%;
      height: 100%;
      justify-items: center;
      padding: 2rem 0;
      width: 100%;
    }
  `}
`;

export const Section = styled.section`
  align-items: center;
  display: ${({ hideInMobile }) => (hideInMobile ? 'none' : 'flex')};
  flex-direction: column;
  height: 100%;
  justify-content: space-around;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    justify-content: ${({ justify }) => justify ?? 'space-between'};
    max-height: 600px;
    padding-bottom: 2rem;
  }
`;

export const DetailsHeader = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.colors.info.light};
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 70%;
  `}
`;

export const AmountResume = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.colors.info.light};
    display: flex;
    flex-direction: column;
    font-size: ${theme.font.size.medium};
    text-align: center;

    strong {
      color: ${theme.colors.primary};
      font: ${`${theme.font.size.xLarge} ${theme.font.family.secondary}`};
      text-transform: capitalize;
    }
  `}
`;

export const FlexWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
