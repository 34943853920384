import styled, { css } from 'styled-components';

export const CardInfoActive = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

export const CardInfoContainer = styled.div`
  ${({ isMobile }) => css`
    display: grid;
    grid-gap: ${isMobile ? '0' : '50px'};
    grid-template-columns: ${isMobile ? '1fr' : 'repeat(2,  1fr)'};
    height: 50%;
    justify-items: ${isMobile ? 'center' : 'auto'};
    margin-top: ${isMobile ? '0' : '50px'};
    // max-width: inherit;
    z-index: 20;

    > * {
      margin-bottom: ${isMobile ? '-20px' : '0'};
    }
  `};
`;

export const ContainerContentTutorial = styled.div`
  ${({ isMobile }) => css`
    align-items: ${isMobile ? 'center' : 'center'};
    display: flex;
    flex-direction: ${isMobile ? 'column' : 'row'};
    gap: 44px;
    height: 100%;
    justify-content: ${isMobile ? 'flex-start' : 'space-between'};
    width: 100%;
    z-index: 1;
  `};
`;

export const ContainerParagraphMobile = styled.div`
  /* ${({ isMobile }) => css`
    width: ${isMobile ? '40%' : '100%'};
  `}; */
  width: 100%;
  > p,
  span {
    font-size: 22px;
    text-align: center;
    line-height: 30.14px;
  }

  span {
    color: ${({ theme }) => theme.colors.light} !important;
    font-weight: 800;
  }
`;

export const Image = styled.img`
  ${({ isMobile }) => css`
    display: ${isMobile ? 'none' : 'block'} !important;
    height: 251px;
    width: 309px;
  `};
`;

export const MarkedText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.divider.dark} !important;
    padding: 5px !important;
    background-color: ${theme.colors.greenVpag}!important;
  `};
`;

export const ModalBodyTutorial = styled.div`
  width: 100%;
`;

export const ModalContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow-y: auto;

  h1 {
    font-size: 26px;
  }
`;

export const ModalContainerTutorial = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-height: ${({ isMobile }) => (isMobile ? '100vh' : '476px')};
  overflow-y: auto;
  padding: ${({ isMobile }) => (isMobile ? 'auto' : '75px 45px')};
  // width: ${({ isMobile }) => (isMobile ? 'auto' : '100%')};
`;

export const ModalHeader = styled.div`
  ${({ isMobile }) => css`
    display: flex;
    height: 476px;
    justify-content: center;
    max-height: 476px;
    position: relative;
    width: 90%;

    > img {
      position: absolute;
      top: ${isMobile ? '0px' : '-80px'};
      z-index: -99;
    }
  `};
`;

export const TitleTutorialContainer = styled.div`
  ${({ isMobile, theme }) => css`
    margin-top: ${isMobile ? '30px' : '0px'};
    text-align: left;
    width: ${isMobile ? '233px' : '454px'};

    > h1 {
      color: ${theme.colors.light};
      font-size: ${isMobile ? 'auto' : '51px'};
      text-align: ${isMobile ? 'center' : 'left'};
    }
  `};
`;
