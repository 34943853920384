import { Field } from 'formik';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ShowPass } from '@/assets/showPass.svg';
import { ReactComponent as HidePass } from '@/assets/hidePass.svg';

function TwFormField({
  placeholder,
  error,
  handleBlur,
  name,
  id,
  disableAutoComplete,
  handleChange,
  errorText = '',
  type = 'text',
  icon,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div>
      <div className="relative">
        <Field
          name={name}
          id={id}
          placeholder={placeholder}
          autoComplete={disableAutoComplete && 'new-password'}
          onBlur={() => handleBlur && handleBlur()}
          type={showPassword ? 'text' : type}
          className={`border-b bg-shadow-700 autofill:bg-yellow-200 placeholder:text-sand-900 h-14 w-full px-4 text-white font-arboria-regular text-base ${
            error
              ? 'border-cherryRed placeholder:text-cherryRed rounded-t-lg'
              : 'border-transparent rounded-lg'
          }`}
          {...(handleChange ? { onChange: handleChange } : {})}
          {...rest}
        />
        {(type === 'password' || icon) && (
          <div
            className={`absolute top-1/2 right-0 transform -translate-x-1/2 -translate-y-1/2 ${
              icon ? '' : 'cursor-pointer'
            } z-40`}
          >
            {icon ||
              (showPassword ? (
                <ShowPass
                  className="w-5"
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <HidePass
                  className="w-5"
                  onClick={() => setShowPassword(true)}
                />
              ))}
          </div>
        )}
      </div>
      {errorText && error && (
        <p className="text-cherryRed font-arboria-book text-sm text-left font-semibold block">
          *{error}
        </p>
      )}
    </div>
  );
}

TwFormField.propTypes = {
  color: PropTypes.string,
  dataTestId: PropTypes.string,
  disableAutoComplete: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  errorText: PropTypes.bool,
  handleBlur: PropTypes.func,
  icon: PropTypes.element,
  id: PropTypes.string,
  isvalidandlocked: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  secondaryTheme: PropTypes.bool,
  togglePassowordButton: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,
}.isRequired;

export default TwFormField;
