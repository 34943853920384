import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import Loading from '@/components/atoms/Loading';
import MerchantIcon from '@/components/atoms/MerchantIcon';
import RoundedButton from '@/components/atoms/Buttons/RoundedButton/styles';
import ErrorMessage from '@/components/molecules/ErrorMessage';
import Tooltip from '@/components/atoms/Tooltip';
import i18n from '@/translate/i18n';
import useDefaultTrackers from '@/hooks/useDefaultTrackers';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import useUsernames from '@/hooks/useUsernames';
import { InternalButton } from '@/components/atoms/Buttons/Button/styles';
import { Paragraph } from '@/components/atoms/Typography/styles';
import { useWalletData } from '@/context/walletContext';
import { getAvailableMerchants } from '@/services/api';

import { ContentWrapper, ListItem, ListWrapper } from './styles';
import TwTitle from '@/components/atoms/TwTitle';
import { useUserData } from '@/context/userContext';

const MULTIPLIER_EXAMPLE = 5;
const ACTIVE_PARAMETER = 1;
const COLOR_BY_STATUS = {
  affiliated: 'primary',
  pending: 'pending',
  refused: 'danger',
  unaffiliated: 'danger',
};

function VPlugStatus({ setSelectedStatus }) {
  const { walletData } = useWalletData();
  const { isMobileLayout } = useDeviceWidth();
  const { savedUsernames, loadingUsernames, getUsernames } = useUsernames();
  const { loadingTrackers } = useDefaultTrackers();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(false);
  const [merchantList, setMerchantList] = useState([]);
  const { userData } = useUserData();

  const trackers = walletData?.trackers ?? [];

  useEffect(() => {
    const controller = new AbortController();
    const getAllMerchants = async () => {
      try {
        setIsFetching(true);
        const res = await getAvailableMerchants(userData?.id, {
          without_pagination: ACTIVE_PARAMETER,
          active: ACTIVE_PARAMETER,
          transfer_in_vsi_integration: ACTIVE_PARAMETER,
        });

        setMerchantList(res.data);
      } catch (err) {
        setError(true);
      } finally {
        setIsFetching(false);
      }
    };

    getAllMerchants();
    getUsernames(controller);
    return () => controller.abort();
  }, []);

  return (
    <>
      <ContentWrapper>
        <TwTitle
          id="vplus-club-status-title"
          size={isMobileLayout ? 'm_lg' : 'd_lg'}
          font="arboriaBold"
          classList="text-center"
        >
          <Trans
            i18n={i18n}
            i18nKey="clubVPlus.statusTitle"
            components={[<span className="text-grass-800" />]}
          />
        </TwTitle>
        {isFetching ? (
          <Loading />
        ) : error ? (
          <ErrorMessage
            content={i18n.t('error.clubVPlusStatus.merchantList')}
          />
        ) : (
          <ListWrapper>
            {merchantList?.map(
              ({ id, name, transferin_multiplier_points, image_url }) => {
                const affiliation = savedUsernames?.find(
                  ({ merchant_id }) => merchant_id === id,
                );
                const tracker = trackers.find(
                  ({ merchant_id }) => merchant_id === id,
                );

                return (
                  <ListItem key={id}>
                    <MerchantIcon imageUrl={image_url} width="30px" />
                    <Paragraph family="secondary" size="base">
                      {name}
                    </Paragraph>
                    <Paragraph
                      family="secondary"
                      color="light"
                    >{`${transferin_multiplier_points}x pts`}</Paragraph>
                    {!loadingUsernames && (
                      <>
                        {!affiliation ? (
                          <Tooltip content={i18n.t('clubVPlus.createAccount')}>
                            {tracker?.url && !loadingTrackers ? (
                              <RoundedButton
                                title={i18n.t('clubVPlus.createAccount')}
                                type="button"
                                disabled={!tracker?.url}
                                onClick={() => window.open(tracker.url)}
                              >
                                {i18n.t('clubVPlus.createAccount')}
                              </RoundedButton>
                            ) : (
                              <span />
                            )}
                          </Tooltip>
                        ) : (
                          <Tooltip
                            content={i18n.t([
                              `generalStatus.${affiliation?.affiliate_status}`,
                              affiliation?.affiliate_status,
                            ])}
                          >
                            <RoundedButton
                              type="button"
                              title={i18n.t([
                                `generalStatus.${affiliation?.affiliate_status}`,
                                affiliation?.affiliate_status,
                              ])}
                              onClick={() =>
                                setSelectedStatus({
                                  merchant: name,
                                  status: affiliation?.affiliate_status,
                                  multiplier: transferin_multiplier_points,
                                  pointsExample:
                                    transferin_multiplier_points *
                                    MULTIPLIER_EXAMPLE,
                                })
                              }
                              bordered
                              borderColor={
                                COLOR_BY_STATUS[affiliation?.affiliate_status]
                              }
                            >
                              {i18n.t([
                                `generalStatus.${affiliation?.affiliate_status}`,
                                affiliation?.affiliate_status,
                              ])}
                            </RoundedButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </ListItem>
                );
              },
            )}
          </ListWrapper>
        )}
        <div />
        {!isMobileLayout && (
          <InternalButton to="/transfer" id="vplus-status-page-transfer-button">
            {i18n.t('clubVPlus.transferNow')}
          </InternalButton>
        )}
      </ContentWrapper>
    </>
  );
}

VPlugStatus.propTypes = {
  setSelectedStatus: PropTypes.func,
};

export default VPlugStatus;
