import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Loading from '@/components/atoms/Loading';
import i18n from '@/translate/i18n';
import { Content, Description, Message, CheckIcon, ErrorIcon } from './styles';
import { validateEmail } from '@/services/api';

const TIME_TO_REDIRECT = 4000;

function EmailVerification({ match }) {
  const history = useHistory();

  const [responseMessage, setResponseMessage] = useState('');
  const [validated, setValidated] = useState(false);
  const [isError, setIsError] = useState(false);

  const { token } = match.params;

  useEffect(() => {
    const redirect = () => {
      setTimeout(() => {
        history.push('/signin');
      }, TIME_TO_REDIRECT);
    };
    const validate = async () => {
      try {
        await validateEmail(token);
        setResponseMessage(i18n.t('validateEmail.sucessMessage'));
        setValidated(true);
      } catch (err) {
        const message = err?.response?.data?.message;
        setIsError(true);

        setResponseMessage(
          i18n.t([
            `error.validateEmail.${message}`,
            'error.validateEmail.unspecific',
          ]),
        );
      } finally {
        redirect();
      }
    };
    validate();
  }, [history, token]);

  return (
    <Content gradient>
      {validated && !isError ? (
        <>
          <CheckIcon />
          <Message id="validate-email-success-message">
            {responseMessage}
          </Message>
          <Description>{i18n.t('validateEmail.redirectMessage')}</Description>
        </>
      ) : isError ? (
        <>
          <ErrorIcon />
          <Message id="validate-email-error-message">{responseMessage}</Message>
        </>
      ) : (
        <Loading marginTop="45vh" />
      )}
    </Content>
  );
}

EmailVerification.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }),
}.isRequired;

export default EmailVerification;
