import i18n from '@/translate/i18n';

export default {
  '1xbet': {
    label: i18n.t('affiliationForm.usernameHelper.1xbet.label'),
    buttonText: i18n.t('affiliationForm.usernameHelper.1xbet.buttonText'),
    placeholder: i18n.t('affiliationForm.usernameHelper.1xbet.placeholder'),
    imageMobile: '/images/affiliation/1xbet-mobile.png',
    imageDesktop: '/images/affiliation/1xbet-desktop.png',
  },
  melbet: {
    label: i18n.t('affiliationForm.usernameHelper.melbet.label'),
    buttonText: i18n.t('affiliationForm.usernameHelper.melbet.buttonText'),
    placeholder: i18n.t('affiliationForm.usernameHelper.melbet.placeholder'),
    imageMobile: '/images/affiliation/melbet-mobile.png',
    imageDesktop: '/images/affiliation/melbet-desktop.png',
  },
  qbet: {
    label: i18n.t('affiliationForm.usernameHelper.qbet.label'),
    buttonText: i18n.t('affiliationForm.usernameHelper.qbet.buttonText'),
    placeholder: i18n.t('affiliationForm.usernameHelper.qbet.placeholder'),
  },
  tempobet: {
    label: i18n.t('affiliationForm.usernameHelper.tempobet.label'),
    buttonText: i18n.t('affiliationForm.usernameHelper.tempobet.buttonText'),
    placeholder: i18n.t('affiliationForm.usernameHelper.tempobet.placeholder'),
    imageMobile: '/images/affiliation/tempobet.png',
    imageDesktop: '/images/affiliation/tempobet.png',
  },
  betwarrior: {
    label: i18n.t('affiliationForm.usernameHelper.betwarrior.label'),
    buttonText: i18n.t('affiliationForm.usernameHelper.betwarrior.buttonText'),
    placeholder: i18n.t(
      'affiliationForm.usernameHelper.betwarrior.placeholder',
    ),
    imageMobile: '/images/affiliation/betwarrior.png',
    imageDesktop: '/images/affiliation/betwarrior.png',
  },
};
