import React, { useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import { InternalButton } from '@/components/atoms/Buttons/Button/styles';
import Input from '@/components/atoms/Inputs/Input';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { Paragraph } from '@/components/atoms/Typography/styles';
import Modal from '@/components/molecules/Modal';
import MerchantUsernameHelper from '@/components/molecules/Modal/Messages/MerchantUsernameHelper';
import { useWalletData } from '@/context/walletContext';
import { AFFILIATION_SCHEMA } from '@/helpers/schemas';
import useDefaultTrackers from '@/hooks/useDefaultTrackers';
import i18n from '@/translate/i18n';

import merchantUsernameHelper from './data';
import { FormStyled, InputWrapper } from './styles';

function AffiliationForm({ merchant }) {
  const { setWalletData, walletData } = useWalletData();
  const { loadingTrackers } = useDefaultTrackers();
  const [showModal, setShowModal] = useState(false);

  const trackers = walletData?.trackers ?? [];
  const selectedMerchantTracker = trackers.find(
    ({ merchant_id }) => merchant_id === merchant?.id,
  );

  const merchantSlug = walletData?.selectedMerchant?.slug;
  const helperData = merchantUsernameHelper?.[merchantSlug];

  return (
    <Formik
      validationSchema={AFFILIATION_SCHEMA}
      initialValues={{
        newUsername: '',
      }}
      onSubmit={(values) => {
        setWalletData((prevState) => ({
          ...prevState,
          transactionData: {
            ...prevState?.transactionData,
            username: values.newUsername,
          },
        }));
      }}
    >
      {({ values, setFieldValue, submitForm }) => {
        const shouldEnableCreation =
          !values.newUsername && selectedMerchantTracker?.url;
        return (
          <FormStyled>
            <span />
            <InputWrapper>
              <Input
                secondaryTheme
                id="newUsername"
                name="newUsername"
                type="text"
                autoFocus
                label={helperData?.label ?? ''}
                placeholder={
                  helperData?.placeholder ??
                  `${i18n.t('transfer.usernamePlaceholder')} ${
                    merchant?.name ?? '...'
                  }`
                }
                color="light"
                onChange={({ target }) => {
                  setFieldValue('newUsername', target.value);
                }}
                disableAutoComplete
              />
              {helperData?.buttonText &&
                (helperData?.imageMobile || helperData?.imageDesktop) && (
                  <TextButton onClick={setShowModal}>
                    {helperData.buttonText}
                  </TextButton>
                )}
            </InputWrapper>
            {shouldEnableCreation ? (
              <Paragraph highlightColor="light">
                <Trans
                  i18n={i18n}
                  i18nKey="affiliationForm.createAccount"
                  components={[<span />]}
                  tOptions={{ merchant: merchant?.name }}
                />
              </Paragraph>
            ) : (
              <Paragraph>
                {i18n.t('affiliationForm.description', {
                  merchant: merchant?.name,
                })}
              </Paragraph>
            )}

            <InternalButton
              id={
                shouldEnableCreation
                  ? 'affiliation-form-create-account-btn'
                  : 'affiliation-form-confirm-username-btn'
              }
              to={
                !values.newUsername && !loadingTrackers
                  ? { pathname: selectedMerchantTracker?.url }
                  : '#'
              }
              {...(shouldEnableCreation
                ? { target: '_blank', rel: 'noopener noreferrer' }
                : {})}
              onClick={values.newUsername && submitForm}
            >
              {shouldEnableCreation
                ? i18n.t('affiliationForm.create')
                : i18n.t('affiliationForm.confirm')}
            </InternalButton>
            <Modal
              showModal={showModal}
              setShowModal={setShowModal}
              width="830px"
            >
              <MerchantUsernameHelper helperData={helperData} />
            </Modal>
          </FormStyled>
        );
      }}
    </Formik>
  );
}

AffiliationForm.propTypes = {
  merchant: PropTypes.string,
}.isRequired;

export default AffiliationForm;
