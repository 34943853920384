import styled from 'styled-components';

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  justify-content: space-between;
  max-width: 80%;
  width: 100vw;
`;

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
