import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  ${({ theme, hideInMobile }) => css`
    cursor: pointer;
    display: ${hideInMobile ? 'none' : 'flex'};
    flex-direction: column;
    gap: 0.5rem;
    height: 100%;
    justify-content: space-between;

    p {
      color: ${theme.colors.primary};
      display: flex;
      font: ${`${theme.font.size.base} ${theme.font.family.secondary}`};
      gap: 5px;
    }

    strong {
      color: ${theme.colors.info.title};
      font: 400 ${`${theme.font.size.large} ${theme.font.family.primary}`};
    }

    span {
      color: ${theme.colors.info.title};
      font: ${`${theme.font.size.large} ${theme.font.family.secondary}`};
    }

    > div {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      display: block;

      span {
        display: none;
      }
    }
  `}
`;

export default Wrapper;
