import React from 'react';
import PropTypes from 'prop-types';
import Banner from '@/assets/bannerPixcashbackInfo.svg';
import SimpleCashback from '@/assets/simpleCashback.svg';
import BackButton from '@/components/atoms/BackButton';
import Stepper from '@/components/molecules/Stepper';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import TitleModal from '@/components/molecules/TitleModal';

import {
  BannerCashbackContainer,
  Image,
  BannerContent,
  BackButtonContainer,
} from './style';

const HEIGHT = '200px';
const HEIGHT_MOBILE = '300px';
const SIZE_ICON = '58px';
const SIZE_ICON_BACK = '40px';
const QUANTITY_STEPS = 3;
const CURRENT_STEP = 1;

function HeadModal({
  handleBackStep,
  currentStep = CURRENT_STEP,
  currentIcon = SimpleCashback,
}) {
  const { isMobileLayout } = useDeviceWidth();

  return (
    <BannerCashbackContainer isMobileLayout={isMobileLayout}>
      {isMobileLayout && <TitleModal handleBackStep={handleBackStep} />}
      <Image
        src={Banner}
        alt="banner vpag"
        height={isMobileLayout ? HEIGHT_MOBILE : HEIGHT}
        className="md:w-full"
      />
      <BannerContent isMobileLayout={isMobileLayout}>
        {!isMobileLayout && (
          <BackButtonContainer>
            <div>
              <BackButton sizeIcon={SIZE_ICON_BACK} onClick={handleBackStep} />
            </div>
          </BackButtonContainer>
        )}
        <img
          src={currentIcon}
          alt="icon cashback"
          width={SIZE_ICON}
          height={SIZE_ICON}
        />
        <Stepper quantity={QUANTITY_STEPS} currentStep={currentStep} />
      </BannerContent>
    </BannerCashbackContainer>
  );
}
HeadModal.propTypes = {
  handleBackStep: PropTypes.func,
  currentIcon: PropTypes.string,
}.isRequired;
export default HeadModal;
