import { useEffect, useState } from 'react';

import { getFaqGroups } from '@/services/api';
import { useWalletData } from '@/context/walletContext';

function useFaq() {
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const { setWalletData } = useWalletData();

  useEffect(() => {
    const controller = new AbortController();

    const getFaqs = async () => {
      try {
        setIsFetching(true);
        const filters = {
          has_parent: 0,
          with_children_level: 4,
          with_questions_level: 4,
          without_pagination: 1,
        };
        const params = new URLSearchParams(filters);
        const res = await getFaqGroups(params, controller.signal);
        const filtered = res?.data.filter(
          ({ parent_group_id }) => parent_group_id === null,
        );
        setData(filtered);
        setWalletData((state) => ({
          ...state,
          faq: filtered,
        }));
      } catch (err) {
        const message = err?.response?.data?.message;
        console.error(message);
      } finally {
        setIsFetching(false);
      }
    };

    getFaqs();
    return () => controller.abort();
  }, [setWalletData]);

  return { isFetching, data };
}

export default useFaq;
