import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import Tooltip from '@/components/atoms/Tooltip';
import { useUserData } from '@/context/userContext';
import { useWalletData } from '@/context/walletContext';
import { calculateBonus, createRandomId } from '@/helpers/functions';
import { formatCurrency } from '@/helpers/stringFormat';
import i18n from '@/translate/i18n';

import { TooltipContent } from './styles';
import TwDetailsLine from '@/components/v2/atoms/TwDetailsLine';

const DECIMAL_PLACES = 2;

const MINIMAL_POINTS_MULTIPLIER = 1;
const ICON_SIZE = 15;

function TransferPreview() {
  const {
    walletData: {
      selectedWallet,
      transactionData,
      selectedBonus,
      selectedMerchant,
      transactionSubmitError,
    },
  } = useWalletData();
  const { userData } = useUserData();

  const bonusAmount = calculateBonus(transactionData?.amount, selectedBonus);

  const affiliation = userData?.usernames?.find(
    ({ merchant_id }) => merchant_id === selectedMerchant?.id,
  );

  const isAffiliated =
    affiliation?.affiliate_status === 'approved' ||
    affiliation?.affiliate_status === 'affiliated';

  const dividendRate =
    transactionData?.exchangeRates?.[
      `BRL-${transactionData?.merchant_currency_iso}`
    ];

  const convertedValue =
    transactionData?.merchant_currency_iso !== 'BRL'
      ? (
          (Number(transactionData?.amount) + Number(bonusAmount)) *
          dividendRate
        ).toFixed(DECIMAL_PLACES)
      : null;

  const fields = [
    {
      label: i18n.t('bonusConfirmation.transferAmount'),
      value: formatCurrency(transactionData?.amount, selectedWallet),
    },
    ...(selectedBonus?.id
      ? [
          {
            label: i18n.t('bonusConfirmation.bonus'),
            value: formatCurrency(bonusAmount, selectedWallet),
          },
        ]
      : []),
    {
      label: transactionSubmitError
        ? i18n.t('transactionStatus.youWouldHaveReceived')
        : i18n.t('bonusConfirmation.youReceive'),
      value:
        transactionData?.merchant_currency_iso !== 'BRL' ? (
          <Tooltip content={i18n.t('transfer.conversionDisclaimer')}>
            <TooltipContent>
              {formatCurrency(
                convertedValue ??
                  Number(transactionData?.amount) + Number(bonusAmount),
                transactionData?.merchant_currency_iso ?? 'BR',
              )}
              <AiOutlineInfoCircle size={ICON_SIZE} />
            </TooltipContent>
          </Tooltip>
        ) : (
          formatCurrency(
            Number(transactionData?.amount) + Number(bonusAmount),
            selectedWallet,
          )
        ),
      border: true,
    },
    ...(selectedMerchant?.transferin_base_points && !userData?.points_blocked
      ? isAffiliated &&
        selectedMerchant?.transferin_multiplier_points >
          MINIMAL_POINTS_MULTIPLIER
        ? [
            {
              label: i18n.t('bonusConfirmation.points'),
              value: Math.floor(
                selectedMerchant.transferin_multiplier_points *
                  selectedMerchant.transferin_base_points *
                  Number(transactionData?.amount),
              ).toFixed(0),
            },
          ]
        : [
            {
              label: i18n.t('bonusConfirmation.points'),
              value: Math.floor(
                selectedMerchant.transferin_base_points *
                  Number(transactionData?.amount),
              ).toFixed(0),
            },
          ]
      : []),
  ];

  return (
    <>
      {fields.map(({ border, label, value }) => (
        <TwDetailsLine
          info={label}
          amount={value}
          size="sm"
          key={createRandomId()}
          divider={border}
        />
      ))}
    </>
  );
}

export default TransferPreview;
