/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

function TwToggleSwitch({
  checked,
  disabled,
  id = 'checkbox',
  onClick,
  type = 'small',
  ...rest
}) {
  const SWITCH_PROPERTIES = {
    standard:
      'w-[36px] h-[18px] bg-shadow-600 after:bg-ashes-600 after:h-4 after:w-4 after:top-[1px] after:start-[1px]',
    small:
      'w-[26px] h-[15px] bg-shadow-700 after:bg-black after:h-3 after:w-3 after:top-[2px] after:start-[2px]',
  };

  return (
    <label className="inline-flex  items-center cursor-pointer">
      <input
        type="checkbox"
        id={id}
        onClick={onClick}
        className="sr-only peer"
        disabled={disabled}
        defaultChecked={checked}
        {...rest}
      />

      <div
        className={`relative ${SWITCH_PROPERTIES[type]} peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:rounded-full after:transition-all  peer-checked:after:bg-grass-600`}
      />
      <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 peer-checked:bg-grass-600" />
    </label>
  );
}
TwToggleSwitch.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  size: PropTypes.number,
};

export default TwToggleSwitch;
