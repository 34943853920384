import styled, { css } from 'styled-components';
import { Field } from 'formik';

import { hexToRGBA } from '@/styles/colors';
import {
  InputStyleDefault,
  LabelStyle,
  ContainerStyle,
} from '@/components/atoms/Inputs/styles';

const RoundedStyle = css`
  background-color: ${({ theme }) => hexToRGBA(theme.colors.shadow, 0.2)};
  border: none;
  border-radius: 30px;
  height: 40px;
  margin-top: 0;
  padding: 0;
  text-align: center;
`;

const LabelRoundedStyled = css`
  color: ${({ theme }) => theme.colors.primary} !important;
  font-size: ${({ theme }) => theme.font.size.xSmall};
  margin: 20px 10px 0 0;
  position: relative;
  text-transform: uppercase;
`;

export const Container = styled(ContainerStyle)`
  margin-top: ${({ variant }) => variant === 'rounded' && 0};
`;
export const SelectField = styled(Field)`
  ${InputStyleDefault}
  ${({ variant }) => variant === 'rounded' && RoundedStyle}

  cursor: pointer;
  font-size: ${({ theme }) => theme.font.size.small};
  margin-bottom: 10px;
  text-transform: capitalize;
`;

export const Option = styled.option`
  background-color: ${({ theme }) => theme.colors.bgGradientDark.start};
  color: ${({ theme }) => theme.colors.info.light};
  font-size: ${({ theme }) => theme.font.size.small};
  text-transform: capitalize;
`;

export const Label = styled(LabelStyle)`
  ${({ theme, variant }) => css`
    ${variant === 'rounded' && LabelRoundedStyled}

    color: ${theme.colors.info.light};
    text-align: center;

    @media screen and (min-width: ${theme.breakpoints.md}) {
      color: ${theme.colors.info.light};
      display: ${variant === 'rounded' && 'none'};
      text-transform: capitalize;
    }
  `}
`;
