import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
`;

export const Radio = styled.input`
  appearance: none;
  border: solid 0.5px ${({ theme }) => theme.colors.primary};
  border-radius: 11px;
  cursor: pointer;
  height: 22px;
  overflow: hidden;
  position: relative;
  width: 22px;

  &::before {
    border-radius: 7.5px;
    content: '';
    height: 15px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
  }

  &:checked::before {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.info.primary};
  font-style: normal;
  letter-spacing: 0;
  line-height: 14px;
  margin-left: 15px;
  text-align: left;
`;
