import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;

  p {
    line-height: 150%;
    max-width: 280px;
  }
`;
