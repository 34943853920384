import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import LoginRegisterSelector from '@/components/molecules/LoginRegisterSelector';
import SignUpForm from '@/components/organisms/SignUpForm';
import { RightWrapper } from '@/components/pages/SignIn/styles';
import RegisterTemplate from '@/components/templates/RegisterTemplate';
import SystemPresentationPanel from '@/components/organisms/SystemPresentationPanel';
import useShowApresentationSystem from '@/hooks/useShowApresentationSystem';

function SignUp() {
  const { pathname } = useLocation();
  const isExternalLogin = pathname?.includes('/external');
  const [isEmailValid, setIsEmailValid] = React.useState(false);
  const [stateProgressBar, setStateProgressBar] = useState('signUp');
  const [isDirectAccess, isMobile] = useShowApresentationSystem();

  return (
    <RegisterTemplate>
      <RightWrapper>
        <LoginRegisterSelector
          isExternalLogin={isExternalLogin}
          origin="signUp"
          isCreateAccount
          isEmailValid={isEmailValid}
          stateProgressBar={stateProgressBar}
        />
        {!isDirectAccess && isMobile ? (
          <SystemPresentationPanel />
        ) : (
          <SignUpForm
            setEmailValid={setIsEmailValid}
            setStateProgressBar={setStateProgressBar}
          />
        )}
      </RightWrapper>
    </RegisterTemplate>
  );
}

export default SignUp;
