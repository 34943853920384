import React from 'react';
import TagManager from 'react-gtm-module';

import Routes from './routes';
import Auth from './context/authContext';
import UserProvider from './context/userContext';
import { useWalletData } from './context/walletContext';
import LoadingFullScreen from './components/molecules/LoadingFullScreen';

import './index.css';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};
TagManager.initialize(tagManagerArgs);

function App() {
  const { isLoading } = useWalletData();

  return (
    <UserProvider>
      {isLoading && <LoadingFullScreen />}
      <Auth>
        <Routes />
      </Auth>
    </UserProvider>
  );
}

export default App;
