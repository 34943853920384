import React from 'react';
import PropTypes from 'prop-types';

import { ToggleSwitch, ToggleSwitchLabel, ToggleSwitchWrapper } from './styles';

function ToggleSwitchButton({
  checked,
  disabled,
  id = 'checkbox',
  onClick,
  size,
  ...rest
}) {
  return (
    <ToggleSwitchWrapper>
      <ToggleSwitch
        id={id}
        type="checkbox"
        defaultChecked={checked}
        onClick={onClick}
        size={size}
        disabled={disabled}
        {...rest}
      />
      <ToggleSwitchLabel htmlFor={id} disabled={disabled} size={size} />
    </ToggleSwitchWrapper>
  );
}

ToggleSwitchButton.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.number,
};

export default ToggleSwitchButton;
