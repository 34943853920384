import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';

import i18n from '@/translate/i18n';
import { DesktopLinks } from '@/components/atoms/TransactionStatusStyles/styles';
import { LinkTextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { Title } from '@/components/atoms/Typography/styles';
import { formatCurrency, formatShortDate } from '@/helpers/stringFormat';
import { titleColorByStatus } from '@/helpers/constants';
import {
  DeskWrapper,
  HideInDesktop,
} from '@/components/atoms/GenerateTransactionStyle/styles';

import {
  AmountValue,
  AmountWrapper,
  Button,
  ContentWrapper,
  Section,
  SuccessIcon,
} from './styles';

function ConversionDetails({ data }) {
  const history = useHistory();

  const { from_currency, from_amount, to_currency, to_amount } = data.details;

  const updatedAt = data?.updated_at && formatShortDate(data.updated_at, true);

  return (
    <DeskWrapper>
      <ContentWrapper>
        <Section>
          <Title
            color={titleColorByStatus[data?.status]}
            size="large"
            highlightUpper
            id="conversion-details-titles"
          >
            <Trans
              i18n={i18n}
              i18nKey={i18n.t(
                `transactionDetails.title.conversion.${data?.status}`,
              )}
              components={[<span />]}
            />
          </Title>
          {updatedAt && <p>{updatedAt}</p>}
          <SuccessIcon />
          {data?.id && `ID ${data.id}`}
        </Section>
        <AmountWrapper>
          <Title>{i18n.t('conversionDetails.youConverted')}</Title>
          <AmountValue id="conversion-details-amount">
            {formatCurrency(from_amount, from_currency)}
          </AmountValue>
          <p>
            {i18n.t('conversionDetails.amountReceived')}
            <span id="conversion-details-received-amount">
              {formatCurrency(to_amount, to_currency)}
            </span>
          </p>
        </AmountWrapper>
        <DesktopLinks>
          <LinkTextButton
            className="!text-lg"
            onClick={() => history.push('/wallet')}
          >
            {i18n.t('conversionDetails.return')}
          </LinkTextButton>
          <LinkTextButton
            className="!text-lg"
            onClick={() => history.push('/transactions')}
            id="conversion-details-show-history-button"
          >
            {i18n.t('conversionDetails.goToHistory')}
          </LinkTextButton>
        </DesktopLinks>
      </ContentWrapper>
      <Button
        type="button"
        onClick={() => history.push('/transfer')}
        id="conversion-details-transfer-button"
      >
        {i18n.t('conversionDetails.transfer')}
      </Button>
      <HideInDesktop>
        <LinkTextButton
          className="!text-lg"
          to="/wallet"
          id="conversion-details-return-button"
        >
          {i18n.t('conversionDetails.return')}
        </LinkTextButton>
      </HideInDesktop>
    </DeskWrapper>
  );
}

ConversionDetails.propTypes = {
  data: PropTypes.shape({
    params: PropTypes.shape({
      conversionId: PropTypes.string,
    }),
  }),
}.isRequired;

export default ConversionDetails;
