import styled from 'styled-components';

export const Section = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  justify-content: space-between;
  width: 70%;
`;

export const FlexWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 100%;
    justify-content: space-between;
    max-width: 750px;
  }
`;
