import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import CpfValidationForm from '@/components/organisms/CpfValidationForm';
import RegistrationForm from '@/components/organisms/RegistrationForm';
import { useUserData } from '@/context/userContext';
import useUserStatus from '@/hooks/useUserStatus';
import RegisterTemplate from '@/components/templates/RegisterTemplate';

import TransferApiTemplate from '@/components/templates/TransferApiTemplate';
import { RightWrapper } from '../SignIn/styles';
import LoginRegisterSelector from '@/components/molecules/LoginRegisterSelector';

function ProceedRegistration() {
  const { pathname } = useLocation();
  const { getUserStatusAndRedirect } = useUserStatus();
  const { userData } = useUserData();
  const [showRegistrationForm, setRegistrationForm] = useState(false);
  const [cpfIsValid, setCpfIsValid] = useState(false);

  useEffect(() => {
    getUserStatusAndRedirect(userData?.status);
  }, [getUserStatusAndRedirect, userData]);

  const isExternalFlow = pathname?.includes('/external');
  const PageTemplate = isExternalFlow ? TransferApiTemplate : RegisterTemplate;
  const shouldRenderMinimalVersion =
    userData?.name && userData?.cpf && userData?.birth_date;

  return (
    <PageTemplate>
      <LoginRegisterSelector
        origin="validateCpf"
        isExternalLogin={isExternalFlow}
        isCreateAccount
        stateProgressBar={cpfIsValid ? 'validateCpf' : 'codeValidateFull'}
      />
      <RightWrapper>
        {showRegistrationForm ? (
          <RegistrationForm minimalVersion={shouldRenderMinimalVersion} />
        ) : (
          <CpfValidationForm
            onComplete={() => setRegistrationForm(true)}
            checkValidCpf={(e) => setCpfIsValid(e)}
          />
        )}
      </RightWrapper>
    </PageTemplate>
  );
}

export default ProceedRegistration;
