import styled from 'styled-components';
import { Form } from 'formik';

export const FormStyled = styled(Form)`
  animation: goLeftDefault 1s;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 40px 0;
  max-width: 400px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    border-radius: 20px;
    margin: 3vh 0;
    max-height: 520px;
    max-width: 400px;
    min-height: 380px;
  }
`;

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.font.size.xSmall};
  margin-bottom: 15px;
  text-align: center;
`;

export const SubmitWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 3vh;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex: 0.8;
  flex-flow: row wrap;
  justify-content: center;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: ${({ templateColumns = '1fr 1fr' }) =>
    templateColumns};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column-gap: 20px;
    width: 100%;
  }
`;

export const FormWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-height: 550px;
`;
