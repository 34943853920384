import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import BasicUserinfo from '@/components/molecules/BasicUserInfo';
import UserMenuItems from './menuItems';
import ValidationProgressSummary from '@/components/molecules/ValidationProgressSummary';
import { AuthContext } from '@/context/authContext';
import { keysToRemoveOnLogout } from '@/helpers/constants';
import { getValidationPercentage } from '@/helpers/functions';
import { Paragraph } from '@/components/atoms/Typography/styles';
import { useUserData } from '@/context/userContext';

import {
  ContentWrapper,
  MenuItemWrapper,
  NavLinkWrapper,
  UserDataWrapper,
} from '../styles';

const KYC_LEVEL_1 = 1;
const ONE_HUNDRED_PERCENT = 100;

function UserMenu({ closeMenu, showMenu }) {
  const history = useHistory();
  const { userData } = useUserData();
  const { pathname } = history.location;
  const { setIsAuthenticated } = useContext(AuthContext);
  const percentageOfValidations = getValidationPercentage(userData.validations);

  return (
    <>
      <UserDataWrapper id="user-menu-desktop">
        <MenuItemWrapper>
          <BasicUserinfo align="flex-start" />
        </MenuItemWrapper>
        {userData?.kyc_level === KYC_LEVEL_1 &&
          percentageOfValidations < ONE_HUNDRED_PERCENT && (
            <MenuItemWrapper padding="1rem 0">
              <ValidationProgressSummary
                minimalVersion
                showLinkButton
                shouldUpdate={showMenu}
              />
            </MenuItemWrapper>
          )}
      </UserDataWrapper>
      {UserMenuItems.map(({ id, name, icon: Icon, path }) => (
        <NavLinkWrapper
          key={id}
          id={id}
          onClick={() => {
            if (path === '/logout') {
              setIsAuthenticated(false);

              keysToRemoveOnLogout.forEach((key) => {
                localStorage.removeItem(key);
              });

              return history.push('/signin');
            }
            return pathname === path ? closeMenu() : history.push(path);
          }}
        >
          <Icon style={{ minWidth: 25 }} />
          <ContentWrapper>
            <Paragraph>{name}</Paragraph>
          </ContentWrapper>
        </NavLinkWrapper>
      ))}
    </>
  );
}

UserMenu.propTypes = {
  closeMenu: PropTypes.bool.isRequired,
  showMenu: PropTypes.bool,
};

export default UserMenu;
