import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { v4 as uuidv4 } from 'uuid';

import { Section, TextContent, Wrapper } from './styles';

function Article({ textContent }) {
  return (
    <Wrapper>
      {textContent.map(({ title, sectionContent }) => (
        <Section key={title}>
          <h1>{title}</h1>
          {sectionContent?.map((item) =>
            item?.renderAsHtml ? (
              <ReactMarkdown rehypePlugins={[rehypeRaw]} key={uuidv4()}>
                {item?.content}
              </ReactMarkdown>
            ) : (
              <TextContent key={uuidv4()} indent={item?.indent}>
                {item?.content}
              </TextContent>
            ),
          )}
        </Section>
      ))}
    </Wrapper>
  );
}

Article.propTypes = {
  textContent: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      sectionContent: PropTypes.arrayOf(
        PropTypes.shape({
          content: PropTypes.string,
          renderAsHtml: PropTypes.bool,
          indent: PropTypes.bool,
        }),
      ),
    }),
  ),
}.isRequired;
export default Article;
