import i18n from '@/translate/i18n';
import { ReactComponent as Logout } from '@/assets/MenuIcons/logoutfull.svg';
import { ReactComponent as Profile } from '@/assets/MenuIcons/userfull.svg';
import { ReactComponent as Settings } from '@/assets/MenuIcons/configfull.svg';

export default [
  {
    id: 'menu-profile',
    name: i18n.t('topRightMenu.profile'),
    path: '/profile',
    description: i18n.t('topRightMenu.profileDescription'),
    icon: Profile,
  },
  {
    id: 'menu-settings',
    name: i18n.t('topRightMenu.settings'),
    path: '/settings',
    description: i18n.t('topRightMenu.settingsDescription'),
    icon: Settings,
  },
  {
    id: 'menu-logout',
    name: i18n.t('topRightMenu.logout'),
    path: '/logout',
    description: '',
    icon: Logout,
  },
];
