import React from 'react';
import PropTypes from 'prop-types';

import ErrorMessage from '@/components/molecules/ErrorMessage';
import i18n from '@/translate/i18n';
import Loading from '@/components/atoms/Loading';
import TransactionResume from '@/components/molecules/TransactionResume';
import { Paragraph } from '@/components/atoms/Typography/styles';

function TransactionList({ listItems, isLoading, isError, errorText }) {
  return (
    <div className="flex flex-col items-center w-full overflow-y-auto mb-5">
      {Boolean(listItems.length) &&
        listItems.map((el) => <TransactionResume data={el} />)}

      {isLoading && !listItems.length && <Loading marginTop="100px" />}
      {isError && !isLoading && <ErrorMessage content={i18n.t(errorText)} />}
      {!listItems.length && !isError && !isLoading && (
        <Paragraph textAlign="center">
          {i18n.t('lastTransactions.noData')}
        </Paragraph>
      )}
    </div>
  );
}

TransactionList.propTypes = {
  errorText: PropTypes.string,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  listItems: PropTypes.shape,
}.isRequired;

export default TransactionList;
