import React from 'react';
import PropTypes from 'prop-types';
import i18n from '@/translate/i18n';
import { CardInfoComponentContainer } from './style';
import TwText from '@/components/v2/atoms/TwText';

const SIZE = '58px';

const CardInfo = ({ icon, title, text, lessBorderBottom, active }) => (
  <CardInfoComponentContainer
    lessBorderBottom={lessBorderBottom}
    isActive={active}
  >
    <div>
      <img src={icon} alt={i18n.t('icon')} width={SIZE} height={SIZE} />
    </div>
    <TwText size="xl" color={active ? 'black' : 'crem'} text={title} />
    <TwText size="sm" tag="p" color={active ? 'black' : 'crem'} text={text} />
  </CardInfoComponentContainer>
);

CardInfo.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  lessBorderBottom: PropTypes.bool,
  active: PropTypes.bool,
}.isRequired;

export default CardInfo;
