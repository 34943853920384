import styled, { css } from 'styled-components';
import { Paragraph } from '@/components/atoms/Typography/styles';
import Subtitle from '@/components/atoms/Subtitle';
import { BannerCashbackBody } from '../CashbackPixInfo/style';

export const BannerCashbackFinishedContainer = styled.div`
  border-radius: 25% !important;
  height: fit-content;
  width: 100%;
`;

export const CashbackFinishedBody = styled(BannerCashbackBody)`
  ${({ isMobileLayout }) => css`
    padding: 0 15px;
    p {
      font-size: ${isMobileLayout ? '24px' : 'auto'};
      span {
        font-size: ${isMobileLayout ? '24px' : 'auto'};
      }
    }
  `};
`;

export const CashbackFinishedImage = styled.img`
  // border-radius: 6px 6px 0 0;
  position: relative;
  z-index: 1;
`;

export const ParagraphStyled = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.light};
  max-height: 88px;
  text-align: center;

  span {
    font-weight: bolder;
  }
`;

export const ParagraphTwo = styled(ParagraphStyled)`
  font-size: 15px;
`;

export const SpanStyled = styled.span`
  color: ${({ theme }) => theme.colors.blue} !important;
`;

export const SubtitleStyled = styled(Subtitle)`
  width: 100%;
`;

export const MobileContainer = styled.div`
  border: 1px solid red;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TitleModalFinishedContainer = styled.div`
  padding: 0 20px;
`;
