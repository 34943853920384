import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import BasicUserinfo from '@/components/molecules/BasicUserInfo';
import MenuItems from '@/components/molecules/UserInfoMenus/UserMenu/menuItems';
import ValidationProgressSummary from '@/components/molecules/ValidationProgressSummary';
import { AuthContext } from '@/context/authContext';
import { keysToRemoveOnLogout } from '@/helpers/constants';
import { getValidationPercentage } from '@/helpers/functions';
import { ReactComponent as CloseIcon } from '@/assets/closeBtn.svg';
import { useUserData } from '@/context/userContext';

import {
  MenuModalContainer,
  CloseButton,
  NavLinkWrapper,
  TextWrapper,
  Text,
  LogoutWrapper,
  Wrapper,
  ArrowButton,
  Arrow,
} from '@/components/atoms/MenuModalStyle/styles';

import { ContentWrapper, ItemWrapper } from './styles';

const TIME_TO_EXECT = 500;
const KYC_LEVEL_1 = 1;
const ONE_HUNDRED_PERCENT = 100;

function ProfileMenuModal({ showMenu, setShowMenu }) {
  const history = useHistory();
  const { userData } = useUserData();
  const { pathname } = useLocation();
  const { setIsAuthenticated } = useContext(AuthContext);
  const [hideAnimation, setHideAnimation] = useState(true);

  const percentageOfValidations = getValidationPercentage(userData.validations);

  const redirectTo = (newPathname) => {
    if (pathname === newPathname) {
      return setShowMenu(false);
    }
    return history.push(newPathname);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHideAnimation(false);
    }, TIME_TO_EXECT);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <MenuModalContainer
      showMenu={showMenu}
      hideAnimation={hideAnimation}
      padding="2rem 0 0"
      id="user-menu-mobile"
    >
      <CloseButton type="button" onClick={() => setShowMenu(false)}>
        <CloseIcon width="20px" height="20px" />
      </CloseButton>
      <ContentWrapper>
        <ItemWrapper>
          <BasicUserinfo showProfileImage />
        </ItemWrapper>
        {userData?.kyc_level === KYC_LEVEL_1 &&
          percentageOfValidations < ONE_HUNDRED_PERCENT && (
            <ItemWrapper padding="1rem 0">
              <ValidationProgressSummary showLinkButton minimalVersion />
            </ItemWrapper>
          )}
        <Wrapper>
          {MenuItems.map(({ name, icon: Icon, path }) => {
            if (path === '/logout') {
              return (
                <LogoutWrapper key={name}>
                  <NavLinkWrapper
                    onClick={() => {
                      setIsAuthenticated(false);

                      keysToRemoveOnLogout.forEach((key) => {
                        localStorage.removeItem(key);
                      });

                      history.push('/signin');
                    }}
                  >
                    <Icon />
                    <TextWrapper>
                      <Text>{name}</Text>
                    </TextWrapper>
                  </NavLinkWrapper>
                </LogoutWrapper>
              );
            }
            return (
              <NavLinkWrapper
                key={name}
                onClick={() => redirectTo(path)}
                padding="20px 30px"
                width="100%"
              >
                <Icon />
                <TextWrapper>
                  <Text>{name}</Text>
                </TextWrapper>
                <ArrowButton>
                  <Arrow />
                </ArrowButton>
              </NavLinkWrapper>
            );
          })}
        </Wrapper>
      </ContentWrapper>
    </MenuModalContainer>
  );
}

ProfileMenuModal.propTypes = {
  showMenu: PropTypes.bool,
  setShowMenu: PropTypes.func,
}.isRequired;

export default ProfileMenuModal;
