import * as yup from 'yup';
import { validateCPF, validatePhone } from 'validations-br';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { brDateRegex } from './constants';
import { validateMinimumAge } from './functions';
import i18n from '@/translate/i18n';

const REQUIRED = i18n.t('error.required');
const EMAIL_REQUIRED = i18n.t('error.email-required');
const EMAIL_INVALID = i18n.t('error.email-invalid');
const PASSWORD_REQUIRED = i18n.t('error.password-required');
const PASSWORD_INVALID = i18n.t('error.password-invalid');
const PASSWORD_NOT_MATCH = i18n.t('error.password-not-match');
export const SIGNIN_SCHEMA = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export const SIGNUP_SCHEMA = yup.object().shape({
  email: yup.string().email(EMAIL_INVALID).required(EMAIL_REQUIRED),
  password: yup
    .string()
    .min(8, PASSWORD_INVALID)
    .matches(/[a-z]+/, PASSWORD_INVALID)
    .matches(/[A-Z]+/, PASSWORD_INVALID)
    .matches(/\d+/, PASSWORD_INVALID)
    .required(PASSWORD_REQUIRED),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], PASSWORD_NOT_MATCH)
    .required(REQUIRED),
  policyConsent: yup.bool().oneOf([true], 'policy-consent-required'),
});

export const CPF_VALIDATION_SCHEMA = yup.object().shape({
  cpf: yup
    .string()
    .test('validate-cpf', (value) => validateCPF(value))
    .required(),
});

export const CPF_PHONE_VALIDATION_SCHEMA = yup.object().shape({
  cpf: yup
    .string()
    .test('validate-cpf', (value) => validateCPF(value))
    .required(),
  phone: yup
    .string()
    .test('validate-phone', (value) => validatePhone(value))
    .required(),
});

export const UPDATE_PASSWORD_SCHEMA = yup.object().shape({
  currPassword: yup.string().required(),
  newPassword: yup
    .string()
    .min(8, 'min-8-characteres')
    .matches(/[a-z]+/, 'one-lowercase')
    .matches(/[A-Z]+/, 'one-uppercase')
    .matches(/\d+/, 'one-number')
    .required('required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'password-does-not-match')
    .required('required'),
});

export const RESET_PASSWORD_SCHEMA = yup.object().shape({
  password: yup
    .string()
    .min(8, 'min-8-characteres')
    .matches(/[a-z]+/, 'one-lowercase')
    .matches(/[A-Z]+/, 'one-uppercase')
    .matches(/\d+/, 'one-number')
    .required('required'),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'password-does-not-match')
    .required('required'),
});

export const RECOVER_PASSWORD_SCHEMA = yup.object().shape({
  newEmail: yup.string().email('Digite um e-mail válido').required(),
});

export const ADDRESS_SCHEMA = yup.object().shape({
  postalCode: yup.string().min(9).required(),
  state: yup.string().min(2).required(),
  city: yup.string().min(3).required(),
  district: yup.string().min(3).required(),
  address: yup.string().min(3).required(),
  number: yup.number().required(),
});

export const AFFILIATION_SCHEMA = yup.object().shape({
  newUsername: yup.string().min(3).required(),
});

export const BANK_ACC_SCHEMA = yup.object().shape({
  agency: yup.string().min(4).required(),
  accountNumber: yup.string().min(6).required(),
  type: yup.string().required(),
});

export const REGISTRATION_SCHEMA = yup.object().shape({
  name: yup
    .string()
    .min(3, 'min-length-3')
    .matches(/^[a-zA-Z \u00C0-\u00FF]*$/, 'only-letters')
    .required(),
  cpf: yup
    .string()
    .test('validate-cpf', 'invalid-cpf', (value) => validateCPF(value))
    .required(),
  phone: yup.string().min(15).required(),
  birth_date: yup
    .string()
    .matches(brDateRegex)
    .required()
    .test('valite-age', 'invalid-age', (value) => validateMinimumAge(value)),
});

export const PHONE_SCHEMA = yup.object().shape({
  phone: yup
    .string()
    .min(15)
    .max(15)
    .test('validate-phone', 'invalid-phone-number', (value) =>
      isValidPhoneNumber(value, 'BR'),
    )
    .required(),
});

export const USERNAME_SCHEMA = yup.object().shape({
  username: yup.string().required(),
});
