import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import InputTransactionValue from '@/components/atoms/Inputs/InputTransactionValue';
import LimitInstructions from '@/components/molecules/Modal/Messages/LimitInstructions';
import MethodIcon from '@/components/atoms/MethodIcon';
import Modal from '@/components/molecules/Modal';
import PixKey from '@/components/atoms/PixKey';
import PaymentCarousel from '@/components/molecules/PaymentCarousel';
import WarningMessage from '@/components/molecules/WarningMessage';
import WithdrawalButton from '@/components/atoms/Buttons/WithdrawalButton';
import MinMaxAmountMessage from '@/components/atoms/MinMaxAmountMessage';
import i18n from '@/translate/i18n';
import useBalance from '@/hooks/useBalance';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { Title } from '@/components/atoms/Typography/styles';
import ConfirmAction from '@/components/molecules/Modal/ConfirmAction';
import { createWithdrawal } from '@/services/api';
import { formatCurrency } from '@/helpers/stringFormat';
import { registrationStatus } from '@/helpers/constants';
import { useUserData } from '@/context/userContext';
import { useWalletData } from '@/context/walletContext';
import {
  ButtonWrapper,
  Span,
  Text,
} from '@/components/atoms/Buttons/TransactionButton/styles';

import { IconWrapper, Wrapper } from './styles';
import TwTitle from '@/components/atoms/TwTitle';

const DECIMAL_PLACES = 2;

function WithdrawalForm({ exchangeData, transactionLimits }) {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { isMobileLayout } = useDeviceWidth();
  const {
    isLoading,
    setIsLoading,
    walletBalance,
    setWalletData,
    walletData: { selectedWallet, transactionData, transactionSubmitError },
  } = useWalletData();
  const { userData } = useUserData();
  const { getBalance } = useBalance();

  const { amount } = transactionData;
  const {
    max_amount_per_transaction: maxValue,
    min_amount_per_transaction: minValue,
  } = transactionLimits;

  const isValidValue = amount > 0 && amount >= minValue && amount <= maxValue;
  const insufficientFund = amount > walletBalance;

  const finalValue =
    selectedWallet !== 'BRL'
      ? Number((exchangeData.spread_rate * amount).toFixed(DECIMAL_PLACES))
      : amount;

  const generateWithdrawal = async () => {
    setIsLoading(true);
    setShowConfirmation(false);
    const payload = {
      customer_currency_iso: selectedWallet,
      psp_currency_iso: selectedWallet,
      customer_amount: transactionData.amount,
      final_amount_preview: transactionData.amount,
      method: transactionData?.method?.slug,
    };

    try {
      const res = await createWithdrawal(payload);
      const { id } = res.data;

      await getBalance();
      history.push(`/transactions/${id}`);
    } catch (err) {
      const { message, data } = err?.response?.data;

      setErrorMessage({ message, data });
      setWalletData((prevState) => ({
        ...prevState,
        transactionSubmitError: true,
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const userHasPendingValidations = !userData?.kyc_level;
  const identityValidationPending =
    userData?.status === registrationStatus.validateId;

  useEffect(() => {
    if (!transactionData?.method) {
      setWalletData((state) => ({
        ...state,
        transactionData: {
          ...state.transactionData,
          method: { id: 1, slug: 'pix', name: 'Pix' },
        },
      }));
    }
  }, []);

  const isMethodPix = transactionData?.method?.slug === 'pix';

  return (
    <Wrapper>
      {transactionSubmitError ? (
        <TwTitle
          size={isMobileLayout ? 'm_lg' : 'd_lg'}
          font="arboriaBold"
          classList="text-center"
        >
          <Trans
            i18nKey={i18n.t('withdrawal.errorTitle')}
            i18n={i18n}
            components={[<strong className="text-cherryRed" />]}
          />
        </TwTitle>
      ) : (
        <TwTitle
          size={isMobileLayout ? 'm_lg' : 'd_lg'}
          font="arboriaBold"
          classList="text-center"
        >
          {i18n.t('withdrawal.title')}{' '}
          <span className="text-grass-800 uppercase">
            {transactionData?.method?.name}
          </span>
        </TwTitle>
      )}
      <IconWrapper>
        {isMobileLayout ? (
          <PaymentCarousel />
        ) : (
          <MethodIcon method="pix" width="50px" flat />
        )}
        {isMethodPix && <PixKey />}
        {transactionSubmitError && (
          <p>
            {i18n.t([
              `error.withdrawal.${errorMessage}`,
              `error.withdrawal.unspecific`,
            ])}
          </p>
        )}
        {isMobileLayout && !transactionSubmitError && isMethodPix && (
          <Title>
            <Trans
              i18nKey={i18n.t('withdrawal.pixKeyRequired')}
              i18n={i18n}
              components={[<span />]}
            />
          </Title>
        )}
      </IconWrapper>
      <InputTransactionValue
        content={
          transactionSubmitError
            ? i18n.t('withdrawal.messageOnError')
            : transactionData?.amount
            ? i18n.t('withdrawal.youWillWithdraw')
            : i18n.t('deposit.enterValue')
        }
        insufficientFund={insufficientFund}
        isError={transactionSubmitError}
        transactionType="withdrawal"
      />
      <MinMaxAmountMessage />
      <ButtonWrapper isValid={isValidValue}>
        {selectedWallet !== 'BRL' && Boolean(amount) && (
          <Text>
            {i18n.t('withdrawal.youWillReceive')}{' '}
            <Span>{formatCurrency(finalValue, 'BRL', true)}</Span>
          </Text>
        )}

        {transactionSubmitError ? (
          <>
            <TextButton onClick={setShowModal}>
              {i18n.t('transactionError.increaseLimit')}
            </TextButton>
            <TextButton
              onClick={() =>
                setWalletData((prevState) => ({
                  ...prevState,
                  transactionSubmitError: false,
                }))
              }
              margin="1rem 0"
            >
              {i18n.t('transactionError.tryAgain')}
            </TextButton>
          </>
        ) : userHasPendingValidations ? (
          <>
            <WarningMessage
              message={
                identityValidationPending
                  ? i18n.t(
                      'validationAlert.identityValidationPendingWithdrawal',
                    )
                  : i18n.t('validationAlert.waitConfirmation')
              }
              linkPath={
                identityValidationPending && '/wallet/validate-identity'
              }
              linkLabel={
                identityValidationPending &&
                i18n.t('validationAlert.validateIdentity')
              }
            />
            {!maxValue && !userHasPendingValidations && (
              <TextButton onClick={setShowModal} margin="2rem 0 0">
                {i18n.t('transactionError.increaseLimit')}
              </TextButton>
            )}
          </>
        ) : (
          <WithdrawalButton
            handleClick={setShowConfirmation}
            disabled={isLoading}
          />
        )}
      </ButtonWrapper>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        height="290px"
        button={i18n.t('modal.understood')}
      >
        <LimitInstructions />
      </Modal>
      <Modal
        showModal={showConfirmation}
        setShowModal={setShowConfirmation}
        height="290px"
      >
        <ConfirmAction
          title={i18n.t('withdrawal.confirmationTitle')}
          description={i18n.t('withdrawal.confirmationDescription')}
          confirmLabel={i18n.t('withdrawal.confirmationLabel')}
          cancelLabel={i18n.t('withdrawal.cancelLabel')}
          closeModal={() => setShowConfirmation(false)}
          handleClick={!isLoading ? generateWithdrawal : null}
        />
      </Modal>
    </Wrapper>
  );
}

WithdrawalForm.propTypes = {
  exchangeData: PropTypes.shape({
    spread_rate: PropTypes.number,
  }),
  userLimits: PropTypes.shape({
    withdrawal: PropTypes.shape({
      max_amount_per_transaction: PropTypes.number,
      min_amount_per_transaction: PropTypes.number,
    }),
  }),
}.isRequired;

export default WithdrawalForm;
