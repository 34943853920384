import React from 'react';
import { useHistory } from 'react-router-dom';

import NavMenu from '@/components/molecules/NavMenu';
import useDeviceWidth from '@/hooks/useDeviceWidth';

import { Container, Logo, LogoCollapsed } from './styles';

function NavMenuLeft() {
  const history = useHistory();
  const { shouldCollapseMenu } = useDeviceWidth();

  return (
    <Container isCollapsed={shouldCollapseMenu}>
      {shouldCollapseMenu ? (
        <LogoCollapsed />
      ) : (
        <Logo onClick={() => history.push('/wallet')} />
      )}
      <NavMenu
        borders={false}
        arrows={false}
        isCollapsed={shouldCollapseMenu}
      />
    </Container>
  );
}

export default NavMenuLeft;
