import React from 'react';
import PropTypes from 'prop-types';

import BankIcons from '@/assets/bank-icons.svg';
import Logo from './styles';

function BankIcon({ slug, width }) {
  return (
    <Logo
      role="presentation"
      aria-hidden="true"
      preserveAspectRatio="xMinYMid"
      width={width}
      height={width}
    >
      <use xlinkHref={`${BankIcons}#${slug}`} width={width} height={width} />
    </Logo>
  );
}

BankIcon.propTypes = {
  type: PropTypes.string,
  width: PropTypes.string,
}.isRequired;

export default BankIcon;
