import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import i18n from '@/translate/i18n';
import BinanceQrCode from '@/components/molecules/BinanceQrCode';
import PixQrCode from '@/components/molecules/PixQrCode';
import { twTitleColorByStatus } from '@/helpers/constants';

import { Wrapper } from './styles';
import TwTitle from '@/components/atoms/TwTitle';
import useDeviceWidth from '@/hooks/useDeviceWidth';

function DepositDetailsSummary({ data }) {
  const paymentMethod = data?.payment_method_slug;
  const { isMobileLayout } = useDeviceWidth();

  return (
    <Wrapper>
      <TwTitle
        size={isMobileLayout ? 'm_lg' : 'd_lg'}
        font="arboriaBold"
        classList="text-center"
      >
        <Trans
          i18n={i18n}
          i18nKey={`transactionDetails.title.deposit.${data?.status}`}
          components={[
            <span
              className={`${twTitleColorByStatus[data?.status ?? 'default']}`}
            />,
          ]}
        />
      </TwTitle>
      {paymentMethod === 'pix' && <PixQrCode data={data} />}
      {paymentMethod === 'binance' && <BinanceQrCode data={data} />}
    </Wrapper>
  );
}

DepositDetailsSummary.propTypes = {
  data: PropTypes.shape({
    customer_bonus: PropTypes.number,
    customer_currency_amount: PropTypes.number,
    customer_currency_iso: PropTypes.string,
    id: PropTypes.number,
    payment_method_name: PropTypes.string,
    payment_method_slug: PropTypes.string,
    status: PropTypes.string,
  }),
  qrCodeOnly: PropTypes.bool,
}.isRequired;

export default DepositDetailsSummary;
