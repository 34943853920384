import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CopyInput from '@/assets/copyInput.svg';
import { ContainerInputCopy, CopySpan, InputStyled } from './style';
import i18n from '@/translate/i18n';

const TIME_WAIT = 2000;
const SIZE_ICON = 24;

function InputCopy({ value }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, TIME_WAIT);
  };

  return (
    <ContainerInputCopy className="w-80 max-w-80 md:max-w-[406px] md:w-[406px]">
      {isCopied ? (
        <CopySpan>{i18n.t('actions.keyCopy')}</CopySpan>
      ) : (
        <InputStyled type="text" value={value} readOnly />
      )}
      <button type="button" onClick={handleCopy}>
        <img
          src={CopyInput}
          width={SIZE_ICON}
          height={SIZE_ICON}
          alt="Button copy"
        />
      </button>
    </ContainerInputCopy>
  );
}

InputCopy.propTypes = {
  value: PropTypes.string.isRequired,
}.isRequired;

export default InputCopy;
