import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bg.dark};
  bottom: 0;
  display: flex;
  position: fixed;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const Button = styled(Link)`
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.colors.bg.darkSecondary};
    border: none;
    color: ${theme.colors.info.primary};
    display: flex;
    font: ${`${theme.font.size.medium} ${theme.font.family.primary}`};
    height: 80px;
    justify-content: center;
    text-decoration: none;
    width: 50vw;

    svg {
      height: 20px;
      margin-right: 10px;
      width: 20px;
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      width: 50%;
    }
  `}
`;

export const Divider = styled.span`
  border-left: 1px solid ${({ theme }) => theme.colors.info.light};
  display: block;
  height: 60px;
`;
