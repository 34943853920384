import styled, { css } from 'styled-components';
import { ButtonHover } from '@/components/atoms/ButtonHover';

export const CreateAccountContainer = styled.div`
  ${({ isMobile }) => css`
    display: flex;
    flex-direction: column;
    gap: ${isMobile ? '25px' : '30px'};
    height: ${isMobile ? '100vh' : '521px'};
    overflow: hidden;
    padding: ${isMobile ? '0' : '0 1.25rem'};
    width: ${isMobile ? '100vw' : '341px'};
  `};
`;

export const CreateAccountBody = styled.div`
  ${({ isMobileLayout }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${isMobileLayout ? '3.75rem' : '1.875rem'};
    height: 100vh;
  `}
`;

export const TitleContainer = styled.div`
  text-align: center;
`;

export const ButtonHoverStyled = styled(ButtonHover)`
  height: 60px;
  width: 320px;
`;

export const SpanStyled = styled.span`
  text-decoration: underline;
  transition: opacity 0.2s;

  :hover {
    opacity: 0.8;
  }
`;
