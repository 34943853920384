import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import { useWalletData } from '@/context/walletContext';
import { TransactionButton } from '@/components/atoms/Buttons/TransactionButton/styles';
import { ButtonWrapper } from '@/components/atoms/Buttons/DepositButton/styles';

function WithdrawalButton({ handleClick, disabled }) {
  const {
    walletBalance,
    walletData: { transactionData, transactionLimits },
  } = useWalletData();

  const {
    max_amount_per_transaction: maxValue,
    min_amount_per_transaction: minValue,
  } = transactionLimits;

  const { amount } = transactionData;

  const isValidValue = amount && amount >= minValue && amount <= maxValue;
  const insufficientFund = amount > walletBalance;

  return (
    <ButtonWrapper>
      <TransactionButton
        type="button"
        disabled={disabled || !isValidValue || insufficientFund}
        isValid={isValidValue && !insufficientFund}
        onClick={handleClick}
        id="withdrawal-button"
      >
        {insufficientFund
          ? i18n.t('withdrawal.unavailableValue')
          : i18n.t('withdrawal.confirm')}
      </TransactionButton>
    </ButtonWrapper>
  );
}

WithdrawalButton.propTypes = {
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default WithdrawalButton;
