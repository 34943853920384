import React from 'react';
import PropTypes from 'prop-types';

import BonusIcon from '@/components/atoms/BonusIcon';
import i18n from '@/translate/i18n';
import { Title } from '@/components/atoms/Typography/styles';

import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { BonusItem, DateWrapper, IconWrapper } from './styles';

const ICON_SIZE = 40;

function BonusListItem({
  bonus,
  handleClick,
  isDefault,
  minimalVersion,
  selectedBonus,
  setShowModal,
}) {
  const isFreeBonus = !bonus?.min_amount_transaction && bonus?.type === 'fixed';

  const TYPE_BONUS = {
    'transfer-in': i18n.t('bonus.transactionType.transfer-in'),
    deposit: i18n.t('bonus.transactionType.deposit'),
  };

  return (
    <BonusItem
      key={bonus.id}
      onClick={() => {
        handleClick(bonus);
        setShowModal(true);
      }}
      selected={selectedBonus?.id === bonus?.id}
      type={isFreeBonus ? 'free' : bonus?.type}
    >
      {isDefault ? (
        <IconWrapper>
          <BonusIcon noBonus={isDefault} />
          <Title size="small">{i18n.t('bonus.proceedWithoutBonus')}</Title>
        </IconWrapper>
      ) : (
        <>
          <IconWrapper>
            <BonusIcon bonus={bonus} size={ICON_SIZE} />
            <div>
              <Title size="small" position="left" textColor="primary">
                {bonus?.title}
              </Title>
              {bonus?.transaction_type && !minimalVersion && (
                <p>
                  {i18n.t('bonusesAndPromotions.transactionType.bonusOf')}
                  {TYPE_BONUS[bonus.transaction_type] || ''}
                </p>
              )}
            </div>
          </IconWrapper>
          {!minimalVersion && (
            <DateWrapper>
              <TextButton>{i18n.t('buttonLabel.learnMore')}</TextButton>
            </DateWrapper>
          )}
        </>
      )}
    </BonusItem>
  );
}

BonusListItem.propTypes = {
  bonus: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    transaction_type: PropTypes.string,
    updated_at: PropTypes.string,
    isDefault: PropTypes.string,
    min_amount_transaction: PropTypes.number,
    merchant_ids: PropTypes.arrayOf(PropTypes.number),
  }),
  handleClick: PropTypes.func,
  isDefault: PropTypes.bool,
  minimalVersion: PropTypes.bool,
  selectedBonus: PropTypes.func,
  setShowModal: PropTypes.func,
};

export default BonusListItem;
