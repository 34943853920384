import { useState, useEffect } from 'react';

import { useWalletData } from '@/context/walletContext';
import { getUserLimitsFull } from '@/services/api';

const initialState = {
  min_amount_per_transaction: 0,
  max_amount_per_transaction: 0,
};

const useTransactionLimits = (
  transactionType,
  currency,
  methodSlug,
  merchantId,
) => {
  const [transactionLimits, setTransactionLimits] = useState(initialState);
  const [limitsError, setLimitsError] = useState(false);
  const [loadingLimits, setLoadingLimits] = useState(true);

  const { setWalletData } = useWalletData();

  useEffect(() => {
    const controller = new AbortController();

    const getTransactionLimits = async () => {
      try {
        setLoadingLimits(true);
        const params = {
          transaction_type: transactionType,
          currency,
          payment_method_slug: methodSlug,
          merchant_id: merchantId,
        };

        const res = await getUserLimitsFull(params, controller.signal);

        setTransactionLimits(res.data);
        setWalletData((state) => ({
          ...state,
          transactionLimits: res.data,
        }));
        setLimitsError(false);
      } catch (err) {
        setLimitsError(true);
      } finally {
        setLoadingLimits(false);
      }
    };

    getTransactionLimits();

    return () => controller.abort();
  }, [transactionType, currency, methodSlug, merchantId]);

  return { transactionLimits, limitsError, loadingLimits };
};

export default useTransactionLimits;
