import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Form } from 'formik';

export const FormStyled = styled(Form)`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: ${({ theme }) => theme.font.size.small};
  height: 100%;
  justify-content: space-around;
  max-width: 280px;

  p {
    text-align: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.font.size.xSmall};
  }
`;

export const LinkButton = styled(Link)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.info.dark};
  display: flex;
  font: ${({ theme }) =>
    `${theme.font.size.large} ${theme.font.family.primary}`};
  height: 60px;
  justify-content: center;
  margin-top: 15px;
  text-decoration: none;
  width: 320px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.font.size.medium};
    height: 40px;
    width: 250px;
  }
`;

export const InputWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
