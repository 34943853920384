export const colors = {
  black: '#000000',
  lightGreen: '#00ac33',
  pix: '#32bcad',
  red: '#ff2b2b',
  white: '#ffffff',
  cream: '#E1D9CD',
  greenVpag: '#A1E600',
  blue: {
    100: '#7db9ed',
    400: '#0986AD',
    500: '#247fd0',
    600: '#80bdff',
  },

  cyan: {
    300: '#00e2ba',
    600: '#00af8f',
  },

  dark: {
    200: '#868983',
    300: '#404040',
    400: '#333333',
    700: '#212323',
    800: '#191a1b',
  },

  lightBlue: {
    300: '#b5edff',
    400: '#6bdcff',
    500: '#21caff',
    600: '#6bdfdf',
  },

  darkGray: {
    100: '#6e757a',
    200: '#5f666a',
    300: '#5f666a',
    400: '#333434',
    450: '#b6b6b6',
    500: '#434546',
    600: '#373d3f',
    700: '#2b2d2e',
    800: '#242525',
    900: '#1d1d1b',
  },

  gray: {
    50: '#f7f7f7',
    100: '#e1d9cd',
    200: '#d8d8d8',
    300: '#646464',
    400: '#a9a9a9',
    500: '#888888',
    600: '#4b5258',
    700: '#393939',
    800: '#32373a',
  },

  green: {
    100: '#00ac33',
    200: '#008A71',
    300: '#a1e600',
    500: '#138334',
    600: '#00571a',
  },

  orange: {
    500: '#ff9900',
    600: '#b66d00',
  },

  yellowGreenDark: {
    100: '#a7d33b',
    200: '#9cc224',
    300: '#93b720',
    400: '#89ab1b',
    500: '#7f9f17',
    600: '#A1E600',
    800: '#617A13',
  },

  yellowGreenLight: {
    100: '#e6fc8b',
    200: '#dcfb76',
    300: '#d3fa61',
    400: '#c9f84b',
    500: '#a1e600',
  },
};

export const hexToRGBA = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha || 1})`;
};
