import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import useOnClickOutside from '@/hooks/useOnClickOutside';
import { Button, Wrapper } from './styles';

function PopupMenu({ options, setShowModal, style }) {
  const popupRef = useRef(null);
  useOnClickOutside(popupRef, setShowModal);

  return (
    <Wrapper ref={popupRef} style={style}>
      {options?.map(
        (el) =>
          !el?.disabled && (
            <Button type="button" onClick={el.action}>
              {el.label}
            </Button>
          ),
      )}
    </Wrapper>
  );
}

PopupMenu.propTypes = {
  options: PropTypes.array,
  setShowModal: PropTypes.func,
}.isRequired;

export default PopupMenu;
