import React from 'react';
import PropTypes from 'prop-types';

import DeskContainer from '@/components/atoms/DeskContainer/styles';
import Container from '@/components/atoms/Container/styles';
import TransferApiHeader from '@/components/organisms/TransferApiHeader';

import { ContentWrapper, InternalBox } from './styles';

function TransferApiTemplate({ children }) {
  return (
    <DeskContainer>
      <Container gradient justify="center">
        <InternalBox>
          <TransferApiHeader />
          <ContentWrapper>{children}</ContentWrapper>
        </InternalBox>
      </Container>
    </DeskContainer>
  );
}

TransferApiTemplate.propTypes = {
  children: PropTypes.element,
}.isRequired;

export default TransferApiTemplate;
