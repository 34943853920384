import styled, { css } from 'styled-components';
import { ReactComponent as BonusIcon } from '@/assets/bonusIcon.svg';
import vpag from '@/styles/themes/vpag';
import { ReactComponent as NoBonus } from '@/assets/noBonus.svg';

const ICON_DEFAULT_SIZE = 50;
const FONT_SIZE_FRACTION = 0.45;
const FONT_SIZE_FRACTION_ONLY_ICON = 0.75;

const COLOR_BY_BONUS_TYPE = {
  default: vpag.colors.bonus.free.icon,
  fixed: vpag.colors.bonus.fixed.icon,
  free: vpag.colors.bonus.free.icon,
  percentage: vpag.colors.bonus.percentage.icon,
};

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  span {
    font: ${({ theme }) =>
      `${theme.font.size.xSmall} ${theme.font.family.secondary}`};
  }
`;

export const Icon = styled.div`
  ${({ theme, size = ICON_DEFAULT_SIZE, type, onlyIcon }) => css`
    align-items: center;
    background: ${COLOR_BY_BONUS_TYPE[type ?? 'default']};
    border-radius: 50%;
    color: ${theme.colors.info.light};
    display: flex;
    font: ${size
        ? `${
            onlyIcon
              ? size * FONT_SIZE_FRACTION_ONLY_ICON
              : size * FONT_SIZE_FRACTION
          }px`
        : theme.font.size.large}
      ${theme.font.family.boldItalic};
    height: ${size}px;
    justify-content: center;
    min-width: ${size}px;
    overflow: hidden;
    width: ${size}px;
  `}
`;

const iconStyles = ({ size = ICON_DEFAULT_SIZE }) => css`
  height: ${size}px;
  min-width: ${size}px;
  width: ${size}px;
`;

export const NoBonusIcon = styled(NoBonus)`
  ${iconStyles}
`;

export const BonusIconStyled = styled(BonusIcon)`
  width: 40px;

  path {
    fill: ${({ theme }) => theme.colors.shadow};
  }

  rect {
    fill: ${({ color }) => color};
  }
`;
