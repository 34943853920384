import React from 'react';
import PropTypes from 'prop-types';
import HighlightedTitle from '@/components/v2/atoms/HighlightedTitle';
import i18n from '@/translate/i18n';
import TwText from '../../atoms/TwText';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import BackgroudFanDesktop from '@/assets/backgroudFanDesktop.svg';
import BackgroudFanMobile from '@/assets/backgroudFanMobile.svg';
import LogoVPag from '@/assets/vpagWalletLogo.svg';
import { TwModal } from '../../atoms/TwModal';
import TwButton from '@/components/atoms/Buttons/TwButton';
import TwTitle from '@/components/atoms/TwTitle';

const PromocodeBonusModalContent = ({
  isOpen = true,
  handleCloseModal,
  nameBonus,
}) => {
  const { isMobileLayout } = useDeviceWidth();
  return (
    <TwModal
      isOpen={isOpen}
      onClose={handleCloseModal}
      classList="size-full md:!w-[75%] md:h-[95%] 2xl:w-[66%] 2xl:h-[80%] relative"
      classNameStyleCloseButton="z-[99]"
      classListChildren="absolute md:bottom-0 size-full !pb-[104px] iphone-se:!pb-[40px] iphone-5:!pb-[5px] md:!pb-5 2xl:mb-0 flex items-center md:items-start flex-col justify-end md:justify-center gap-[50px] iphone-se:gap-[20px] md:gap-6 top-0"
    >
      <img
        className="absolute inset-0 size-full object-cover"
        src={isMobileLayout ? BackgroudFanMobile : BackgroudFanDesktop}
        alt="validate your data"
      />

      <div className="w-screen md:w-[80%] h-min flex flex-col gap-14 iphone-5:gap-1 md:gap-5 2xl:gap-y-12 z-[99] md:mt-20 md:ml-24 2xl:ml-32">
        {isMobileLayout ? (
          <div>
            <div className="bg-black">
              <TwTitle
                text={i18n.t(
                  'validateIdentity.popupBonus.ReceiveABonusOfMobile',
                )}
              />
            </div>
            <div className="bg-grass-800">
              <TwTitle text={nameBonus} color="black" />
            </div>
          </div>
        ) : (
          <>
            <img
              src={LogoVPag}
              alt="logo vpag"
              width={107}
              height={44}
              className="hidden md:block mt-4 z-[99] 2xl:-ml-5 md:-ml-6"
            />
            <div>
              <HighlightedTitle
                text={i18n.t('validateIdentity.popupBonus.ReceiveABonusOf')}
                className="w-[224px] md:w-max z-20 relative"
                classNameContainerText="md:!bg-black md:!p-0 md:!pb-[15px]"
                classNameText="md:text-white md:font-arboria-regular"
              />
              <HighlightedTitle
                text={i18n.t('validateIdentity.popupBonus.BonusOF')}
                className="w-[224px] md:w-max z-20 relative"
                classNameContainerText="md:!bg-black md:!p-0 md:!pb-[15px]"
                classNameText="md:text-white md:font-arboria-regular"
              />
              <HighlightedTitle
                text={nameBonus}
                className="w-[334px] md:w-max pr-0 md:pt-0 z-10 relative -mt-[15px] md:before:content-[''] md:before:block md:before:w-5 md:before:h-5 md:before:-ml-5 md:before:bg-grass-800"
                classNameText="font-bold"
              />
            </div>
          </>
        )}

        <TwText
          text={i18n.t('validateIdentity.popupBonus.instruction')}
          classList="!text-[22px] text-left w-[285px] m-auto md:mx-0 md:min-w-[433px] md:!mt-5"
        />
      </div>
      <TwButton
        height="secondary"
        label="Continuar"
        onClick={handleCloseModal}
        classList="!w-72 min-h-[54px] !rounded-[7.2px] !text-sm md:ml-24 2xl:ml-32 z-[99]"
      />
    </TwModal>
  );
};

PromocodeBonusModalContent.propTypes = {
  handleCloseModal: PropTypes.func,
  nameBonus: PropTypes.string,
  isOpen: PropTypes.bool,
};

export default PromocodeBonusModalContent;
