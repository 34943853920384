import React from 'react';
import PropTypes from 'prop-types';
import Webcam from 'react-webcam';

import ProfilePicDefault from '@/assets/userfull.svg';
import i18n from '@/translate/i18n';
import { UserProfilePic } from '@/components/molecules/ProfileHeader/styles';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';

import { Wrapper, ButtonsWraper } from './styles';

function WebcamCapture({
  handleCancel,
  handleCapture,
  handleSave,
  image,
  reset,
  webcamRef,
}) {
  return (
    <Wrapper>
      {image ? (
        <UserProfilePic
          alt="Profile Pic"
          src={URL.createObjectURL(image)}
          style={{ width: 150, height: 150 }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = ProfilePicDefault;
          }}
        />
      ) : (
        <Webcam
          width={280}
          height={280}
          screenshotFormat="image/png"
          ref={webcamRef}
          mirrored
        />
      )}
      <ButtonsWraper>
        <TextButton type="button" onClick={image ? handleSave : handleCapture}>
          {image ? i18n.t('actions.save') : i18n.t('actions.capture')}
        </TextButton>
        {image && (
          <TextButton type="button" onClick={reset}>
            {i18n.t('actions.tryAgain')}
          </TextButton>
        )}
        <TextButton type="button" onClick={handleCancel}>
          {i18n.t('actions.cancel')}
        </TextButton>
      </ButtonsWraper>
    </Wrapper>
  );
}

WebcamCapture.propTypes = {
  webcamRef: PropTypes.string,
}.isRequired;

export default WebcamCapture;
