import styled from 'styled-components';

export const ContainerInputCopy = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  display: flex;
  height: 50px;
  justify-content: center;
  padding: 0 20px;

  button {
    background-color: transparent;
    border: none;
    height: fit-content;
    width: fit-content;

    :hover {
      opacity: 0.5;
    }
  }
`;

export const CopySpan = styled.span`
  color: ${({ theme }) => theme.colors.greenVpag};
  font-size: 16px;
  font-weight: 700;
  margin-right: 10px;
  width: 100%;
  text-align: center;
`;
export const InputStyled = styled.input`
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  margin-right: 10px;
  text-align: center;
  width: 100%;
`;
