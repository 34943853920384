import React from 'react';
import PropTypes from 'prop-types';

import { Trans } from 'react-i18next';
import i18n from '@/translate/i18n';
import Banner from '@/assets/bannerPixCashback.svg';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import TitleModal from '@/components/molecules/TitleModal';
import TwTextButton from '@/components/atoms/Buttons/TwTextButton';

import {
  CashBackPixBody,
  ContainerCashbackPix,
  OptionsCashbackContainer,
  ParagraphContainer,
} from './style';

function CashbackPix({
  merchantName,
  children,
  handleCloseModal,
  handleOpenModalTutorial,
}) {
  const { isMobileLayout } = useDeviceWidth();

  return (
    <ContainerCashbackPix isMobileLayout={isMobileLayout}>
      <div className="block w-full md:hidden">
        <TitleModal handleBackStep={handleCloseModal} />
      </div>
      <img
        src={Banner}
        className={`${
          isMobileLayout ? 'object-fill h-auto' : 'w-full'
        } md:rounded-t-2xl md:mt-16 `}
        alt="banner vpag"
      />
      <CashBackPixBody className="md:pb-28 ">
        <Title size="medium" className="text-2xl text-white">
          <Trans i18nKey="cashback.seeCashback" values={{ merchantName }} />
        </Title>
        <ParagraphContainer>
          <Paragraph size="medium" textAlign="center">
            <Trans i18n={i18n} i18nKey="cashback.clickMoreInfo" />
          </Paragraph>
        </ParagraphContainer>
        <OptionsCashbackContainer className=" w-full flex justify-center gap-7 md:gap-14  ">
          {children}
        </OptionsCashbackContainer>
        <TwTextButton
          label={<Trans i18n={i18n} i18nKey="cashback.seeTutorial" />}
          onClick={handleOpenModalTutorial}
        />
      </CashBackPixBody>
    </ContainerCashbackPix>
  );
}

CashbackPix.propTypes = {
  merchantName: PropTypes.string,
  children: PropTypes.node,
  handleCloseModal: PropTypes.func,
  handleOpenModalTutorial: PropTypes.func,
}.isRequired;
export default CashbackPix;
