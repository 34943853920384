import styled from 'styled-components';

export const SdkIframe = styled.iframe`
  border: none;
  height: 100%;
  width: 100%;
`;

export const ContentWrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  justify-content: space-evenly;
  width: 100%;
`;
