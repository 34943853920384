import styled, { css } from 'styled-components';

import { ReactComponent as ArrowIcon } from '@/assets/simpleArrow.svg';
import { HoverEffect } from '@/components/atoms/HoverEffect/styles';
import { hexToRGBA } from '@/styles/colors';
import vpag from '@/styles/themes/vpag';

const COLOR_BY_BONUS_TYPE = {
  default: vpag.colors.bonus.free.title,
  fixed: vpag.colors.bonus.fixed.title,
  free: vpag.colors.bonus.free.title,
  percentage: vpag.colors.bonus.percentage.title,
};

export const BonusItem = styled.div`
  ${({ theme, selected, type }) => css`
    ${HoverEffect}
    align-items: center;
    color: ${theme.colors.info.light};
    cursor: pointer;
    display: flex;
    font-size: ${theme.font.size.medium};
    justify-content: space-between;
    padding: 0.8rem 0.3rem;
    position: relative;
    width: 100%;
    border-bottom: 1px solid ${hexToRGBA(theme.colors.info.primary, 0.2)};

    div {
      display: flex;
      flex-direction: column;
    }

    span {
      color: ${COLOR_BY_BONUS_TYPE[type ?? 'default']};
      font: ${`${theme.font.size.small} ${theme.font.family.secondary}`};
      text-transform: uppercase;
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      background: ${selected &&
      ` linear-gradient(
        90deg,
        ${hexToRGBA(theme.colors.light, 0.001)} 0%,
        ${hexToRGBA(theme.colors.light, 0.05)}44.35%,
        ${hexToRGBA(theme.colors.light, 0.05)}63.02%,
        ${hexToRGBA(theme.colors.light, 0.001)} 100%
      )`};
      font-size: ${theme.font.size.small};
      max-width: 380px;
      padding-right: 0;
    }
  `}
`;

export const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row !important;
  gap: 1rem;

  p {
    ${({ theme }) => css`
      color: ${theme.colors.info.title};
      font: ${`${theme.font.size.xSmall} ${theme.font.family.secondary}`};
    `};
  }
`;

export const ArrowButton = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.button.active};
  border-radius: 37px;
  display: flex;
  height: 27px;
  justify-content: center;
  position: absolute;
  right: 0;
  width: 27px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none !important;
  }
`;

export const Arrow = styled(ArrowIcon)`
  fill: ${({ theme }) => theme.colors.light};
`;

export const DateWrapper = styled.div`
  align-items: end;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.font.size.xSmall} !important;
  font-weight: 600;

  span {
    color: ${({ theme }) => theme.colors.info.title};
    font-size: ${({ theme }) => theme.font.size.xSmall};
    text-transform: none;
  }
`;
