import { useState } from 'react';

import { listMerchants } from '@/services/api';
import { useWalletData } from '@/context/walletContext';

const PAYLOAD = { without_pagination: true, active: true };

const useAllMerchants = () => {
  const { setWalletData } = useWalletData();

  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(false);

  const getAllMerchants = async () => {
    try {
      setIsFetching(true);
      const res = await listMerchants(PAYLOAD);

      setWalletData((prevState) => ({ ...prevState, allMerchants: res.data }));

      localStorage.setItem('merchants', JSON.stringify(res.data));
    } catch (err) {
      setError(true);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    error,
    isFetching,
    getAllMerchants,
  };
};

export default useAllMerchants;
