import styled from 'styled-components';

const ContentWrapper = styled.div`
  align-items: center;
  animation: goLeftDefault 0.5s;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  overflow-x: hidden;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: center;
    animation: none;
    display: grid;
    grid-template-columns: ${({ isLoading }) =>
      isLoading ? '1fr' : '47% 1% 47%'};
    max-height: 1080px;
    padding: 4vh 0;
  }
`;

export default ContentWrapper;
