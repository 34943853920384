import { v4 as uuidv4 } from 'uuid';

import { VPAG_STORE_URL } from '@/helpers/constants';
import i18n from '@/translate/i18n';

export const carouselData = [
  {
    id: uuidv4(),
    imagePath: '/images/carousel/home/1.png',
    imageAlt: i18n.t('carousel.home.alts.1'),
    textContent: i18n.t('carousel.home.titles.1'),
    buttonId: 'banner1-store-link',
    buttonLabel: i18n.t('carousel.home.buttons.1'),
    handleClick: () => {
      const storeToken = JSON.parse(localStorage.getItem('store_token'));
      window.open(`${VPAG_STORE_URL}${storeToken ?? ''}`);
    },
  },
  {
    id: uuidv4(),
    imagePath: '/images/carousel/home/2.png',
    imageAlt: i18n.t('carousel.home.alts.2'),
    textContent: i18n.t('carousel.home.titles.2'),
    buttonId: 'banner2-vplus-club',
    buttonLabel: i18n.t('carousel.home.buttons.2'),
    handleClick: () => {},
    buttonPath: '/club-vplus',
  },
];
