import styled from 'styled-components';

import { ReactComponent as LogoImage } from '@/assets/vpagLogo.svg';
import { ReactComponent as SmsIcon } from '@/assets/sms-tracking.svg';

export const ContentWrapper = styled.div`
  align-items: center;
  animation: goDownAndAppear 0.6s forwards;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 2rem 0;
  width: 100%;
`;

export const Footer = styled.footer`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 100%;
`;

export const Logo = styled(LogoImage)`
  fill: ${({ theme }) => theme.colors.primary};
  height: 8vh;
  margin-top: 3vh;
  max-height: 90px;
  max-width: 90px;
  min-height: 50px;
`;

export const SmsImage = styled(SmsIcon)``;

export const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 200px;
  text-align: center;
`;
