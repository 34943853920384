import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;s
    padding-bottom: 2rem;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      align-items: center;
      column-gap: 2%;
      display: grid;
      grid-template-columns: 47% 1% 47%;
      height: 100%;
      justify-items: center;
      padding: 2rem 0;
    }
  `}
`;

export const Section = styled.section`
  ${({ hideInMobile, theme }) => css`
    align-items: center;
    display: ${hideInMobile ? 'none' : 'flex'};
    flex-direction: column;
    gap: 5vh;
    justify-content: space-between;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      animation: none;
      display: flex;
      height: 100%;
      justify-content: space-between;
      max-height: 600px;
    }
  `}
`;

export const SwitchButtonWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.dark};
  bottom: 0;
  display: flex;
  min-width: 65%;
  position: fixed;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const AmountResume = styled.div`
  ${({ theme }) => css`
    display: none;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      align-items: center;
      color: ${theme.colors.info.light};
      display: flex;
      flex-direction: column;
      font-size: ${theme.font.size.medium};
      text-align: center;

      strong {
        color: ${theme.colors.primary};
        font: ${`${theme.font.size.xLarge} ${theme.font.family.secondary}`};
        text-transform: capitalize;
      }
    }
  `}
`;

export const LinksWrapper = styled.div`
  align-items: center;
  bottom: 12%;
  display: ${({ showInMobile }) => (showInMobile ? 'flex' : 'none')};
  text-align: center;

  a:nth-child(2) {
    &:before {
      color: ${({ theme }) => theme.colors.info.light};
      content: '|';
      margin: 0 0.5rem;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
  }
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    a:last-of-type {
      display: none;
    }
  }
`;

export const HiddenWrapper = styled.div`
  display: ${({ hideInMobile }) => (hideInMobile ? 'none' : 'block')};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: ${({ hideInDesktop }) => (hideInDesktop ? 'none' : 'block')};
  }
`;
