import styled from 'styled-components';

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.info.primary};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: 500;
  margin-top: 18.75rem;
  text-align: center;
  width: 300px;
`;
