import React from 'react';

import { Logo, Wrapper } from './styles';

function TransferApiHeader() {
  return (
    <Wrapper>
      <Logo />
    </Wrapper>
  );
}

export default TransferApiHeader;
