import React from 'react';

import { Paragraph } from '@/components/atoms/Typography/styles';
import { useWalletData } from '@/context/walletContext';
import { getMinimumAmountRequired } from '@/helpers/functions';
import { formatCurrency } from '@/helpers/stringFormat';
import i18n from '@/translate/i18n';

function MinMaxAmountMessage() {
  const {
    walletData: {
      selectedWallet,
      selectedBonus,
      transactionData,
      transactionLimits,
    },
  } = useWalletData();

  const {
    max_amount_per_transaction: maxValue,
    min_amount_per_transaction: minValue,
  } = transactionLimits;

  const { amount } = transactionData;
  const minimumAmount = getMinimumAmountRequired(minValue, selectedBonus);
  const isValueAboveMaximum = amount > maxValue;

  return (
    <div>
      {isValueAboveMaximum ? (
        <Paragraph color="gray">
          *
          {`${i18n.t('deposit.maxValue')} ${formatCurrency(
            maxValue,
            selectedWallet,
            true,
          )}`}
        </Paragraph>
      ) : (
        <Paragraph color="primary">
          *
          {`${i18n.t('deposit.minValue')} ${formatCurrency(
            minimumAmount,
            selectedWallet,
            true,
          )}`}
        </Paragraph>
      )}
    </div>
  );
}

export default MinMaxAmountMessage;
