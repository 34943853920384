import React, { useState, useEffect } from 'react';

import ConfirmConversion from '@/components/molecules/Modal/Messages/ConfirmConversion';
import CurrencySelection from '@/components/molecules/CurrencySelection';
import ExchangeRate from '@/components/atoms/ExchangerRate';
import InputTransactionValue from '@/components/atoms/Inputs/InputTransactionValue';
import Loading from '@/components/atoms/Loading';
import MainTemplate from '@/components/templates/MainTemplate';
import Modal from '@/components/molecules/Modal';
import TransactionError from '@/components/molecules/TransactionError';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import useConversion from '@/hooks/useConversion';
import useTransactionLimits from '@/hooks/useTransactionLimits';
import { ContentWrapper } from '@/components/atoms/GenerateTransactionStyle/styles';
import { formatCurrency } from '@/helpers/stringFormat';
import { useWalletData } from '@/context/walletContext';

import { ConversionButton, MobileContent } from './styles';
import TwTitle from '@/components/atoms/TwTitle';

function Conversion() {
  const {
    walletBalance,
    setWalletData,
    walletData: { selectedWallet, wallets, transactionData },
  } = useWalletData();

  const [submitError, setSubmitError] = useState('');
  const { isMobileLayout } = useDeviceWidth();

  const [showModal, setShowModal] = useState(false);
  const [toCurrency, setToCurrency] = useState(
    Object.keys(wallets).filter((el) => el !== selectedWallet)[0],
  );
  const { exchangeData, exchangeError } = useConversion(
    selectedWallet,
    toCurrency,
    'conversion',
  );

  const { amount } = transactionData;

  const { limitsError, transactionLimits, loadingLimits } =
    useTransactionLimits('conversion', selectedWallet);

  const {
    max_amount_per_transaction: maxValue,
    min_amount_per_transaction: minValue,
  } = transactionLimits;

  const isValidValue = amount >= minValue && amount <= maxValue && amount > 0;

  const insufficientFund = amount > walletBalance;

  useEffect(() => {
    setToCurrency(
      Object.keys(wallets).filter((el) => el !== selectedWallet)[0],
    );
  }, [selectedWallet, wallets]);

  const toAmount =
    exchangeData?.from_amount > exchangeData?.to_amount
      ? amount / exchangeData?.spread_rate
      : amount * exchangeData?.spread_rate;

  useEffect(
    () => () => {
      setWalletData((prevState) => ({
        ...prevState,
        transactionData: { amount: 0 },
      }));
    },
    [setWalletData],
  );

  return (
    <MainTemplate
      headerProps={{
        returnTo: '/wallet',
        showAvailableBalance: true,
        title: i18n.t('conversion.title'),
      }}
    >
      {!loadingLimits &&
      !limitsError &&
      !exchangeError &&
      !submitError.length ? (
        <ContentWrapper>
          {maxValue ? (
            <>
              <TwTitle
                id="conversion-title"
                size="xl"
                font="arboriaBold"
                color="shadow-500"
                classList="text-center"
              >
                {isMobileLayout
                  ? i18n.t('conversion.descriptionMobile')
                  : i18n.t('conversion.descriptionDesktop')}
              </TwTitle>
              <MobileContent>
                <CurrencySelection variant="large" />
              </MobileContent>

              <ExchangeRate
                handleChange={setToCurrency}
                rate={exchangeData?.spread_rate}
              />

              <InputTransactionValue
                content={i18n.t('conversion.youWillConvert')}
                insufficientFund={insufficientFund}
                maxValue={maxValue}
                minValue={minValue}
                value={amount}
                transactionType="conversion"
              />

              <ConversionButton
                type="button"
                disabled={!isValidValue || insufficientFund}
                isValid={isValidValue && !insufficientFund}
                onClick={setShowModal}
                id="conversion-button"
              >
                {!amount
                  ? i18n.t('conversion.enterValue')
                  : amount <= minValue && !insufficientFund
                  ? `${i18n.t('conversion.minValue')} ${minValue}`
                  : amount >= maxValue && !insufficientFund
                  ? `${i18n.t('conversion.maxValue')} ${maxValue}`
                  : maxValue === 0 && !insufficientFund
                  ? i18n.t('error.userLimits.limitUnvailable')
                  : insufficientFund
                  ? i18n.t('conversion.unavailableValue')
                  : `${i18n.t('conversion.confirm')} ${formatCurrency(
                      toAmount,
                      toCurrency,
                      true,
                    )}`}
              </ConversionButton>
            </>
          ) : (
            <TransactionError
              content={i18n.t('error.conversion.limitUnvailable')}
              height="100%"
              showInstructions={1}
            />
          )}
        </ContentWrapper>
      ) : limitsError || exchangeError || submitError ? (
        <TransactionError
          content={submitError || i18n.t('error.conversion.limitRequestError')}
          height="80%"
          showInstructions={false}
        />
      ) : (
        <Loading />
      )}
      <Modal showModal={showModal} setShowModal={setShowModal} height="190px">
        <ConfirmConversion
          setShowModal={setShowModal}
          amount={amount}
          toCurrency={toCurrency}
          fromCurrency={selectedWallet}
          rate={exchangeData}
          setSubmitError={setSubmitError}
        />
      </Modal>
    </MainTemplate>
  );
}

export default Conversion;
