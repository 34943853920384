import styled, { css } from 'styled-components';

import { Button } from '@/components/atoms/Buttons/Button/styles';

export const TransactionButton = styled(Button)`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    color: ${theme.colors.info.dark};
    font-family: ${theme.font.family.secondary};

    &:disabled {
      background-color: ${theme.colors.button.disabled};
      cursor: not-allowed;
      opacity: 1;
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      width: 26vw;
    }

    @media screen and (min-width: ${theme.breakpoints.md}) {
      width: 13vw;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-self: center;
    margin-bottom: 0.8rem;
    width: 26vw;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 20vw;
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.info.light};
  font-size: ${({ theme }) => theme.font.size.small};
  position: absolute;
`;

export const Span = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;
