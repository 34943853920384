import i18n from '@/translate/i18n';

const sofContentByLevel = {
  default: {
    content: '',
  },
  underLevel: {
    content: i18n.t('profile.sof.availableForLevelOneUsers'),
    buttonLabel: i18n.t('profile.increaseLimits'),
    color: 'shadow-600',
  },
  null: {
    content: i18n.t('profile.sof.unavailable'),
    buttonLabel: i18n.t('profile.register'),
    color: 'shadow-600',
  },
  completed: {
    content: i18n.t('profile.sof.inValidation'),
  },
  validated: {
    content: i18n.t('profile.sof.validated'),
    buttonLabel: i18n.t('profile.sof.new'),
  },
  rejected: {
    content: i18n.t('profile.sof.refused'),
    buttonLabel: i18n.t('profile.register'),
    color: 'cherryRed',
  },
};

export default sofContentByLevel;
