import { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const SIZE_MOBILE = 768;

const useShowApresentationSystem = () => {
  const history = useHistory();
  const { pathname, state } = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (state && state.showCarroucelMobile) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [state]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (state) {
        history.push(`${pathname}`, { showCarroucelMobile: false });
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [state]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = useMemo(() => windowWidth <= SIZE_MOBILE, [windowWidth]);

  return [isVisible, isMobile];
};

export default useShowApresentationSystem;
