import styled from 'styled-components';

import { ReactComponent as VPagLogo } from '@/assets/vpagLogoHorizontal.svg';

export const LogoWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Logo = styled(VPagLogo)`
  fill: ${({ theme }) => theme.colors.primary};
  height: 80px;
  width: 155px;

  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;
