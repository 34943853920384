import { colors, hexToRGBA } from '../colors';

const vpag = {
  title: 'VPag',

  colors: {
    blue: colors.blue[600],
    danger: colors.red,
    dark: colors.darkGray[900],
    lightgray: colors.gray[50],
    light: colors.white,
    primary: colors.yellowGreenLight[500],
    primaryDark: colors.yellowGreenDark[600],
    secondary: colors.darkGray[900],
    highlight: colors.yellowGreenDark[500],
    shadow: colors.black,
    pix: colors.pix,
    contrast: colors.yellowGreenDark[500],
    pending: colors.blue[100],
    autoCompleteBg: colors.dark[800],
    cream: colors.cream,
    greenVpag: colors.greenVpag,
    white: colors.white,
    divider: {
      light: colors.gray[100],
      base: colors.gray[500],
      dark: colors.gray[800],
    },

    sidebar: {
      bg: colors.dark[800],
      text: colors.gray[400],
      active: colors.white,
    },

    bg: {
      primary: colors.yellowGreenLight[500],
      white: colors.white,
      secondary: colors.gray[800],
      dark: colors.darkGray[900],
      darkSecondary: colors.dark[800],
      lightDark: colors.darkGray[300],
      light: colors.yellowGreenLight[100],
      tertiary: colors.gray[800],
      input: colors.dark[400],
    },

    logo: {
      primary: colors.yellowGreenLight[500],
      secondary: colors.darkGray[900],
    },

    info: {
      danger: colors.red,
      primary: colors.gray[100],
      secondary: colors.yellowGreenLight[500],
      secondaryLight: colors.yellowGreenLight[100],
      secondaryDark: colors.yellowGreenDark[600],
      light: colors.white,
      gray: colors.gray[600],
      dark: colors.darkGray[900],
      inactive: colors.gray[600],
      title: hexToRGBA(colors.gray[100], 0.75),
      points: colors.lightBlue[500],
      percentage: colors.green[200],
      free: colors.yellowGreenDark[800],
      fixed: colors.orange[600],
      subtitle: colors.gray[100],
    },

    bonus: {
      primary: colors.yellowGreenLight[500],
      secondary: colors.lightBlue[400],
      fixed: {
        icon: colors.orange[600],
        title: colors.orange[500],
      },
      percentage: {
        icon: colors.cyan[600],
        title: colors.cyan[300],
      },
      free: {
        icon: colors.yellowGreenDark[800],
        title: colors.yellowGreenLight[500],
      },
    },

    button: {
      primary: colors.yellowGreenLight[500],
      secondary: colors.darkGray[900],
      active: colors.gray[600],
      disabled: colors.dark[200],
      inactive: colors.gray[800],
      dark: colors.black,
    },

    toggle: {
      primary: colors.yellowGreenDark[100],
      light: colors.yellowGreenLight[100],
      secondary: colors.yellowGreenDark[500],
      dark: colors.gray[600],
    },

    datePicker: {
      highlight: colors.yellowGreenDark[500],
    },

    bgGradient: {
      start: colors.gray[600],
      end: colors.gray[800],
    },
    bgGradientLight: {
      start: colors.darkGray[100],
      end: colors.gray[600],
    },
    bgGradientDark: {
      start: colors.darkGray[700],
      end: colors.darkGray[800],
    },
    bgGradientTransfer: {
      start: colors.darkGray[500],
      end: colors.darkGray[400],
    },
    bgGradientSettings: {
      start: colors.darkGray[900],
      end: colors.darkGray[600],
    },
  },

  font: {
    family: {
      primary: 'Arboria Book, sans-serif',
      secondary: 'Arboria Bold, sans-serif',
      tertiary: 'Arboria Light, sans-serif',
      boldItalic: 'Arboria Bold Italic, sans-serif',
    },
    size: {
      xxSmall: '0.563rem', // 9px
      xSmall: '0.75rem', // 12px
      small: '0.875rem', // 14px
      base: '1rem', // 16px
      medium: '1.125rem', // 18px
      large: '1.5rem', // 24px
      xLarge: '1.875rem', // 30px
      xxLarge: '2.25rem', // 36px
      xxxLarge: '2.75rem', // 48px

      xSmallCalc: 'calc(0.875em + 0.5vh)',
      smallCalc: 'calc(1em + 0.2vh)',
      mediumCalc: 'calc(1em + 0.5vh)',
    },
  },

  breakpoints: {
    xs: '320px',
    sm: '750px',
    md: '1024px',
    lg: '1250px',
    xl: '1550px',
  },
};

export default vpag;
