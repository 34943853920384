import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as UploadIcon } from '@/assets/uploadIcon.svg';

import Modal from '@/components/molecules/Modal';
import Warning from '@/components/molecules/Modal/Messages/Warning';
import i18n from '@/translate/i18n';

import Wrapper from './styles';

function DragAndDrop({
  accept,
  acceptedFiles,
  hideInput,
  inputRef,
  multiple,
  onChange,
}) {
  const [showModal, setShowModal] = useState(false);
  const preventDefault = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.target.files[0];

    const fileExt = file?.type?.split('/')[1];

    if (acceptedFiles?.length && !acceptedFiles.includes(fileExt)) {
      setShowModal(true);
      onChange('');
    } else {
      onChange(file);
    }
  };

  return (
    <Wrapper
      htmlFor="input-file"
      onDragEnter={preventDefault}
      onDragLeave={preventDefault}
      onDragOver={preventDefault}
      onDrop={fileDrop}
      ref={inputRef}
      hideInput={hideInput}
    >
      <UploadIcon />

      <input
        id="input-file"
        type="file"
        multiple={multiple}
        onChange={fileDrop}
        accept={accept}
      />
      <Modal
        setShowModal={setShowModal}
        showModal={showModal}
        button="Entendido"
        height="250px"
      >
        <Warning
          title={i18n.t('dragAndDrop.invalidFile.title')}
          message={i18n.t('dragAndDrop.invalidFile.message')}
        />
      </Modal>
    </Wrapper>
  );
}

DragAndDrop.propTypes = {
  acceptedFiles: PropTypes.arrayOf(PropTypes.string),
  accept: PropTypes.string,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  hideInput: PropTypes.bool,
  inputRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
}.isRequired;

export default DragAndDrop;
