import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { colors } from '@/styles/colors';

export const Nav = styled.nav`
  align-items: center;
  border: solid 1px ${({ theme }) => theme.colors.primaryDark};
  border-radius: 32px;
  display: flex;
  max-height: 30px;
  overflow: hidden;
  width: 250px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: ${({ hideInDesktop }) => (hideInDesktop ? 'none' : 'flex')};
    margin: 0;
  }
`;

export const NavItem = styled(Link)`
  ${({ theme, active }) => css`
    align-items: center;
    background-color: ${active ? theme.colors.primaryDark : 'transparent'};
    border: 1px solid ${active ? theme.colors.primaryDark : 'transparent'};
    border-radius: ${active ? '32px' : '0'};
    color: ${active ? theme.colors.shadow : theme.colors.info.light};
    display: flex;
    flex: 1;
    font-family: ${active
      ? theme.font.family.secondary
      : theme.font.family.primary};
    font-size: ${theme.font.size.base};
    justify-content: center;
    padding: 0 0.5rem;
    height: 30px;
    position: relative;
    text-decoration: none;
    text-transform: capitalize;
    transition: 0.5s;
  `}
`;

export const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
`;

export const NavItemFilled = styled(NavItem)`
  ${({ theme, active, index }) => css`
    background-color: ${active ? theme.colors.primary : theme.colors.info.gray};
    border-radius: ${index ? '0 5px 5px 0' : '5px 0 0 5px'};
    color: ${active ? theme.colors.dark : theme.colors.info.primary};
    font-family: ${theme.font.family.secondary};
    height: ${active ? '24px' : '22px'};
    padding: 0 1rem;
  `}
`;

export const ProgressBar = styled.div`
  background-color: ${colors.darkGray[600]};
  height: 3px;
  width: 100%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0%, -50%);
    background-color: ${({ theme }) => theme.colors.primaryDark};
    height: 3px;
    width: ${({ progress }) => `${progress || 0}%`};
    border-radius: 3px;
  }
`;
