import React from 'react';
import PropTypes from 'prop-types';

import { Icon, InputBar, Wrapper } from './styles';

function SearchBar({ handleChange, placeholder }) {
  return (
    <Wrapper>
      <InputBar
        name="search-bar"
        onChange={({ target: t }) => handleChange(t.value)}
        placeholder={placeholder}
      />
      <Icon />
    </Wrapper>
  );
}

SearchBar.propTypes = {
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
}.isRequired;

export default SearchBar;
