import styled, { css } from 'styled-components';

import { ReactComponent as ArrowIcon } from '@/assets/simpleArrow.svg';
import { hexToRGBA } from '@/styles/colors';
import { HoverScrollBar } from '../ScrollBar/styles';

const NavLinkStyles = ({
  borders,
  disabled,
  highlight,
  isCollapsed,
  theme,
}) => css`
  align-items: center;
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
  display: flex;
  filter: ${disabled ? 'grayscale(100%)' : 'none'};
  justify-content: ${isCollapsed ? 'center' : 'flex-start'};
  max-width: 768px;
  opacity: ${disabled ? 0.6 : 1};
  padding: 0.7rem 0.5rem;
  position: relative;
  width: 100%;

  svg {
    opacity: ${disabled ? 0.6 : 1};
  }

  &:hover {
    background: ${!disabled &&
    `radial-gradient(
      circle,
      ${hexToRGBA(theme.colors.info.gray, 0.05)} 0%,
      ${hexToRGBA(theme.colors.info.gray, 0.5)} 0%,
      ${hexToRGBA(theme.colors.info.gray, 0.05)} 100%
    )`};
  }

  &:not(:last-child) {
    border-bottom: ${borders && `1px solid ${theme.colors.info.gray}`};
  }

  &:nth-last-child(2) {
    border: none;
  }

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    background: ${highlight &&
    `linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 13.54%,
      rgba(0, 0, 0, 0.1) 86.98%,
      rgba(0, 0, 0, 0) 100%
    )`};
  }
`;

export const MenuModalContainer = styled.div`
  align-items: center;
  animation: ${({ showMenu }) =>
    showMenu ? ` goDownMenuModal` : ` goUpMenuModal forwards`};
  animation-duration: ${({ hideAnimation }) => (hideAnimation ? '0' : '0.5s')};
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.colors.bgGradientSettings.start} 0%, ${theme.colors.bgGradientSettings.end} 100%)`};
  box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 3vh;
  justify-content: flex-start;
  left: 0;
  min-height: 100vh;
  padding: ${({ padding }) => padding || 0};
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99999;
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  left: ${({ left }) => left && '20px'};
  position: absolute;
  right: ${({ left }) => !left && '20px'};
  top: 20px;
`;

export const NavLinkWrapper = styled.nav`
  ${NavLinkStyles}
`;

export const LogoutWrapper = styled.div`
  align-items: center;
  border-top: ${({ theme }) =>
    `1px solid ${hexToRGBA(theme.colors.info.primary, 0.1)}`};
  display: flex;
  max-width: 768px;
  width: 100%;

  & > nav {
    padding-top: 1rem;
  }
`;

export const TextWrapper = styled.div`
  display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'flex')};
  flex-direction: column;
  margin: 0 20px;
`;

export const Text = styled.p`
  ${({ theme, highlight }) => css`
    color: ${highlight
      ? theme.colors.sidebar.active
      : theme.colors.sidebar.text};
    font: ${theme.font.size.medium}
      ${highlight ? theme.font.family.secondary : theme.font.family.primary};

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      font: ${highlight
        ? `${theme.font.size.small} ${theme.font.family.secondary}`
        : `${theme.font.size.small} ${theme.font.family.primary}`};
    }
  `}
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font: ${({ theme }) =>
    `${theme.font.size.small} ${theme.font.family.primary}`};
  gap: 1rem;
  padding: 1rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    ${HoverScrollBar}
    gap: 0;
    margin: 5vh 0 0;
    overflow-y: auto;
    padding: 0;
  }
`;

export const ArrowButton = styled.div`
  border-radius: 50%;
  display: grid;
  background-color: ${({ theme }) => theme.colors.button.active};
  height: 27px;
  place-content: center;
  position: absolute;
  right: 0;
  width: 27px;
`;

export const Arrow = styled(ArrowIcon)`
  path {
    fill: ${({ theme }) => theme.colors.light};
  }
`;
