import styled, { css } from 'styled-components';

const DEFAULT_SIZE = 18;
const WIDTH_MULTIPLIER = 2;

export const ToggleSwitchWrapper = styled.div`
  position: relative;
  display: grid;
  place-content: center;
`;

export const ToggleSwitchLabel = styled.label`
  ${({
    theme,
    disabled,
    size = DEFAULT_SIZE,
    position = 'absolute',
    top = 0,
  }) => css`
    background: ${theme.colors.toggle.dark};
    border-radius: ${`${size}px`};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    height: ${size}px;
    left: 0;
    position: ${position};
    top: ${top};
    width: ${WIDTH_MULTIPLIER * size}px;

    &::after {
      background: ${theme.colors.button.disabled};
      border-radius: 50%;
      box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
      content: '';
      display: block;
      height: ${size}px;
      transition: 0.2s;
      width: ${size}px;
    }
  `}
`;

export const ToggleSwitch = styled.input`
  ${({ theme, size = DEFAULT_SIZE }) => css`
    border-radius: 50%;
    height: ${size}px;
    opacity: 0 !important;
    width: ${WIDTH_MULTIPLIER * size}px;
    z-index: 1;

    &:checked + ${ToggleSwitchLabel} {
      background: ${theme.colors.toggle.dark};

      &::after {
        background: ${theme.colors.primary};
        border-radius: 50%;
        content: '';
        display: block;
        height: ${size - 1}px;
        margin-left: ${size}px;
        transition: 0.2s;
        width: ${size - 1}px;
      }
    }
  `}
`;
