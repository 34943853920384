import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Loading from '@/components/atoms/Loading';
import { Paragraph } from '@/components/atoms/Typography/styles';
import PromotionCard from '@/components/molecules/PromotionCard';
import { useWalletData } from '@/context/walletContext';
import { getFirstCategoryWithActivePromotion } from '@/helpers/functions';
import usePromotions from '@/hooks/usePromotions';
import i18n from '@/translate/i18n';
import { Nav, NavItem, PromotionsWrapper, Wrapper } from './styles';
import TwTitle from '@/components/atoms/TwTitle';
import useDeviceWidth from '@/hooks/useDeviceWidth';

const CATEGORIES = ['vpag', 'sports', 'casino'];

function PromotionList({ setTermsSlug }) {
  const [selectedCategory, setSelectedCategory] = useState(CATEGORIES[0]);
  const { isFetching, error } = usePromotions();
  const { isMobileLayout } = useDeviceWidth();
  const {
    walletData: { promotions },
  } = useWalletData();

  useEffect(() => {
    if (promotions?.length) {
      const initialSelectedCategory = getFirstCategoryWithActivePromotion(
        promotions,
        CATEGORIES,
      );
      setSelectedCategory(initialSelectedCategory);
    }
  }, [promotions]);

  const mapList = promotions.filter(
    ({ category }) => category === selectedCategory,
  );
  const categoriesThatWasPromo = CATEGORIES.filter((slug) =>
    promotions.find(({ category }) => category === slug),
  );

  return (
    <Wrapper>
      <TwTitle
        text={i18n.t('bonusesAndPromotions.promotions')}
        size={isMobileLayout ? 'm_lg' : 'd_lg'}
        font="arboriaBold"
        classList="text-center"
      />
      {isFetching ? (
        <Loading />
      ) : error ? (
        <Paragraph textAlign="center">
          {i18n.t('error.promotionList.unspecific')}
        </Paragraph>
      ) : mapList.length ? (
        <>
          <Nav className="mt-2" id="promotions-nav-menu-categories">
            {categoriesThatWasPromo.map((slug) => (
              <NavItem
                key={slug}
                onClick={() => {
                  setSelectedCategory(slug);
                }}
                selected={selectedCategory === slug}
              >
                {i18n.t([`bonusesAndPromotions.promotionTypes.${slug}`, slug])}
              </NavItem>
            ))}
          </Nav>
          <PromotionsWrapper id="promotions-list">
            {mapList.map((promo) => (
              <PromotionCard
                key={promo.id}
                promotion={promo}
                setTermsSlug={setTermsSlug}
              />
            ))}
          </PromotionsWrapper>
        </>
      ) : (
        <Paragraph>{i18n.t('bonusesAndPromotions.noPromotionFound')}</Paragraph>
      )}
      <span />
    </Wrapper>
  );
}

PromotionList.propTypes = {
  setTermsSlug: PropTypes.func,
}.isRequired;

export default PromotionList;
