import React from 'react';
import PropTypes from 'prop-types';

import { useWalletData } from '@/context/walletContext';

import {
  ButtonLarge,
  ButtonSmall,
  ButtonWrapper,
  Text,
  Wrapper,
} from './styles';

const MINIMUM_WALLETS_NUMBER_TO_SHOW = 2;

function CurrencySelection({ label, variant }) {
  const { walletData, setWalletData } = useWalletData(null);

  const { wallets, selectedWallet } = walletData;

  const Button = variant === 'large' ? ButtonLarge : ButtonSmall;

  const shouldShowWalletSelector =
    Object.keys(wallets).length >= MINIMUM_WALLETS_NUMBER_TO_SHOW;

  return shouldShowWalletSelector ? (
    <Wrapper>
      {label && <Text>{label}</Text>}
      <ButtonWrapper>
        {Object.keys(wallets).map((item) => (
          <Button
            key={item}
            type="button"
            active={selectedWallet === item}
            onClick={() => {
              localStorage.setItem('wallet', item);
              setWalletData((prevState) => ({
                ...prevState,
                selectedWallet: item,
              }));
            }}
          >
            {item}
          </Button>
        ))}
      </ButtonWrapper>
    </Wrapper>
  ) : null;
}

CurrencySelection.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
}.isRequired;

export default CurrencySelection;
