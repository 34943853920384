import styled, { css } from 'styled-components';

import vpag from '@/styles/themes/vpag';

const COLOR_BY_BONUS_TYPE = {
  default: vpag.colors.bonus.free.title,
  fixed: vpag.colors.bonus.fixed.title,
  free: vpag.colors.bonus.free.title,
  percentage: vpag.colors.bonus.percentage.title,
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: space-between;
    margin-top: 0;
    height: 80%;
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  animation: goRightDefault 0.6s;
  color: ${({ theme }) => theme.colors.info.primary};
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InfoWrapper = styled.div`
  ${({ border, theme, type = 'default' }) => css`
    align-items: start;
    border-top: ${border ? `1px solid ${theme.colors.info.gray}` : 'none'};
    column-gap: 2rem;
    display: grid;
    font-size: ${theme.font.size.small};
    grid-template-columns: 1fr 1fr;
    min-width: 280px;
    padding-top: ${border ? '1rem' : 0};

    & > *:nth-child(even) {
      color: ${theme.colors.info.light};
      justify-self: end;
      font-family: ${theme.font.family.secondary};
      text-transform: capitalize;
    }

    p > span {
      color: ${COLOR_BY_BONUS_TYPE[type]};
      font-family: ${theme.font.family.secondary};
    }

    strong {
      color: ${theme.colors.primary};
    }

    @media screen and (min-width: ${theme.breakpoints.md}) {
      row-gap: 1rem;
    }
  `}
`;

export const LinksWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-items: center;
    animation: goUpDefault 0.4s;
    display: flex;
    flex-direction: column;
  }
`;
