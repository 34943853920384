import React from 'react';
import { Trans } from 'react-i18next';

import { Paragraph } from '@/components/atoms/Typography/styles';
import i18n from '@/translate/i18n';

import { Wrapper, TextWrapper, ContentWrapper } from './styles';
import TwTitle from '@/components/atoms/TwTitle';
import useDeviceWidth from '@/hooks/useDeviceWidth';

const CONTENT = [
  {
    key: 'vPlusClubInfo.content1',
    path: '/icons/userVPlus.svg',
    alt: 'arrow',
    highlightColor: 'light',
  },
  {
    key: 'vPlusClubInfo.content2',
    path: '/icons/vPlus.svg',
    reverse: true,
    alt: 'plus',
    highlightColor: 'light',
  },
  {
    key: 'vPlusClubInfo.content3',
    path: '/icons/store.svg',
    alt: 'vpag',
    highlightColor: 'primary',
  },
];

function VPlusClubInfo() {
  const { isMobileLayout } = useDeviceWidth();
  return (
    <Wrapper>
      <TwTitle
        id="vplus-club-info-title"
        size={isMobileLayout ? 'm_lg' : 'd_lg'}
        font="arboriaBold"
        classList="text-center"
      >
        <Trans
          i18n={i18n}
          i18nKey="clubVPlus.infoTitle"
          components={[<span />]}
        />
      </TwTitle>
      <ContentWrapper>
        {CONTENT.map(({ key, path, alt, reverse }) => (
          <TextWrapper key={key} reverse={reverse}>
            <img src={path} alt={alt} />
            <p>
              <Trans
                i18nKey={key}
                i18n={i18n}
                components={[<strong />, <span />]}
              />
            </p>
          </TextWrapper>
        ))}
      </ContentWrapper>
      <Paragraph textAlign="center" size="xSmall">
        {i18n.t('vPlusClubInfo.approvalDescription')}
      </Paragraph>
    </Wrapper>
  );
}

export default VPlusClubInfo;
