import styled from 'styled-components';

export const StepperContainer = styled.div`
  display: flex;
`;

export const Row = styled.div`
  border: 1px solid black;
  height: 1px;
  width: 21px;
`;

export const StepContent = styled.div`
  align-items: center;
  display: flex;
`;
