import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';

import Checkbox from '@/components/atoms/Checkbox';
import Loading from '@/components/atoms/Loading';
import SubmitButton from '@/components/atoms/Buttons/SubmitButton';
import i18n from '@/translate/i18n';
import {
  GENERAL_COMMUNICATION_SLUG,
  TRANSACTION_INTEREST_SLUGS,
} from '@/helpers/constants';
import { isArrayEqual } from '@/helpers/functions';
import { updateUserInterests } from '@/services/api';
import { useUserData } from '@/context/userContext';

import { Content, OptionsWrapper, TextWrapper } from './styles';
import TwTitle from '@/components/atoms/TwTitle';

const filterInterest = (arr) =>
  arr?.filter(
    (el) =>
      !TRANSACTION_INTEREST_SLUGS.includes(el) &&
      el !== GENERAL_COMMUNICATION_SLUG,
  );

function InterestPreference({ data, handleClose, generalUserInterests }) {
  const { userData, updateInterests } = useUserData();
  const { interests: userInterests } = userData.details.notification;

  const [preferences, setPreferences] = useState([]);
  const [interestList, setInterestList] = useState(
    filterInterest(userInterests),
  );
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setPreferences(
      data?.filter(
        ({ slug }) =>
          !TRANSACTION_INTEREST_SLUGS?.includes(slug) &&
          slug !== GENERAL_COMMUNICATION_SLUG,
      ),
    );
  }, [data]);

  const handleCheck = ({ target: { name } }) => {
    if (interestList.includes(name)) {
      const newList = interestList.filter((interest) => interest !== name);
      setInterestList(newList);
    } else {
      setInterestList((state) => [...state, name]);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsUpdating(true);
      const filtered = data.filter(({ slug }) =>
        [...generalUserInterests, ...interestList].includes(slug),
      );
      const interestsIds = filtered.map((el) => el.id);
      const payload = {
        interests: interestsIds,
      };

      await updateUserInterests(localStorage.getItem('id'), payload);
      updateInterests([...interestList, ...generalUserInterests]);
      handleClose();
    } catch (err) {
      setInterestList(userInterests);
      toast.error(
        i18n.t('error.unspecific', { id: 'update-interests-error-message' }),
      );
    } finally {
      setIsUpdating(false);
    }
  };

  const wasModified = !isArrayEqual(
    filterInterest(userInterests) ?? [],
    interestList ?? [],
  );

  return (
    <Content>
      <TwTitle
        size="xl"
        text={i18n.t('interestsPreferences.title')}
        font="arboriaBold"
        color="shadow-500"
        classList="text-center"
      />
      {!preferences.length ? (
        <Loading marginTop="20%" />
      ) : (
        <OptionsWrapper id="settings-interest-list">
          {preferences.map(({ interest, slug }) => (
            <Checkbox
              key={slug}
              id={slug}
              name={slug}
              label={interest.replace('-', ' ')}
              checked={interestList?.includes(slug)}
              onChange={handleCheck}
            />
          ))}
        </OptionsWrapper>
      )}
      {wasModified ? (
        <SubmitButton
          before={i18n.t('interestsPreferences.save')}
          after={i18n.t('interestsPreferences.save')}
          isValid
          handleClick={handleSubmit}
          isSubmitting={isUpdating}
          submitText={i18n.t('interestsPreferences.submitting')}
          width="60%"
        />
      ) : (
        <TextWrapper>
          <span />
        </TextWrapper>
      )}
    </Content>
  );
}

InterestPreference.propTypes = {
  data: PropTypes.object,
  isNotificationActive: PropTypes.bool,
  isUpdating: PropTypes.bool,
  setIsUpdating: PropTypes.func,
  setMainAction: PropTypes.func,
  handleClose: PropTypes.func,
}.isRequired;

export default InterestPreference;
