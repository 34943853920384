import styled from 'styled-components';

import { ReactComponent as LogoHorizontal } from '@/assets/vpagLogoHorizontal.svg';

export const Wrapper = styled.header`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  height: 110px;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    border-radius: 50px 50px 0 0;
  }
`;

export const Logo = styled(LogoHorizontal)`
  fill: ${({ theme }) => theme.colors.dark};
  width: 120px;
`;
