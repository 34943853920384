import styled from 'styled-components';

export const SwiperWrapper = styled.div`
  align-items: center;
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: space-between;
  max-width: 90vw;

  & > span {
    min-height: 18px;
  }

  .swiper {
    max-width: 90vw;
  }

  .swiper-container {
    width: 300px;

    .swiper-slide {
      align-items: center;
      background: transparent;
      display: flex;
      font-size: ${({ theme }) => theme.font.size.medium};
      justify-content: center;
      text-align: center;
    }

    .swiper-slide img {
      display: block;
      object-fit: cover;
    }

    .swiper-pagination {
      bottom: 50px !important;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: ${({ hide }) => (hide ? 'none' : 'flex')};
    height: ${({ minimalVersion }) => (minimalVersion ? 'auto' : '100%')};
    max-width: 35vw;

    .swiper {
      max-width: 300px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: ${({ hide }) => (hide ? 'none' : 'flex')};
  }
`;

export const IconWrapper = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 80px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    background-color: ${({ theme, minimalVersion }) =>
      minimalVersion ? 'transparent' : theme.colors.bg.tertiary};
  }
`;

export const MethodWrapper = styled.div`
  align-items: center;
  animation: goLeftDefault 0.6s;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.font.size.large};
  margin: 10px;
  text-align: center;
  text-transform: capitalize;

  & > p {
    display: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    & > p {
      display: ${({ minimalVersion }) => (minimalVersion ? 'none' : 'inline')};
      margin-top: 0.8rem;
    }
  }
`;
