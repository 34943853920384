import React from 'react';
import PropTypes from 'prop-types';

import PointsIcon from '@/components/atoms/PointsIcon';
import { Paragraph } from '@/components/atoms/Typography/styles';
import { formatShortDate } from '@/helpers/stringFormat';
import i18n from '@/translate/i18n';

import { FlexWrapper, Wrapper } from './styles';

function PointsListItem({ points }) {
  return (
    <Wrapper>
      <PointsIcon type={points?.type} width="25px" />
      <FlexWrapper direction="column">
        <Paragraph family="secondary" color="light">
          {i18n.t(`pointsHistory.type.${points?.type}`)}{' '}
          {points?.type === 'transaction' ||
          points?.type === 'add_by_campaign' ||
          points?.type === 'subtract_by_campaign'
            ? `ID ${points?.id}`
            : points?.type === 'affiliate'
            ? points?.merchant?.name
            : null}
        </Paragraph>
        {points?.updated_at && (
          <Paragraph family="secondary" size="xSmall">
            {formatShortDate(points.updated_at)}
          </Paragraph>
        )}
      </FlexWrapper>

      <FlexWrapper gap="0.5rem">
        <Paragraph
          family="secondary"
          color={points?.points > 0 ? 'secondary' : 'primary'}
          size="xSmall"
        >
          {points?.points > 0 ? `+${points?.points}` : points?.points}
        </Paragraph>
        <Paragraph family="secondary" color="light">
          {points?.balance}
        </Paragraph>
      </FlexWrapper>
    </Wrapper>
  );
}

PointsListItem.propTypes = {
  points: PropTypes.shape({
    balance: PropTypes.number,
    id: PropTypes.number,
    merchant: PropTypes.string,
    points: PropTypes.number,
    type: PropTypes.string,
    updated_at: PropTypes.string,
  }),
};

export default PointsListItem;
