import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { DefaultIcon, Logo } from './style';

function MethodIcon({ method, width, height, flat }) {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(false);
  }, [method]);

  return (
    <>
      {!isError ? (
        <Logo
          src={
            flat
              ? `/images/methods/${method}-flat.svg`
              : `/images/methods/${method}.svg`
          }
          width={width}
          height={height}
          onError={setIsError}
        />
      ) : (
        <DefaultIcon width={width} height={height} />
      )}
    </>
  );
}

MethodIcon.propTypes = {
  method: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}.isRequired;

export default MethodIcon;
