import React, { useEffect } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import i18n from '@/translate/i18n';
import useUserValidations from '@/hooks/useUserValidations';
import { Title } from '@/components/atoms/Typography/styles';
import { useUserData } from '@/context/userContext';
import {
  getUserPendingValidations,
  getValidationPercentage,
} from '@/helpers/functions';

import {
  Button,
  CircularProgressBarWrapper,
  Container,
  ContentWrapper,
  MainText,
} from './styles';

import 'react-circular-progressbar/dist/styles.css';
import TwTitle from '@/components/atoms/TwTitle';

const BAR_STROKE_WIDTH = 5;
const DEFAULT_ANIMATION_VALUE = 25;
const ONE_HUNDRED_PERCENT = 100;

function ValidationProgressSummary({
  showLinkButton,
  minimalVersion,
  shouldUpdate,
}) {
  const { colors } = useTheme();
  const { isFetching, getValidations } = useUserValidations();
  const {
    userData: { validations },
  } = useUserData();

  const percentageOfValidations = getValidationPercentage(validations);

  const instructionKey = getUserPendingValidations(validations);
  const isValidationInProcess = instructionKey.includes('pending');

  useEffect(() => {
    if (shouldUpdate) {
      getValidations();
    }
  }, []);

  return (
    <Container minimalVersion={minimalVersion}>
      {!minimalVersion && (
        <TwTitle
          text={i18n.t('validationProgressSummary.title')}
          size="xl"
          font="arboriaBold"
          color="shadow-500"
          classList="text-center"
        />
      )}
      <div>
        <CircularProgressBarWrapper minimalVersion={minimalVersion}>
          <CircularProgressbarWithChildren
            counterClockwise
            strokeWidth={BAR_STROKE_WIDTH}
            className={isFetching ? 'circular-progressbar-rotate ' : ''}
            value={
              isFetching ? DEFAULT_ANIMATION_VALUE : percentageOfValidations
            }
            styles={{
              path: {
                stroke: isValidationInProcess ? colors.pending : colors.primary,
              },
              trail: {
                stroke: colors.bg.secondary,
              },
            }}
          >
            {!minimalVersion && (
              <p>{i18n.t('validationProgressSummary.completedValidations')}</p>
            )}
            {!isFetching && (
              <MainText
                minimalVersion={minimalVersion}
                isPending={isValidationInProcess}
              >
                {percentageOfValidations}
                <span>%</span>
              </MainText>
            )}
          </CircularProgressbarWithChildren>
        </CircularProgressBarWrapper>

        {percentageOfValidations < ONE_HUNDRED_PERCENT && (
          <ContentWrapper minimalVersion={minimalVersion}>
            {isValidationInProcess ? (
              <Title size="small" color="pending" highlightUpper>
                <Trans
                  i18n={i18n}
                  i18nKey="validationProgressSummary.pendingApproval"
                  components={[<span />]}
                />
              </Title>
            ) : (
              <>
                <strong>
                  {i18n.t('validationProgressSummary.increaseYourLimits')}
                </strong>
                <p>
                  {minimalVersion
                    ? i18n.t('validationProgressSummary.minimalDescription')
                    : i18n.t('validationProgressSummary.description')}
                </p>
                {showLinkButton && (
                  <Button
                    to="/validations"
                    fontSize="small"
                    fontFamily="secondary"
                  >
                    {i18n.t('validationProgressSummary.buttonLabel')}
                  </Button>
                )}
              </>
            )}
          </ContentWrapper>
        )}
      </div>
      <span />
    </Container>
  );
}

ValidationProgressSummary.propTypes = {
  showLinkButton: PropTypes.bool,
}.isRequired;

export default ValidationProgressSummary;
