import styled, { css } from 'styled-components';

export const IconContainer = styled.div`
  ${({ theme, isBackgroundWhite }) => css`
    align-items: center;
    background-color: ${isBackgroundWhite ? 'white' : theme.colors.primary};
    border-radius: 50%;
    display: flex;
    height: 42.25px;
    justify-content: center;
    padding: 8px;
    width: 42.25px;

    > img {
      width: 25.35px;
      height: 16.81px;
    }
  `};
`;
