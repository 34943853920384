import styled from 'styled-components';

export const Container = styled.div`
  align-items: start;
  animation: ${({ showModal }) =>
    showModal
      ? `opacityAnimationDefault 0.4s forwards`
      : `opacityOneToZero 0.4s forwards`};
  background-color: ${({ theme }) => theme.colors.bg.dark};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  width: 260px;
  z-index: 2;
`;

export const Wrapper = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    gap: 0.5rem;
    justify-content: space-around;
    margin-right: 10px;
    max-width: 150px;
    position: relative;
    width: 20%;
    z-index: 999;
  }
`;

export const ProfileImage = styled.img`
  border: 2px solid
    ${({ theme, active }) => (active ? theme.colors.primary : 'transparent')};
  border-radius: 50%;
  height: 40px;
  object-fit: cover;
  width: 40px;
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  z-index: 3;
`;

export const ToggleArrow = styled.span`
  border: solid ${({ theme }) => theme.colors.info.light};
  border-width: 0 1px 1px 0;
  display: inline-block;
  margin-bottom: ${({ isOpen }) => !isOpen && '2px'};
  margin-left: 5px;
  padding: 4px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(-135deg)' : 'rotate(45deg)')};
  transition: 0.5s;
`;

export const CollapseContent = styled.div`
  align-items: center;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 110%;
  transform: ${({ collapsed }) =>
    collapsed ? 'translateY(-100%)' : 'translateY(0)'};
  transition: 0.5s;
  width: 100%;
  z-index: ${({ collapsed }) => (collapsed ? '-1' : 2)};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    right: 50px;
  }
`;
