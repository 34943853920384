import React from 'react';
import PropTypes from 'prop-types';
import { TitleInsertUsernameContainer, SubtitleText } from './style';

function Subtitle({ children, fontSize, opacity }) {
  return (
    <TitleInsertUsernameContainer>
      <SubtitleText size="small" fontSize={fontSize} opacity={opacity}>
        {children}
      </SubtitleText>
    </TitleInsertUsernameContainer>
  );
}
Subtitle.propTypes = {
  children: PropTypes.node,
  fontSize: PropTypes.string,
  opacity: PropTypes.string,
};

export default Subtitle;
