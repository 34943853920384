import styled from 'styled-components';

export const Content = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.info.light};
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: 600;
  margin: 2rem 0;
`;

export const InfoWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
  width: 100%;
`;

export const TextWrapper = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: 500;
  margin-left: 20px;
  width: 80%;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.info.light};
  font-weight: 400;
  margin-top: 5px;
`;
