import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import i18n from '@/translate/i18n';
import LimitInstructions from '@/components/molecules/Modal/Messages/LimitInstructions';
import Modal from '@/components/molecules/Modal';

import {
  Button,
  Content,
  ErrorIcon,
  LinkText,
  LinkWrapper,
  Strong,
  Text,
  TextWrapper,
  Wrapper,
} from './styles';

function TransactionError({
  content,
  errorData,
  height,
  redirectTo,
  showInstructions,
  type,
}) {
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);

  const isLimitError =
    errorData?.message === 'validation-business-rules-exception';

  return (
    <Wrapper height={height}>
      <Content>
        <ErrorIcon />
        <TextWrapper>
          <Text>
            <Strong>{i18n.t('transactionError.errorHasOccurred')}</Strong>
          </Text>
          {errorData ? (
            <Text>
              {isLimitError
                ? i18n.t([
                    `error.${type}.${errorData.data?.errors['user-exceeds-limits']}`,
                    `error.${type}.unspecific`,
                  ])
                : i18n.t([
                    `error.${type}.${errorData.message}`,
                    `error.${type}.unspecific`,
                  ])}
            </Text>
          ) : (
            <Text>{content}</Text>
          )}
        </TextWrapper>
      </Content>
      <LinkWrapper>
        {(isLimitError || showInstructions) && (
          <LinkText
            to="#"
            onClick={() => setShowModal(!showModal)}
            id="increase-limit-instructions-btn"
          >
            {i18n.t('transactionError.increaseLimit')}
          </LinkText>
        )}
        <LinkText to="/wallet">{i18n.t('transactionError.return')}</LinkText>
      </LinkWrapper>

      {redirectTo && (
        <Button type="button" onClick={() => history.push(redirectTo)}>
          {i18n.t('transactionError.tryAgain')}
        </Button>
      )}

      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        height="290px"
        button={i18n.t('modal.understood')}
      >
        <LimitInstructions />
      </Modal>
    </Wrapper>
  );
}

TransactionError.propTypes = {
  content: PropTypes.string,
  height: PropTypes.string,
  showInstructions: PropTypes.bool,
  type: PropTypes.string,
  redirectTo: PropTypes.string,
  errorData: PropTypes.shape({
    message: PropTypes.string,
    data: PropTypes.shape({
      errors: PropTypes.shape({
        'user-exceeds-limits': PropTypes.string,
      }),
    }),
  }),
}.isRequired;

export default TransactionError;
