import { useEffect, useState } from 'react';

import { getLastTransactions } from '@/services/api';
import { useWalletData } from '@/context/walletContext';

const NUM_OF_TRANSACTIONS_IN_RESUME = 3;

const useLastTransactions = (numberOfItems) => {
  const { setWalletData } = useWalletData();

  const [lastTransactions, setLastTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    const controller = new AbortController();

    const getData = async () => {
      try {
        setIsLoading(true);
        const CUSTOMER_ID = localStorage.getItem('id');

        const payload = {
          'customer_id[]': CUSTOMER_ID,
          per_page: numberOfItems ?? NUM_OF_TRANSACTIONS_IN_RESUME,
          order: 'desc',
          order_by: 'id',
          status: ['pending', 'completed', 'processing'],
        };
        const res = await getLastTransactions(payload, controller.signal);

        setLastTransactions(res.data);
        setWalletData((state) => ({
          ...state,
          minimalTransactions: res.data,
          shouldRenderFirstDepositBtn: Boolean(!res?.data?.length),
        }));
      } catch (err) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getData();

    return () => {
      controller.abort();
    };
  }, []);

  return {
    lastTransactions,
    isLoading,
    error,
  };
};

export default useLastTransactions;
