import React from 'react';

import IncreaseLimitsInstructions from '@/components/organisms/IncreaseLimitsInstructions';
import IncreaseLimitsTemplate from '@/components/templates/IncreaseLimitsTemplate';
import useUserValidations from '@/hooks/useUserValidations';
import { useUserData } from '@/context/userContext';

function IncreaseLimits() {
  const { isFetching } = useUserValidations();

  const {
    userData: { validations },
  } = useUserData();

  return (
    <IncreaseLimitsTemplate isFetching={isFetching} validations={validations}>
      <IncreaseLimitsInstructions validations={validations} />
    </IncreaseLimitsTemplate>
  );
}

export default IncreaseLimits;
