import styled from 'styled-components';

import vpag from '@/styles/themes/vpag';

const COLOR_BY_BONUS_TYPE = {
  default: vpag.colors.bonus.free.title,
  fixed: vpag.colors.bonus.fixed.title,
  free: vpag.colors.bonus.free.title,
  percentage: vpag.colors.bonus.percentage.title,
};

const Wrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex-flow: row wrap;
  gap: 0.3rem;

  p {
    color: ${({ type = 'default', min_amount_transaction }) =>
      type === 'fixed' && min_amount_transaction === 0
        ? COLOR_BY_BONUS_TYPE.free
        : COLOR_BY_BONUS_TYPE[type]};
    font: ${({ theme }) =>
      `${theme.font.size.small} ${theme.font.family.secondary}`};
    padding-top: 3px;
    text-transform: uppercase;
  }
`;

export default Wrapper;
