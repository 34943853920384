import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { Paragraph } from '@/components/atoms/Typography/styles';
import i18n from '@/translate/i18n';

import { TextWrapper } from './styles';
import TwTitle from '@/components/atoms/TwTitle';

function RegisterValidationErrorMessage({ restartValidation }) {
  return (
    <>
      <TwTitle
        size="xl"
        font="arboriaBold"
        color="shadow-500"
        classList="text-center"
      >
        {' '}
        <Trans
          i18nKey="registerValidationErrorMessage.title"
          i18n={i18n}
          components={[<strong className="text-cherryRed" />]}
        />
      </TwTitle>
      <TextWrapper>
        <Paragraph size="base" highlightColor="light">
          <Trans
            i18nKey="registerValidationErrorMessage.description"
            i18n={i18n}
            components={[<span />]}
          />
        </Paragraph>
      </TextWrapper>
      {restartValidation && (
        <TextButton type="button" onClick={restartValidation}>
          {i18n.t('registerValidationErrorMessage.resendDocs')}
        </TextButton>
      )}
    </>
  );
}

RegisterValidationErrorMessage.propTypes = {
  restartValidation: PropTypes.func,
};

export default RegisterValidationErrorMessage;
