import { useState, useEffect } from 'react';

import { listDefaultTrackers } from '@/services/api';
import { useWalletData } from '@/context/walletContext';

const useDefaultTrackers = () => {
  const { setWalletData, walletData } = useWalletData();

  const [loadingTrackers, setLoadingTrackers] = useState(false);
  const [error, setError] = useState(false);
  const [trackers, setTrackers] = useState([]);

  useEffect(() => {
    const controller = new AbortController();

    const getUsernames = async () => {
      try {
        setLoadingTrackers(true);
        const res = await listDefaultTrackers(controller.signal);

        setTrackers(res.data);
        setWalletData((prevState) => ({ ...prevState, trackers: res.data }));
      } catch (err) {
        setWalletData((prevState) => ({ ...prevState, trackers: [] }));
        setError(true);
      } finally {
        setLoadingTrackers(false);
      }
    };

    if (!walletData?.trackers) {
      getUsernames();
    }

    return () => controller.abort();
  }, [walletData?.trackers]);

  return { loadingTrackers, error, trackers };
};

export default useDefaultTrackers;
