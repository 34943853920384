import styled from 'styled-components';

import { hexToRGBA } from '@/styles/colors';

export const Wrapper = styled.ul`
  display: ${({ hideInMobile }) => hideInMobile && 'none'};
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
    max-height: 90%;
    max-width: 60%;
    overflow-y: auto;
    padding: 0 2rem 2rem 0;
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: 600;
  padding: 0 0 0.6rem 3rem;
  text-transform: capitalize;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 1rem;
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Nav = styled.nav`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`;

export const ListWrapper = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    &:not(:first-child) {
      margin-top: 1.3rem;
    }
  }
`;

export const ListItem = styled.li`
  align-items: center;
  background-color: ${({ isOpen }) => isOpen && 'rgba(0, 0, 0, 0.2)'};
  border-top: 1px solid
    ${({ theme }) => hexToRGBA(theme.colors.info.primary, 0.2)};
  color: ${({ theme }) => theme.colors.info.light};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font: ${({ theme }) =>
    `${theme.font.size.medium} ${theme.font.family.secondary}`};
  justify-content: flex-start;
  max-height: ${({ isOpen }) => (isOpen ? '1000px' : 'auto')};
  overflow: hidden;
  padding: 2rem 1.7rem;
  transition: 0.5s all ease;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-height: ${({ isOpen }) => (isOpen ? '1000px' : 'auto')};
    padding: 1rem;
  }
`;

export const ItemWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TextContent = styled.div`
  font-family: ${({ theme }) => theme.font.family.tertiary};
  margin-left: 25px;
  padding: 1.2rem 0;
  width: 100%;
  text-align: left;

  a {
    color: ${({ theme }) => theme.colors.highlight};

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.font.size.small};
  }
`;

export const CollapseButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0 1rem;
  width: 27px;
`;

export const Span = styled.span`
  color: ${({ theme, isOpen }) => isOpen && theme.colors.primary};
  display: inline-block;

  strong {
    text-transform: uppercase;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font: ${({ theme }) =>
      `600 ${theme.font.size.small} ${theme.font.family.tertiary} `};

    strong {
      font: ${({ theme }) =>
        `600 ${theme.font.size.small} ${theme.font.family.primary} `};
    }
  }
`;
