import styled, { css } from 'styled-components';
import { Paragraph } from '../Typography/styles';

export const StepContainer = styled.div`
  ${({ activated, theme }) => css`
    align-items: center;
    background-color: ${activated
      ? theme.colors.autoCompleteBg
      : theme.colors.greenVpag};
    border-radius: 50%;
    border: 2px solid ${theme.colors.autoCompleteBg};
    color: ${activated ? theme.colors.greenVpag : theme.colors.autoCompleteBg};
    display: flex;
    height: ${activated ? '36px' : '32px'};
    justify-content: center;
    padding: 5px;
    width: ${activated ? '36px' : '32px'};
  `};
`;

export const ParagraphStyled = styled(Paragraph)`
  font-weight: 800;
`;
