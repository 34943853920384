import React from 'react';
import PropTypes from 'prop-types';

function TwButton({
  handleClick,
  isSubmitting,
  disabled,
  label,
  height = 'primary',
  classList = '',
  ...rest
}) {
  const sizes = {
    primary: 'h-16 rounded-lg md:text-lg',
    secondary: 'h-[49px] rounded-sm md:text-base',
  };

  return (
    <button
      type="submit"
      {...rest}
      handleClick={handleClick}
      disabled={disabled || isSubmitting}
      className={`w-full text-xl font-arboria-regular ${sizes[height]} ${
        !disabled ? 'cursor-pointer' : 'cursor-not-allowed'
      } ${
        !disabled ? 'bg-grass-800' : 'bg-ashes-700'
      } text-sm font-arboria-regular ${classList}`}
    >
      {label}
    </button>
  );
}

TwButton.propTypes = {
  label: PropTypes.string,
  handleClick: PropTypes.func,
  isSubmitting: PropTypes.bool,
  disabled: PropTypes.bool,
  height: PropTypes.string,
  classList: PropTypes.string,
};

export default TwButton;
