/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@/assets/close.svg';

export const TwModal = ({
  isOpen,
  onClose,
  children,
  classList,
  classListChildren,
  classListContainer,
  classNameStyleCloseButton,
  closeButtonWhite,
  closeButtonWhiteOutlined,
}) => {
  if (!isOpen) return null;

  const handleContainerClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ${classListContainer}`}
      onClick={onClose}
    >
      <div
        className={`bg-[#333333] rounded-lg shadow-lg size-full md:w-1/2 lg:w-3/6  ${classList}`}
        onClick={handleContainerClick}
      >
        <div className="flex justify-end items-center p-4">
          <button
            onClick={onClose}
            className={`z-[100] ${
              closeButtonWhite
                ? 'w-7 h-7 p-1 pb-3  bg-white border rounded-full  hover:bg-gray-700 hover:text-grass-900 transition-transform transform hover:scale-110 text-start text-4xl flex flex-col justify-center items-center'
                : closeButtonWhiteOutlined
                ? 'flex flex-col justify-center items-center text-white hover:rounded-full hover:bg-black'
                : 'text-grass-900 hover:text-gray-700 text-5xl'
            } ${classNameStyleCloseButton}`}
            type="button"
          >
            {closeButtonWhiteOutlined ? (
              <img src={CloseIcon} className="size-10" alt="close button" />
            ) : (
              <>&times;</>
            )}
          </button>
        </div>
        <div className={`p-4 ${classListChildren}`}>{children}</div>
      </div>
    </div>
  );
};

TwModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  classList: PropTypes.string,
  classListChildren: PropTypes.string,
  classListContainer: PropTypes.string,
  closeButtonWhite: PropTypes.bool,
  closeButtonWhiteOutlined: PropTypes.bool,
  classNameStyleCloseButton: PropTypes.string,
};
