import React from 'react';
import PropTypes from 'prop-types';
import {
  ParagraphStyled,
  Container,
  MoreChosenBody,
  MoreChosenContainer,
  OptionCashBackContainer,
} from './style';

const SIZE_ICON = '44px';

function OptionCashback({ isVpag = false, srcIcon, textBody, onClick }) {
  return (
    <Container onClick={onClick}>
      {isVpag && (
        <MoreChosenContainer>
          <MoreChosenBody>Mais escolhido</MoreChosenBody>
        </MoreChosenContainer>
      )}
      <OptionCashBackContainer vpag={isVpag}>
        <img
          src={srcIcon}
          alt={`icon ${textBody}`}
          width={SIZE_ICON}
          height={SIZE_ICON}
        />
        <ParagraphStyled size="small">{textBody}</ParagraphStyled>
      </OptionCashBackContainer>
    </Container>
  );
}

OptionCashback.propTypes = {
  isVpag: PropTypes.bool,
  srcIcon: PropTypes.string,
  textBody: PropTypes.string,
}.isRequired;
export default OptionCashback;
