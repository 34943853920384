import axios from 'axios';
import { setup } from 'axios-cache-adapter';

import { keysToRemoveOnLogout } from '@/helpers/constants';

import { getCookie } from '@/helpers/functions';
import { getURLCached } from './cache';

const FORBIDDEN_HTTP_STATUS_CODE = 403;
const THIRTY_SECONDS = 30_000;
const ONE_MINUTE = 60_000;

const API_DEFAULT_CONFIG = {
  baseURL: process.env.REACT_APP_PAYMENT_API_URL,
  cache: {
    exclude: {
      filter: (req) => getURLCached(req),
      query: false,
    },
    maxAge: THIRTY_SECONDS,
    methods: ['get'],
  },
};

export const apiUser = setup(API_DEFAULT_CONFIG);
export const apiWallet = setup(API_DEFAULT_CONFIG);
export const apiPayment = setup(API_DEFAULT_CONFIG);
export const apiMerchant = setup(API_DEFAULT_CONFIG);
export const apiPromotion = setup(API_DEFAULT_CONFIG);
export const apiIbge = setup({
  baseURL: 'https://servicodados.ibge.gov.br',
  cache: {
    exclude: {
      filter: (req) => getURLCached(req),
      query: false,
    },
    maxAge: ONE_MINUTE,
    methods: ['get'],
  },
});

export const apiViaCep = setup({
  baseURL: 'https://viacep.com.br',
  cache: {
    exclude: {
      filter: (req) => getURLCached(req),
      query: false,
    },
    maxAge: ONE_MINUTE,
    methods: ['get'],
  },
});

const setHeaders = (config) => {
  const token = JSON.parse(localStorage.getItem('accessToken'));
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers['x-guid'] = getCookie('guid');
  return config;
};

const rejectPromise = (error) => Promise.reject(error);

const refreshToken = async () => {
  const expiredToken = JSON.parse(localStorage.getItem('accessToken'));

  if (!expiredToken) {
    return null;
  }

  const config = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${expiredToken}`,
    },
  };

  return axios(
    `${process.env.REACT_APP_USER_API_URL}/user/auth/refresh`,
    config,
  ).then(({ data }) => data.data.token);
};

let refreshTokenPromise = null;

const checkAndRefreshToken = async (error) => {
  const accessToken = localStorage.getItem('accessToken');

  if (error.response.status === FORBIDDEN_HTTP_STATUS_CODE && accessToken) {
    if (!refreshTokenPromise) {
      refreshTokenPromise = refreshToken()
        .then((newToken) => {
          localStorage.setItem('accessToken', JSON.stringify(newToken));

          refreshTokenPromise = null;
          return newToken;
        })
        .catch((err) => {
          keysToRemoveOnLogout.forEach((key) => {
            localStorage.removeItem(key);
          });

          if (!localStorage.getItem('alertDisplayed')) {
            const myEvent = new CustomEvent('invalid_token', {
              bubbles: true,
              cancelable: true,
            });
            document.querySelector('body').dispatchEvent(myEvent);
            localStorage.setItem('alertDisplayed', true);
          }

          return Promise.reject(err);
        });
    }

    return refreshTokenPromise.then((token) => {
      if (!token) {
        return Promise.reject(error);
      }
      error.config.headers.Authorization = `Bearer ${token}`;
      return axios.request(error.config);
    });
  }
  return Promise.reject(error);
};

// Interceptors requests
apiUser.interceptors.request.use(setHeaders, rejectPromise);

apiWallet.interceptors.request.use(setHeaders, rejectPromise);

apiPayment.interceptors.request.use(setHeaders, rejectPromise);

apiMerchant.interceptors.request.use(setHeaders, rejectPromise);

apiPromotion.interceptors.request.use(setHeaders, rejectPromise);

// Interceptors responses
apiUser.interceptors.response.use((response) => response, checkAndRefreshToken);

apiWallet.interceptors.response.use((response) => {
  if ('content-disposition' in response.headers) {
    const file = response.data;
    const name = response.headers['content-disposition']
      ?.split('filename=')[1]
      .replace(/"/g, '');
    const blob = new Blob([file], {
      type: 'application/pdf',
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    return response;
  }
  return response;
}, checkAndRefreshToken);

apiPayment.interceptors.response.use(
  (response) => response,
  checkAndRefreshToken,
);

apiMerchant.interceptors.response.use(
  (response) => response,
  checkAndRefreshToken,
);

apiPromotion.interceptors.response.use(
  (response) => response,
  checkAndRefreshToken,
);
