import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';

import SubmitButton from '@/components/atoms/Buttons/SubmitButton';
import Loading from '@/components/atoms/Loading';
import { Paragraph } from '@/components/atoms/Typography/styles';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';
import TransferApiBalance from '@/components/molecules/TransferApiBalance';
import TransferAPIStatus from '@/components/organisms/TransferAPIStatus';
import TransferApiTemplate from '@/components/templates/TransferApiTemplate';
import { useWalletData } from '@/context/walletContext';
import { formatCurrency } from '@/helpers/stringFormat';
import { confirmTransactionApi, getTransactionByUuid } from '@/services/api';
import i18n from '@/translate/i18n';

import {
  ContentWrapper,
  FlexWrapper,
  Label,
  MessageWrapper,
  TitleWrapper,
} from './styles';

function TransferApi() {
  const history = useHistory();
  const { id: uuid } = useParams();
  const { walletBalance } = useWalletData();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const transactionData = JSON.parse(localStorage.getItem('transaction'));
  const urlToRedirectionOnFail = transactionData?.start_request?.redirect_fail;

  const getTransactionData = async () => {
    try {
      const res = await getTransactionByUuid(uuid);

      const { transaction, merchant } = res.data;

      localStorage.setItem(
        'transaction',
        JSON.stringify({ ...transaction, merchant }),
      );
    } catch (err) {
      history.push(`/transactions/signin/${uuid}`);
    }
  };

  useEffect(() => {
    const validateData = async () => {
      if (!transactionData) {
        const accessToken = JSON.parse(localStorage.getItem('accessToken'));

        if (accessToken) {
          await getTransactionData();
        } else {
          history.push(`/transactions/signin/${uuid}`);
        }
      }
    };

    validateData();
  }, []);

  const insufficientBalance =
    transactionData?.type === 'transfer-in' &&
    transactionData?.amount > walletBalance;

  const createTransfer = async () => {
    try {
      setIsLoading(true);
      const payload = {
        status: 'confirmed',
      };
      await confirmTransactionApi(payload, uuid);

      const updatedData = { ...transactionData, merchant_status: 'completed' };
      localStorage.setItem('transaction', JSON.stringify(updatedData));
    } catch (err) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    localStorage.clear();
    if (urlToRedirectionOnFail) {
      window.location = urlToRedirectionOnFail;
    } else {
      history.push(`/transactions/signin/${uuid}`);
    }
  };

  const customer = transactionData?.merchant_customer_data?.full_name;
  const customerName = customer?.split(' ')?.[0];

  return (
    <TransferApiTemplate>
      <ContentWrapper>
        {transactionData?.merchant_status === 'completed' || error ? (
          <TransferAPIStatus
            error={error}
            data={transactionData}
            setError={setError}
          />
        ) : (
          <>
            <TitleWrapper>
              <Paragraph size="medium" color="light" highlightColor="light">
                {i18n.t('transferApi.title')} <strong>{customerName}</strong>
              </Paragraph>
              <Paragraph size="medium" family="secondary">
                {i18n.t([
                  `transferApi.subtitle.${transactionData?.type}`,
                  'transferApi.subtitle.transfer-in',
                ])}
              </Paragraph>
            </TitleWrapper>
            {transactionData?.type !== 'transfer-out' && (
              <TransferApiBalance insufficientBalance={insufficientBalance} />
            )}
            {insufficientBalance && (
              <MessageWrapper>
                <IoClose />
                <Paragraph color="dark" family="secondary" size="base">
                  {i18n.t('transferApi.insufficientBalanceMessage')}
                </Paragraph>
              </MessageWrapper>
            )}
            <FlexWrapper>
              <Label>
                {i18n.t('transferApi.merchant')}
                <input
                  type="text"
                  value={transactionData?.merchant?.name}
                  disabled
                />
              </Label>
              <Label>
                {i18n.t([
                  `transferApi.amount.${`${transactionData?.type}`}`,
                  'transferApi.amount.transfer-in',
                ])}
                <input
                  type="text"
                  value={formatCurrency(
                    transactionData?.amount,
                    transactionData?.request_currency,
                  )}
                  disabled
                />
              </Label>
            </FlexWrapper>

            {isLoading ? (
              <Loading disableFlexGrow />
            ) : (
              <FlexWrapper>
                <SubmitButton
                  before={
                    insufficientBalance
                      ? i18n.t('transferApi.insufficientBalance')
                      : i18n.t([
                          `transferApi.submit.${transactionData.type}`,
                          'transferApi.submit.transfer-in',
                        ])
                  }
                  after={i18n.t([
                    `transferApi.submit.${transactionData.type}`,
                    'transferApi.submit.transfer-in',
                  ])}
                  isValid={!insufficientBalance}
                  handleClick={createTransfer}
                  width="220px"
                />
                <TextButton type="button" color="light" onClick={handleCancel}>
                  {`${i18n.t('transferApi.cancel')}`}
                </TextButton>
              </FlexWrapper>
            )}
          </>
        )}
      </ContentWrapper>
    </TransferApiTemplate>
  );
}

export default TransferApi;
