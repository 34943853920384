import React, { useState } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// import toast from 'react-hot-toast';
import Input from '@/components/atoms/Inputs/Input';
import PhoneInput from '@/components/atoms/Inputs/PhoneInput';
import SubmitButton from '@/components/atoms/Buttons/SubmitButton';
import i18n from '@/translate/i18n';
import { Title } from '@/components/atoms/Typography/styles';
import { completeRegistration } from '@/services/api';
import { useUserData } from '@/context/userContext';
import { BR_PHONE_CODE } from '@/helpers/constants';
import { PHONE_SCHEMA, REGISTRATION_SCHEMA } from '@/helpers/schemas';
import { extractErrorSlug } from '@/helpers/functions';

import {
  formatCpf,
  parseDateFormat,
  formatDate,
  formatOnlyNumber,
  formatPhoneMask,
} from '@/helpers/stringFormat';

import {
  FormStyled,
  ButtonWrapper,
  ErrorMessage,
  InputAnimationWrapper,
  Footer,
  SummaryWrapper,
} from './styles';

import { initialErrors, initialErrorsMinimal } from './validations';
import TwTitle from '@/components/atoms/TwTitle';

function RegistrationForm({ minimalVersion }) {
  const history = useHistory();
  const { userData, setUserData } = useUserData();
  const [errorMessage, setErrorMessage] = useState('');

  const { pathname } = history.location;
  const isExternalFlow = pathname?.includes('/external');

  return (
    <>
      {minimalVersion && (
        <SummaryWrapper>
          <TwTitle
            text={i18n.t('registration.welcome')}
            size="xl"
            font="arboriaBold"
            color="shadow-500"
            classList="text-center"
          />
          <TwTitle
            size="xl"
            font="arboriaBold"
            color="shadow-500"
            classList="text-center capitalize"
          >
            <span>{userData?.name && userData?.name}</span>
          </TwTitle>
          <div>
            <p>
              {i18n.t('registration.cpf')}: {formatCpf(userData?.cpf)}
            </p>
            <p>
              {i18n.t('registration.birthdate')}:{' '}
              {formatDate(userData?.birth_date)}
            </p>
          </div>
        </SummaryWrapper>
      )}
      <Formik
        validationSchema={minimalVersion ? PHONE_SCHEMA : REGISTRATION_SCHEMA}
        initialErrors={minimalVersion ? initialErrorsMinimal : initialErrors}
        initialValues={{
          name: userData?.name ?? '',
          cpf: userData?.cpf ?? '',
          phone: userData?.phone?.replaceAll(BR_PHONE_CODE, '') ?? '',
          birth_date: userData?.birth_date
            ? formatDate(userData.birth_date)
            : '',
        }}
        onSubmit={async (values) => {
          try {
            const cpf = formatOnlyNumber(values.cpf);
            const phone = `${BR_PHONE_CODE}${formatOnlyNumber(values.phone)}`;
            const res = await completeRegistration({
              ...values,
              cpf,
              phone,
            });

            setUserData(res.data);

            window.dataLayer.push({
              event: process.env.REACT_APP_ENV
                ? `register_user_data_try_${process.env.REACT_APP_ENV}`
                : 'register_user_data_try',
              userId: userData?.id,
              userCpf: values?.cpf,
              userPhone: values?.phone,
              userBirthday: values?.birth_date,
            });

            history.push(
              isExternalFlow
                ? '/external/validate-register'
                : '/wallet/validate-register',
            );
          } catch (err) {
            const message = extractErrorSlug(err?.response);
            setErrorMessage(
              i18n.t([
                `error.registration.${message?.[0]}`,
                'error.registration.unspecific',
              ]),
            );
          }
        }}
      >
        {({
          values,
          setFieldValue,
          setFieldTouched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <>
            <FormStyled
              onChange={() => {
                setErrorMessage('');
              }}
            >
              <Title>
                {minimalVersion
                  ? i18n.t('registration.enterYourPhone')
                  : i18n.t('registration.fillInData')}
              </Title>

              <InputAnimationWrapper>
                {!minimalVersion && (
                  <>
                    <Input
                      mask="999.999.999-99"
                      id="cpf"
                      name="cpf"
                      type="text"
                      placeholder={i18n.t('registration.cpf')}
                      value={values.cpf}
                      dataTestId="registration-input-cpf"
                      secondaryTheme
                      handleChange={({ target: t }) => {
                        setFieldTouched('cpf', true);
                        setFieldValue('cpf', formatCpf(t.value));
                      }}
                      errorText={
                        errors?.cpf === 'invalid-cpf' &&
                        i18n.t('error.registration.invalid-cpf-numbers')
                      }
                    />
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      placeholder={i18n.t('registration.name')}
                      value={values.name}
                      secondaryTheme
                      dataTestId="registration-input-name"
                      validationError={errors?.name}
                      errorText={
                        errors.name === 'only-letters' &&
                        i18n.t('registration.insertValidName')
                      }
                    />
                    <Input
                      mask="99/99/9999"
                      id="birth_date"
                      name="birth_date"
                      type="text"
                      placeholder={i18n.t('registration.birthdate')}
                      value={values.birth_date}
                      dataTestId="registration-input-birthdate"
                      secondaryTheme
                      handleChange={({ target: t }) => {
                        setFieldTouched('birth_date', true);
                        setFieldValue('birth_date', parseDateFormat(t.value));
                      }}
                    />
                  </>
                )}
                <PhoneInput
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  placeholder={i18n.t('registration.phoneNumber')}
                  value={values.phone}
                  dataTestId="registration-input-phone-number"
                  secondaryTheme
                  handleChange={({ target: t }) => {
                    setFieldTouched('phone', true);
                    if (t.value.length > values.phone.length) {
                      setFieldValue('phone', formatPhoneMask(t.value));
                    } else {
                      setFieldValue('phone', t.value);
                    }
                  }}
                />
              </InputAnimationWrapper>

              <Footer>
                {errorMessage && (
                  <ErrorMessage data-testid="registration-error-message">
                    {errorMessage}
                  </ErrorMessage>
                )}
                {errors?.birth_date === 'invalid-age' && (
                  <ErrorMessage data-testid="registration-error-message">
                    {i18n.t('registration.invalidAge')}
                  </ErrorMessage>
                )}
                <ButtonWrapper>
                  <SubmitButton
                    id="complete-registration-submit-btn"
                    before={
                      errors?.phone === 'invalid-phone-number'
                        ? i18n.t('registration.invalidPhoneNumber')
                        : i18n.t('registration.fillInData')
                    }
                    after={i18n.t('registration.finish')}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                  />
                </ButtonWrapper>
              </Footer>
            </FormStyled>
          </>
        )}
      </Formik>
    </>
  );
}

RegistrationForm.propTypes = {
  minimalVersion: PropTypes.bool.isRequired,
};

export default RegistrationForm;
