import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoCopySharp } from 'react-icons/io5';

import ProfilePicDefault from '@/assets/userfull.svg';
import Tooltip from '@/components/atoms/Tooltip';
import {
  Paragraph,
  TextOverflowEllipsis,
  Title,
} from '@/components/atoms/Typography/styles';
import { useUserData } from '@/context/userContext';
import { capitalizeText } from '@/helpers/stringFormat';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import i18n from '@/translate/i18n';

import { ProfileImage, TextIconWrapper, Wrapper } from './styles';

function BasicUserinfo({ showProfileImage, align }) {
  const [showCopiedTooltip, setCopiedTooltip] = useState(false);
  const { userData } = useUserData();
  const { isMobileLayout } = useDeviceWidth();

  return (
    <Wrapper align={align}>
      {showProfileImage && (
        <ProfileImage
          alt="Profile image"
          src={userData?.profile_image_url ?? ProfilePicDefault}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = ProfilePicDefault;
          }}
        />
      )}
      <Title size="small">
        <span>{userData?.name && capitalizeText(userData?.name)}</span>
      </Title>
      <Tooltip content={userData?.email}>
        <TextOverflowEllipsis
          color="light"
          maxWidth={isMobileLayout ? '300px' : '200px'}
        >
          {userData?.email}
        </TextOverflowEllipsis>
      </Tooltip>
      <Tooltip
        content={i18n.t('profile.idCopied')}
        onClickOutside={() => setCopiedTooltip(false)}
        placement="top-start"
        visible={showCopiedTooltip}
      >
        <TextIconWrapper>
          <Paragraph title={userData?.id} color="light">
            ID: {userData?.id}
          </Paragraph>
          <IoCopySharp
            onClick={() => {
              navigator.clipboard.writeText(userData?.id ?? '');
              setCopiedTooltip(true);

              setTimeout(() => setCopiedTooltip(false), 3000);
            }}
          />
        </TextIconWrapper>
      </Tooltip>
    </Wrapper>
  );
}
BasicUserinfo.propTypes = {
  showProfileImage: PropTypes.bool,
  align: PropTypes.string,
};

export default BasicUserinfo;
