import React, { useRef, useState } from 'react';

import ProfilePicDefault from '@/assets/user_icon.svg';
import UserMenu from '@/components/molecules/UserInfoMenus/UserMenu';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { useUserData } from '@/context/userContext';

import {
  ButtonWrapper,
  CollapseContent,
  Container,
  ProfileImage,
  ToggleArrow,
  Wrapper,
} from './styles';

function UserInfo() {
  const ref = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const {
    userData: { profile_image_url: imageUrl },
  } = useUserData();

  useOnClickOutside(ref, setShowMenu);

  return (
    <Wrapper open={showMenu} ref={ref}>
      <ButtonWrapper onClick={() => setShowMenu((state) => !state)}>
        <ProfileImage
          id="header-profile-image"
          active={showMenu && imageUrl}
          alt="Profile image"
          src={imageUrl ?? ProfilePicDefault}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = ProfilePicDefault;
          }}
        />
        <ToggleArrow isOpen={showMenu} />
      </ButtonWrapper>
      <CollapseContent collapsed={!showMenu}>
        <Container showModal={showMenu}>
          {showMenu && (
            <UserMenu
              closeMenu={() => setShowMenu(false)}
              showMenu={showMenu}
            />
          )}
        </Container>
      </CollapseContent>
    </Wrapper>
  );
}

export default UserInfo;
