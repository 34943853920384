import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const TextButtonStyles = ({
  color = 'primary',
  margin = 0,
  size,
  theme,
  display = 'block',
  family = 'secondary',
}) => css`
  background-color: transparent;
  border: none;
  color: ${theme.colors[color]};
  display: ${({ hideInMobile }) => (hideInMobile ? 'none' : display)};
  font: ${`${theme.font.size[size ?? 'small']} ${theme.font.family[family]}`};
  margin: ${margin};
  text-decoration: underline;

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    display: block;
  }
`;

export const TextButton = styled.button`
  ${TextButtonStyles}
`;

export const LinkTextButton = styled(Link)`
  ${TextButtonStyles}
`;
