import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import { registrationStatus } from '@/helpers/constants';
import { LinkText } from './style';

function SettingValidationMessage({ status }) {
  switch (status) {
    case registrationStatus.validateId:
      return (
        <p className="text-left font-arboria-bold text-sand-800 text-sm">
          {i18n.t('settings.validationNeeded')}
          <LinkText to="/validate-id">
            {i18n.t('settings.validateIdentity')}
          </LinkText>
        </p>
      );
    case registrationStatus.waitingIdValidation:
      return (
        <p className="text-left font-arboria-bold text-sand-800 text-sm">
          {i18n.t('settings.validationInProgress')}
        </p>
      );
    case registrationStatus.validated:
      return (
        <p className="text-left font-arboria-bold text-sand-800 text-sm">
          {i18n.t('settings.identityValidated')}
        </p>
      );
    default:
      return null;
  }
}

SettingValidationMessage.propTypes = {
  status: PropTypes.bool,
}.isRequired;

export default SettingValidationMessage;
