import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import DateRangePicker from '@/components/molecules/DateRangePicker';
import i18n from '@/translate/i18n';
import { Button } from '@/components/atoms/Buttons/Button/styles';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { ReactComponent as FilterIcon } from '@/assets/filterIcon.svg';

import TwText from '@/components/v2/atoms/TwText';
import TwMultiselect from '@/components/atoms/TwMultiselect';
import FiltersSelected from '@/components/atoms/FiltersSelected';
import TwTitle from '@/components/atoms/TwTitle';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { filterFields } from '@/util/filterFields';

function Filters({
  filtersType,
  setFilters,
  filters,
  isDirty,
  isApplied,
  metaData,
  handleSubmit,
  setShowFilter,
  setCurrentFilters,
  setShowAnimation,
  resetAllFilters,
}) {
  const { isMobileLayout } = useDeviceWidth();

  const resetFilter = () => {
    resetAllFilters();
    setShowAnimation(false);
    if (isMobileLayout) {
      setShowFilter(false);
    }
  };

  useEffect(() => {
    if (
      filters?.type?.some(
        (type) => type.value === 'deposit' || type.value === 'withdrawal',
      )
    ) {
      setFilters((state) => ({ ...state, merchant_id: [] }));
    }
  }, [filters.type]);

  return (
    <div className="flex flex-col justify-between w-full lg:w-[39%] animate-left">
      <button
        className="flex items-center justify-center"
        type="button"
        onClick={() => {
          setShowFilter(false);
          setShowAnimation(true);
        }}
      >
        {!isMobileLayout && <FilterIcon />}
        <TwTitle
          text={i18n.t('filters.title')}
          size={isMobileLayout ? 'm_lg' : 'd_lg'}
          font="arboriaBold"
          classList="text-left ml-2"
        />
      </button>

      <div className="w-full flex flex-col justify-between items-center">
        <div className="w-full mb-4 2xl:mb-16 h-24 flex justify-center items-center">
          <FiltersSelected data={filters} setData={setFilters} />
        </div>
        <div
          className="max-w-[460px] min-h-[200px] 2xl:min-h-[280px] w-full"
          id="transactions-filter"
        >
          <div className="flex w-full flex-wrap justify-center">
            {filterFields[filtersType].map((item) => {
              if (item.type === 'multiselect') {
                return (
                  <div className="mb-5 mx-2 w-full max-w-52 relative">
                    <TwText
                      inlineSize="sm"
                      classList="mb-2"
                      color="crem"
                      text={i18n.t(`filters.${item.label || item.name}`)}
                    />
                    <TwMultiselect
                      options={item.options || metaData[item.name]}
                      placeHolder={item.placeholder}
                      size="sm"
                      name={item.name}
                      data={filters}
                      setData={setFilters}
                      isDisabled={
                        item.isDisabled ? item.isDisabled(filters) : false
                      }
                    />
                  </div>
                );
              }
              if (item.type === 'dateRange') {
                return (
                  <div className="mb-5 mx-2 w-full max-w-52 relative">
                    <TwText
                      inlineSize="sm"
                      classList="mb-2"
                      color="crem"
                      text={i18n.t(`filters.${item.label || item.name}`)}
                    />
                    <DateRangePicker
                      setState={setFilters}
                      filters={filters}
                      lastMonth
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
      <div className="h-20 flex flex-col items-center justify-between">
        {isDirty && (
          <Button
            type="button"
            disabled={!isDirty}
            onClick={() => {
              setCurrentFilters(filters);
              setShowAnimation(false);
              if (isMobileLayout) {
                setShowFilter(false);
              }
            }}
          >
            {i18n.t('filters.apply')}
          </Button>
        )}
        {isApplied && (
          <TextButton
            type="button"
            onClick={() => {
              resetFilter();
            }}
          >
            {i18n.t('filters.clear')}
          </TextButton>
        )}
      </div>
    </div>
  );
}

Filters.propTypes = {
  setShowFilter: PropTypes.func,
  filterParams: PropTypes.shape({
    type: PropTypes.string,
    status: PropTypes.string,
    startAt: PropTypes.string,
    endDate: PropTypes.string,
  }),
}.isRequired;

export default Filters;
