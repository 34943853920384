import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

function TwMultiselect({
  name,
  options = [],
  data,
  setData,
  value = 'value',
  label = 'label',
  placeHolder,
  size = 'md',
  font = 'arboriaRegular',
  classList = '',
  color = 'white',
  isDisabled = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const itemData = data[name];
  const filterRef = useRef(null);
  const sizes = {
    sm: 'text-sm',
    md: 'text-base',
  };
  const fonts = {
    arboriaLight: 'font-arboria-light',
    arboriaRegular: 'font-arboria-regular',
    arboriaBold: 'font-arboria-bold',
  };
  const isItemSelected = (item) => itemData.some((opc) => opc.value === item);

  const handleSelect = () => {
    setIsOpen((state) => !state);
  };
  const handleItem = (item) => {
    if (!itemData.some((opc) => opc.value === item[value])) {
      if (item.value) {
        setData((state) => ({
          ...state,
          [name]: [...state[name], { value: item[value], label: item[label] }],
        }));
      } else {
        setData((state) => ({
          ...state,
          [name]: [],
        }));
        setIsOpen(false);
      }
    }
  };

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`${sizes[size]} text-${color} ${fonts[font]} ${classList}`}
      ref={filterRef}
    >
      <button
        type="button"
        className={`w-full flex items-center justify-center relative ${
          isOpen
            ? 'bg-shadow-600 rounded-t-2xl'
            : 'bg-black bg-opacity-20 rounded-2xl'
        } ${isDisabled && 'bg-shadow-600 cursor-default'}  h-10`}
        onClick={!isDisabled && handleSelect}
      >
        <span
          className={`text-white ${
            isDisabled && 'text-ashes-800'
          } block max-w-[70%] overflow-hidden whitespace-nowrap text-ellipsis`}
        >
          {itemData?.length > 0
            ? itemData
                .map(
                  (opt) =>
                    options.find((option) => option[value] === opt.value)?.[
                      label
                    ],
                )
                .join(', ')
            : placeHolder}
        </span>
        <p className="absolute flex items-center right-3 text-grass-800 h-4 w-4">
          {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </p>
      </button>
      <div
        className={`z-10 w-full bg-shadow-600 rounded-b-2xl text-white h-[120px] overflow-x-hidden px-4 ${
          isOpen ? 'absolute' : 'hidden'
        }`}
      >
        {options.map((opt) => (
          <button
            type="button"
            onClick={() => {
              handleItem(opt);
            }}
            className={`filter-row w-full flex items-center justify-center h-10 border-b border-shadow-700 last:border-b-0 ${
              isItemSelected(opt[value]) && 'text-ashes-600'
            }`}
            key={opt[value]}
          >
            {opt[label]}
          </button>
        ))}
      </div>
    </div>
  );
}

TwMultiselect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.node,
  setData: PropTypes.node,
  size: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  placeHolder: PropTypes.string,
  font: PropTypes.string,
  color: PropTypes.string,
  classList: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default TwMultiselect;
