import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, Autoplay } from 'swiper/modules';

import { InternalButton } from '@/components/atoms/Buttons/Button/styles';
import { DELAYS_IN_SECONDS } from '@/helpers/constants';

import { CarouselItem, SwiperCarousel } from './styles';

import 'swiper/css';
import 'swiper/css/pagination';

function Carousel({ data = [] }) {
  return (
    <SwiperCarousel
      pagination
      modules={[Pagination, Autoplay]}
      autoplay={{ delay: DELAYS_IN_SECONDS.long }}
      loopAdditionalSlides={1}
      observer
      observeParents
      loop
      slidesPerView={1}
    >
      {data.map(
        ({
          buttonLabel,
          buttonId,
          buttonPath,
          customHeight,
          customMarginTop,
          externalLink,
          handleClick,
          id,
          imageAlt,
          imagePath,
          textContent,
        }) => (
          <CarouselItem
            key={id}
            $customHeight={customHeight}
            customMarginTop={customMarginTop}
          >
            <div>
              <img src={imagePath} alt={imageAlt} />
              <div>
                <p>{textContent}</p>
                {buttonLabel && (buttonPath || handleClick) && (
                  <InternalButton
                    id={buttonId}
                    to={
                      externalLink
                        ? { pathname: buttonPath ?? '#' }
                        : buttonPath ?? '#'
                    }
                    {...(externalLink ? { target: '_blank' } : {})}
                    onClick={handleClick}
                  >
                    {buttonLabel}
                  </InternalButton>
                )}
              </div>
            </div>
          </CarouselItem>
        ),
      )}
    </SwiperCarousel>
  );
}

Carousel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      buttonLabel: PropTypes.string,
      buttonPath: PropTypes.string,
      customHeight: PropTypes.string,
      customMarginTop: PropTypes.string,
      externalLink: PropTypes.bool,
      handleClick: PropTypes.func,
      id: PropTypes.string,
      imageAlt: PropTypes.string,
      imagePath: PropTypes.string,
      textContent: PropTypes.string,
    }),
  ),
};

export default Carousel;
