import styled from 'styled-components';

import { ReactComponent as KeyIcon } from '@/assets/key.svg';

export const Wrapper = styled.div`
  align-items: center;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.info.gray};
  display: flex;
  gap: 0.3rem;
  height: 25px;
  justify-content: center;
  margin-right: 5px;
  text-transform: uppercase;
  width: 66px;

  span {
    font: ${({ theme }) =>
      `${theme.font.size.medium} ${theme.font.family.secondary}`};
  }
`;

export const Key = styled(KeyIcon)`
  width: 22px;
`;
