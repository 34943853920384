import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import toast from 'react-hot-toast';
import IdentityValidationStatus from '@/components/organisms/IdentityValidationStatus';
import IFrameOcr from '@/components/organisms/IFrameOcr';
import TransferApiTemplate from '@/components/templates/TransferApiTemplate';
import IdwallSdk from '@/components/organisms/IdwallSdk';
import MainTemplate from '@/components/templates/MainTemplate';
import useUserStatus from '@/hooks/useUserStatus';
import { useUserData } from '@/context/userContext';
import { getPromocode, getUserInfo, retryValidation } from '@/services/api';
import { registrationStatus } from '@/helpers/constants';

import { SdkWrapper } from './styles';
import CafValidation from '@/components/organisms/CafValidation';
import useUpdateUser from '@/hooks/useUpdateUser';
import RegisterValidationErrorMessage from '@/components/molecules/RegisterValidationErrorMessage';
import i18n from '@/translate/i18n';
import { extractErrorSlug } from '@/helpers/functions';
import PromocodePointsModalContent from '@/components/v2/Molecules/PromocodePointsModalContent';
import PromocodeBonusModalContent from '@/components/v2/Molecules/PromocodeBonusModalContent';

const STATUS_PENDING = 2;
const STATUS_REJECTED = 3;
const STATUS_PROCESSING = 4;

function ValidateIdentity() {
  const [points, setPoints] = useState(0);
  const [titleBonuspromocode, setTitleBonuspromocode] = useState('');
  const [showCompleteModal, setShowCompleteModal] = useState(true);
  const [hasToShowIframe, setHasToShowIframe] = useState(false);
  const { userData, setUserData } = useUserData();
  const { pathname } = useLocation();
  const { getUserStatusAndRedirect } = useUserStatus();
  const [documentRejected, setDocumentRejected] = useState(false);
  const { updateUserData } = useUpdateUser();

  const alreadyInValidation = userData?.status >= STATUS_PROCESSING;
  const [error, setError] = useState('');
  const [documentSent, setDocumentSent] = useState(alreadyInValidation);
  const [retryIds, setRetryIds] = useState({ timeout: null, interval: null });
  const isExternalFlow = pathname.includes('/external');
  const PageTemplate = isExternalFlow ? TransferApiTemplate : MainTemplate;

  const clearTimers = () => {
    clearTimeout(retryIds.timeout);
    clearInterval(retryIds.interval);
  };

  const getUserPromocode = async () => {
    try {
      const req = await getPromocode();
      if (req.data.points) {
        setPoints(req.data.points);
      } else {
        setTitleBonuspromocode(req.data.bonus.title);
      }
    } catch (err) {
      extractErrorSlug(err?.response);
    }
  };

  const checkStatus = async (clearIntervals) => {
    const res = await getUserInfo();
    if (res?.data?.status === registrationStatus.validateId) {
      setError(true);
      clearIntervals();
    } else if (res?.data?.status >= registrationStatus.waitingIdValidation) {
      setDocumentSent(true);
      if (res?.data?.status === registrationStatus.waitingIdValidation) {
        updateUserData();
      }
    } else if (res?.data?.status === registrationStatus.validateRegistration) {
      setDocumentSent(false);
    }
  };

  const restart = () => {
    const request = retryValidation();
    toast.promise(request, {
      loading: i18n.t('validateIdentity.sending'),
      success: () => {
        const checker = () => {
          const intervalId = setInterval(() => {
            checkStatus(clearTimers);
            setRetryIds((state) => ({ ...state, interval: intervalId }));
          }, 15000);
        };

        const timeout = () => {
          const timeoutId = setTimeout(() => {
            checker();
          }, 6000);

          setRetryIds((state) => ({ ...state, timeout: timeoutId }));
        };

        timeout();
        setHasToShowIframe(false);
        setDocumentSent(false);
        setError(false);
        setUserData((state) => ({ ...state, status: 2 }));
        return i18n.t('validateIdentity.resetSuccess');
      },
      error: (err) => {
        const messages = extractErrorSlug(err?.response);
        return i18n.t([
          `error.resendEmail.${messages?.[0]}`,
          'error.resendEmail.unspecific',
        ]);
      },
    });
  };

  useEffect(() => {
    getUserStatusAndRedirect(userData?.status);
  }, [getUserStatusAndRedirect, userData]);

  useEffect(() => {
    let timeoutId;
    let intervalId;

    getUserPromocode();

    if (userData?.status === registrationStatus.validateId) {
      setError(true);
    }

    const clearEffectTimers = () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };

    const checker = () => {
      intervalId = setInterval(() => {
        checkStatus(clearEffectTimers);
      }, 15000);
    };

    const timeout = () => {
      timeoutId = setTimeout(() => {
        checker();
      }, 6000);
    };

    if (
      userData.status <= registrationStatus.waitingIdValidation &&
      userData.status !== registrationStatus.validateId
    ) {
      timeout();
    }

    if (userData?.status >= registrationStatus.validateId) {
      setShowCompleteModal(false);
    }

    return () => {
      clearEffectTimers();
      if (retryIds.interval) {
        clearTimers();
      }
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    showCompleteModal &&
      userData?.status > STATUS_PROCESSING &&
      toast.success(i18n.t('registration.success'));
  }, [userData, showCompleteModal]);

  useEffect(() => {
    let newUrl = pathname;
    switch (userData?.status) {
      case STATUS_PENDING:
        newUrl += '?status=pending';
        break;
      case STATUS_REJECTED:
        newUrl += '?status=rejected';
        break;
      case STATUS_PROCESSING:
        newUrl += '?status=processing';
        break;
      default:
    }
    window.history.pushState(null, '', newUrl);
  }, [userData]);

  return (
    <>
      <PageTemplate
        containerProps={{ gradient: true, background: 'black' }}
        headerProps={{ mobileFullVersion: true, background: 'black' }}
        wrapperProps={{ background: 'black' }}
        hideHeader={isExternalFlow}
        hideNavMenu={isExternalFlow}
      >
        {error ? (
          <div className="flex flex-col justify-center items-center h-4/5 gap-10">
            <RegisterValidationErrorMessage restartValidation={restart} />
          </div>
        ) : !hasToShowIframe && !alreadyInValidation && !documentSent ? (
          <CafValidation renderIframe={() => setHasToShowIframe(true)} />
        ) : documentSent && !documentRejected ? (
          <IdentityValidationStatus
            isExternalFlow={isExternalFlow}
            setDocumentRejected={setDocumentRejected}
            alreadyInValidation={alreadyInValidation}
          />
        ) : (
          <SdkWrapper>
            {userData?.kyc_provider === 'idwall' ? (
              <IdwallSdk
                setErrorMessage={setError}
                onSuccess={() => setDocumentSent(true)}
              />
            ) : (
              <IFrameOcr
                ocrProviderSlug={userData?.kyc_provider}
                setError={setError}
                onSuccess={() => setDocumentSent(true)}
              />
            )}
          </SdkWrapper>
        )}
      </PageTemplate>
      {showCompleteModal && Boolean(points) && (
        <PromocodePointsModalContent
          handleCloseModal={() => setShowCompleteModal(false)}
          points={points}
        />
      )}
      {showCompleteModal && !points && titleBonuspromocode !== '' && (
        <PromocodeBonusModalContent
          handleCloseModal={() => setShowCompleteModal(false)}
          nameBonus={titleBonuspromocode}
        />
      )}
    </>
  );
}

export default ValidateIdentity;
