import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import { colors } from '@/styles/colors';
import { Title } from '../Typography/styles';

import { BonusIconStyled, Icon, NoBonusIcon, Wrapper } from './styles';

const DEFAULT_ICON_SIZE = 40;

const DEFAULT_ICON_SYMBOL = {
  free: 'G',
  fixed: 'F',
  percentage: '%',
};

const BONUS_COLORS = {
  'transfer-in': colors.green[300],
  deposit: colors.lightBlue[400],
};

function BonusIcon({ bonus, isDefault, showTitle, size, noBonus, onlyIcon }) {
  const isFreeBonus = !bonus?.min_amount_transaction && bonus?.type === 'fixed';
  const bonusType = isFreeBonus ? 'free' : bonus?.type;

  if (onlyIcon) {
    return (
      <Icon type={bonusType} size={size} onlyIcon>
        {DEFAULT_ICON_SYMBOL[bonusType] || 'F'}
      </Icon>
    );
  }

  return (
    <Wrapper>
      {noBonus ? (
        <NoBonusIcon size={DEFAULT_ICON_SIZE} />
      ) : (
        <BonusIconStyled color={BONUS_COLORS[bonus?.transaction_type]} />
      )}
      {showTitle && bonus?.title && (
        <Title textColor="title">{bonus.title}</Title>
      )}
      {showTitle && bonus?.type && (
        <span>
          {i18n.t([`bonusesAndPromotions.bonusType.${bonusType}`, bonus?.type])}
        </span>
      )}
    </Wrapper>
  );
}

BonusIcon.propTypes = {
  bonus: PropTypes.shape({
    type: PropTypes.string,
    amount: PropTypes.number,
    title: PropTypes.string,
  }),
  isDefault: PropTypes.bool,
  showTitle: PropTypes.bool,
}.isRequired;

export default BonusIcon;
