import React, { useEffect } from 'react';

import Carousel from '@/components/organisms/Carousel';
import GetStart from '@/components/organisms/GetStart';
import MainTemplate from '@/components/templates/MainTemplate';
import { useUserData } from '@/context/userContext';
import useUserStatus from '@/hooks/useUserStatus';

import { carouselData } from './data';
import { ContainerSlider, Section, SectionDivider } from './styles';
import useAllMerchants from '@/hooks/useAllMerchants';
import CafValidation from '@/components/organisms/CafValidation';

const PENDING_STATUS = 2;

function WalletHome() {
  const { getUserStatusAndRedirect } = useUserStatus();
  const { getAllMerchants } = useAllMerchants();

  const {
    userData: { status },
  } = useUserData();
  const isPending = status === PENDING_STATUS;
  const pendingBackground = isPending ? 'black' : '';

  useEffect(() => {
    getUserStatusAndRedirect(status);
  }, [getUserStatusAndRedirect, status]);

  useEffect(() => {
    const controller = new AbortController();
    const merchantList =
      localStorage.getItem('merchants') !== 'undefined'
        ? JSON.parse(localStorage.getItem('merchants'))
        : null;

    if (!merchantList) {
      getAllMerchants();
    }

    return () => controller.abort();
  }, []);

  return (
    <MainTemplate
      containerProps={{
        gradient: true,
        background: pendingBackground,
      }}
      headerProps={{
        mobileFullVersion: true,
        background: pendingBackground,
      }}
      wrapperProps={{ background: pendingBackground }}
    >
      {isPending ? (
        <CafValidation />
      ) : (
        <div className="flex justify-around items-start sm:items-center size-full md:py-10">
          <GetStart />
          <SectionDivider />
          <Section hideInMobile>
            <ContainerSlider>
              <Carousel data={carouselData} />
            </ContainerSlider>
          </Section>
        </div>
      )}
    </MainTemplate>
  );
}

export default WalletHome;
