import styled from 'styled-components';

import { ReactComponent as ErrorSvg } from '@/assets/errorIcon.svg';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 25%;
  min-height: ${({ height }) => height || 'inheit'};
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.info.light};
  font-size: ${({ theme }) => theme.font.size.small};
  max-width: 300px;
  text-align: center;
`;

export const ErrorIcon = styled(ErrorSvg)`
  margin-bottom: 30px;
`;
