import React from 'react';
import PropTypes from 'prop-types';

import Loading from '@/components/atoms/Loading';
import MainTemplate from '@/components/templates/MainTemplate';
import SectionDivider from '@/components/atoms/SectionDivider/styles';
import ValidationProgressSummary from '@/components/molecules/ValidationProgressSummary';
import i18n from '@/translate/i18n';
import {
  ContentWrapper,
  Section,
} from '@/components/templates/SectionStyles/styles';

function IncreaseLimitsTemplate({ children, returnPath, isFetching }) {
  return (
    <MainTemplate
      containerProps={{ gradientLight: true }}
      headerProps={{
        title: i18n.t('increaseLimits.title'),
        returnTo: returnPath ?? '/wallet',
      }}
    >
      {isFetching ? (
        <Loading marginTop="25%" />
      ) : (
        <ContentWrapper>
          <Section hideInMobile>
            <ValidationProgressSummary />
          </Section>

          <SectionDivider />
          {children}
        </ContentWrapper>
      )}
    </MainTemplate>
  );
}

IncreaseLimitsTemplate.propTypes = {
  children: PropTypes.element,
  returnPath: PropTypes.string,
}.isRequired;

export default IncreaseLimitsTemplate;
