import React from 'react';

import i18n from '@/translate/i18n';
import { calculateBonus, createRandomId } from '@/helpers/functions';
import { formatCurrency } from '@/helpers/stringFormat';
import { useWalletData } from '@/context/walletContext';
import TwDetailsLine from '@/components/v2/atoms/TwDetailsLine';

function DepositPreview() {
  const {
    walletData: {
      selectedWallet,
      transactionData,
      selectedBonus,
      transactionSubmitError,
    },
  } = useWalletData();
  const bonusAmount = calculateBonus(transactionData?.amount, selectedBonus);
  const totalAmount = Number(bonusAmount) + Number(transactionData?.amount);

  const fields = [
    {
      label: i18n.t('bonusConfirmation.method'),
      value: transactionData?.method?.name,
    },
    ...(selectedBonus?.id
      ? [
          {
            label: selectedBonus?.title,
            value: formatCurrency(bonusAmount ?? 0, selectedWallet),
          },
        ]
      : []),
    {
      label: transactionSubmitError
        ? i18n.t('transactionStatus.youWouldHaveReceived')
        : i18n.t('bonusConfirmation.youReceive'),
      value: formatCurrency(totalAmount, selectedWallet),
      border: true,
    },
  ];

  return (
    <>
      {fields.map(({ border, label, value, isBonusField }) => (
        <TwDetailsLine
          info={label}
          amount={value}
          size="sm"
          key={createRandomId()}
          divider={border}
        />
      ))}
    </>
  );
}

export default DepositPreview;
