import styled from 'styled-components';

import { Form } from 'formik';

export const FormStyled = styled(Form)`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 90%;
  width: 100%;
`;

export const InputWrapper = styled.div`
  margin: 20px 0 50px;
`;

export const AnimationWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.primary};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.font.size.small};
  height: 80%;
  justify-content: space-evenly;
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 2rem;
    height: 100%;
  }
`;
