import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    align-items: center;
    animation: goLeftDefault 0.5s;
    color: ${theme.colors.info.primary};
    display: flex;
    flex-direction: column;
    gap: 5vh;
    height: 100%;
    justify-content: space-between;
    max-width: 400px;
    padding: 0 2rem 7.5rem 2rem;
    width: 100%;

    span {
      color: ${theme.colors.primary};
      font-family: ${theme.font.family.secondary};
    }
    & > p {
      margin-top: 1rem;
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      gap: 3vh;
      height: 90%;
      padding: 0;
      width: 80%;
    }
  `}
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  font-size: ${({ theme }) => theme.font.size.small};
  gap: 2rem;
  justify-content: space-between;
  padding: 0.8rem 0;
  width: 100%;

  p {
    max-width: 240px;
  }
`;
