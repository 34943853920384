import styled, { css } from 'styled-components';

export const Container = styled.footer`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.bg.secondary};
    display: none;
    flex-direction: column-reverse;
    gap: 0.6rem;
    justify-content: space-between;
    padding: 2rem;
    text-align: center;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 40px;
      padding: 1.5rem 1rem;
      text-align: left;
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      padding: 1.5rem 3rem;
    }

    div {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
    }

    p {
      align-items: center;
      color: ${theme.colors.info.light};
      display: flex;
      gap: 0.3rem;
    }

    svg {
      height: 23px;
      width: 23px;
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      align-items: center;
      flex-direction: row;
      height: 40px;
      padding: 1.5rem 3rem;

      div {
        flex-direction: row;
        gap: 2rem;
      }

      svg {
        height: 20px;
        width: 20px;
      }

      p {
        font-size: ${theme.font.size.xSmall};
      }
    }

    @media screen and (min-width: ${theme.breakpoints.md}) {
      svg {
        height: 23px;
        width: 23px;
      }

      p {
        font-size: ${theme.font.size.small};
      }
    }
  `}
`;
