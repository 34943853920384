import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  SkeletonCarouselMechContainer,
  SkeletonDiscoMerchentBody,
  SkeletonDiscoMerchent,
  SkeletonMinDiscoMerchentContainer,
  SkeletonDiscoMerchentSelected,
  SkeletonNameMerchant,
  SkeletonMinDiscoMerchent,
  SkeletonDiscoMerchentContainer,
} from './styles';

const QUANTITY_MIN_SKELETON = 5;

function SkeletonCarouselMech() {
  return (
    <>
      <SkeletonCarouselMechContainer>
        <SkeletonDiscoMerchentBody>
          <SkeletonDiscoMerchentContainer>
            <SkeletonDiscoMerchent />
          </SkeletonDiscoMerchentContainer>
          <SkeletonDiscoMerchentSelected />
          <SkeletonDiscoMerchentContainer>
            <SkeletonDiscoMerchent />
          </SkeletonDiscoMerchentContainer>
        </SkeletonDiscoMerchentBody>
        <SkeletonNameMerchant />
        <SkeletonMinDiscoMerchentContainer>
          {Array.from({ length: QUANTITY_MIN_SKELETON }).map(() => (
            <SkeletonMinDiscoMerchent key={uuidv4()} />
          ))}
        </SkeletonMinDiscoMerchentContainer>
      </SkeletonCarouselMechContainer>
    </>
  );
}

export default SkeletonCarouselMech;
