import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.info.primary};
    display: flex;
    gap: 0.5rem;
    margin: 1rem;

    span {
      display: none;
    }

    strong {
      color: ${theme.colors.info.points};
      font: ${`${theme.font.size.large} ${theme.font.family.boldItalic}`};
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      span {
        display: inline;
        font: ${`${theme.font.size.large} ${theme.font.family.secondary}`};
        text-transform: capitalize;
      }
    }
  `}
`;

export default Wrapper;
