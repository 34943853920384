import React from 'react';
import PropTypes from 'prop-types';
import i18n from '@/translate/i18n';

function FiltersSelected({ data = [], setData }) {
  return (
    <div className="max-w-[460px] px-[8%] w-full flex items-center justify-center flex-wrap text-sm text-white font-arboria-regular">
      {Object.entries(data).map(([key, value]) => {
        if (Array.isArray(value) && value.length) {
          return value?.map((filter) => (
            <button
              className="flex mb-2 items-center justify-between rounded-md px-2 mx-1 text-black h-[20px] bg-ashes-500 hover:bg-ashes-600"
              type="button"
              name={filter.label}
              onClick={() => {
                setData((state) => ({
                  ...state,
                  [key]: state[key].filter((opc) => opc.value !== filter.value),
                }));
              }}
              key={filter.label}
            >
              <span className="mr-2">{filter.label}</span>X
            </button>
          ));
        }
        if (value && key === 'created_at_to') {
          return (
            <button
              className="flex mb-2 items-center justify-between rounded-md px-2 mx-1 text-black h-[20px] bg-ashes-500 hover:bg-ashes-600"
              type="button"
              name={key}
              onClick={() => {
                setData((state) => {
                  const currentState = JSON.parse(JSON.stringify(state));
                  delete currentState.created_at_from;
                  delete currentState.created_at_to;
                  return currentState;
                });
              }}
              key={key}
            >
              <span className="mr-2">{i18n.t('filters.date')}</span>X
            </button>
          );
        }
        return null;
      })}
    </div>
  );
}

FiltersSelected.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  setData: PropTypes.node,
};

export default FiltersSelected;
