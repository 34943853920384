import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ hideInDesktop, hideInMobile, marginBottom = '20px', theme }) => css`
    align-items: center;
    display: ${hideInMobile ? 'none' : 'flex'};
    flex-direction: column;
    justify-content: center;
    margin-bottom: ${marginBottom};

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      display: ${hideInDesktop && 'none'};
    }
  `}
`;

export const Image = styled.img`
  height: 4vw;
  max-height: 90px;
  max-width: 90px;
  min-height: 50px;
  min-width: 50px;
  width: 4vw;
`;

export const LoadingText = styled.p`
  animation: blinker 1.5s linear infinite;
  color: ${({ theme, isDark }) =>
    isDark ? theme.colors.info.dark : theme.colors.primary};
  font: ${({ theme }) =>
    `${theme.font.size.small} ${theme.font.family.primary}`};
`;
