import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

import ToggleSwitchButton from '@/components/atoms/Buttons/ToggleSwitchButton';
import SectionDivider from '@/components/atoms/SectionDivider/styles';
import SettingValidationMessage from '@/components/atoms/SettingValidationMessage';
import InterestPreference from '@/components/molecules/Modal/Messages/InterestPreferences';
import MainTemplate from '@/components/templates/MainTemplate';
import UpdatePasswordForm from '@/components/molecules/Modal/Messages/UpdatePasswordForm';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import {
  GENERAL_COMMUNICATION_SLUG,
  TRANSACTION_INTEREST_SLUGS,
} from '@/helpers/constants';
import { useUserData } from '@/context/userContext';
import { useWalletData } from '@/context/walletContext';
import { getInterests, updateUserInterests } from '@/services/api';
import i18n from '@/translate/i18n';

import {
  ContentWrapper,
  DetailsWrapper,
  ModalButton,
  SectionWrapper,
  TitleWrapper,
  Wrapper,
} from './styles';
import TwTitle from '@/components/atoms/TwTitle';

const SWITCH_BTN_SIZE = 16;

function Settings() {
  const { isMobileLayout } = useDeviceWidth();
  const { userData, updateInterests } = useUserData();
  const [interests, setInterests] = useState([]);
  const [showDetails, setShowDetails] = useState('');
  const { setIsLoading: setIsUpdating } = useWalletData();

  const { interests: userInterests } = userData.details.notification;
  const isNotificationActive = TRANSACTION_INTEREST_SLUGS.every((el) =>
    userInterests.includes(el),
  );

  const handleToggleNotification = async (interestSlugs) => {
    try {
      setIsUpdating(true);
      let newValue = [];

      if (interestSlugs.every((el) => userInterests.includes(el))) {
        newValue = userInterests.filter(
          (slug) => !interestSlugs.includes(slug),
        );
      } else {
        newValue = [...userInterests, ...interestSlugs];
      }

      const filtered = interests.filter(({ slug }) => newValue.includes(slug));
      const interestsIds = filtered.map((el) => el.id);
      const payload = {
        interests: interestsIds,
      };

      await updateUserInterests(localStorage.getItem('id'), payload);
      updateInterests(newValue);
    } catch (err) {
      toast.error(
        i18n.t('error.unspecific', { id: 'update-notification-error-message' }),
      );
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        const res = await getInterests({}, controller.signal);

        setInterests(res.data);
      } catch (err) {
        console.error(err?.response?.data?.message);
      }
    };
    fetchData();
    return () => controller.abort();
  }, []);

  const showInterestPreferences = !isMobileLayout && !showDetails;
  const shouldDisableButtons =
    showDetails === 'passwordUpdate' && !isMobileLayout;

  const generalUserInterests = userInterests?.filter(
    (el) =>
      TRANSACTION_INTEREST_SLUGS.includes(el) ||
      el === GENERAL_COMMUNICATION_SLUG,
  );

  return (
    <MainTemplate
      className="!text-left"
      containerProps={{ gradientLight: true }}
      headerProps={{
        title: i18n.t('settings.title'),
        returnTo: '/wallet',
        returnAction: showDetails ? () => setShowDetails('') : null,
      }}
    >
      <ContentWrapper>
        {(!showDetails || !isMobileLayout) && (
          <Wrapper>
            <TitleWrapper>
              <TwTitle
                id="settings-title"
                text={i18n.t('settings.title')}
                size="xl"
                font="arboriaBold"
                color="shadow-500"
                classList="text-center"
              />
            </TitleWrapper>
            <SectionWrapper isOpen={shouldDisableButtons}>
              <TwTitle
                id="settings-interest"
                text={i18n.t('settings.interests')}
                size="xs"
                font="arboriaBold"
                color="shadow-500"
                classList="text-left uppercase"
              />
              <p className="text-left font-arboria-bold text-sand-800 text-sm">
                {i18n.t('settings.interestsDescription')}
              </p>
              <ModalButton
                type="button"
                onClick={() => {
                  setShowDetails('interestDetails');
                }}
                disabled={shouldDisableButtons}
                hideInDesktop
              >
                {i18n.t('settings.update')}
              </ModalButton>
            </SectionWrapper>
            <SectionWrapper isOpen={shouldDisableButtons}>
              <TwTitle
                id="settings-security"
                text={i18n.t('settings.security')}
                size="xs"
                font="arboriaBold"
                color="shadow-500"
                classList="text-left uppercase"
              />
              <p className="text-left font-arboria-bold text-sand-800 text-sm">
                {i18n.t('settings.securityDescription')}
              </p>
              <ModalButton
                type="button"
                disabled={shouldDisableButtons}
                onClick={() => {
                  setShowDetails('passwordUpdate');
                }}
              >
                {i18n.t('settings.update')}
              </ModalButton>
            </SectionWrapper>
            <SectionWrapper isOpen={shouldDisableButtons}>
              <TwTitle
                id="settings-notifications"
                text={i18n.t('settings.notifications')}
                size="xs"
                font="arboriaBold"
                color="shadow-500"
                classList="text-left uppercase"
              />
              <p className="text-left font-arboria-bold text-sand-800 text-sm">
                {i18n.t('settings.notificationDescription')}
              </p>
              <div className="flex flex-col gap-2">
                <div className="grid grid-cols-2 items-center">
                  <p className="text-left font-arboria-bold text-sand-800 text-sm">
                    {i18n.t('settings.transactions')}
                  </p>
                  <ToggleSwitchButton
                    id="transaction-switch"
                    disabled={shouldDisableButtons}
                    onClick={() =>
                      handleToggleNotification(TRANSACTION_INTEREST_SLUGS)
                    }
                    checked={isNotificationActive}
                    size={SWITCH_BTN_SIZE}
                  />
                </div>
                <div className="grid grid-cols-2 items-center">
                  <p className="text-left font-arboria-bold text-sand-800 text-sm">
                    {i18n.t('settings.generalCommunication')}
                  </p>
                  <ToggleSwitchButton
                    name="transaction-switch"
                    id="communication-switch"
                    disabled={shouldDisableButtons}
                    onClick={() =>
                      handleToggleNotification([GENERAL_COMMUNICATION_SLUG])
                    }
                    checked={userInterests.includes(GENERAL_COMMUNICATION_SLUG)}
                    size={SWITCH_BTN_SIZE}
                  />
                </div>
              </div>
            </SectionWrapper>
            <SectionWrapper isOpen={shouldDisableButtons}>
              <TwTitle
                id="settings-identity"
                text={i18n.t('settings.identity')}
                size="xs"
                font="arboriaBold"
                color="shadow-500"
                classList="text-left uppercase"
              />
              <SettingValidationMessage status={userData?.status} />
            </SectionWrapper>
          </Wrapper>
        )}

        <SectionDivider height="70%" />

        {(showDetails === 'interestDetails' || showInterestPreferences) && (
          <DetailsWrapper>
            <InterestPreference
              data={interests}
              handleClose={() => setShowDetails('')}
              generalUserInterests={generalUserInterests}
            />
          </DetailsWrapper>
        )}
        {showDetails === 'passwordUpdate' && (
          <DetailsWrapper>
            <UpdatePasswordForm
              handleClose={() => setShowDetails('')}
              setIsUpdating={setIsUpdating}
            />
          </DetailsWrapper>
        )}
      </ContentWrapper>
    </MainTemplate>
  );
}

export default Settings;
