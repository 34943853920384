import styled, { css } from 'styled-components';
import { MdRefresh } from 'react-icons/md';
import { hexToRGBA } from '@/styles/colors';

export const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: inline;
  }
`;

export const Amount = styled.h1`
  ${({ theme, noAmount }) => css`
    color: ${theme.colors.primary};
    cursor: pointer;
    font: ${`${theme.font.size.xLarge} ${theme.font.family.primary}`};
    user-select: none;

    span {
      color: ${noAmount
        ? hexToRGBA(theme.colors.info.primary, 0.75)
        : theme.colors.primary};
      margin-left: 5px;
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      font-size: ${theme.font.size.xLarge};
    }
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    color: ${hexToRGBA(theme.colors.info.primary, 0.75)};
    font: ${`${theme.font.size.xSmall} ${theme.font.family.secondary}`};
  `}
`;

export const ErrorWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  margin: 12% 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 0;
  }
`;

export const RefreshButton = styled.button`
  background-color: transparent;
  border: none;
`;

export const RefreshIcon = styled(MdRefresh)`
  animation: ${({ animate }) => animate && 'loading 1s linear infinite'};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 22px;
  margin-left: 5px;
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`;

export const CurrencyButton = styled.div`
  ${({ theme, active }) => css`
    border-bottom: ${active ? `1px solid ${theme.colors.primary}` : 'none'};
    color: ${active ? theme.colors.info.light : theme.colors.info.title};
    cursor: pointer;
    font: ${theme.font.size.small} ${theme.font.family.secondary};
  `}
`;
