import React, { useState } from 'react';

import Loading from '@/components/atoms/Loading';
import MainTemplate from '@/components/templates/MainTemplate';
import ProfileHeader from '@/components/molecules/ProfileHeader';
import ProfileSections from '@/components/organisms/ProfileSections';
import SectionDivider from '@/components/atoms/SectionDivider/styles';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import useUserValidations from '@/hooks/useUserValidations';
import { useUserData } from '@/context/userContext';

import ContentWrapper from './styles';
import TwTitle from '@/components/atoms/TwTitle';

function Profile() {
  const [activeItem, setActiveItem] = useState('');
  const { isFetching } = useUserValidations();
  const {
    userData: { validations },
  } = useUserData();

  const { isMobileLayout } = useDeviceWidth();

  return (
    <MainTemplate
      containerProps={{ gradientLight: true }}
      headerProps={{ title: i18n.t('profile.title'), returnTo: '/wallet' }}
    >
      <ContentWrapper isLoading={isFetching}>
        {!isFetching ? (
          <>
            {isMobileLayout && (
              <TwTitle
                text={i18n.t('profile.personalData')}
                size={isMobileLayout ? 'm_lg' : 'd_lg'}
                font="arboriaBold"
                classList="text-center mb-2"
              />
            )}
            <ProfileHeader />
            <SectionDivider breakpoint="1024px" height="70%" />
            <ProfileSections
              setActiveItem={setActiveItem}
              validations={validations}
              isFetching={isFetching}
              isOpen={!!activeItem.length}
            />
          </>
        ) : (
          <Loading marginTop={isMobileLayout ? '40vh' : ''} />
        )}
      </ContentWrapper>
    </MainTemplate>
  );
}

export default Profile;
