import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';
import MainTemplate from '@/components/templates/MainTemplate';
import { getListMerchantsCashback, getMerchant } from '@/services/api';
import BackButton from '@/components/atoms/BackButton';

import useDeviceWidth from '@/hooks/useDeviceWidth';

import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';
import InputCopy from '@/components/atoms/Inputs/InputCopy';
import TitleModal from '@/components/molecules/TitleModal';
import { formatCurrency } from '@/helpers/stringFormat';
import { useWalletData } from '@/context/walletContext';
import i18n from '@/translate/i18n';
import CashbackTutorialModal from '@/components/organisms/CashbackTutorialModal';
import { STATUS_AFFILIATE } from '@/helpers/constants';
import IconVplus from '@/components/molecules/IconVplus';
import MerchanDisc from '@/components/atoms/MerchanDisco';
import { TwModal } from '@/components/v2/atoms/TwModal';
import TwTitle from '@/components/atoms/TwTitle';
import TwButton from '@/components/atoms/Buttons/TwButton';
import TwText from '@/components/v2/atoms/TwText';
import Loading from '@/components/atoms/Loading';
import TwTextButton from '@/components/atoms/Buttons/TwTextButton';

const ZERO = 0;
const AFFILIATED = 'affiliated';
const VPLUS = 'vplus';
const VPAG = 'Vpag';
const CASHBACK_PIX = 'cashback-pix';
const GENERAL = 'general';
const EMPTY_STRING = '';
const ONE_HUNDRED = 100;

function CashbackDetail() {
  const {
    walletData: { selectedWallet },
  } = useWalletData();
  const { idMerchant } = useParams();
  const [merchant, setMerchant] = useState({});
  const { isMobileLayout } = useDeviceWidth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [pixKey, setPixKey] = useState('');
  const [infoCashback, setInfoCashback] = useState({});
  const [showModalSeeDetails, setShowModalseeDetails] = useState(false);
  const [showModalTutorial, setShowModalTutorial] = useState(false);
  const [affiliated, setAffiliated] = useState(false);
  const [cashbackBlocked, setCashbackBlocked] = useState(false);

  const handleCloseModalTutorial = () => {
    setShowModalTutorial(false);
  };

  const handleCloseModalSeeDetails = () => {
    setShowModalseeDetails(false);
  };

  const getInfoMerchant = async (item) => {
    setIsLoading(true);
    const res = await getMerchant(item);
    const marchantsCashbacks = await getListMerchantsCashback();
    const marchant = marchantsCashbacks.data.filter(
      (element) => String(element.id) === String(item),
    );
    setIsLoading(false);
    return { ...res.data, info: marchant[ZERO] };
  };

  const handleBackStep = () => {
    history.push('/cashback/merchants-actives');
  };
  const isObjectEmpty = (obj) => Object.keys(obj).length === ZERO;

  useEffect(async () => {
    setMerchant(await getInfoMerchant(idMerchant));
  }, []);

  useEffect(() => {
    if (isObjectEmpty(merchant)) return;
    const cashbacks = merchant.info.cashbacks.filter(
      (element) => element.transaction_type === CASHBACK_PIX,
    );
    if (merchant.info.usernames[ZERO]?.affiliate_status === AFFILIATED) {
      setInfoCashback(
        cashbacks.find((element) => element.user_type === VPLUS) || '',
      );
    } else {
      setInfoCashback(
        cashbacks.find((element) => element.user_type === GENERAL) || '',
      );
    }
    setPixKey(merchant?.info.pix_key || '');
  }, [merchant]);

  useEffect(() => {
    if (isObjectEmpty(merchant)) return;
    setAffiliated(
      STATUS_AFFILIATE === merchant.info.usernames[ZERO]?.affiliate_status,
    );
  }, [merchant]);

  useEffect(() => {
    setCashbackBlocked(
      merchant?.info?.usernames?.[ZERO]?.cashback_blocked ?? false,
    );
  }, [merchant]);

  return (
    <MainTemplate
      containerProps={{ gradient: true }}
      headerProps={{ mobileFullVersion: false }}
    >
      <div className="w-full pt-2.5 pr-0 pb-12.5 pl-15 lg:mt-[1.875rem] mr-0 mb-[1.75rem] ml-0 ">
        {!isMobileLayout && (
          <div className="w-fit ml-7">
            <BackButton to="/cashback/merchants-actives" text="Voltar" />
          </div>
        )}
      </div>
      <div className="absolute w-full gap-8 flex flex-col items-center mb-10 -mt-[50px] md:relative md:mt-0">
        {isLoading ? (
          <div className="h-screen md:h-full flex flex-col justify-center items-center">
            <Loading />
          </div>
        ) : (
          <>
            {isMobileLayout && <TitleModal handleBackStep={handleBackStep} />}
            <div className="flex flex-col items-center gap-8 h-fit">
              <div className="relative h-fit">
                {affiliated && (
                  <div className="relative flex flex-row-reverse -mb-[30px] z-[3]">
                    <IconVplus white />
                  </div>
                )}
                <MerchanDisc
                  src={
                    affiliated
                      ? merchant.user_vplus_image_url
                        ? merchant.user_vplus_image_url
                        : merchant.image_url
                      : merchant.image_url
                  }
                />
              </div>
              <TwTitle text={merchant.name} classList="-mt-3" />
              <TwText classList="!text-[20px]">
                <Trans
                  i18n={i18n}
                  i18nKey="cashbackScreenDetail.typeCashback"
                  components={[
                    <TwText
                      font="arboriaBold"
                      classList="font-extrabold !text-[20px]"
                      tag="span"
                    />,
                  ]}
                  values={{
                    typeCashback: infoCashback.user_type
                      ? VPAG
                      : 'Simples' || EMPTY_STRING,
                    activeCashback: cashbackBlocked ? 'Desativado' : 'Ativado',
                  }}
                />
              </TwText>
              {cashbackBlocked ? (
                <>
                  <TwText
                    text={i18n.t('cashback.textCashbackDesable')}
                    classList="font-extrabold mx-12 md:w-[438px] !text-[20px]"
                  />
                </>
              ) : (
                <>
                  <TextButton onClick={() => setShowModalseeDetails(true)}>
                    {i18n.t('cashbackScreenDetail.seeDetails')}
                  </TextButton>
                  <TwTitle>
                    {!isObjectEmpty(infoCashback) && (
                      <>
                        <Trans
                          i18n={i18n}
                          i18nKey="cashbackScreenDetail.winCashback"
                          components={[
                            <span className="text-blue-secondary" />,
                          ]}
                          values={{
                            percent: infoCashback.percent * ONE_HUNDRED,
                            limit: formatCurrency(
                              infoCashback.limit,
                              selectedWallet,
                            ),
                          }}
                        />
                      </>
                    )}
                  </TwTitle>
                  <TwText>{i18n.t('cashbackScreenDetail.transferPix')}</TwText>
                </>
              )}
              {!isLoading && <InputCopy value={pixKey} />}
            </div>
            {cashbackBlocked ? (
              <TwText
                text={i18n.t('cashback.youMayUseKey')}
                classList="!text-[20px] mx-12 md:w-[438px] mb-10 md:mb-0"
              />
            ) : (
              <TwTextButton
                onClick={() => setShowModalTutorial(true)}
                label={i18n.t('cashbackScreenDetail.howUseKey')}
                classList="font-bold"
              />
            )}

            <TwModal
              isOpen={showModalSeeDetails}
              onClose={handleCloseModalSeeDetails}
              classList="size-full md:max-w-[428px] md:h-min !rounded-[20px]"
              classListChildren="flex flex-col gap-[25px] my-auto items-center h-full"
              closeButtonWhiteOutlined
            >
              <>
                <TwTitle color="grass-800" classList="!text-[26px]">
                  <Trans
                    i18n={i18n}
                    i18nKey="cashbackSeeDetailsModal.title"
                    values={{ merchantName: merchant.name }}
                  />
                </TwTitle>
                <TwText
                  tag="p"
                  classList="text-[22px] font-[50]"
                  text={
                    <Trans
                      i18n={i18n}
                      i18nKey="cashbackSeeDetailsModal.firstParagraph"
                      components={<span className="font-extrabold" />}
                    />
                  }
                />

                {infoCashback.percent && infoCashback.limit && (
                  <TwText
                    tag="p"
                    classList="text-[22px] font-[50] font-extrabold"
                    text={
                      <Trans
                        i18n={i18n}
                        i18nKey="cashbackSeeDetailsModal.advantages"
                        values={{
                          percent: infoCashback.percent * ONE_HUNDRED,
                          limit: formatCurrency(
                            infoCashback.limit,
                            selectedWallet,
                          ),
                        }}
                      />
                    }
                  />
                )}

                <TwButton
                  onClick={handleCloseModalSeeDetails}
                  classList="mt-[28px] !w-[20rem] !h-[60px] text-[20px] font-bold mx-auto mb-7 !text-[20px]"
                  label={i18n.t('cashbackSeeDetailsModal.back')}
                />
              </>
            </TwModal>
            <CashbackTutorialModal
              handleClose={handleCloseModalTutorial}
              showModal={showModalTutorial}
            />
          </>
        )}
      </div>
    </MainTemplate>
  );
}

export default CashbackDetail;
