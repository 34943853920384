import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  align-items: center;
  animation: goLeftDefault 0.5s forwards ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    animation: none;
    gap: 1.5rem;
    padding-left: 2.3rem;
  }
`;

export const IconWrapper = styled.section`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    font-size: ${theme.font.size.small};
    gap: 1rem;

    p {
      font: ${theme.font.size.medium} ${theme.font.family.secondary};
    }

    strong {
      color: ${theme.colors.primary};
      font-weight: 600;
    }
  `}
`;

export const ContainerAffiliation = styled.div`
  align-items: center;
  display: flex;
  color: ${({ theme }) => theme.colors.info.light};
  flex-direction: column;
`;
