import styled from 'styled-components';

import Container from '@/components/atoms/Container/styles';
import { ReactComponent as Checked } from '@/assets/checked.svg';
import { ReactComponent as Error } from '@/assets/errorIcon.svg';

export const Message = styled.h1`
  color: ${({ theme }) => theme.colors.info.light};
  font-size: ${({ theme }) => theme.font.size.large};
  font-weight: 300;
  margin-top: 5vh;
  text-align: center;
`;

export const Content = styled(Container)`
  justify-content: center;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.contrast};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: 400;
  text-align: center;
  width: 200px;
`;

export const CheckIcon = styled(Checked)`
  width: 100px;
`;

export const ErrorIcon = styled(Error)`
  width: 40px;
`;
