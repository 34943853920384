import styled, { css } from 'styled-components';

import { ReactComponent as ArrowIcon } from '@/assets/simpleArrow.svg';

const hideInDesktop = css`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const TitleWrapper = styled.div`
  ${hideInDesktop}
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.info.gray}`};
  display: flex;
  justify-content: center;
  padding: 30px 10px 10px;
  width: 100%;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.info.light};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: 600;
  text-align: left;
`;

export const BankWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  max-width: 768px;
  overflow-y: auto;
  width: 85%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 5vh;
    max-width: 400px;
    text-align: center;
  }
`;

export const LogoWrapper = styled.div`
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.info.gray}`};
  color: ${({ theme }) => theme.colors.info.light};
  cursor: pointer;
  display: flex;
  font: ${({ theme }) =>
    `${theme.font.size.medium} ${theme.font.family.secondary}`};
  height: 79px;
  position: relative;
  width: 100%;

  p {
    margin-left: 5vw;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-items: center;
    animation: ${({ index }) => `goLeftDefault ${1 + index * 0.1}s`};
    border: ${({ theme, selected }) =>
      selected ? `1px solid ${theme.colors.primary}` : 'none'};
    flex-direction: column;
    margin: 20px;
    max-width: 60px;
    width: unset;

    p {
      font-size: ${({ theme }) => theme.font.size.xSmall};
      margin: 10px 0;
    }
  }
`;

export const ArrowButton = styled.div`
  ${hideInDesktop}
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bgGradientLight.start};
  border-radius: 37px;
  display: flex;
  height: 27px;
  justify-content: center;
  position: absolute;
  right: 10%;
  width: 27px;
`;

export const Arrow = styled(ArrowIcon)`
  fill: ${({ theme }) => theme.colors.light};
`;
