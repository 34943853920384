import React, { cloneElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import {
  Button,
  ButtonWrapper,
  CancelButton,
  Container,
  ContentWrapper,
  MenuTitle,
} from './styles';

const FIVE_HUNDRED_MILLISECONDS = 500;

function BottomMenu({
  cancelAction,
  children,
  options,
  removeBlur,
  setShowModal,
  showModal,
  title,
}) {
  const [hideAnimation, setHideAnimation] = useState(true);
  const [mainAction, setMainAction] = useState(null);
  const [newTitle, setNewTitle] = useState('');

  const cancelFunction = cancelAction || (() => setShowModal(false));

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHideAnimation(false);
    }, FIVE_HUNDRED_MILLISECONDS);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <Container
      showModal={showModal}
      onClick={() => setShowModal(false)}
      removeBlur={removeBlur}
      hideAnimation={hideAnimation}
    >
      <ContentWrapper
        showModal={showModal}
        onClick={(e) => e.stopPropagation()}
        hideAnimation={hideAnimation}
      >
        <ButtonWrapper>
          <MenuTitle highlightUpper>{newTitle || title}</MenuTitle>
          {options?.length ? (
            options?.map((el) => (
              <Button
                type="button"
                onClick={el.action}
                disabled={el?.disabled}
                key={el.label}
              >
                {el.label}
              </Button>
            ))
          ) : (
            <div>
              {cloneElement(children, {
                setNewTitle,
                setMainAction,
                mainAction,
              })}
            </div>
          )}
        </ButtonWrapper>
        <CancelButton
          type="button"
          onClick={mainAction?.action || cancelFunction}
        >
          {mainAction?.label || i18n.t('actions.close')}
        </CancelButton>
      </ContentWrapper>
    </Container>
  );
}

BottomMenu.propTypes = {
  cancelAction: PropTypes.func,
  children: PropTypes.element,
  options: PropTypes.array,
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  title: PropTypes.bool,
}.isRequired;

export default BottomMenu;
