import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoMdCopy } from 'react-icons/io';

import MethodIcon from '@/components/atoms/MethodIcon';
import i18n from '@/translate/i18n';

import { CopyButton, QrCode } from '../DepositDetailsSummary/styles';

function BinanceQrCode({ data }) {
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const shouldRenderQrCode =
    data?.status === 'pending' &&
    data?.payment_method_slug === 'pix' &&
    data?.details?.psp_payment_info?.qr_code;

  return (
    <>
      {shouldRenderQrCode ? (
        <QrCode
          value={data.details.psp_payment_info.qr_code}
          alt="QR Code"
          id="deposit-details-qr-code"
        />
      ) : (
        data?.payment_method_slug && (
          <MethodIcon
            method={data?.payment_method_slug}
            width="70px"
            flat
            id="deposit-details-method-icon"
          />
        )
      )}
      {shouldRenderQrCode && (
        <CopyButton
          id="deposit-details-copy-pix-key-btn"
          bgColor={showSuccessMsg ? 'primary' : 'pending'}
          onClick={() => {
            navigator.clipboard
              .writeText(data.details.psp_payment_info.qr_code)
              .then(() => setShowSuccessMsg(true));
          }}
        >
          {showSuccessMsg ? (
            i18n.t('transactionDetailsSummary.successMsg')
          ) : (
            <>
              <IoMdCopy />{' '}
              <span>{i18n.t('transactionDetailsSummary.copyPixKey')}</span>
            </>
          )}
        </CopyButton>
      )}
    </>
  );
}

BinanceQrCode.propTypes = {
  data: PropTypes.shape({
    customer_bonus: PropTypes.number,
    customer_currency_amount: PropTypes.number,
    customer_currency_iso: PropTypes.string,
    id: PropTypes.number,
    payment_method_name: PropTypes.string,
    payment_method_slug: PropTypes.string,
    status: PropTypes.string,
    details: PropTypes.shape({
      psp_payment_info: PropTypes.shape({
        qr_code: PropTypes.string,
      }),
    }),
  }),
};

export default BinanceQrCode;
