import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from '@/assets/closeBtn.svg';
import NavMenu from '@/components/molecules/NavMenu';
import { Paragraph } from '@/components/atoms/Typography/styles';
import i18n from '@/translate/i18n';
import { CONTACT_EMAIL } from '@/helpers/constants';

import {
  CloseButton,
  MenuModalContainer,
} from '@/components/atoms/MenuModalStyle/styles';

import { LogoWrapper, Logo, TextWrapper } from './styles';

function MenuModal({ showMenu, setShowMenu }) {
  const [hideAnimation, setHideAnimation] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHideAnimation(false);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <MenuModalContainer
      showMenu={showMenu}
      hideAnimation={hideAnimation}
      padding="1.5rem 0 0"
      id="nav-menu-left-mobile"
    >
      <CloseButton type="button" onClick={() => setShowMenu(false)} left>
        <CloseIcon width="20px" height="20px" />
      </CloseButton>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <NavMenu arrows={1} />
      <TextWrapper>
        <Paragraph>{i18n.t('footer.support')}</Paragraph>
        <Paragraph>
          {i18n.t('footer.email', { email: CONTACT_EMAIL })}
        </Paragraph>
        <Paragraph color="secondary">
          {i18n.t('footer.allRightsReserved', {
            year: new Date().getFullYear(),
          })}
        </Paragraph>
      </TextWrapper>
    </MenuModalContainer>
  );
}

MenuModal.propTypes = {
  showMenu: PropTypes.bool,
  setShowMenu: PropTypes.func,
}.isRequired;

export default MenuModal;
