import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@/components/atoms/Buttons/Button/styles';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';

import { ButtonWrapper, Wrapper } from './styles';

function ConfirmAction({
  cancelLabel,
  closeModal,
  confirmButtonId,
  confirmLabel,
  description,
  handleClick,
  invertButtonAction,
  title,
}) {
  return (
    <Wrapper>
      <Title textColor="secondary">{title}</Title>
      <Paragraph color="light">{description}</Paragraph>
      <ButtonWrapper>
        <Button
          id={confirmButtonId ?? ''}
          type="button"
          onClick={invertButtonAction ? closeModal : handleClick}
        >
          {confirmLabel}
        </Button>
        <TextButton
          type="button"
          onClick={invertButtonAction ? handleClick : closeModal}
        >
          {cancelLabel}
        </TextButton>
      </ButtonWrapper>
    </Wrapper>
  );
}

ConfirmAction.propTypes = {
  cancelLabel: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  confirmButtonId: PropTypes.string,
  confirmLabel: PropTypes.string,
  description: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  invertButtonAction: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export default ConfirmAction;
