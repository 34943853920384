import styled, { css } from 'styled-components';
import { hexToRGBA } from '@/styles/colors';

export const Text = styled.p`
  ${({ theme }) => css`
    color: ${hexToRGBA(theme.colors.info.light)};
    font: 12px;
    line-height: 19.52px;
  `}
`;

export const SpanText = styled.span`
  font-weight: 800;
`;
