import styled from 'styled-components';

import { ReactComponent as Alert } from '@/assets/alertIcon.svg';

export const Wrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.primary};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.font.size.small};
  justify-content: space-evenly;
  text-align: center;
  width: 260px;

  a {
    margin-top: 1rem;
  }
`;

export const AlertIcon = styled(Alert)`
  width: 50px;
`;
