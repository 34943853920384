import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import { InternalButton } from '@/components/atoms/Buttons/Button/styles';
import { Title } from '@/components/atoms/Typography/styles';
import { getUserPendingValidations } from '@/helpers/functions';

import Wrapper from './styles';
import validationBasedOnKycLevel from './data';

function IncreaseLimitsInstructions({ validations }) {
  const history = useHistory();

  const instructionKey = getUserPendingValidations(validations ?? []);
  const isValidationInProcess = instructionKey.includes('pending');
  const highlightColor = isValidationInProcess ? 'pending' : 'primary';

  if (instructionKey === 'sof') {
    history.push('/validations/sof');
  }

  return (
    <Wrapper>
      <div>
        <Title
          size="large"
          color={highlightColor}
          highlightUpper={isValidationInProcess}
        >
          <Trans
            i18nKey={validationBasedOnKycLevel[instructionKey]?.title}
            i18n={i18n}
            components={[<span />, <strong />]}
          />
        </Title>
        <p>{validationBasedOnKycLevel[instructionKey]?.subtitle}</p>
      </div>
      <Title size="small" color={highlightColor}>
        <Trans
          i18nKey={validationBasedOnKycLevel[instructionKey]?.description}
          i18n={i18n}
          components={[<span />]}
        />
      </Title>

      <InternalButton
        bgColor={highlightColor}
        to={validationBasedOnKycLevel[instructionKey]?.path}
      >
        {validationBasedOnKycLevel[instructionKey]?.button}
      </InternalButton>
    </Wrapper>
  );
}

IncreaseLimitsInstructions.propTypes = {
  validations: PropTypes.arrayOf(PropTypes.object),
}.isRequired;

export default IncreaseLimitsInstructions;
