import React from 'react';
import PropTypes from 'prop-types';

function TwTextButton({
  handleClick,
  isSubmitting,
  disabled,
  label,
  classList = '',
  ...rest
}) {
  return (
    <button
      type="submit"
      {...rest}
      handleClick={handleClick}
      disabled={disabled || isSubmitting}
      className={`w-full h-11 rounded-sm ${classList} ${
        !disabled ? 'cursor-pointer' : 'cursor-not-allowed'
      } bg-transparent text-sm font-arboria-regular text-grass-800 underline`}
    >
      {label}
    </button>
  );
}

TwTextButton.propTypes = {
  label: PropTypes.string,
  handleClick: PropTypes.func,
  isSubmitting: PropTypes.bool,
  disabled: PropTypes.bool,
  classList: PropTypes.string,
};

export default TwTextButton;
