import styled, { css } from 'styled-components';

const Button = styled.button`
  ${({ theme, selected }) => css`
    align-items: center;
    background: ${selected ? theme.colors.primary : theme.colors.shadow};
    border: none;
    color: ${selected ? theme.colors.shadow : theme.colors.info.light};
    display: flex;
    font: ${`${theme.font.size.small} ${
      theme.font.family[selected ? 'secondary' : 'primary']
    }`};
    height: 80px;
    justify-content: center;
    transition: 0.3s background-color ease-in-out;
    width: 50vw;

    svg {
      margin-right: 10px;
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      width: 50%;
    }
  `}
`;

export default Button;
