import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoMdCopy } from 'react-icons/io';

import MethodIcon from '@/components/atoms/MethodIcon';
import { Paragraph } from '@/components/atoms/Typography/styles';
import { formatCurrency } from '@/helpers/stringFormat';
import i18n from '@/translate/i18n';

import {
  CopyButton,
  QrCode,
} from '@/components/molecules/DepositDetailsSummary/styles';

import { FlexWrapper, Wrapper } from './styles';

const LOGO_SIZE = 24;

function DepositPendingSummary({ data }) {
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const paymentMethod = data?.payment_method_slug;
  const pixQrCodeValue = data?.details?.psp_payment_info?.qr_code;
  const binanceQrCodeValue = data?.details?.psp_payment_info?.checkout_url;
  const isPixMethod = paymentMethod === 'pix';
  const shouldRenderQrCode =
    data?.status === 'pending' && (pixQrCodeValue || binanceQrCodeValue);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(data.details.psp_payment_info.qr_code)
      .then(() => setShowSuccessMsg(true));
  };

  const redirectToBinance = () =>
    window.open(data.details.psp_payment_info.checkout_url);

  return (
    <Wrapper>
      {shouldRenderQrCode ? (
        <QrCode
          value={isPixMethod ? pixQrCodeValue : binanceQrCodeValue}
          alt="QR Code"
          id="deposit-details-qr-code"
          imageSettings={
            paymentMethod === 'binance' && {
              src: '/images/methods/binance.svg',
              height: LOGO_SIZE,
              width: LOGO_SIZE,
              excavate: true,
            }
          }
        />
      ) : (
        data?.payment_method_slug && (
          <MethodIcon
            method={data?.payment_method_slug}
            width="70px"
            flat
            id="deposit-details-method-icon"
          />
        )
      )}
      <FlexWrapper width="50%">
        <FlexWrapper>
          <Paragraph size="medium">
            {i18n.t('transactionDetailsSummary.depositAmount')}
          </Paragraph>
          <Paragraph size="medium" color="light" family="secondary">
            {formatCurrency(data?.customer_amount, data?.customer_currency_iso)}
          </Paragraph>
        </FlexWrapper>
        {shouldRenderQrCode && (
          <CopyButton
            id="deposit-details-copy-pix-key-btn"
            bgColor={showSuccessMsg && isPixMethod ? 'primary' : 'pending'}
            onClick={isPixMethod ? handleCopy : redirectToBinance}
          >
            {isPixMethod && showSuccessMsg ? (
              i18n.t('transactionDetailsSummary.successMsg')
            ) : isPixMethod ? (
              <>
                <IoMdCopy />{' '}
                <span>{i18n.t('transactionDetailsSummary.copyPixKey')}</span>
              </>
            ) : (
              <span>{i18n.t('transactionDetailsSummary.continueBinance')}</span>
            )}
          </CopyButton>
        )}
      </FlexWrapper>
    </Wrapper>
  );
}

DepositPendingSummary.propTypes = {
  data: PropTypes.shape({
    customer_amount: PropTypes.number,
    customer_currency_amount: PropTypes.number,
    customer_currency_iso: PropTypes.string,
    payment_method_name: PropTypes.string,
    payment_method_slug: PropTypes.string,
    status: PropTypes.string,
    details: PropTypes.shape({
      psp_payment_info: PropTypes.shape({
        qr_code: PropTypes.string,
        checkout_url: PropTypes.string,
      }),
    }),
  }),
};

export default DepositPendingSummary;
