/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import { Container, ContentWrapper, TableWrapper } from './styles';

function TermsAndConditionsBonus({ terms }) {
  return (
    <Container className="p-0">
      <ContentWrapper>
        <TableWrapper>
          {terms && (
            <div
              className="bonus-terms h-[70vh]"
              dangerouslySetInnerHTML={{ __html: terms }}
            />
          )}
        </TableWrapper>
      </ContentWrapper>
    </Container>
  );
}

TermsAndConditionsBonus.propTypes = {
  terms: PropTypes.shape({}),
}.isRequired;

export default TermsAndConditionsBonus;
