import React from 'react';
import PropTypes from 'prop-types';

import Content from './styles';
import BankIcon from '@/components/atoms/BankIcon';

const ICON_WIDTH = 40;

function BankInfo({ slug }) {
  return (
    <Content>
      {slug.replaceAll('-', ' ')}
      <BankIcon slug={slug} width={ICON_WIDTH} />
    </Content>
  );
}

BankInfo.propTypes = {
  slug: PropTypes.string,
}.isRequired;

export default BankInfo;
