import styled from 'styled-components';

export const RegisterLayout = styled.div`
  display: flex;
  height: 100%;
  min-height: 600px;
  overflow-y: auto;
  background-color: black;
`;

export const SliderWrapper = styled.div`
  display: none;
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.shadow};
    display: flex;
    flex: 1.5;
    flex-direction: column;
    justify-content: center;

    div {
      width: 55vw;
    }
  }
`;
