import { useState, useEffect } from 'react';

import { getUserPoints } from '@/services/api';
import { useUserData } from '@/context/userContext';

const useUserPoints = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(false);
  const { userData, setUserData } = useUserData();

  const getPoints = async () => {
    try {
      setIsFetching(true);
      const userId = localStorage.getItem('id');
      const res = await getUserPoints(userId);

      setUserData((state) => ({ ...state, points: res?.data?.points }));
    } catch (err) {
      setUserData((state) => ({ ...state, points: 0 }));
      setError(true);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    if (userData?.points === null) {
      getPoints();
    }

    return () => controller.abort();
  }, [userData.points]); // eslint-disable-line react-hooks/exhaustive-deps

  return { isFetching, error, getPoints };
};

export default useUserPoints;
