import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { checkIsAuthenticated } from '@/services/api';
import { useUserData } from './userContext';
import { useWalletData } from './walletContext';

export const AuthContext = React.createContext({});

export default function Auth({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { setUserData, resetUserData } = useUserData();
  const { resetWalletData } = useWalletData();

  useEffect(() => {
    const checkAuth = () =>
      checkIsAuthenticated()
        .then((response) => {
          setUserData((state) => ({
            ...state,
            ...(response?.data?.data || {}),
          }));
          setIsAuthenticated(response);
        })
        .then(() => setIsLoading(false));

    checkAuth();
  }, [setUserData]);

  useEffect(() => {
    if (!isAuthenticated) {
      resetUserData();
      resetWalletData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        setIsAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

Auth.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
}.isRequired;
