/* eslint-disable react/prop-types */
import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { PiWarningCircle } from 'react-icons/pi';
import TwFormField from '@/components/atoms/Inputs/TwFormField';
import i18n from '@/translate/i18n';
import { Paragraph } from '@/components/atoms/Typography/styles';
import { checkString } from '@/helpers/functions';

const ICON_SIZE = 17;
const PASSWORD_MIN_LENGTH = 8;

function PasswordFormCheck({
  errors,
  touched,
  values,
  firstRenderPasswordControl,
  firstRenderPasswordConfirmControl,
  setFieldTouched,
}) {
  const checkError = (field) => touched[field] && errors[field];
  const { hasUppercase, hasLowercase, hasNumeric } = checkString(
    values.password,
  );
  const lengthPassword = values.password?.length;
  const isPasswordLengthLongerMinimum = lengthPassword >= PASSWORD_MIN_LENGTH;
  const isPasswordValid =
    isPasswordLengthLongerMinimum && hasUppercase && hasLowercase && hasNumeric;
  const isOpenPasswordRequirements = !touched.password
    ? true
    : !isPasswordValid && !touched.password && lengthPassword !== 0;
  const lengthPasswordConfirmation = values.password_confirmation?.length;
  const isPasswordConfirmationValid =
    values.password === values.password_confirmation;
  const isOpenPasswordVerify = !touched?.password_confirmation
    ? true
    : !isPasswordConfirmationValid &&
      !lengthPasswordConfirmation !== 0 &&
      !touched?.password_confirmation;
  return (
    <>
      <TwFormField
        id="password"
        name="password"
        type="password"
        togglePassowordButton
        error={checkError('password')}
        errorText
        placeholder={i18n.t('signup.passwordPlaceholder')}
        value={values.password}
        dataTestId="signup-input-password"
        onFocus={() => {
          if (firstRenderPasswordControl.current) {
            firstRenderPasswordControl.current = false;
          }
          setFieldTouched('password', false);
        }}
        onKeyUp={() => {
          if (firstRenderPasswordControl.current) {
            firstRenderPasswordControl.current = false;
          }
          setFieldTouched('password', false);
        }}
        handleBlur={() => setFieldTouched('password')}
        secondaryTheme
        disableAutoComplete
      />
      {isOpenPasswordRequirements && !firstRenderPasswordControl.current && (
        <div className="pl-5">
          <div className="flex flex-col gap-2">
            <div className="flex gap-1 items-center">
              {isPasswordLengthLongerMinimum ? (
                <FaCheck size={ICON_SIZE} className="text-grass-800" />
              ) : (
                <PiWarningCircle
                  size={ICON_SIZE}
                  className="!text-white text-opacity-50"
                />
              )}
              <Paragraph
                className={
                  isPasswordLengthLongerMinimum
                    ? '!text-white'
                    : '!text-white text-opacity-50'
                }
              >
                {i18n.t('signup.password.minLength')}
              </Paragraph>
            </div>
            <div className="flex gap-1 items-center">
              {hasLowercase && hasUppercase ? (
                <FaCheck size={ICON_SIZE} className="text-grass-800" />
              ) : (
                <PiWarningCircle
                  size={ICON_SIZE}
                  className="!text-white text-opacity-50"
                />
              )}
              <Paragraph
                className={
                  hasLowercase && hasUppercase
                    ? '!text-white'
                    : '!text-white text-opacity-50'
                }
              >
                {i18n.t('signup.password.upperAndLowerCase')}
              </Paragraph>
            </div>
            <div className="flex gap-1 items-center">
              {hasNumeric ? (
                <FaCheck size={ICON_SIZE} className="text-grass-800" />
              ) : (
                <PiWarningCircle
                  size={ICON_SIZE}
                  className="!text-white text-opacity-50"
                />
              )}
              <Paragraph
                className={
                  hasNumeric ? '!text-white' : '!text-white text-opacity-50'
                }
              >
                {i18n.t('signup.password.number')}
              </Paragraph>
            </div>
          </div>
        </div>
      )}

      <TwFormField
        id="password_confirmation"
        name="password_confirmation"
        type="password"
        togglePassowordButton
        error={checkError(errors, touched, 'password_confirmation')}
        errorText
        placeholder={i18n.t('signup.confirmPassPlaceholder')}
        dataTestId="signup-input-confirm-password"
        secondaryTheme
        disableAutoComplete
        handleBlur={() => setFieldTouched('password_confirmation')}
        onFocus={() => {
          if (firstRenderPasswordConfirmControl.current) {
            firstRenderPasswordConfirmControl.current = false;
          }
          setFieldTouched('password_confirmation', false);
        }}
        onKeyUp={() => {
          if (firstRenderPasswordConfirmControl.current) {
            firstRenderPasswordConfirmControl.current = false;
          }
          setFieldTouched('password_confirmation', false);
        }}
      />
      {isOpenPasswordVerify && !firstRenderPasswordConfirmControl.current && (
        <div className="flex gap-1 items-center">
          {values.password?.length && isPasswordConfirmationValid ? (
            <FaCheck size={ICON_SIZE} className="text-grass-800" />
          ) : (
            <PiWarningCircle
              size={ICON_SIZE}
              className="!text-white text-opacity-50"
            />
          )}
          <Paragraph
            className={
              values.password?.length &&
              values.password === values.password_confirmation
                ? '!text-white'
                : '!text-white text-opacity-50'
            }
          >
            {i18n.t('signup.password.match')}
          </Paragraph>
        </div>
      )}
    </>
  );
}

export default PasswordFormCheck;
