import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';

import BankIcon from '@/components/atoms/BankIcon';
import BankInfo from '@/components/atoms/BankInfo';
import Container from '@/components/atoms/Container/styles';
import DeskContainer from '@/components/atoms/DeskContainer/styles';
import Input from '@/components/atoms/Inputs/Input';
import InputRadio from '@/components/atoms/Inputs/InputRadio';
import NavMenuLeft from '@/components/organisms/NavMenuLeft';
import SubmitButton from '@/components/atoms/Buttons/SubmitButton';
import WalletHeader from '@/components/molecules/WalletHeader';
import { BANK_ACC_SCHEMA } from '@/helpers/schemas';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { DeskWrapper } from '@/components/atoms/GenerateTransactionStyle/styles';
import { accountsMock } from '@/data/mocks';

import { Wrapper, Main, Section, Text } from './styles';

const INPUT_SIZE = 10;

function AddBankAccount({ location }) {
  const history = useHistory();

  const { bankSlug, bankName, data } = location.state;
  const { isMobileLayout } = useDeviceWidth();

  return (
    <DeskContainer>
      <NavMenuLeft />
      <Container>
        <WalletHeader
          title={i18n.t('bankAccounts.title')}
          returnTo="/bank-accounts/banks"
        />
        <DeskWrapper>
          {isMobileLayout && (
            <>
              <BankIcon slug={data?.slug || bankSlug} width="120px" />
              <Text>{bankName}</Text>
            </>
          )}

          {bankSlug && <BankInfo slug={bankSlug} />}

          <Formik
            validationSchema={BANK_ACC_SCHEMA}
            initialValues={{
              agency: data?.agency || '',
              accountNumber: data?.accountNumber || '',
              dv: data?.dv || '',
              type: data?.type || '',
            }}
            initialErrors={{
              agency: 'Required',
              accountNumber: 'Required',
              dv: 'Required',
              type: 'Required',
            }}
            onSubmit={(values) => {
              const payload = {
                ...values,
                bank: bankName,
                slug: bankSlug,
              };

              accountsMock.push(payload);
              history.push('/bank-accounts');
            }}
          >
            {({ values, setValues, isValid }) => (
              <Main>
                <Section>
                  <Input
                    id="agency"
                    name="agency"
                    type="number"
                    placeholder={i18n.t('bankAccounts.agency')}
                    value={values.agency}
                    color="light"
                    width={isMobileLayout ? '62vw' : '230px'}
                  />

                  <Wrapper>
                    <Input
                      id="accountNumber"
                      name="accountNumber"
                      type="number"
                      placeholder={i18n.t('bankAccounts.accountNumber')}
                      value={values.accountNumber}
                      width="98%"
                      size={INPUT_SIZE}
                      rule="margin-right"
                      color="light"
                    />
                    <Input
                      id="dv"
                      name="dv"
                      type="number"
                      placeholder={i18n.t('bankAccounts.dv')}
                      value={values.dv}
                      width="60px"
                      color="light"
                    />
                  </Wrapper>
                </Section>
                <Wrapper>
                  <InputRadio
                    textContent={i18n.t('bankAccounts.checkingAccount')}
                    name="type"
                    value="checkingAccount"
                    onChange={({ target }) =>
                      setValues((state) => ({ ...state, type: target.value }))
                    }
                  />
                  <InputRadio
                    textContent={i18n.t('bankAccounts.savingsAccount')}
                    name="type"
                    value="savingsAccount"
                    onChange={({ target }) =>
                      setValues((state) => ({ ...state, type: target.value }))
                    }
                  />
                </Wrapper>
                <SubmitButton
                  before={i18n.t('bankAccounts.fillInputMessage')}
                  after={
                    data
                      ? i18n.t('bankAccounts.saveChanges')
                      : i18n.t('bankAccounts.addAccount')
                  }
                  isValid={isValid}
                  width="200px"
                />
              </Main>
            )}
          </Formik>
        </DeskWrapper>
      </Container>
    </DeskContainer>
  );
}

AddBankAccount.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      bankSlug: PropTypes.string,
      bankName: PropTypes.string,
      data: PropTypes.shape({
        agency: PropTypes.string,
        accountNumber: PropTypes.string,
        dv: PropTypes.string,
        type: PropTypes.string,
      }),
    }),
  }),
}.isRequired;

export default AddBankAccount;
