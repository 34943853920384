import React from 'react';
import EllipseVpag from '@/components/atoms/EllipseVpag';
import TwTitle from '@/components/atoms/TwTitle';
import i18n from '@/translate/i18n';

const SlideContentOneApresentation = () => (
  <>
    <div className="flex flex-col justify-end ">
      <div className="flex flex-col-reverse w-full">
        <div className="max-h-810:mt-8 flex flex-col justify-center w-full">
          <div className="ml-24 w-fit">
            <EllipseVpag />
          </div>
        </div>
        <div className="flex justify-center mt-1">
          <EllipseVpag />
        </div>
      </div>
      <TwTitle classList="!text-[2.375rem] font-normal leading-[2.125rem] my-11">
        {i18n.t('apresentationScreen.firstMenssage')}
      </TwTitle>
      <div className="max-h-810:mb-8 w-full  flex-col justify-center">
        <div className="!ml-24 w-fit">
          <EllipseVpag />
        </div>

        <div className="flex justify-center mt-6">
          <EllipseVpag />
        </div>
      </div>
      <div className="max-h-810:overflow-visible w-[18.5625rem] mx-auto max-h-810:max-h-[9.75rem] max-h-[12.5rem]">
        <div className="bg-gradient-to-t relative from-black to-transparent z-20 max-h-810:h-[9.75rem] h-[12.5rem] top-[1.5625rem] w-full overflow-hidden" />
        <img
          src="/images/carousel/onboarding/walletMobile.svg"
          alt="wallet"
          className="relative max-h-810:-top-[12.8125rem] -top-[13.4375rem] z-10"
        />
      </div>
    </div>
  </>
);

export default SlideContentOneApresentation;
