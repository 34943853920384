import styled, { css } from 'styled-components';

const RoundedButton = styled.button`
  ${({
    bgColor = 'primary',
    borderColor = 'primary',
    bordered,
    color = 'shadow',
    family = 'secondary',
    size = 'small',
    theme,
  }) => css`
    background-color: ${bordered
      ? theme.colors.shadow
      : theme.colors.button[bgColor]};
    border: ${bordered ? `1px solid ${theme.colors[borderColor]}` : 'none'};
    border-radius: 100px;
    color: ${theme.colors[bordered ? borderColor : color]};
    font: ${theme.font.size[size]} ${theme.font.family[family]};
    opacity: 0.85;
    overflow: hidden;
    padding: 3px 4px;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      opacity: 1;
    }
  `}
`;

export default RoundedButton;
