import styled, { css } from 'styled-components';

const INPUT_WIDTH_DEFAULT = 1;
const INPUT_WIDTH_BASE = 0.3;
const INPUT_WIDTH_REF = 3;

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    font: ${`${theme.font.size.xxxLarge} ${theme.font.family.secondary}`};
    text-align: center;
  `}
`;

export const NumericInput = styled.input`
  ${({ theme, insufficientFund, isError, length }) => css`
    background-color: transparent;
    border: none;
    color: ${insufficientFund
      ? theme.colors.danger
      : isError
      ? theme.colors.info.title
      : theme.colors.primary};
    font: ${`${theme.font.size.xxxLarge} ${theme.font.family.primary}`};
    margin-left: 5px;
    transition: 0.1s width ease;
    width: ${(length && length <= INPUT_WIDTH_REF
      ? INPUT_WIDTH_BASE
      : INPUT_WIDTH_DEFAULT) + length}ch;

    &:disabled {
      font-family: ${theme.font.family.secondary};
      opacity: 1;
    }

    &::placeholder {
      color: ${theme.colors.primary};
    }
  `}
`;

export const Label = styled.label`
  ${({ theme, insufficientFund, isError, locked }) => css`
    color: ${insufficientFund
      ? theme.colors.danger
      : isError
      ? theme.colors.info.title
      : theme.colors.primary};
    display: flex;
    font: ${`${theme.font.size.xxxLarge} ${
      theme.font.family[locked ? 'secondary' : 'primary']
    }`};
    justify-content: center;
    text-align: center;
  `}
`;

export const TooltipContent = styled.div`
  position: relative;

  svg {
    margin-top: 10px;
    position: absolute;
    top: 0;
  }
`;
