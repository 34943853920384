import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import { TransactionButton } from '@/components/atoms/Buttons/TransactionButton/styles';
import { useWalletData } from '@/context/walletContext';

import { ButtonWrapper } from './styles';

function DepositButton({ handleSubmit, showDetails, disabled }) {
  const {
    walletData: { transactionData, transactionLimits },
  } = useWalletData();

  const {
    max_amount_per_transaction: maxValue,
    min_amount_per_transaction: minValue,
  } = transactionLimits;

  const { amount } = transactionData;

  const isValidValue = amount >= minValue && amount <= maxValue;

  return (
    <ButtonWrapper isValid={isValidValue}>
      <TransactionButton
        id={!showDetails ? 'deposit-btn-next' : 'deposit-btn-confirm'}
        type="button"
        disabled={disabled || !isValidValue}
        onClick={handleSubmit}
      >
        {!showDetails ? i18n.t('deposit.next') : i18n.t('deposit.confirm')}
      </TransactionButton>
    </ButtonWrapper>
  );
}

DepositButton.propTypes = {
  showDetails: PropTypes.bool,
  handleSubmit: PropTypes.bool,
}.isRequired;

export default DepositButton;
