import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import BankIcon from '@/components/atoms/BankIcon';
import { hideAccountNumber } from '@/helpers/stringFormat';
import {
  BankInfo,
  ButtonWrapper,
  ContentWrapper,
  ModalButton,
  Text,
  TextWrapper,
  Title,
} from './styles';

function DeleteBankAccount({ setShowModal, account }) {
  const { slug, bank, accountNumber, dv } = account;

  return (
    <ContentWrapper>
      <Title>{i18n.t('deleteBankAccount.title')}</Title>
      <BankInfo>
        <BankIcon slug={slug} width="26px" />
        <TextWrapper>
          <Text>
            <strong>{bank}</strong>
          </Text>
          <Text>{hideAccountNumber(`${accountNumber}-${dv}`)}</Text>
        </TextWrapper>
      </BankInfo>

      <ButtonWrapper>
        <ModalButton type="button">
          {i18n.t('deleteBankAccount.yes')}
        </ModalButton>
        <ModalButton type="button" onClick={() => setShowModal(false)}>
          {i18n.t('deleteBankAccount.no')}
        </ModalButton>
      </ButtonWrapper>
    </ContentWrapper>
  );
}

DeleteBankAccount.propTypes = {
  setShowModal: PropTypes.bool,
}.isRequired;

export default DeleteBankAccount;
