import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Loading from '@/components/atoms/Loading';
import i18n from '@/translate/i18n';
import useBalance from '@/hooks/useBalance';
import { createConversion } from '@/services/api';
import { formatCurrency } from '@/helpers/stringFormat';

import {
  Arrow,
  Button,
  ButtonWrapper,
  Content,
  Section,
  Title,
} from './styles';

function ConfirmConversion({
  amount,
  fromCurrency,
  rate,
  setShowModal,
  toCurrency,
  setSubmitError,
}) {
  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);
  const { getBalance } = useBalance();

  const generateConversion = async () => {
    try {
      setSubmitting(true);
      const payload = {
        from_currency: fromCurrency,
        to_currency: toCurrency,
        from_amount: amount,
      };

      const res = await createConversion(payload);
      const { id } = res.data;
      await getBalance();
      history.push(`/transactions/${id}`);
    } catch (err) {
      const message = err?.response?.data?.message;
      setSubmitError(
        i18n.t([`error.conversion.${message}`, 'error.conversion.unspecific']),
      );
      setShowModal(false);
    } finally {
      setSubmitting(false);
    }
  };

  const toAmount =
    rate?.from_amount > rate?.to_amount
      ? amount / rate?.spread_rate
      : amount * rate?.spread_rate;
  return (
    <Content>
      {!submitting ? (
        <>
          <Title id="confirm-conversion-title">
            {i18n.t('conversion.confirmationMsg')}
          </Title>
          <Section>
            <span id="conversion-preview-from-amount">
              {formatCurrency(amount, fromCurrency)}
            </span>
            <Arrow />
            <span id="conversion-preview-to-amount">
              {rate && formatCurrency(toAmount, toCurrency)}
            </span>
          </Section>

          <ButtonWrapper>
            <Button
              type="button"
              onClick={generateConversion}
              id="confirm-conversion-button"
            >
              {i18n.t('conversion.yes')}
            </Button>
            <Button
              type="button"
              onClick={() => setShowModal(false)}
              id="cancel-conversion-button"
            >
              {i18n.t('conversion.no')}
            </Button>
          </ButtonWrapper>
        </>
      ) : (
        <Loading marginTop="50px" />
      )}
    </Content>
  );
}

ConfirmConversion.propTypes = {
  amount: PropTypes.number,
  fromCurrency: PropTypes.string,
  rate: PropTypes.shape({
    from_amount: PropTypes.number,
    spread_rate: PropTypes.number,
    to_amount: PropTypes.number,
  }),
  setShowModal: PropTypes.func,
  setSubmitError: PropTypes.func,
  toCurrency: PropTypes.string,
}.isRequired;

export default ConfirmConversion;
