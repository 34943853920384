import styled from 'styled-components';

import { ReactComponent as ArrowRight } from '@/assets/arrowCyan.svg';
import { hexToRGBA } from '@/styles/colors';

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.font.family.primary};
  height: 190px;
  justify-content: space-around;
  padding: 10px 0 60px;
  width: 100%;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.info.light};
  font: ${({ theme }) =>
    `${theme.font.size.medium} ${theme.font.family.primary}`};
  text-align: center;
  width: 100%;
`;

export const Section = styled.section`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  font-size: ${({ theme }) => theme.font.size.medium};
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const Arrow = styled(ArrowRight)`
  margin: 0 10px;
  transform: rotate(180deg);
  width: 20px;

  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const ButtonWrapper = styled.div`
  bottom: 0;
  position: absolute;
  width: 100%;
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
  border-top: 2px solid ${({ theme }) => theme.colors.button.active};
  color: ${({ theme }) => theme.colors.primary};
  font: ${({ theme }) =>
    `${theme.font.size.medium} ${theme.font.family.secondary}`};
  height: 60px;
  width: 50%;

  & + button {
    border-left: 2px solid ${({ theme }) => theme.colors.button.active};
  }

  &:hover {
    background-color: ${({ theme }) => hexToRGBA(theme.colors.shadow, 0.1)};
  }
`;
