import React from 'react';
import { useParams } from 'react-router-dom';

import LoginRegisterSelector from '@/components/molecules/LoginRegisterSelector';
import SignInForm from '@/components/organisms/SignInForm';
import TransferApiTemplate from '@/components/templates/TransferApiTemplate';

function SignInTransferApi() {
  const { id } = useParams();

  if (id) {
    sessionStorage.setItem('pendingApiTransaction', id);
  }

  return (
    <TransferApiTemplate>
      <LoginRegisterSelector isExternalLogin origin="signIn" />
      <SignInForm transactionId={id} invertAnimation />
    </TransferApiTemplate>
  );
}

export default SignInTransferApi;
