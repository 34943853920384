import styled from 'styled-components';

const Content = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-items: center;
    color: ${({ theme }) => theme.colors.info.light};
    display: flex;
    font-size: ${({ theme }) => theme.font.size.medium};
    position: absolute;
    right: 40px;
    text-transform: capitalize;
    top: 20px;

    svg {
      margin-left: 15px;
    }
  }
`;

export default Content;
