import React from 'react';
import PropTypes from 'prop-types';
import HighlightedTitle from '@/components/v2/atoms/HighlightedTitle';
import i18n from '@/translate/i18n';
import TwText from '../../atoms/TwText';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import BackgroudFanDesktop from '@/assets/backgroudFanDesktop.svg';
import BackgroudFanMobile from '@/assets/backgroudFanMobile.svg';
import LogoVPag from '@/assets/vpagWalletLogo.svg';
import { TwModal } from '../../atoms/TwModal';
import TwButton from '@/components/atoms/Buttons/TwButton';

const PromocodePointsModalContent = ({
  isOpen = true,
  handleCloseModal,
  points,
}) => {
  const { isMobileLayout } = useDeviceWidth();
  return (
    <TwModal
      isOpen={isOpen}
      onClose={handleCloseModal}
      classList="size-full md:!w-[75%] md:!min-h-[90%] md:h-[75%] 2xl:!size-[66%] relative"
      classNameStyleCloseButton="z-[99]"
      classListChildren="absolute md:bottom-0 size-full !pb-[104px] md:!pb-0 iphone-se:!pb-[40px] iphone-5:!pb-[5px] s2xl:!pb-20 2xl:mb-0 flex items-center md:items-start flex-col justify-end md:justify-center gap-[50px] iphone-se:gap-[20px] md:gap-6 top-0"
    >
      <img
        className="absolute inset-0 size-full object-cover"
        src={isMobileLayout ? BackgroudFanMobile : BackgroudFanDesktop}
        alt="validate your data"
      />
      <div className="w-screen md:w-[80%] h-min flex flex-col gap-14 iphone-5:gap-1 md:gap-5 2xl:ml-32 z-[99] md:ml-24  md:mt-12">
        <img
          src={LogoVPag}
          alt="logo vpag"
          width={107}
          height={44}
          className="hidden md:block mt-4 z-[99] 2xl:-ml-16 md:-ml-8 2xl:mb-20 md:mt-5"
        />
        <div>
          <HighlightedTitle
            text={i18n.t('validateIdentity.popupPoints.earn')}
            className="w-[224px] md:w-max z-20 relative"
            classNameContainerText="md:!bg-black md:!p-0 md:!pb-[15px]"
            classNameText="md:text-white md:font-arboria-regular"
          />
          <HighlightedTitle
            text={points + i18n.t('validateIdentity.popupPoints.points')}
            className="w-[334px] md:w-max pr-0 md:pt-0 z-10 relative -mt-[15px] md:before:content-[''] md:before:block md:before:w-5 md:before:h-5 md:before:-ml-5 md:before:bg-grass-800"
            classNameText="font-bold"
          />
        </div>

        <TwText
          text={i18n.t('validateIdentity.popupPoints.completeValidation')}
          classList="!text-[22px] text-left w-[285px] m-auto md:mx-0 md:min-w-[433px]"
        />
      </div>
      <TwButton
        height="secondary"
        label="Continuar"
        onClick={handleCloseModal}
        classList="!w-72 min-h-[54px] !rounded-[7.2px] !text-sm md:!mt-5 md:ml-24 2xl:ml-32 z-[99]"
      />
    </TwModal>
  );
};

PromocodePointsModalContent.propTypes = {
  handleCloseModal: PropTypes.func,
  points: PropTypes.number,
  isOpen: PropTypes.bool,
};

export default PromocodePointsModalContent;
