import React from 'react';
import PropTypes from 'prop-types';

import { ImageWrapper, Wrapper } from './styles';
import useDeviceWidth from '@/hooks/useDeviceWidth';

function MerchantUsernameHelper({ helperData }) {
  const { isMobileLayout } = useDeviceWidth();

  return (
    <Wrapper>
      <ImageWrapper>
        <img
          src={
            isMobileLayout ? helperData?.imageMobile : helperData?.imageDesktop
          }
          alt="Merchant username tutorial"
        />
      </ImageWrapper>
    </Wrapper>
  );
}

MerchantUsernameHelper.propTypes = {
  helperData: PropTypes.shape({
    imageMobile: PropTypes.string,
    imageDesktop: PropTypes.string,
  }),
};

export default MerchantUsernameHelper;
