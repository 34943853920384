import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import { InternalButton } from '@/components/atoms/Buttons/Button/styles';
import { formatFullDate } from '@/helpers/stringFormat';
import { useWalletData } from '@/context/walletContext';

import { FlexWrapper, Wrapper } from './styles';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';

function BonusDetails({ bonus, setTermsSlug, enableSecondaryAction }) {
  const { setWalletData } = useWalletData();

  const expiresDate = bonus?.expires_at ?? bonus?.user_bonuses?.[0]?.expires_at;
  const isAvailableOnlyForOneMerchant = bonus?.merchant_ids?.length === 1;

  const bonusForSomeMerchants = {
    filteredMerchantsByBonus: bonus?.merchant_ids,
    isMerchantsFilteredByBonus: true,
  };

  const bonusForAllMerchants = {
    filteredMerchantsByBonus: null,
    isMerchantsFilteredByBonus: true,
  };

  const newBonusState = {
    ...(bonus?.merchant_ids?.length
      ? bonusForSomeMerchants
      : bonusForAllMerchants),
    ...(isAvailableOnlyForOneMerchant
      ? { initialSelectedMerchant: bonus?.merchant_ids[0] }
      : {}),
  };
  const primaryAction = () => {
    setWalletData((prevState) => ({
      ...prevState,
      selectedBonus: bonus,
      ...newBonusState,
    }));
  };

  const secondaryAction = () => {
    setWalletData((prevState) => ({
      ...prevState,
      selectedMerchant: {},
      selectedBonus: bonus,
      ...newBonusState,
    }));
  };

  return (
    <Wrapper id="bonus-details">
      <FlexWrapper>
        <h1>{bonus?.title}</h1>
        {expiresDate && (
          <span>
            {i18n.t('bonusDetails.expiresAt')}
            {formatFullDate(expiresDate)}
          </span>
        )}
      </FlexWrapper>

      <FlexWrapper>
        <InternalButton
          secondary
          onClick={enableSecondaryAction ? secondaryAction : primaryAction}
          to="/transfer"
          id="use-bonus-button"
        >
          {i18n.t('bonusDetails.useBonus')}
        </InternalButton>
        {bonus?.terms_slug && (
          <TextButton
            type="button"
            onClick={() => setTermsSlug(bonus.terms_slug)}
          >
            {i18n.t('bonusDetails.regulation')}
          </TextButton>
        )}
      </FlexWrapper>
    </Wrapper>
  );
}

BonusDetails.propTypes = {
  setTermsSlug: PropTypes.func,
  enableSecondaryAction: PropTypes.bool,
  bonus: PropTypes.shape({
    description: PropTypes.string,
    terms: PropTypes.string,
    expires_at: PropTypes.string,
    merchant_ids: PropTypes.arrayOf(PropTypes.number),
    transaction_type: PropTypes.string,
    user_bonuses: PropTypes.arrayOf(PropTypes.object),
  }),
}.isRequired;

export default BonusDetails;
