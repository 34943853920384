import styled from 'styled-components';

import DropdownArrow from '@/assets/dropdown-arrow.svg';

const Select = styled.select`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: ${`url(${DropdownArrow}) no-repeat`};
  background-color: ${({ theme }) => theme.colors.bg.dark};
  background-position-x: 80%;
  background-position-y: center;
  border-radius: 40px;
  border: none;
  color: ${({ theme }) => theme.colors.light};
  cursor: pointer;
  font: ${({ theme }) =>
    `${theme.font.size.medium} ${theme.font.family.secondary}`};
  height: 35px;
  min-width: 100px;
  padding-right: 10px;
  text-align: center;

  option {
    background-color: ${({ theme }) => theme.colors.bgGradientDark.start};
    font: ${({ theme }) =>
      `${theme.font.size.small} ${theme.font.family.secondary}`};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-left: 10px;
  }
`;

export default Select;
