import styled from 'styled-components';

export const Content = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    border-radius: 20px;
    height: 100%;
    justify-content: space-between;
    max-height: 550px;
    max-width: 340px;
  }
`;

export const OptionsWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 150px;
  justify-content: center;
  margin-bottom: 5vh;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    grid-template-columns: 150px 150px;
    max-height: 550px;
    padding-left: 4rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 1rem 0;
  }
`;

export const TextWrapper = styled.div`
  min-height: 45px;
`;
