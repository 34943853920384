import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  ${({ theme, minimalVersion }) => css`
    align-items: center;
    color: ${theme.colors.info.primary};
    display: flex;
    flex-direction: ${minimalVersion ? 'row' : 'column'};
    font-size: ${minimalVersion
      ? theme.font.size.xSmall
      : theme.font.size.small};
    gap: 1rem;
    text-align: ${minimalVersion ? 'left' : 'center'};
    width: 100%;

    strong {
      color: ${theme.colors.info.light};
      display: block;
      font: ${`500 ${theme.font.size.small} ${theme.font.family.secondary}`};
    }

    & > div {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: ${minimalVersion ? '0.5rem' : '2rem'};
      height: 100%;
      justify-content: space-between;

      & > div {
        flex-direction: ${minimalVersion ? 'row' : 'column'};
      }
    }
  `}
`;

export const CircularProgressBarWrapper = styled.div`
  font-size: ${({ theme }) => theme.font.size.xxSmall};
  min-width: 70px;
  text-transform: uppercase;
  width: 120px;
`;

export const MainText = styled.p`
  ${({ theme, minimalVersion, isPending }) => css`
    color: ${isPending ? theme.colors.pending : theme.colors.highlight};
    font: ${`${
      minimalVersion ? theme.font.size.xLarge : theme.font.size.xxLarge
    } ${theme.font.family.secondary}`};

    span {
      font-size: ${minimalVersion
        ? theme.font.size.small
        : theme.font.size.large};
    }
  `}
`;

export const ContentWrapper = styled.div`
  ${({ theme, minimalVersion }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      align-items: ${minimalVersion ? 'start' : 'center'};
      padding-left: ${minimalVersion ? '0.3rem' : 0};
    }
  `}
`;

export const Button = styled(Link)`
  ${({
    theme,
    fontSize = 'medium',
    fontFamily = 'secondary',
    bgColor = 'primary',
  }) => css`
    animation: none;
    background-color: ${theme.colors[bgColor]};
    border-radius: 5px;
    color: ${theme.colors.info.dark};
    display: block;
    font: ${theme.font.size[fontSize]} ${theme.font.family[fontFamily]};
    max-width: 230px;
    padding: 0.4rem 1rem;
    text-align: center;
    text-decoration: none;
    width: 100%;
  `}
`;
