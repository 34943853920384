import React from 'react';
import parse from 'html-react-parser';
import { Link, useParams } from 'react-router-dom';

import Container from '@/components/atoms/Container/styles';
import Loading from '@/components/atoms/Loading';
import { Paragraph } from '@/components/atoms/Typography/styles';
import Footer from '@/components/organisms/Footer';
import useTerms from '@/hooks/useTerms';
import i18n from '@/translate/i18n';

import { ContentWrapper, Logo, TermsHeader } from './styles';
import TwTitle from '@/components/atoms/TwTitle';
import useDeviceWidth from '@/hooks/useDeviceWidth';

function Terms() {
  const { slugTerms } = useParams();
  const { termsContent, error, isFetching } = useTerms(slugTerms);
  const { isMobileLayout } = useDeviceWidth();

  return (
    <Container>
      <TermsHeader>
        <Link to="/signin">
          <Logo />
        </Link>
      </TermsHeader>
      <TwTitle
        text={i18n.t('termsAndConditions.title')}
        size={isMobileLayout ? 'm_lg' : 'd_lg'}
        font="arboriaBold"
        classList="text-center"
      />

      <ContentWrapper>
        {isFetching ? (
          <Loading />
        ) : error ? (
          <Paragraph>{i18n.t('error.termsAndConditions.unspecific')}</Paragraph>
        ) : (
          parse(termsContent)
        )}
      </ContentWrapper>
      <Footer />
    </Container>
  );
}

export default Terms;
