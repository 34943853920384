import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 5vh;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  align-items: start;
  display: flex;
  height: 90%;
  justify-content: space-around;
  width: 100%;
`;
