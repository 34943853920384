import React from 'react';

import { ReactComponent as DepositIcon } from '@/assets/depositMinimal.svg';
import { ReactComponent as TransferIcon } from '@/assets/transferMinimal.svg';
import { Button, Divider, Wrapper } from './styles';

function HomeNavButtons() {
  return (
    <Wrapper>
      <Button to="/deposit">
        <DepositIcon />
        Depositar
      </Button>
      <Divider />
      <Button to="transfer">
        <TransferIcon />
        Transferir
      </Button>
    </Wrapper>
  );
}

export default HomeNavButtons;
