import React from 'react';
import { Toaster } from 'react-hot-toast';
import { useTheme } from 'styled-components';

import useDeviceWidth from '@/hooks/useDeviceWidth';

function ToasterContainer() {
  const theme = useTheme();
  const { isMobileLayout } = useDeviceWidth();

  const defaultStyles = {
    background: theme.colors.bg.secondary,
    color: theme.colors.info.light,
    fontFamily: theme.font.family.primary,
    textAlign: 'left',
  };

  return (
    <Toaster
      position={isMobileLayout ? 'top-center' : 'top-right'}
      toastOptions={{
        success: {
          style: defaultStyles,
          iconTheme: {
            primary: theme.colors.primary,
            secondary: theme.colors.bg.secondary,
          },
        },
        error: {
          style: defaultStyles,
        },
        loading: {
          style: defaultStyles,
        },
      }}
    />
  );
}

export default ToasterContainer;
