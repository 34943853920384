import React from 'react';

import PhoneNumberForm from '@/components/organisms/PhoneNumberForm';
import ProfileTemplate from '@/components/templates/ProfileTemplate';

function UpdatePhoneNumber() {
  return (
    <ProfileTemplate>
      <PhoneNumberForm />
    </ProfileTemplate>
  );
}

export default UpdatePhoneNumber;
