import styled, { css } from 'styled-components';

import { hexToRGBA } from '@/styles/colors';

const SectionDivider = styled.div`
  ${({ theme, breakpoint }) => css`
    display: none;

    @media screen and (min-width: ${breakpoint ?? theme.breakpoints.sm}) {
      align-self: center;
      animation: opacityAnimationDefault 0.8s ease;
      border-left: 1px solid ${hexToRGBA(theme.colors.info.primary, 0.2)};
      display: block;
      height: ${({ height = '90%' }) => height};
    }
  `}
`;

export default SectionDivider;
