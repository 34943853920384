import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Formik, Form } from 'formik';
import HeadModal from '@/components/molecules/HeadModal';
import PersonPlus from '@/assets/personPlus.svg';
import i18n from '@/translate/i18n';
import { USERNAME_SCHEMA } from '@/helpers/schemas';
import Input from '@/components/atoms/Inputs/Input';

import { Container } from '../CashbackPixInfo/style';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { LOCAL_STORAGE_KEY_USERNAME } from '@/helpers/constants';
import {
  InsertUsernameBody,
  InsertUsernameContent,
  SubmitButtonStyled,
} from './style';
import { Title } from '@/components/atoms/Typography/styles';

const SECOND_STEP = 2;

function CashInsertUsername({ handleBackStep, merchantName, handleNextStep }) {
  const [submitError, setSubmitError] = useState(false);
  const handleSubmit = async (username) => {
    localStorage.setItem(LOCAL_STORAGE_KEY_USERNAME, username);
    handleNextStep();
  };
  const { isMobileLayout } = useDeviceWidth();

  return (
    <Container isMobile={isMobileLayout}>
      <HeadModal
        currentStep={SECOND_STEP}
        handleBackStep={handleBackStep}
        currentIcon={PersonPlus}
        className="-m-28"
      />
      <InsertUsernameBody isMobileLayout={isMobileLayout}>
        <Title size="medium" className="text-xl md:text-2xl text-white">
          {i18n.t('cashbackModalInsertUsername.title')}
          {merchantName}
        </Title>

        <Formik
          validationSchema={USERNAME_SCHEMA}
          initialValues={{
            username: '',
          }}
          initialErrors={{
            username: 'Required',
          }}
          onSubmit={({ username }) => {
            handleSubmit(username);
          }}
        >
          {({ values, isValid, isSubmitting }) => (
            <Form>
              <InsertUsernameContent
                className="table-column-group  md:mx-14"
                onChange={() => {
                  setSubmitError(false);
                }}
              >
                <Input
                  id="username"
                  name="username"
                  type="text"
                  placeholder="username123"
                  value={values.username}
                  color="light"
                  secondaryTheme
                  disableAutoComplete
                  className="!w-full"
                />
                {submitError && <span>{submitError}</span>}
                <div className="mx-20 md:mx-10">
                  <p className="text-sands text-[16px]font-extralight">
                    {i18n.t('cashbackModalInsertUsername.info')}
                    <span className="font-bold">{merchantName}</span>
                  </p>
                </div>
                <SubmitButtonStyled
                  className="!w-full"
                  isValid={isValid}
                  type="submit"
                  isSubmitting={isSubmitting}
                  submitText={i18n.t('cashbackModalInsertUsername.confirm')}
                  before={i18n.t('cashbackModalInsertUsername.confirm')}
                  after={i18n.t('cashbackModalInsertUsername.confirm')}
                />
              </InsertUsernameContent>
            </Form>
          )}
        </Formik>
      </InsertUsernameBody>
    </Container>
  );
}

CashInsertUsername.propTypes = {
  handleBackStep: PropTypes.func,
  handleNextStep: PropTypes.func,
  merchantName: PropTypes.string,
  merchantURLCreateAccount: PropTypes.string,
}.isRequired;
export default CashInsertUsername;
