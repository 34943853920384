import { useState, useEffect } from 'react';

import { listUsernames } from '@/services/api';
import { useUserData } from '@/context/userContext';

const useUsernames = () => {
  const [loadingUsernames, setLoadingUsernames] = useState(false);
  const [usernamesError, setUsernamesError] = useState(false);
  const [savedUsernames, setSavedUsernames] = useState([]);
  const { setUserData, userData } = useUserData();
  const { usernames } = userData;

  const getUsernames = async (controller) => {
    try {
      setLoadingUsernames(true);
      const res = await listUsernames(
        localStorage.getItem('id'),
        controller.signal,
      );
      setSavedUsernames(res.data);
      setUserData((prevState) => ({ ...prevState, usernames: res.data }));
    } catch (error) {
      setUsernamesError(true);
    } finally {
      setLoadingUsernames(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (!usernames?.length) {
      getUsernames(controller);
    } else {
      setSavedUsernames(usernames || []);
    }

    return () => controller.abort();
  }, [setUserData, usernames?.length]);

  return { loadingUsernames, usernamesError, savedUsernames, getUsernames };
};

export default useUsernames;
