import React from 'react';
import PropTypes from 'prop-types';
import Subtitle from '@/components/atoms/Subtitle';
import { TitleWhite } from '../Modal/Messages/CashbackPixInfo/style';

import { RowInfo } from './style';

const OPACITY = 0.9;

function Row({ title, value }) {
  return (
    <RowInfo>
      <Subtitle opacity={OPACITY}>{title}</Subtitle>
      <TitleWhite size="small">{value}</TitleWhite>
    </RowInfo>
  );
}
Row.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
}.isRequired;

export default Row;
