import React from 'react';
import { Trans } from 'react-i18next';

import { LinkTextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { Paragraph } from '@/components/atoms/Typography/styles';
import { Wrapper } from '@/components/organisms/UploadSof/styles';
import i18n from '@/translate/i18n';
import TwTitle from '@/components/atoms/TwTitle';

function SofContent() {
  return (
    <Wrapper>
      <div>
        <TwTitle
          size="xl"
          font="arboriaBold"
          color="shadow-500"
          classList="text-center uppercase"
        >
          <Trans
            i18nKey="validations.sof.title"
            i18n={i18n}
            components={[<span />]}
          />
        </TwTitle>
      </div>
      <Paragraph family="secondary" color="title">
        {i18n.t('validations.validated_level_one.description')}
      </Paragraph>
      <LinkTextButton className="!text-lg" to="/wallet">
        {i18n.t('returnButton.return')}
      </LinkTextButton>
    </Wrapper>
  );
}

export default SofContent;
