import React from 'react';

import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import i18n from '@/translate/i18n';

import { Wrapper } from './styles';
import { Paragraph } from '@/components/atoms/Typography/styles';

function VPlusAffiliationStatus({ affiliationStatus }) {
  const { status, merchant, pointsExample, multiplier } = affiliationStatus;

  return (
    Boolean(Object.keys(affiliationStatus).length) && (
      <Wrapper>
        <Paragraph color="light">
          <Trans
            i18n={i18n}
            i18nKey={`clubVPlus.affiliationStatus.${status}`}
            tOptions={{
              status,
              merchant,
              multiplier,
              pointsExample,
            }}
            components={[<strong />, <span />]}
          />
        </Paragraph>

        {status === 'affiliated' && (
          <Paragraph color="light">
            <Trans
              i18n={i18n}
              i18nKey="clubVPlus.affiliationStatus.example"
              tOptions={{
                status,
                merchant,
                pointsExample,
                multiplier,
              }}
              components={[<strong />, <span />]}
            />
          </Paragraph>
        )}
      </Wrapper>
    )
  );
}
VPlusAffiliationStatus.propTypes = {
  affiliationStatus: PropTypes.shape({
    status: PropTypes.string,
    merchant: PropTypes.string,
    pointsExample: PropTypes.string,
    multiplier: PropTypes.string,
  }),
};

export default VPlusAffiliationStatus;
