import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as WarningIcon } from '@/assets/warning-yellow.svg';
import i18n from '@/translate/i18n';

import { ActionButton, TextWrapper, Wrapper } from './styles';

function ExpiredSession({ close }) {
  return (
    <Wrapper>
      <WarningIcon />
      <TextWrapper>
        <span>{i18n.t('modal.expiredSession')}</span>
      </TextWrapper>
      <ActionButton onClick={close}>Ok</ActionButton>
    </Wrapper>
  );
}

ExpiredSession.propTypes = {
  close: PropTypes.func,
};

export default ExpiredSession;
