import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import BankIcon from '@/components/atoms/BankIcon';
import Container from '@/components/atoms/Container/styles';
import DeskContainer from '@/components/atoms/DeskContainer/styles';
import NavMenuLeft from '@/components/organisms/NavMenuLeft';
import SearchBar from '@/components/atoms/SearchBar';
import WalletHeader from '@/components/molecules/WalletHeader';
import i18n from '@/translate/i18n';
import { DeskWrapper } from '@/components/atoms/GenerateTransactionStyle/styles';
import { banksMock } from '@/data/mocks';

import {
  ArrowButton,
  LogoWrapper,
  BankWrapper,
  Title,
  TitleWrapper,
  Arrow,
} from './styles';

function SelectBank() {
  const history = useHistory();

  const [banks, setBanks] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setBanks(banksMock.filter((el) => el.active));
  }, []);

  useEffect(() => {
    const arr = banks.filter(({ name }) =>
      name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
    );
    setFiltered(arr);
  }, [searchTerm, banks]);

  const data = searchTerm ? filtered : banks;

  return (
    <DeskContainer>
      <NavMenuLeft />
      <Container>
        <WalletHeader />
        <WalletHeader
          title={i18n.t('selectBank.title')}
          returnTo="/bank-accounts/banks"
        />
        <DeskWrapper>
          <SearchBar
            handleChange={setSearchTerm}
            placeholder={i18n.t('selectBank.searchBar')}
          />

          <TitleWrapper>
            <Title>{i18n.t('selectBank.listTitle')}</Title>
          </TitleWrapper>
          <BankWrapper>
            {data.map(({ id, slug, name }) => (
              <LogoWrapper
                onClick={() => {
                  history.push({
                    pathname: '/add-bank-account',
                    state: {
                      bankSlug: slug,
                      bankName: name,
                    },
                  });
                }}
                key={id}
              >
                <BankIcon slug={slug} width="50px" />
                <p>{name}</p>
                <ArrowButton>
                  <Arrow />
                </ArrowButton>
              </LogoWrapper>
            ))}
          </BankWrapper>
        </DeskWrapper>
      </Container>
    </DeskContainer>
  );
}

export default SelectBank;
