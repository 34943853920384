import React from 'react';
import i18n from '@/translate/i18n';
import TwText from '@/components/v2/atoms/TwText';

const EmptyKeys = () => (
  <div className="h-96 flex flex-col justify-center">
    <TwText
      text={i18n.t('pixArea.emptyKeys')}
      classList="hidden md:block md:text-[32px] md:leading-10"
    />
  </div>
);

export default EmptyKeys;
