import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import AffiliationStatus from '@/components/atoms/AffiliationStatus';
import MerchantIcon from '@/components/atoms/MerchantIcon';
import SelectDefault from '@/components/atoms/SelectDefault';
import TransferForm from '@/components/organisms/TransferForm';
import { useUserData } from '@/context/userContext';
import { useWalletData } from '@/context/walletContext';
import { calculateBonus } from '@/helpers/functions';
import useUsernames from '@/hooks/useUsernames';
import i18n from '@/translate/i18n';

import { ContainerAffiliation, IconWrapper, Wrapper } from './styles';
import TwTitle from '@/components/atoms/TwTitle';
import useDeviceWidth from '@/hooks/useDeviceWidth';

function DesktopTransferForm({ setShowDetails, showDetails }) {
  const [merchantCurrency, setMerchantCurrency] = useState('BRL');
  const { loadingUsernames } = useUsernames();
  const { userData } = useUserData();
  const {
    walletData: {
      selectedMerchant,
      selectedBonus,
      transactionData,
      transactionSubmitError,
    },
  } = useWalletData();

  const affiliation = userData.usernames?.find(
    ({ merchant_id }) => merchant_id === selectedMerchant?.id,
  );

  const bonusAmount = calculateBonus(transactionData?.amount, selectedBonus);
  const amountWithBonus = Number(transactionData.amount) + Number(bonusAmount);
  const { isMobileLayout } = useDeviceWidth();

  useEffect(() => {
    setMerchantCurrency(selectedMerchant?.currencies?.[0]);
  }, [selectedMerchant]);

  return (
    <Wrapper>
      {transactionSubmitError ? (
        <TwTitle
          size="xl"
          font="arboriaBold"
          color="shadow-500"
          classList="text-center uppercase"
        >
          <Trans
            i18nKey={i18n.t('transfer.errorTitle')}
            i18n={i18n}
            components={[<strong />]}
          />
        </TwTitle>
      ) : (
        <TwTitle
          text={i18n.t('transfer.title')}
          size={isMobileLayout ? 'm_lg' : 'd_lg'}
          font="arboriaBold"
          classList="text-center"
        />
      )}
      <ContainerAffiliation>
        <IconWrapper>
          <MerchantIcon imageUrl={selectedMerchant?.image_url} width="50px" />
          <p id="transfer-in-selected-merchant-name">
            {selectedMerchant?.name}
          </p>
        </IconWrapper>
        {!loadingUsernames && <AffiliationStatus data={affiliation} />}
        {(affiliation?.username || transactionData?.username) && (
          <SelectDefault
            options={selectedMerchant?.currencies}
            onChange={({ target }) => setMerchantCurrency(target.value)}
            disabled={showDetails}
          />
        )}
      </ContainerAffiliation>
      <TransferForm
        merchant={selectedMerchant}
        isFetching={false}
        usernameOnMerchant={affiliation?.username ?? null}
        setShowModal={false}
        isFirstTransfer
        setShowDetails={setShowDetails}
        showDetails={showDetails}
        finalAmount={amountWithBonus}
        merchantCurrency={merchantCurrency}
      />
    </Wrapper>
  );
}

DesktopTransferForm.propTypes = {
  usernames: PropTypes.arrayOf(PropTypes.object),
}.isRequired;

export default DesktopTransferForm;
