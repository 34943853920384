import styled from 'styled-components';

const HEIGHT_BREAKPOINT = '475px';

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-self: center;
    margin-bottom: 10px;
    width: 26vw;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 20vw;
  }
`;

export const AbsoluteWrapper = styled.div`
  position: relative;
  margin-top: 1rem;

  @media screen and (min-height: ${HEIGHT_BREAKPOINT}) {
    position: absolute;
    top: -7vh;
  }
`;
