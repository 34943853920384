import styled, { css } from 'styled-components';
import VPagCard from '@/assets/vpag-card.svg';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-height: 550px;
  padding: 0 1.5rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 2.5rem 1.5rem;
  }
`;

export const VirtualCard = styled.div`
  aspect-ratio: 1.58;
  background: url(${VPagCard}) no-repeat;
  background-size: contain;
  color: ${({ theme }) => theme.colors.info.dark};
  filter: ${({ active }) => (active ? 'none' : 'grayscale(100%)')};
  margin: 1rem 0 2rem;
  max-height: 196px;
  max-width: 310px;
  position: relative;
  width: 100%;
`;

export const CardAmount = styled.p`
  font: ${({ theme }) =>
    `${theme.font.size.medium} ${theme.font.family.secondary}`};
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 3;
`;

export const CardInfo = styled.div`
  align-items: flex-start;
  bottom: 15px;
  display: flex;
  flex-direction: column;
  font: ${({ theme }) =>
    `${theme.font.size.small} ${theme.font.family.primary}`};
  left: 15px;
  position: absolute;
`;

export const StatusIcon = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bg.darkSecondary};
  border-radius: 50%;
  bottom: -20px;
  display: flex;
  height: 50px;
  justify-content: center;
  position: absolute;
  right: -20px;
  width: 50px;
  z-index: 3;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const CardDataWrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.info.title};
    column-gap: 2rem;
    display: grid;
    font: ${`${theme.font.size.small}  ${theme.font.family.secondary}`};
    grid-template-columns: 2fr 1fr;
    justify-items: start;
    max-width: 310px;
    width: 100%;

    span {
      color: ${theme.colors.info.light};
    }

    & > * {
      padding: 0.5rem 0;
    }

    & > *:nth-child(even) {
      align-self: center;
      justify-self: end;
    }
  `}
`;
