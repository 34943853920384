import React from 'react';
import PropTypes from 'prop-types';

import { Title } from '@/components/molecules/WalletHeader/style';
import BackButton from '@/components/atoms/BackButton';
import CurrentBalance from '../CurrentBalance';
import i18n from '@/translate/i18n';

const TitleModal = ({ handleBackStep }) => (
  <header className="h-28 w-full px-5 flex flex-col items-center justify-center">
    <div className="w-full flex items-center py-5 relative">
      <BackButton onClick={handleBackStep} />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Title fontSize="18px">{i18n.t('cashback.title')}</Title>
      </div>
    </div>

    <CurrentBalance />
  </header>
);

TitleModal.propTypes = {
  handleBackStep: PropTypes.func,
}.isRequired;
export default TitleModal;
