import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { Trans } from 'react-i18next';

import Input from '@/components/atoms/Inputs/Input';
import Select from '@/components/atoms/Select';
import SubmitButton from '@/components/atoms/Buttons/SubmitButton';
import { useUserData } from '@/context/userContext';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { brazilianStates } from '@/helpers/constants';
import { extractErrorSlug } from '@/helpers/functions';
import { ADDRESS_SCHEMA } from '@/helpers/schemas';
import { formatAddress, formatCep } from '@/helpers/stringFormat';
import {
  createUserAddress,
  getAddressByPostalCode,
  updateUserAddress,
  getCitiesByState,
} from '@/services/api';

import {
  ErrorText,
  FormStyled,
  FormWrapper,
  GridWrapper,
  InputsWrapper,
  SubmitWrapper,
} from './styles';
import TwTitle from '@/components/atoms/TwTitle';

const BREAKPOINT = 1024;
const POSTAL_CODE_STRING_LENGTH = 9;
const BASIC_FORM_FIELDS = [
  { name: 'state', label: 'uf' },
  { name: 'city', label: 'localidade' },
  { name: 'district', label: 'bairro' },
  { name: 'address', label: 'logradouro' },
];
const indexFirstCity = 0;

function AddressForm({ onComplete }) {
  const formRef = useRef();
  const inputNumberRef = useRef(null);
  const history = useHistory();
  const { userData, setUserData } = useUserData();
  const { pathname } = useLocation();
  const { deviceWidth } = useDeviceWidth();
  const [errorMessage, setErrorMessage] = useState('');
  const [wasUpdated, setWasUpdated] = useState(false);
  const [requestCitiesError, setRequestCitiesError] = useState(false);
  const [listOfCities, setListOfCities] = useState([]);
  if (pathname.includes('update') && !userData?.address?.address) {
    history.push('/profile');
  }

  const getCities = async (state) => {
    try {
      const data = await getCitiesByState(state);
      const formatted = data
        .map(({ nome }) => ({
          value: nome,
          label: nome,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setListOfCities(formatted);
    } catch (err) {
      setRequestCitiesError(true);
    }
  };

  const getAddressInfo = async (postalCode, setFieldValue) => {
    try {
      const data = await getAddressByPostalCode(postalCode);

      if (data?.erro) {
        BASIC_FORM_FIELDS.forEach(({ name }) => setFieldValue(name, ''));
      } else {
        await getCities(data?.uf);
        BASIC_FORM_FIELDS.forEach(({ name, label }) =>
          setFieldValue(name, data?.[label]),
        );

        inputNumberRef?.current.focus();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const isUpdate = userData?.address?.address?.length;
  const isProfilePage = history.location.pathname.includes('/profile');
  const redirectAfterSubmit = () => history.push('/profile');

  useEffect(() => {
    if (!listOfCities.length) return;
    formRef.current.setFieldValue('city', listOfCities[indexFirstCity].value);
  }, [formRef?.current?.values.state, listOfCities]);

  useEffect(() => {
    if (formRef?.current?.values?.state) {
      getCities(formRef.current.values.state);
    }
  }, []);

  return (
    <FormWrapper>
      <TwTitle
        size="xl"
        font="arboriaBold"
        color="shadow-500"
        classList="text-center"
      >
        <Trans
          i18n={i18n}
          i18nKey={
            isUpdate ? 'addressForm.myAddress' : 'addressForm.registerAddress'
          }
          components={<span />}
        />
      </TwTitle>
      <Formik
        innerRef={formRef}
        initialValues={{
          postalCode: userData?.address?.postal_code || '',
          state: userData?.address?.state || '',
          city: userData?.address?.city || '',
          district: userData?.address?.district || '',
          address: formatAddress(userData?.address?.address, 'street') || '',
          complement: userData?.address?.complement || '',
          number: formatAddress(userData?.address?.address, 'number') || '',
        }}
        validationSchema={ADDRESS_SCHEMA}
        initialErrors={{
          postalCode: 'required',
          state: 'required',
          city: 'required',
          district: 'required',
          address: 'required',
          number: 'required',
        }}
        onSubmit={async ({
          address,
          city,
          complement,
          district,
          postalCode,
          state,
          number,
        }) => {
          try {
            const userId = localStorage.getItem('id');
            const payload = {
              user_id: userId,
              address: `${address}, ${number}`,
              city,
              complement,
              district,
              postal_code: postalCode,
              state,
            };

            const res = isUpdate
              ? await updateUserAddress(userData?.address?.id, payload)
              : await createUserAddress(payload);

            if (!isUpdate) {
              window.dataLayer.push({
                event: process.env.REACT_APP_ENV
                  ? `address_creation_${process.env.REACT_APP_ENV}`
                  : 'address_creation',
                userId,
                address: `${formatAddress(address, 'street')}, ${number}`,
                city,
                complement,
                district,
                postalCode,
                state,
              });
            }

            setUserData((prevState) => ({ ...prevState, address: res.data }));
            return onComplete ? onComplete() : redirectAfterSubmit();
          } catch (err) {
            const messages = extractErrorSlug(err?.response);
            setErrorMessage(
              i18n.t([
                `error.address${messages?.[0]}.`,
                'error.address.unspecific',
              ]),
            );
            return null;
          }
        }}
      >
        {({
          errors,
          initialValues,
          isSubmitting,
          isValid,
          setFieldTouched,
          setFieldValue,
          touched,
          values,
        }) => (
          <FormStyled id="address-form">
            {JSON.stringify(initialValues) !== JSON.stringify(values)
              ? setWasUpdated(true)
              : setWasUpdated(false)}
            <InputsWrapper>
              <GridWrapper>
                <Input
                  id="postalCode"
                  name="postalCode"
                  mask="99999-999"
                  type="text"
                  placeholder={i18n.t('addressForm.cep')}
                  label={i18n.t('addressForm.cep')}
                  value={values.postalCode}
                  defaultValue={userData?.address?.postal_code || ''}
                  handleChange={async ({ target: t }) => {
                    setFieldValue('postalCode', formatCep(t.value));

                    if (
                      formatCep(t.value).length === POSTAL_CODE_STRING_LENGTH
                    ) {
                      await getAddressInfo(
                        formatCep(t.value)?.replace('-', ''),
                        setFieldValue,
                      );
                    }
                  }}
                  width="100%"
                  color="light"
                  disableAutoComplete
                  handleBlur={() => setFieldTouched('postalCode')}
                  error={
                    errors?.postalCode &&
                    errors.postalCode !== 'required' &&
                    touched?.postalCode
                  }
                />
                <Select
                  id="state"
                  name="state"
                  options={brazilianStates || [{ value: '', label: '' }]}
                  value={values.state}
                  defaultValue={values.state}
                  width="100%"
                  onChange={async ({ target }) => {
                    setFieldValue('state', target.value);
                    setFieldValue('city', '');
                    getCities(target.value);
                  }}
                  color="light"
                  disableAutoComplete
                  placeholder={
                    values.state !== '' ? i18n.t('addressForm.state') : null
                  }
                  handleBlur={() => setFieldTouched('state')}
                  error={
                    errors?.state &&
                    errors.state !== 'required' &&
                    touched?.state
                  }
                />
                {requestCitiesError ? (
                  <Input
                    id="city"
                    name="city"
                    type="text"
                    placeholder={i18n.t('addressForm.city')}
                    label={i18n.t('addressForm.city')}
                    value={values.city}
                    width="100%"
                    color="light"
                    disableAutoComplete
                    handleBlur={() => setFieldTouched('city')}
                    error={
                      errors?.city &&
                      errors.city !== 'required' &&
                      touched?.city
                    }
                  />
                ) : (
                  <Select
                    id="city"
                    name="city"
                    options={
                      values.state !== ''
                        ? [...listOfCities]
                        : [{ value: '', label: i18n.t('addressForm.city') }]
                    }
                    placeholder={
                      values.city !== '' ? i18n.t('addressForm.city') : null
                    }
                    value={values.city}
                    width="100%"
                    onChange={async ({ target }) => {
                      setFieldValue('city', target.value);
                    }}
                    color="light"
                    disableAutoComplete
                    handleBlur={() => setFieldTouched('city')}
                    classList="truncate whitespace-nowrap overflow-hidden"
                    error={
                      errors?.city &&
                      errors.city !== 'required' &&
                      touched?.city
                    }
                  />
                )}
                <Input
                  id="district"
                  name="district"
                  type="text"
                  placeholder={i18n.t('addressForm.district')}
                  label={i18n.t('addressForm.district')}
                  value={values.district}
                  width="100%"
                  color="light"
                  disableAutoComplete
                  handleBlur={() => setFieldTouched('district')}
                  error={
                    errors?.district &&
                    errors.district !== 'required' &&
                    touched?.district
                  }
                />
              </GridWrapper>
              <GridWrapper templateColumns="1.5fr 0.6fr">
                <Input
                  id="address"
                  name="address"
                  type="text"
                  placeholder={i18n.t('addressForm.address')}
                  label={i18n.t('addressForm.address')}
                  value={values.address}
                  width="100%"
                  color="light"
                  disableAutoComplete
                  handleBlur={() => setFieldTouched('address')}
                  error={
                    errors?.address &&
                    errors.address !== 'required' &&
                    touched?.address
                  }
                />
                <Input
                  innerRef={inputNumberRef}
                  id="number"
                  name="number"
                  type="text"
                  placeholder={i18n.t('addressForm.number')}
                  label={i18n.t('addressForm.number')}
                  value={values.number}
                  width="100%"
                  color="light"
                  disableAutoComplete
                  handleBlur={() => setFieldTouched('number')}
                  error={
                    errors?.number &&
                    errors.number !== 'required' &&
                    touched?.number
                  }
                />
              </GridWrapper>
              <Input
                id="complement"
                name="complement"
                type="text"
                placeholder={i18n.t('addressForm.complement')}
                label={i18n.t('addressForm.complement')}
                value={values.complement}
                width={deviceWidth > BREAKPOINT ? '320px' : '80vw'}
                color="light"
                disableAutoComplete
              />
            </InputsWrapper>

            <SubmitWrapper>
              <ErrorText>{errorMessage}</ErrorText>
              <SubmitButton
                before={i18n.t('addressForm.confirm')}
                after={i18n.t('addressForm.confirm')}
                isValid={isValid && wasUpdated}
                isSubmitting={isSubmitting}
                width={deviceWidth > BREAKPOINT ? '200px' : '80vw'}
              />
              {window.innerWidth > BREAKPOINT && (
                <TextButton type="button" onClick={history.goBack}>
                  {isProfilePage
                    ? i18n.t('addressForm.cancel')
                    : i18n.t('returnButton.return')}
                </TextButton>
              )}
            </SubmitWrapper>
          </FormStyled>
        )}
      </Formik>
      <span />
    </FormWrapper>
  );
}

AddressForm.propTypes = {
  onComplete: PropTypes.func,
};

export default AddressForm;
