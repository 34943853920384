import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddressForm from '@/components/organisms/AddressForm';
import ErrorMessage from '@/components/molecules/ErrorMessage';
import IncreaseLimitsTemplate from '@/components/templates/IncreaseLimitsTemplate';
import Loading from '@/components/atoms/Loading';
import useUserValidations from '@/hooks/useUserValidations';
import { getUserPendingValidations } from '@/helpers/functions';
import { useUserData } from '@/context/userContext';

function RegisterAddress() {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const { isFetching, getValidations } = useUserValidations();
  const {
    userData: { validations },
  } = useUserData();

  const validationStatus = getUserPendingValidations(validations);

  if (validationStatus !== 'address') {
    history.push('/validations');
  }

  return (
    <IncreaseLimitsTemplate returnPath="/validations">
      {isFetching ? (
        <Loading />
      ) : errorMessage ? (
        <ErrorMessage content={errorMessage} />
      ) : (
        <div>
          <AddressForm
            setErrorMessage={setErrorMessage}
            onComplete={getValidations}
          />
        </div>
      )}
    </IncreaseLimitsTemplate>
  );
}

export default RegisterAddress;
