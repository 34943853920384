import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Trans } from 'react-i18next';
import i18n from '@/translate/i18n';

export const carouselData = [
  {
    id: uuidv4(),
    sliderType: 'left',
    textColor: 'white',
    logoPath: '/images/carousel/logo.svg',
    logoAlt: i18n.t('carousel.alts.logo'),
    imagePath: '/images/carousel/onboarding/1.png',
    imageAlt: i18n.t('carousel.onboarding.alts.1'),
    filter: true,
    title: i18n.t('carousel.onboarding.titles.1'),
    subtitle: i18n.t('carousel.onboarding.subtitles.1'),
    background: 'bg-onboarding1',
  },
  {
    background: 'bg-onboarding2',
    id: uuidv4(),
    imageAlt: i18n.t('carousel.onboarding.alts.2'),
    imagePath: '/images/carousel/onboarding/2.png',
    imgPosition: 'right',
    logoAlt: i18n.t('carousel.alts.logo'),
    logoPath: '/images/carousel/logo-black.svg',
    sliderType: 'left',
    subtitle: i18n.t('carousel.onboarding.subtitles.2'),
    textColor: 'black',
    title: i18n.t('carousel.onboarding.titles.2'),
  },
  {
    id: uuidv4(),
    sliderType: 'center',
    textColor: 'white',
    logoPath: '/images/carousel/logo.svg',
    logoAlt: i18n.t('carousel.alts.logo'),
    imagePath: '/images/carousel/onboarding/3.png',
    imageAlt: i18n.t('carousel.onboarding.alts.3'),
    filter: true,
    title: (
      <Trans
        i18n={i18n}
        i18nKey="carousel.onboarding.titles.3"
        components={[<span className="text-grass-800" />]}
      />
    ),
    subtitle: i18n.t('carousel.onboarding.subtitles.3'),
  },
];
