import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ocrValidation } from '@/services/api';
import i18n from '@/translate/i18n';

function IdwallSdk({ setErrorMessage, onSuccess }) {
  const history = useHistory();

  useEffect(() => {
    const idwSDK = window.idwSDKWeb;
    idwSDK({
      token: process.env.REACT_APP_IDW_SDK_TOKEN,
      onComplete: async ({ token }) => {
        try {
          await ocrValidation({ token });
          onSuccess();
        } catch (err) {
          setErrorMessage(i18n.t('error.validateId.default'));
        }
      },
    });
  }, [history, setErrorMessage]);

  return <div data-idw-sdk-web />;
}

IdwallSdk.propTypes = {
  setErrorMessage: PropTypes.func,
  onSuccess: PropTypes.func,
}.isRequired;

export default IdwallSdk;
