import styled from 'styled-components';

export const Label = styled.label`
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.bgGradientLight.start : theme.colors.info.light};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: block;
  font-size: ${({ theme }) => theme.font.size.smallCalc};
  padding-left: 25px;
  position: relative;
  user-select: none;

  span:after {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border: ${({ theme }) => theme.colors.bg.dark};
    border-width: 0 2px 2px 0;
    height: 10px;
    left: 4px;
    top: -1px;
    transform: rotate(45deg);
    width: 5px;
  }

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  input:checked + span {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.bgGradientLight.start : theme.colors.primary};
  }

  &:hover input ~ span {
    filter: brightness(0.9);
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border: solid ${({ theme }) => theme.colors.bg.dark};
    border-width: 0 2px 2px 0;
    height: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

export const CheckBoxInput = styled.span`
  background-color: transparent;
  border: 2px solid
    ${({ theme, disabled }) =>
      disabled ? theme.colors.bgGradientLight.start : theme.colors.primary};
  border-radius: 4px;
  height: 18px;
  left: 0px;
  position: absolute;
  top: 0;
  width: 18px;

  &:after {
    content: '';
    display: none;
    position: absolute;
  }
`;
