const URL_CACHED = [
  '/transactions',
  '/user/validation',
  '/user/transaction-limits',
  '/misc/faqs/groups',
  '/api/v1/localidades/estados/',
  '/ws/',
  '/wallet/customer-bank-account/current',
];

export function getURLCached({ url }) {
  const urlMapped = URL_CACHED.map((pattern) => {
    const patternRegexp = new RegExp(pattern);
    return patternRegexp.test(url);
  });
  const shouldCached = urlMapped.includes(true);
  return !shouldCached;
}
