import styled from 'styled-components';

import { hexToRGBA } from '@/styles/colors';

export const Wrapper = styled.section`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    background-color: ${({ theme }) => theme.colors.dark};
    border-radius: 10px;
    bottom: -3rem;
    display: block;
    position: absolute;
    right: 1.5rem;
    width: 170px;
    z-index: 3;
  }
`;

export const Button = styled.div`
  align-items: center;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.info.light};
  cursor: pointer;
  display: flex;
  font-size: ${({ theme }) => theme.font.size.small};
  height: 45px;
  justify-content: center;
  text-align: center;

  &:not(:last-child) {
    background: ${({ theme }) =>
      `linear-gradient(${hexToRGBA(
        theme.colors.info.primary,
        0.2,
      )}, ${hexToRGBA(theme.colors.info.primary, 0.2)}) bottom no-repeat`};
    background-size: 90% 1px;
  }

  &:hover {
    background-color: ${({ theme }) => hexToRGBA(theme.colors.shadow, 0.1)};
  }
`;
