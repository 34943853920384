import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Logo, Wrapper } from './styles';

const DEFAULT_IMG_PATH = '/images/merchant/merchant-generic.svg';

function MerchantIcon({ width, opacity, imageUrl }) {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(false);
  }, [imageUrl]);

  return (
    <Wrapper width={width}>
      {!isError ? (
        <Logo
          src={imageUrl ?? DEFAULT_IMG_PATH}
          width={width}
          height={width}
          onError={setIsError}
          opacity={opacity}
        />
      ) : (
        <Logo
          src={DEFAULT_IMG_PATH}
          width={width}
          height={width}
          onError={setIsError}
          opacity={opacity}
        />
      )}
    </Wrapper>
  );
}

MerchantIcon.propTypes = {
  opacity: PropTypes.number,
  slug: PropTypes.string,
  width: PropTypes.string,
}.isRequired;

export default MerchantIcon;
