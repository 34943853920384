import { useState } from 'react';

function useFilter(initialFilters = {}) {
  const [filters, setFilters] = useState(initialFilters);
  const [showAnimation, setShowAnimation] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(initialFilters);

  const formatFilter = () => {
    const formattedFilter = {};
    Object.entries(filters).forEach(([key, value]) => {
      if (!Array.isArray(value)) {
        formattedFilter[key] = value;
        return;
      }
      formattedFilter[key] = value.map((item) => item.value);
    });
    return formattedFilter;
  };

  const formattedFilters = formatFilter();

  const isDirty = JSON.stringify(currentFilters) !== JSON.stringify(filters);
  const isApplied =
    JSON.stringify(currentFilters) !== JSON.stringify(initialFilters);

  const resetAllFilters = () => {
    setCurrentFilters(initialFilters);
    setFilters(initialFilters);
  };

  return {
    filters,
    setFilters,
    formattedFilters,
    currentFilters,
    setCurrentFilters,
    showAnimation,
    setShowAnimation,
    showFilter,
    setShowFilter,
    isDirty,
    isApplied,
    resetAllFilters,
  };
}

export default useFilter;
