import { css } from 'styled-components';

export const HoverScrollBar = ({ theme }) => css`
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: none;
    transition: background-color 0.3s ease;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.bgGradientLight.end};
    }
  }
`;
