import { useState, useEffect } from 'react';

import { getPaymentMethods } from '@/services/api';
import { useWalletData } from '@/context/walletContext';

const useMethods = () => {
  const {
    walletData: { methods },
    setWalletData,
  } = useWalletData();

  const [loadingMethods, setLoadingMethods] = useState(false);
  const [methodsError, setMethodsError] = useState(false);

  useEffect(() => {
    const controller = new AbortController();

    const getMethods = async () => {
      try {
        setLoadingMethods(true);
        const res = await getPaymentMethods(controller.signal);
        const filtered = res.data.filter(({ active }) => active);
        setWalletData((prevState) => ({ ...prevState, methods: filtered }));
      } catch (err) {
        setWalletData((prevState) => ({ ...prevState, methods: [] }));
        setMethodsError(true);
      } finally {
        setLoadingMethods(false);
      }
    };

    if (!methods) {
      getMethods();
    }

    return () => controller.abort();
  }, [methods, setWalletData]);

  return { loadingMethods, methodsError };
};

export default useMethods;
