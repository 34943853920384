import { Link } from 'react-router-dom';

const { default: styled } = require('styled-components');

export const LinkStyled = styled(Link)`
  align-items: center;
  color: ${({ theme }) => theme.colors.cream};
  display: flex;
  font-size: 18px;
  gap: 10px;
  text-decoration: none;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;
