import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.info.light};
  font-size: ${({ theme }) => theme.font.size.medium};
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.info.light};
  font-size: ${({ theme }) => theme.font.size.small};
  margin-bottom: 70px;
  max-width: 290px;
  text-align: center;
`;

export const LinkText = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
`;
