import React, { useState } from 'react';

import SectionDivider from '@/components/atoms/SectionDivider/styles';
import BonusDetails from '@/components/organisms/BonusDetails';
import BonusList from '@/components/organisms/BonusList';
import Loading from '@/components/atoms/Loading';
import MainTemplate from '@/components/templates/MainTemplate';
import Modal from '@/components/molecules/Modal';
import PromotionList from '@/components/organisms/PromotionList';
import RegulationTerms from '@/components/molecules/Modal/Messages/RegulationTerms';
import WalletButton from '@/components/atoms/Buttons/WalletButton';
import i18n from '@/translate/i18n';
import useUserBonuses from '@/hooks/useUserBonuses';
import { ReactComponent as BonusesIcon } from '@/assets/bonusesIcon.svg';
import { ReactComponent as PromotionButtonIcon } from '@/assets/promotionIcon.svg';

import { ContentWrapper, Section, SwitchButtonWrapper } from './styles';

function BonusesAndPromotions() {
  const { isFetching } = useUserBonuses({ allBonuses: false });
  const [showPromotions, setShowPromotions] = useState(false);
  const [selectedBonus, setSelectedBonus] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [termsSlug, setTermsSlug] = useState('');

  const closeModal = () => {
    setShowModal(false);
    setSelectedBonus({});
    setTermsSlug('');
  };
  return (
    <MainTemplate
      containerProps={{ gradientLight: true }}
      headerProps={{
        title: i18n.t('bonusesAndPromotions.title'),
        returnTo: '/wallet',
      }}
    >
      <ContentWrapper>
        <Section showDetails={showPromotions}>
          {isFetching ? (
            <Loading />
          ) : (
            <BonusList
              selectedBonus={selectedBonus}
              setSelectedBonus={setSelectedBonus}
              setShowModal={setShowModal}
            />
          )}
        </Section>
        <SectionDivider height="75%" />
        <Section showDetails={showPromotions} animationReverse align="baseline">
          <PromotionList setTermsSlug={setTermsSlug} />
        </Section>
      </ContentWrapper>
      <SwitchButtonWrapper>
        <WalletButton
          content={i18n.t('bonusesAndPromotions.bonuses')}
          selected={!showPromotions}
          onClick={() => setShowPromotions(false)}
          icon={BonusesIcon}
        />
        <WalletButton
          content={i18n.t('bonusesAndPromotions.promotions')}
          selected={showPromotions}
          onClick={() => setShowPromotions(true)}
          icon={PromotionButtonIcon}
        />
      </SwitchButtonWrapper>
      <Modal
        showModal={Boolean(termsSlug)}
        setShowModal={setShowModal}
        handleClose={closeModal}
        width="900px"
        height="auto"
      >
        <RegulationTerms termsSlug={termsSlug} />
      </Modal>
      <Modal
        showModal={Boolean(selectedBonus?.id) && showModal && !termsSlug}
        setShowModal={setShowModal}
        handleClose={closeModal}
        height="250px"
        width="350px"
      >
        <BonusDetails
          bonus={selectedBonus}
          setTermsSlug={setTermsSlug}
          setSelectedBonus={setSelectedBonus}
        />
      </Modal>
    </MainTemplate>
  );
}

export default BonusesAndPromotions;
