import React from 'react';

import Loading from '@/components/atoms/Loading';
import Wrapper from './styles';

function LoadingFullScreen() {
  return (
    <Wrapper>
      <Loading />
    </Wrapper>
  );
}

export default LoadingFullScreen;
