import React from 'react';
import PropTypes from 'prop-types';

import { Loader, Wrapper } from './styles';

function CircularLoader({ size }) {
  return (
    <Wrapper>
      <Loader size={size} />
    </Wrapper>
  );
}

CircularLoader.propTypes = {
  size: PropTypes.number,
};

export default CircularLoader;
