import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

export const SwiperCarousel = styled(Swiper)`
  width: 100%;

  .swiper-pagination {
    &-bullet {
      background: ${({ theme }) => theme.colors.info.title};
      border-radius: 0;
      height: 2px;
      margin: 0 0 2px;
      width: 91px;

      &-active {
        background: ${({ theme }) => theme.colors.info.primary};
        height: 4px;
        margin: 0;
      }
    }
  }
`;

export const CarouselItem = styled(SwiperSlide)`
  ${({ $customHeight = '100%', customMarginTop = '0', theme }) => css`
    > div {
      align-items: center;
      display: flex;
      flex-direction: column;

      > img {
        height: ${$customHeight};
        margin-top: ${customMarginTop};
        max-height: 360px;
        object-fit: contain;
        width: 100%;
      }

      > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 0 0 2rem;

        p {
          color: ${theme.colors.primary};
          font: ${`${theme.font.size.xLarge} ${theme.font.family.secondary}`};
          margin-bottom: 1rem;
          text-align: center;
        }
      }
    }
  `};
`;
