import styled, { css } from 'styled-components';
import { hexToRGBA } from '@/styles/colors';

export const InputStyleDefault = ({
  theme,
  color,
  isvalidandlocked,
  type,
  value,
}) => css`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid
    ${color ? theme.colors[color] : theme.colors.info.dark};
  color: ${type === 'password'
    ? theme.colors.primary
    : color
    ? theme.colors[color]
    : theme.colors.info.dark};
  font: ${theme.font.size.mediumCalc} ${theme.font.family.secondary};
  height: 4.5vh;
  letter-spacing: ${type === 'password' && Boolean(value) ? '5px' : '0'};
  margin: 5px 0 25px;
  max-height: 50px;
  max-width: 332px;
  min-height: 32px;
  padding: 10px 10px 0;
  text-align: ${isvalidandlocked ? 'center' : 'left'};
  width: ${({ width = '70vw' }) => width};

  &::placeholder {
    color: ${theme.colors.info.primary};
  }

  &:disabled {
    border-bottom: ${isvalidandlocked
      ? 'none'
      : `1px solid ${theme.colors.info.primary}`};
    opacity: 1;
  }

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    width: ${({ width = '17vw' }) => width};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${theme.colors.autoCompleteBg} inset !important;
    background-color: ${theme.colors.autoCompleteBg} !important;
    background-clip: content-box !important;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: ${theme.colors.info.light} !important;
  }
`;

export const ContainerStyle = styled.div`
  align-items: ${({ isvalidandlocked }) => isvalidandlocked && 'center'};
  display: ${({ hideInput }) => (hideInput ? 'none' : 'flex')};
  flex-direction: column;
  position: relative;
`;

export const LabelStyle = styled.label`
  ${({ theme, error, isvalidandlocked }) => css`
    color: ${error ? theme.colors.danger : theme.colors.info.primary};
    font-size: ${isvalidandlocked
      ? theme.font.size.xSmall
      : theme.font.size.xxSmall};
    margin-left: ${isvalidandlocked ? 0 : '10px'};
    position: absolute;
    text-align: ${isvalidandlocked && 'center'};
    top: 0;
  `}
`;

// Secondary style

export const ContainerSecondaryStyle = styled(ContainerStyle)`
  align-items: center;
  margin: 0;
`;

export const LabelSecondaryStyle = styled(LabelStyle)`
  color: ${({ theme, error }) =>
    error ? theme.colors.danger : hexToRGBA(theme.colors.info.primary, 0.75)};
  font: ${({ theme }) =>
    `${theme.font.size.small} ${theme.font.family.secondary}`};
  margin: 0;
  position: relative;
`;

export const InputSecondaryStyle = css`
  ${({ theme, error }) => css`
    background-color: ${hexToRGBA(theme.colors.shadow, 0.5)};
    border: ${error ? `1px solid ${theme.colors.danger}` : 'none'};
    border-radius: 5px;
    color: ${theme.colors.info.primary};
    font-family: ${theme.font.family.primary};
    height: 40px;
    margin-bottom: 10px;
    max-width: 300px;
    padding: ${({ $extraPadding }) => ($extraPadding ? '0 0 0 2.3rem' : 0)};
    text-align: center;
    width: ${({ width }) => width || '70vw'};

    &::placeholder {
      color: ${hexToRGBA(theme.colors.info.primary, 0.6)};
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      height: 35px;
      min-width: 250px
      width: ${({ width }) => width || '20vw'};
    }
  `}
`;
