import React from 'react';
import PropTypes from 'prop-types';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';

import Loading from '@/components/atoms/Loading';
import { Paragraph } from '@/components/atoms/Typography/styles';
import useTerms from '@/hooks/useTerms';
import i18n from '@/translate/i18n';

import { Container, ContentWrapper, ErrorWrapper, TextContent } from './styles';
import TwTitle from '@/components/atoms/TwTitle';

function RegulationTerms({ termsSlug }) {
  const { termsContent, error, isFetching } = useTerms(termsSlug);

  return (
    <Container>
      {isFetching ? (
        <Loading />
      ) : error ? (
        <ErrorWrapper>
          <Paragraph>{i18n.t('error.termsAndConditions.unspecific')}</Paragraph>
        </ErrorWrapper>
      ) : (
        <>
          <TwTitle
            text={i18n.t('termsAndConditions.title')}
            size="xl"
            font="arboriaBold"
            color="shadow-500"
            classList="text-center"
          />
          <ContentWrapper>
            <TextContent>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {termsContent}
              </ReactMarkdown>
            </TextContent>
          </ContentWrapper>
        </>
      )}
    </Container>
  );
}

RegulationTerms.propTypes = {
  termsSlug: PropTypes.string,
};

export default RegulationTerms;
