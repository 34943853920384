import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { Arrow, ArrowButton, NavItem, Span, Title, Wrapper } from './styles';
import { Nav, ContentWrapper } from '@/components/atoms/FaqStyles/styles';

function FaqGlossary({ data }) {
  const { hash } = useLocation();
  const { isMobileLayout } = useDeviceWidth();

  return (
    <Wrapper>
      <Title>{i18n.t('faq.glossary')}</Title>
      <ContentWrapper>
        <Nav>
          {data?.map((group) => (
            <NavItem
              key={group?.id}
              index={group?.id}
              to={isMobileLayout ? `/faq/${group?.slug}` : `#${group?.slug}`}
            >
              <Span
                to={`#${group?.slug}`}
                active={hash === `#${group?.slug}` ? 1 : 0}
              >
                {group?.title}
              </Span>
              <ArrowButton>
                <Arrow />
              </ArrowButton>
            </NavItem>
          ))}
        </Nav>
      </ContentWrapper>
    </Wrapper>
  );
}

FaqGlossary.propTypes = {
  data: PropTypes.shape,
}.isRequired;

export default FaqGlossary;
