import React from 'react';
import PropTypes from 'prop-types';

import Select from './styles';

function SelectRounded({ options, onChange, defaultValue, ...rest }) {
  return (
    <Select onChange={onChange} defaultValue={defaultValue} {...rest}>
      {options.map((opt) => (
        <option key={opt} value={opt}>
          {opt}
        </option>
      ))}
    </Select>
  );
}

SelectRounded.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.string, PropTypes.string]),
}.isRequired;

export default SelectRounded;
