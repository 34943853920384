import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Button as ButtonStyles } from '@/components/atoms/Buttons/Button/styles';
import { ReactComponent as ErrorSvg } from '@/assets/errorFull.svg';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  min-height: ${({ height }) => height || 'unset'};
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex: 0.8;
  flex-direction: column;
  margin-top: 15vh;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex: 0;
    margin-top: 0;
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.info.light};
  font: ${({ theme }) =>
    `${theme.font.size.small} ${theme.font.family.primary}`};
  max-width: 300px;
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 500px;
  }
`;

export const Strong = styled.p`
  font: ${({ theme }) =>
    `${theme.font.size.small} ${theme.font.family.secondary}`};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15vh;
`;

export const ErrorIcon = styled(ErrorSvg)`
  height: 80px;
  margin-bottom: 25px;
  width: 80px;
`;

export const LinkWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: flex-end;
    position: absolute;
    right: 50px;
    top: 40%;
  }
`;

export const LinkText = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font: ${({ theme }) =>
    `${theme.font.size.small} ${theme.font.family.secondary}`};
  margin-bottom: 20px;
`;

export const Button = styled(ButtonStyles)`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    background-color: ${({ theme }) => theme.colors.primary};
    bottom: 20px;
    color: ${({ theme }) => theme.colors.info.dark};
    display: block;
    position: absolute;
    right: 30px;
    width: 13vw;
  }
`;
