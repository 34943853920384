import React from 'react';
import PropTypes from 'prop-types';
import TwText from '../TwText';

const HighlightedTitle = ({
  className,
  classNameText,
  text,
  classNameContainerText,
}) => (
  <div className={`bg-black p-[18px] pl-0 pb-0 ${className}`}>
    <div
      className={`bg-grass-800 text-black h-full p-[15px] !pl-[55px] md:!px-5 ${classNameContainerText}`}
    >
      <TwText
        classList={`!text-[40px] text-left ${classNameText}`}
        tag="h1"
        color="black"
        text={text}
      />
    </div>
  </div>
);

HighlightedTitle.propTypes = {
  className: PropTypes.string,
  classNameText: PropTypes.string,
  text: PropTypes.string.isRequired,
  classNameContainerText: PropTypes.bool,
};

export default HighlightedTitle;
