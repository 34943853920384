import React from 'react';
import PropTypes from 'prop-types';

import useDeviceWidth from '@/hooks/useDeviceWidth';
import i18n from '@/translate/i18n';

import { months, weekDays } from './data';
import { DateRangePickerInput } from './styles';

import 'react-multi-date-picker/styles/layouts/mobile.css';

const BREAKPOINT = 1024;
const TIME_CONSTANT = 'T00:00:00';

function DateRangePicker({ setState, filters, lastMonth = false }) {
  const { deviceWidth } = useDeviceWidth();

  const extractDate = (dateArr) => {
    const [startDate, endDate] = dateArr;

    const startDateFormatted = startDate
      ? new Date(startDate)?.toISOString().split('T')[0]
      : null;
    const endDateFormatted = endDate
      ? new Date(endDate)?.toISOString().split('T')[0]
      : null;

    setState((state) => {
      if (!endDate) {
        delete state?.created_at_to;
      }

      return {
        ...state,
        ...(startDate ? { created_at_from: startDateFormatted } : {}),
        ...(endDate ? { created_at_to: endDateFormatted } : {}),
      };
    });
  };

  return (
    <div className="calendar-wrapper">
      <DateRangePickerInput
        arrow={false}
        className={deviceWidth <= BREAKPOINT ? 'rmdp-mobile' : ''}
        months={months}
        onChange={extractDate}
        range
        value={[
          new Date(`${filters?.created_at_from}${TIME_CONSTANT}`),
          new Date(`${filters?.created_at_to}${TIME_CONSTANT}`),
        ]}
        weekDays={weekDays}
        placeholder={i18n.t(
          `calendar.${lastMonth ? 'lastMonth' : 'selectDate'}`,
        )}
        format="DD/MM/YYYY"
        mobileLabels={{
          CANCEL: i18n.t('actions.cancel'),
        }}
      />
    </div>
  );
}

DateRangePicker.propTypes = {
  setState: PropTypes.func,
  filters: PropTypes.shape,
  lastMonth: PropTypes.bool,
};

export default DateRangePicker;
