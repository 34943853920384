import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ShowPass } from '@/assets/showPass.svg';
import { ReactComponent as HidePass } from '@/assets/hidePass.svg';

import {
  ContainerSecondaryStyle,
  ContainerStyle,
  LabelSecondaryStyle,
  LabelStyle,
} from '@/components/atoms/Inputs/styles';

import {
  ErrorText,
  HiddenPasswordBtn,
  IconWrapper,
  InputPrimary,
  InputSecondary,
} from './styles';

const ICON_STYLE = { width: 18 };

function Input({
  color,
  dataTestId,
  disableAutoComplete,
  disabled,
  error,
  errorText,
  handleBlur,
  handleChange,
  hideInput,
  icon: Icon,
  id,
  innerRef,
  isvalidandlocked,
  label,
  placeholder,
  secondaryTheme,
  togglePassowordButton,
  type,
  value,
  width,
}) {
  const InputStyled = secondaryTheme ? InputSecondary : InputPrimary;
  const Container = secondaryTheme ? ContainerSecondaryStyle : ContainerStyle;
  const Label = secondaryTheme ? LabelSecondaryStyle : LabelStyle;

  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container isvalidandlocked={isvalidandlocked} hideInput={hideInput}>
      {(value?.length || secondaryTheme) && (
        <Label
          htmlFor={id}
          color={color}
          error={error}
          isvalidandlocked={isvalidandlocked}
        >
          {label}
        </Label>
      )}
      <InputStyled
        innerRef={innerRef}
        id={id}
        name={id}
        type={showPassword ? 'text' : type}
        placeholder={placeholder}
        value={value}
        data-testid={dataTestId}
        disabled={disabled}
        width={width}
        isvalidandlocked={isvalidandlocked}
        color={color}
        error={error ? 1 : 0}
        onBlur={() => handleBlur && handleBlur()}
        autoComplete={disableAutoComplete && 'new-password'}
        $extraPadding={Icon}
        {...(handleChange ? { onChange: handleChange } : {})}
      />
      {togglePassowordButton && (
        <HiddenPasswordBtn
          type="button"
          onClick={togglePassword}
          color={color}
          secondaryTheme={secondaryTheme}
          data-testid="input-password-toggle-visibility-btn"
        >
          {showPassword ? (
            <ShowPass style={ICON_STYLE} />
          ) : (
            <HidePass style={ICON_STYLE} />
          )}
        </HiddenPasswordBtn>
      )}
      {Icon && (
        <IconWrapper>
          <Icon />
        </IconWrapper>
      )}
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </Container>
  );
}

Input.propTypes = {
  color: PropTypes.string,
  dataTestId: PropTypes.string,
  disableAutoComplete: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  handleBlur: PropTypes.func,
  icon: PropTypes.element,
  id: PropTypes.string,
  isvalidandlocked: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  secondaryTheme: PropTypes.bool,
  togglePassowordButton: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,
}.isRequired;

export default Input;
