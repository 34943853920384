import styled from 'styled-components';
import { Form } from 'formik';

export const Main = styled(Form)`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const Section = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: grid;
    grid-column-gap: 5px;
    grid-template-areas:
      1fr
      0.8fr 0.2fr;
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.info.light};
  font-size: ${({ theme }) => theme.font.size.large};
  margin-top: 10px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 230px;
`;
