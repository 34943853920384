import React from 'react';
import PropTypes from 'prop-types';

import { LinkTextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { Wrapper, AlertIcon } from './styles';

function WarningMessage({ message, linkPath, linkLabel }) {
  return (
    <Wrapper>
      <AlertIcon />
      <p>{message}</p>

      {linkLabel && linkPath && (
        <LinkTextButton className="!text-lg" to={linkPath}>
          {linkLabel}
        </LinkTextButton>
      )}
    </Wrapper>
  );
}

WarningMessage.propTypes = {
  message: PropTypes.string,
}.isRequired;

export default WarningMessage;
