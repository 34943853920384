import { useState } from 'react';

import vpag from '@/styles/themes/vpag';

const NAV_MENU_BREAKPOINT = 1024;

const useDeviceWidth = () => {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  window.addEventListener('resize', () => setDeviceWidth(window.innerWidth));

  const isMobileLayout = deviceWidth <= vpag.breakpoints.sm.replace('px', '');

  const shouldCollapseMenu =
    deviceWidth > vpag.breakpoints.sm.replace('px', '') &&
    deviceWidth < NAV_MENU_BREAKPOINT;

  return { deviceWidth, isMobileLayout, shouldCollapseMenu };
};

export default useDeviceWidth;
