import React from 'react';
import PropTypes from 'prop-types';

const DEFAULT_STEPS = 3;

function TwStepper({ index, steps = DEFAULT_STEPS, labels }) {
  return (
    <div className="flex items-center w-full justify-center h-24">
      {Array.from({ length: steps }).map((_item, idx) => (
        <div className="flex items-center">
          <div
            className={`${
              idx < index + 1 ? 'bg-grass-800' : 'bg-ashes-800'
            } size-11 rounded-full flex items-center justify-center relative`}
          >
            <span className="font-arboria-regular md:font-arboria-bold text-xl md:text-2xl pb-1 text-black">
              {idx + 1}
            </span>
            <div className="absolute w-40 bottom-0 left-1/2 transform -translate-x-1/2 translate-y-6 hidden md:block">
              <span className="text-white text-sm font-arboria-regular">
                {labels[idx]}
              </span>
            </div>
          </div>
          {idx + 1 < steps && (
            <div
              className={`flex-1 w-24 md:w-40 border ${
                index - 1 < idx ? 'border-ashes-800' : 'border-grass-800'
              }`}
            />
          )}
        </div>
      ))}
    </div>
  );
}

TwStepper.propTypes = {
  index: PropTypes.string.isRequired,
  steps: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
};

export default TwStepper;
