import styled, { css } from 'styled-components';

import { HoverScrollBar } from '@/components/atoms/ScrollBar/styles';

export const InternalBox = styled.div`
  background-color: ${({ theme }) => theme.colors.shadow};
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    aspect-ratio: 0.75;
    border-radius: 50px;
    max-height: 800px;
    min-width: 500px;
    padding-bottom: 1rem;
    width: unset;
  }
`;

export const ContentWrapper = styled.div`
  ${HoverScrollBar}
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: calc(100% - 110px);
    overflow: hidden auto;
    padding: 2rem 0;
    width: 100%;

    input {
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px ${theme.colors.info.gray} inset !important;
        background-clip: content-box !important;
      }
    }
  `}
`;
