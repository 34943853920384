import styled from 'styled-components';

import { hexToRGBA } from '@/styles/colors';

export const NavLinkWrapper = styled.div`
  align-items: center;
  align-self: center;
  border-top: ${({ theme }) => `1px solid ${theme.colors.divider.dark}`};
  display: flex;
  filter: ${({ disabled }) => (disabled ? 'grayscale(100%)' : 'none')};
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  padding: 8% 15px;
  width: 90%;

  &:hover {
    background: radial-gradient(
      circle,
      ${({ theme }) => hexToRGBA(theme.colors.info.gray, 0.05)} 0%,
      ${({ theme }) => hexToRGBA(theme.colors.info.gray, 0.5)} 0%,
      ${({ theme }) => hexToRGBA(theme.colors.info.gray, 0.05)} 100%
    );
  }

  svg {
    width: 20px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    svg {
      width: 25px;
    }
  }
`;

export const UserDataWrapper = styled.div`
  align-self: center;
  width: 90%;
`;

export const ContentWrapper = styled.div`
  align-self: center;
  padding: 0 1rem;

  h1 {
    margin-bottom: 8px;
  }
`;

export const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ padding = '1rem' }) => padding};
  width: 100%;

  h1 {
    text-align: left;
  }

  &:not(:first-child) {
    border-top: ${({ theme }) => `1px solid ${theme.colors.divider.dark}`};
  }
`;
