import styled from 'styled-components';

import { ReactComponent as Success } from '@/assets/checked.svg';

export const Wrapper = styled.div`
  align-items: center;
  background-image: ${({ theme }) =>
    `linear-gradient(to right, ${theme.colors.bgGradientDark.start},${theme.colors.bgGradientDark.end} )`};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  padding: 0 1rem;
  text-align: center;
  width: 100%;

  p {
    max-width: 390px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    background-image: none;
    width: auto;
  }
`;

export const SuccessIcon = styled(Success)`
  height: 70px;
  width: 70px;
`;

export const SdkWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
