import styled from 'styled-components';

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
  padding: 0 1rem 6rem;
  width: 100%;

  img {
    border-radius: 10px;
  }

  h1 {
    text-align: center;
  }
`;

export const TextWrapper = styled.p`
  color: ${({ theme }) => theme.colors.info.light};
  font-size: ${({ theme }) => theme.font.size.small};
  max-width: 70%;
`;
