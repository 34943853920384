import styled, { css } from 'styled-components';

const colorsBasedOnStatus = {
  default: 'light',
  approved: 'primary',
  affiliated: 'primary',
  pending: 'pending',
  refused: 'danger',
};

const Wrapper = styled.div`
  ${({ theme, status = 'default' }) => css`
    align-items: start;
    cursor: pointer;
    display: flex;
    font: ${theme.font.size.base} ${theme.font.family.secondary};
    gap: 2px;
    margin: 0.5rem 0;

    svg {
      fill: ${theme.colors[colorsBasedOnStatus[status]]};
    }

    span {
      color: ${theme.colors[colorsBasedOnStatus[status]]};
    }

    @media screen and (min-width: ${theme.breakpoints.md}) {
      font-size: ${theme.font.size.base};
    }
  `}
`;

export default Wrapper;
