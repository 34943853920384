import styled from 'styled-components';

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 324px;
  justify-content: space-evenly;
  width: 90%;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.info.light};
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: normal;
  line-height: 27px;
  margin-top: 20px;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
`;

export const ModalButton = styled.button`
  background-color: transparent;
  border-radius: 3px;
  border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  color: ${({ theme }) => theme.colors.primary};
  font: ${({ theme }) =>
    `${theme.font.size.medium} ${theme.font.family.primary}`};
  height: 40px;
  max-width: 140px;
  padding: 7px 0;
  width: 35vw;
`;

export const BankInfo = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 70%;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.info.light};
  font-size: ${({ theme }) => theme.font.size.xSmall};
  margin-left: 15px;
`;
