import { createGlobalStyle, css } from 'styled-components';

import ArboriaBook from '@/fonts/Arboria-Book.ttf';
import ArboriaBold from '@/fonts/Arboria-Bold.ttf';
import ArboriaBoldItalic from '@/fonts/Arboria-Bold-Italic.ttf';
import ArboriaLight from '@/fonts/Arboria-Light.ttf';

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Arboria Book';
    src: url(${ArboriaBook}) format('truetype');
  }

  @font-face {
    font-family: 'Arboria Bold';
    src: url(${ArboriaBold}) format('truetype');
  }

  @font-face {
    font-family: 'Arboria Bold Italic';
    src: url(${ArboriaBoldItalic}) format('truetype');
  }

  @font-face {
    font-family: 'Arboria Light';
    src: url(${ArboriaLight}) format('truetype');
  }

  * {
    box-sizing: border-box;
    margin: 0;
    outline: 0;
    padding: 0;
  }

  #root {
    white-space: pre-line;
  }

  html, body, #root {
    height: 100%;
    min-height: 100%;
  }


  input[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  button {
    cursor: pointer;
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  .poptin-title {
    display: none;
  }

 .tippy-box {
  ${({ theme }) => css`
    background-color: ${theme.colors.light};
    color: ${theme.colors.info.dark};
    font: ${`${theme.font.size.small} ${theme.font.family.primary}`};

    .tippy-arrow {
      color: ${theme.colors.light};
    }
  `}

  }

  .expired-session-popup {
    ${({ theme }) => css`
      background-color: black;
      border: 1px solid ${theme.colors.light};
      color: ${theme.colors.info.light};
      font: ${`${theme.font.size.small} ${theme.font.family.secondary}`};
      min-height: 70px;
    `}

  }

  .custom-input {
    background-color: #333333 !important;
    color: #595757;
    font-weight: 900;

    &:disabled {
      opacity: 1;
    }
  }

  .bonus-terms {
    height: fit-content;
    max-height: 70vh;

    & h2 {
      color: #FFF;
      font: 1.5rem Arboria Bold, sans-serif;
      margin-bottom: 10px;
    }
    & p {
      color: #e1d9cd;
      font-family: Arboria Book, sans-serif;
      font-size: 0.875rem;
      margin-bottom: 2rem;
    }
  }

  .pointer {
    cursor: pointer;
  }

  :root {
    /* react-phone-number-input variable overwrite */
    --PhoneInputCountryFlag-borderWidth: 0;
    --PhoneInputCountryFlag-height: 20px;
    --PhoneInputCountrySelectArrow-width: 0.5em;
    --PhoneInputCountrySelectArrow-borderWidth: 2px;
    --PhoneInputCountrySelectArrow-color: ${({ theme }) =>
      theme.colors.bg.tertiary};
    --PhoneInputCountryFlag-backgroundColor--loading: transparent;

    /* swiper variable overwrite */
    --swiper-navigation-size: 22px;
    --swiper-theme-color: ${({ theme }) => theme.colors.light};

    /* react-tooltip variable overwrite */
    --rt-opacity: 1;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    *::-webkit-scrollbar {
      width: 8px;
    }

    *::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.bgGradientDark.start};
      opacity: 0.3;
    }

    *::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.bgGradientLight.end};
      border: 1px solid ${({ theme }) => theme.colors.bgGradientDark.start};
      border-radius: 4px;
    }
  }

  .circular-progressbar-rotate {
    animation: rotating 1s linear infinite;
  }

  @keyframes rotating {
    from {

      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes loading {
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes opacityAnimationDefault {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes opacitySubmitButton {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.4;
    }
  }

  @keyframes opacityOneToZero{
    0% {
      opacity: 1;
    }
    99% {
      opacity: 0;
      height: 0;
    }
    100% {
      height: 0;
      max-height: 0;
      min-height: 0;
      overflow: hidden;
    }
  }

  @keyframes goDownAndAppear{
    0% {
      display: none;
      transform: translateY(-500px);
    }
    70% {
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes goUpForm {
    0% {
      transform: translateY(650px);
    }
    90% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes goUpAndApperModalContent {
    0% {
      opacity: 0;
      transform: translateY(600px);
    }
    70% {
      opacity: 1;
      transform: translateY(-30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes goDownAndApperModalContent {
    0% {
      opacity: 0;
      transform: translateY(-600px);
    }
    70% {
      opacity: 1;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes goDownAndAppearModalWrapper{
    0% {
      transform: translateY(-900px);
    }
    70% {
      transform: translateY(15px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes goUpAndDisappearModalWrapper {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    30% {
      opacity: 1;
      transform: translateY(20px);
    }
    100% {
      height: 0;
      opacity: 0;
      transform: translateY(-900px);
    }
  }


  @keyframes modalWrapperIn {
    0% {
      height: 0;
      background-color: transparent;
      transform: translateY(-900px);
    }
    70% {
      background-color: transparent;
   transform: translateY(0);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.5);
      transform: translateY(0);
    }
  }

  @keyframes modalWrapperOut {
    0% {
      background-color: rgba(0, 0, 0, 0.5);
      transform: translateY(0);
    }
    10% {
      background-color: transparent;
      transform: translateY(0);
    }
    100% {
      height: 0;
      background-color: transparent;
      transform: translateY(-900px);
    }
  }

  @keyframes goUpDefault {
    0% {
      transform: translateY(200px);
    }
    70% {
      transform: translateY(-15px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes goDownDefault {
    0% {
      opacity: 0;
      transform: translateY(-900px);
    }
    70% {
      opacity: 1;
      transform: translateY(15px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes goDownMenuModal {
    0% {
      transform: translateY(-900px);
    }
    100%{
      transform: translateY(0);
    }
  }

  @keyframes goUpMenuModal {
    0% {
      transform: translateY(0);
    }
    100%{
      transform: translateY(-350vh);
    }
  }

  @keyframes goLeftDefault {
    0% {
      transform: translateX(500px);
    }
    70% {
      transform: translateX(-15px);
    }
    100%{
      transform: translateX(0);
    }
  }

  @keyframes goRightDefault {
    0% {
      transform: translateX(-500px);
    }
    70% {
      transform: translateX(15px);
    }
    100%{
      transform: translateX(0);
    }
  }

  @keyframes goLeftAndDisappear {
    0% {
      opacity: 0;
      transform: translateX(25vw);
      width: 0;
    }
    10% {
      display: none;
    }
    100%{
      display: none;
      height: 0;
      opacity: 0;
      transform: translateX(-100vw);
      width: 0;
    }
  }

  @keyframes goRightAndAppear {
    0% {
      opacity: 0;
      transform: translateX(-100vw);
    }
    100%{
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes goLeftAndAppear {
    0% {
      transform: translateX(100vw);
    }
    100%{
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes goRightAndDisapppear {
    0% {
      transform: translateX(-25vw);
      opacity: 0;
      width: 0;
    }
    10% {
      display: none;
    }
    100%{
      display: none;
      height: 0;
      opacity: 0;
      transform: translateX(100vw);
      width: 0;
    }
  }

  @keyframes goLeftSettings {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-60%);
    }
  }

  @keyframes goRightSettings {
    0% {
      transform: translateX(-60%);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes goLeftTransfer {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-65%);
    }
  }

  @keyframes goRightTransfer {
    0% {
      transform: translateX(-20%);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes mobileModalUp {
    0% {
      transform: translateY(200vh);
    }
    70% {
      transform: translateY(-15px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes mobileModalDown {
    0% {
      transform: translateY(0vh);
    }

    100% {
      transform: translateY(200vh);
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes fade-in {
  0% {
    opacity: 0;
    width:0;
    height:0;

  }
  100% {
    opacity: 1;
    width:100vw;
    height:100vh;

  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
    z-index:1;
  }
  100% {
    opacity: 0;
    z-index:99;

  }
}
    @keyframes goApperModalContent {
    0% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes goDisapperModalContent {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }


`;

export default GlobalStyle;
