import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      align-items: center;
      column-gap: 2%;
      display: grid;
      grid-template-columns: 47% 1% 47%;
      height: 100%;
      justify-items: center;
      padding: 2rem 0;
      width: 100%;
    }
  `}
`;

export const Section = styled.section`
  ${({ theme, hideInMobile }) => css`
    align-items: center;
    color: ${theme.colors.info.light};
    display: ${hideInMobile ? 'none' : 'flex'};
    flex-direction: column;
    font-size: ${theme.font.size.small};
    height: 90%;
    justify-content: space-between;
    padding-bottom: 2rem;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      display: flex;
      height: 100%;
      max-height: 550px;
    }
  `}
`;

export const MethodWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
