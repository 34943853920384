import styled from 'styled-components';
import { Form } from 'formik';

import { ReactComponent as EmailIcon } from '@/assets/emailIcon.svg';

export const ModalWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  text-align: center;
  width: 100%;

  p {
    line-height: 150%;
    max-width: 320px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex: 1;
    justify-content: space-evenly;
  }
`;

export const StyledForm = styled(Form)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 90px;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex: 0.8;
    justify-content: space-evenly;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 60px;
  width: 100%;
`;

export const EmailImage = styled(EmailIcon)`
  fill: ${({ theme }) => theme.colors.primary};
  margin: 70px;
  width: 81px;
`;

export const SuccessWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex: 0.8;
    justify-content: space-evenly;
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.danger};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: 600;
  position: absolute;
  text-align: center;
  top: 240px;
  width: 250px;
  z-index: 9;
`;
