import styled, { css } from 'styled-components';
import { HoverScrollBar } from '@/components/atoms/ScrollBar/styles';

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: start;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-items: start;
    column-gap: 2%;
    display: grid;
    grid-template-columns: 47% 1% 47%;
    height: 100%;
    justify-items: center;
    padding-top: 4vh;
  }
`;

export const ListWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${({ justifyContent = 'unset' }) => justifyContent};
  max-height: 90%;
  overflow-y: auto;
  padding: 0 0.5rem 4.5rem 0.5rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    ${HoverScrollBar}
    max-height: 60vh;
    max-width: 700px;
    padding: 0;
  }
`;

export const Section = styled.section`
  ${({ theme, hideInMobile }) => css`
    align-items: center;
    animation: goLeftDefault 0.6s;
    color: ${theme.colors.info.primary};
    display: ${hideInMobile ? 'none' : 'flex'};
    flex-direction: column;
    gap: 2rem;
    height: 100%;
    width: 90%;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      display: flex;
      justify-content: space-between;
    }
  `}
`;

export const FilterButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  height: 40px;
  justify-content: center;
  width: 40px;
`;

export const PaginationWrapper = styled.div`
  ${({ theme }) => css`
    background-image: ${`linear-gradient(90deg, ${theme.colors.bgGradientDark.start},${theme.colors.bgGradientDark.end} )`};
    border: none;
    bottom: 0;
    display: flex;
    height: 90px;
    justify-content: center;
    position: fixed;
    width: 100%;

    ul {
      align-items: center;
      display: flex;

      .page-link {
        border-radius: 4rem;
        color: ${theme.colors.primary};
        font-size: ${theme.font.size.small};
        opacity: 0.6;
        padding: 0 0.7rem;

        &:hover {
          opacity: 1;
        }
      }

      .current-page {
        color: ${theme.colors.primary};
        font: ${theme.font.size.medium} ${theme.font.family.secondary};
        opacity: 1;
      }

      .prev-button,
      .next-button {
        align-items: center;
        color: ${theme.colors.primary};
        display: flex;
        font-size: ${theme.font.size.medium};
        justify-content: center;
        opacity: 0.6;
        padding: 0 1rem;

        &:hover {
          opacity: 1;
        }
      }

      li {
        cursor: pointer;
        list-style-type: none;
      }
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      background-image: none;
      position: static;
    }

    @media screen and (min-width: ${theme.breakpoints.md}) {
      align-items: flex-start;
      height: auto;
      justify-content: center;
      justify-self: center;
      width: 100%;

      ul {
        height: 60px;
      }
    }
  `}
`;
