import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styles';

function PointsMultiplierSummary({ merchant }) {
  return (
    <Wrapper>
      <span>{merchant?.name}</span>
    </Wrapper>
  );
}

PointsMultiplierSummary.propTypes = {
  merchant: PropTypes.shape({
    name: PropTypes.string,
  }),
}.isREquired;

export default PointsMultiplierSummary;
