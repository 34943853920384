import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import { Trans } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { InternalButton } from '@/components/atoms/Buttons/Button/styles';
import Loading from '@/components/atoms/Loading';
import {
  LinkTextButton,
  TextButton,
} from '@/components/atoms/Buttons/TextButton/styles';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';
import DepositPendingSummary from '@/components/molecules/DepositPendingSummary';
import TransactionDetailsSummary from '@/components/molecules/TransactionDetailsSummary';
import TransferApiTemplate from '@/components/templates/TransferApiTemplate';
import { titleColorByStatus } from '@/helpers/constants';
import { formatCurrency, formatShortDate } from '@/helpers/stringFormat';
import useBalance from '@/hooks/useBalance';
import { getTransaction } from '@/services/api';
import i18n from '@/translate/i18n';

import { ContentWrapper, FlexDiv, Section } from './styles';

function DepositDetailsExternal() {
  const history = useHistory();
  const { transactionId } = useParams();
  const { getBalance } = useBalance();
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [data, setData] = useState({});

  const pendingTransferUuid = sessionStorage.getItem('pendingApiTransaction');
  const isPending = data?.status === 'pending';

  useEffect(() => {
    const controller = new AbortController();

    const getTransactionData = async (signal) => {
      try {
        setIsLoading(true);
        const res = await getTransaction(transactionId, signal);

        setData(res.data);
      } catch (err) {
        const message = err?.response?.data?.message;
        setErrorMessage(
          i18n.t([
            `error.transactionStatus.${message}`,
            'error.transactionStatus.default',
          ]),
        );
      } finally {
        setIsLoading(false);
      }
    };

    getTransactionData(controller.signal);

    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (isPending) {
      const socket = io(process.env.REACT_APP_WEB_SOCKET_SERVER_URL, {
        path: '/websocket/socket.io',
      });

      socket.on('confirmTransaction', async (socketData) => {
        if (socketData?.transaction_id === Number(data?.id)) {
          await getBalance();
          setData((state) => ({ ...state, status: socketData?.status }));
          socket.disconnect();
        }
      });
    }
  }, [data]);

  const detailsData = {
    method: data?.payment_method_name ?? '',
    id: data?.id,
    dueDate: data?.details?.expires_at
      ? formatShortDate(data.details.expires_at)
      : '-',
    amount: formatCurrency(
      data?.customer_amount ?? 0,
      data?.customer_currency_iso,
    ),
    [`youReceive.${data?.status}`]: formatCurrency(
      data?.customer_amount,
      data?.customer_currency_iso,
    ),
  };

  return (
    <TransferApiTemplate>
      <ContentWrapper>
        {isLoading ? (
          <Loading />
        ) : errorMessage ? (
          <Paragraph textAlign="center">{errorMessage}</Paragraph>
        ) : (
          <Section>
            <Title
              size="large"
              color={titleColorByStatus[data?.status ?? 'default']}
            >
              <Trans
                i18n={i18n}
                i18nKey={`transactionDetails.title.deposit.${data?.status}`}
                components={[<span />]}
              />
            </Title>
            {isPending ? (
              <DepositPendingSummary data={data} />
            ) : (
              <TransactionDetailsSummary
                data={detailsData}
                bonus={data?.bonus}
                status={data?.status}
                hideTitle
              />
            )}
            {data?.status === 'completed' && (
              <>
                <InternalButton
                  to={
                    pendingTransferUuid
                      ? `/transfer-api/${pendingTransferUuid}`
                      : '#'
                  }
                >
                  {i18n.t('transactionDetails.finalizeTransfer')}
                </InternalButton>

                <TextButton
                  type="button"
                  color="light"
                  onClick={() => {
                    localStorage.clear();
                    history.push(`/transactions/signin/${pendingTransferUuid}`);
                  }}
                >
                  {`${i18n.t('actions.cancel')}`}
                </TextButton>
              </>
            )}
            {data?.status !== 'cancelled' && data?.status !== 'expired' && (
              <LinkTextButton
                className="!text-lg"
                to={`/transfer-api/${pendingTransferUuid}`}
              >
                {i18n.t('transactionDetails.alreadyPaid')}
              </LinkTextButton>
            )}
            <FlexDiv>
              {isPending && (
                <Paragraph textAlign="center">
                  <Trans
                    i18n={i18n}
                    i18nKey="transactionDetails.depositPendingWarning"
                    components={[<span />]}
                  />
                </Paragraph>
              )}
              <LinkTextButton
                className="!text-lg"
                to={`/transfer-api/${pendingTransferUuid}`}
                color="light"
              >
                {`${i18n.t('returnButton.return')}`}
              </LinkTextButton>
            </FlexDiv>
          </Section>
        )}
      </ContentWrapper>
    </TransferApiTemplate>
  );
}

DepositDetailsExternal.propTypes = {
  setData: PropTypes.func,
  reloadAction: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.number,
    details: PropTypes.shape({
      psp_amount: PropTypes.number,
      expires_at: PropTypes.date,
      psp_payment_info: PropTypes.shape({
        qr_code: PropTypes.string,
      }),
    }),
  }),
}.isRequired;

export default DepositDetailsExternal;
