import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import i18n from '@/translate/i18n';
import { AuthContext } from '@/context/authContext';
import { useUserData } from '@/context/userContext';
import { login, loginForTransaction } from '@/services/api';
import { registrationStatus } from '@/helpers/constants';

import useUserCampaigns from './useUserCampaigns';

function useLogin() {
  const history = useHistory();
  const { setIsAuthenticated } = useContext(AuthContext);
  const { setUserData } = useUserData();
  const { getUserCampaigns } = useUserCampaigns();

  const submitLogin = async (payload, setErrorMessage, setLoginError) => {
    try {
      const res = await login(payload);

      const { token, status, id, store_token } = res.data;
      localStorage.setItem('accessToken', JSON.stringify(token));
      localStorage.setItem('store_token', JSON.stringify(store_token));
      localStorage.setItem('id', JSON.stringify(id));

      window.dataLayer.push({
        event: process.env.REACT_APP_ENV
          ? `login_${process.env.REACT_APP_ENV}`
          : 'login',
        userId: id,
      });

      getUserCampaigns();
      setIsAuthenticated(true);
      setUserData(res.data);

      const hashmap = new Map();

      hashmap.set(registrationStatus.validateEmail, () => {
        history.push('/validate-email');
      });

      hashmap.set(registrationStatus.finalizeRegistration, () => {
        history.push('/registration');
      });

      hashmap.set(registrationStatus.validateRegistration, () => {
        history.push('/wallet/validate-register');
      });

      hashmap.set(registrationStatus.validateId, () => {
        history.push('/wallet/validate-register');
      });

      hashmap.set(registrationStatus.waitingIdValidation, () => {
        history.push('/wallet/validate-identity');
      });

      hashmap.set(registrationStatus.validated, () => {
        const previousPath = sessionStorage.getItem('redirectTo');
        sessionStorage.removeItem('redirectTo');

        history.push(previousPath ?? '/wallet');
      });

      hashmap.get(status)();
    } catch (err) {
      const message = err?.response?.data?.message;

      setErrorMessage(
        i18n.t([`error.signin.${message}`, 'error.signin.unspecific']),
      );
      setLoginError(true);
    }
  };

  const submitLoginForTransaction = async (
    payload,
    transactionId,
    setErrorMessage,
    setLoginError,
  ) => {
    try {
      const res = await loginForTransaction(payload, transactionId);

      const { token, id, transaction, merchant, status } = res.data;
      localStorage.setItem('accessToken', JSON.stringify(token));
      localStorage.setItem('id', JSON.stringify(id));
      localStorage.setItem(
        'transaction',
        JSON.stringify({ ...transaction, merchant }),
      );

      window.dataLayer.push({
        event: process.env.REACT_APP_ENV
          ? `login_${process.env.REACT_APP_ENV}`
          : 'login',
        userId: id,
      });

      setIsAuthenticated(true);
      setUserData(res.data);

      const hashmap = new Map();

      hashmap.set(registrationStatus.validateEmail, () => {
        history.push('/external/validate-email');
      });

      hashmap.set(registrationStatus.finalizeRegistration, () => {
        history.push('/external/registration');
      });

      hashmap.set(registrationStatus.validateRegistration, () => {
        history.push('/external/validate-register');
      });

      hashmap.set(registrationStatus.validateId, () => {
        history.push('/external/validate-register');
      });

      hashmap.set(registrationStatus.waitingIdValidation, () => {
        history.push('/external/validate-identity');
      });

      hashmap.set(registrationStatus.validated, () => {
        history.push(`/transfer-api/${transactionId}`);
      });

      hashmap.get(status)();
    } catch (err) {
      const message =
        err?.response?.data?.data?.message ?? err?.response?.data?.message;

      setErrorMessage(
        i18n.t([`error.signin.${message}`, 'error.signin.unspecific']),
      );
      setLoginError(true);
    }
  };

  return { submitLogin, submitLoginForTransaction };
}

export default useLogin;
