import React from 'react';
import PropTypes from 'prop-types';

import DepositPreview from '@/components/molecules/DepositPreview';
import TransferPreview from '@/components/molecules/TransferPreview';
import i18n from '@/translate/i18n';
import { LinkText } from '@/components/molecules/TransactionError/styles';
import { useWalletData } from '@/context/walletContext';

import { ContentWrapper, LinksWrapper, Wrapper } from './styles';
import TwTitle from '@/components/atoms/TwTitle';
import useDeviceWidth from '@/hooks/useDeviceWidth';

function TransactionPreview({ type = 'deposit', isExternalFlow }) {
  const {
    walletData: { transactionSubmitError },
  } = useWalletData();

  const previewComponentsByType = {
    deposit: <DepositPreview />,
    transfer: <TransferPreview />,
  };
  const { isMobileLayout } = useDeviceWidth();

  return (
    <Wrapper id={`${type}-summary`}>
      <TwTitle
        text={i18n.t('bonusConfirmation.title')}
        size={isMobileLayout ? 'm_lg' : 'd_lg'}
        font="arboriaBold"
        classList="text-center"
      />
      <ContentWrapper>{previewComponentsByType[type]}</ContentWrapper>
      {transactionSubmitError ? (
        <LinksWrapper>
          {!isExternalFlow && (
            <LinkText to="/transactions" id="view-history-btn">
              {i18n.t('transactionError.goToHistory')}
            </LinkText>
          )}
        </LinksWrapper>
      ) : (
        <span />
      )}
    </Wrapper>
  );
}

TransactionPreview.propTypes = {
  type: PropTypes.string.isRequired,
  isExternalFlow: PropTypes.bool,
};
export default TransactionPreview;
