import styled, { css } from 'styled-components';

import { ReactComponent as VpagWalletLogo } from '@/assets/vpag-wallet-logo-horizontal.svg';

export const TermsHeader = styled.header`
  display: flex;
  justify-content: center;
  max-width: 1788px;
  padding: 2rem 2rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: space-between;
  }
`;

export const Logo = styled(VpagWalletLogo)`
  max-width: 200px;
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.info.light};
    flex: 1;
    font: ${theme.font.size.small} ${theme.font.family.primary};
    margin-top: 3rem;
    max-width: 1600px;
    overflow: hidden auto;
    padding: 0 2.5rem;
    text-align: justify;
    width: 100%;

    a {
      color: ${theme.colors.primary};
      text-decoration: underline;
    }

    h1,
    h2,
    h3,
    p {
      margin-bottom: 1rem;
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      margin-bottom: 2rem;
      width: 80%;
    }
  `}
`;
