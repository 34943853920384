import styled from 'styled-components';
import { hexToRGBA } from '@/styles/colors';

export const ModalContainer = styled.div`
  align-items: center;
  animation: ${({ showModal }) =>
    showModal ? 'modalWrapperIn forwards' : 'modalWrapperOut forwards'};

  animation-duration: ${({ hideAnimation }) => (hideAnimation ? '0' : '0.8s')};
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99;
  overflow: auto;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: ${({ hideInDesktop }) => hideInDesktop && 'none'};
  }
`;

export const ModalWrapper = styled.div`
  align-items: center;
  height: fit-content;
  padding: ${({ padding }) => padding || 'unset'};
  max-height: ${({ maxHeigth }) => maxHeigth || 'unset'};
  overflow-y: scroll;
  justify-content: center;
  animation: ${({ showModal }) =>
    showModal
      ? 'goDownAndAppearModalWrapper forwards'
      : 'goUpAndDisappearModalWrapper forwards'};
  animation-duration: ${({ hideAnimation }) => (hideAnimation ? '0' : '0.8s')};
  background-color: ${({ theme, bgColor = 'darkSecondary' }) =>
    theme.colors.bg[bgColor]};
  border-radius: 20px;
  box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.font.family.primary};
  justify-content: space-around;
  max-width: ${({ maxWidth, width }) => maxWidth || width || '350px'};
  min-height: ${({ height, bonusTerms }) =>
    bonusTerms ? 'unset ' : height || '450px'};
  position: absolute;
  width: ${({ width }) => width || '90vw'};
  overflow: ${({ bonusTerms }) => (bonusTerms ? 'hidden' : 'unset')};
  z-index: 9;
`;

export const CloseButton = styled.button`
  align-items: center;
  background-color: ${({ theme, closeButtonWhite }) =>
    closeButtonWhite ? theme.colors.light : theme.colors.bg.darkSecondary};
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: 5px;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 9;
  &:hover {
    background-color: ${({ theme }) => hexToRGBA(theme.colors.light, 0.05)};
  }
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
  border-top: 2px solid
    ${({ theme }) => hexToRGBA(theme.colors.info.primary, 0.2)};
  bottom: 0;
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.font.family.secondary};
  height: 50px;
  margin-top: 0.3rem;
  position: fixed;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => hexToRGBA(theme.colors.shadow, 0.1)};
  }
`;
