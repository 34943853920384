import styled, { css } from 'styled-components';
import { ReactComponent as WelcomeImage } from '@/assets/show-mobile1.svg';
import { Button } from '@/components/atoms/Buttons/Button/styles';
import { Paragraph } from '@/components/atoms/Typography/styles';

export const WelcomingImage = styled(WelcomeImage)`
  width: 17.5rem;
`;

export const TitleContainer = styled.div`
  ${({ isMobileLayout }) => css`
    align-items: center;
    display: flex;
    justify-content: ${isMobileLayout ? 'space-between' : 'auto'};
    width: 100%;
  `}
`;

export const Box = styled.div`
  width: 100%;
`;

export const SpacingBox = styled.div`
  ${({ vPadding = 0, hPadding = 0, isMobileLayout }) => css`
    padding: ${vPadding} ${hPadding};
    width: ${isMobileLayout ? '16.9375rem' : '25.3125rem'};
  `};
`;

export const ContainerContent = styled.div`
  ${({ isMobileLayout }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1.5625rem;
    margin-top: ${isMobileLayout ? '-3.125rem' : '0rem'};
    position: ${isMobileLayout ? 'absolute' : 'relative'};
    width: 100%;
  `}
`;

export const ButtonActiveKey = styled(Button)`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 50px;
  padding: 1rem;
  text-align: center;
  transition: opacity 0.2s;

  :hover {
    opacity: 0.8;
  }
`;

export const SpacingEmpyt = styled.div`
  margin-top: 0.3125rem;
`;

export const ParagraphStyled = styled(Paragraph)`
  display: flex;
  gap: 0.3125rem;
  margin-bottom: 1.875rem;
`;

export const ButtonsContainer = styled.div`
  ${({ isMobileLayout }) => css`
    display: flex;
    flex-direction: ${isMobileLayout ? 'column' : 'row'};
    align-items: center;
    justify-content: center;
    gap: 1.5625rem;
  `}
`;
