import styled from 'styled-components';
import { hexToRGBA } from '@/styles/colors';

export const Wrapper = styled.section`
  align-items: center;
  display: flex;
  gap: 1.5rem;

  svg {
    height: 35px;
    width: 35px;
  }
`;

export const TextWrapper = styled.div`
  align-items: center;
  border-right: ${({ theme }) =>
    `2px solid ${hexToRGBA(theme.colors.info.primary, 0.2)}`};
  display: flex;
  gap: 1rem;
  height: 40px;
  padding-right: 1.5rem;
`;

export const ActionButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.info.light};
  font: ${({ theme }) =>
    `${theme.font.size.small} ${theme.font.family.secondary}`};
`;
