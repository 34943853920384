import styled, { css } from 'styled-components';
import { HashLink } from 'react-router-hash-link';

import { ReactComponent as ArrowIcon } from '@/assets/simpleArrow.svg';
import { hexToRGBA } from '@/styles/colors';

const hideInDesktop = css`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-height: 60%;
    width: 200px;
  }
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    border-bottom: 1px solid ${hexToRGBA(theme.colors.info.primary, 0.2)};
    color: ${theme.colors.primary};
    font-size: ${theme.font.size.small};
    padding: 0 0 0.7rem 1rem;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      margin-bottom: 0.7rem;
      padding-left: 0;
    }
  `}
`;

export const Span = styled.span`
  ${({ theme, active }) => css`
    color: ${theme.colors.info.light};
    font: ${`${theme.font.size.medium} ${theme.font.family.secondary}`};
    padding: 0 1.5rem;
    text-transform: capitalize;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      color: ${active ? theme.colors.light : theme.colors.info.primary};
      font-family: ${active
        ? theme.font.family.secondary
        : theme.font.family.primary};
      font-size: ${theme.font.size.small};
      padding: 0 0 1.3rem;
    }
  `}
`;

export const NavItem = styled(HashLink)`
  ${({ theme }) => css`
    align-items: center;
    border-top: 1px solid ${hexToRGBA(theme.colors.info.primary, 0.2)};
    color: ${theme.colors.info.light};
    cursor: pointer;
    display: flex;
    font: ${`${theme.font.size.medium} ${theme.font.family.secondary}`};
    height: 79px;
    padding: 0 1.5rem;
    text-decoration: none;
    width: 100%;

    p {
      margin-left: 25px;
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      animation: none;
      border: none;
      height: auto;
      padding: 0;
    }
  `}
`;

export const ArrowButton = styled.div`
  ${hideInDesktop}
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bgGradientLight.start};
  border-radius: 37px;
  display: flex;
  height: 27px;
  justify-content: center;
  position: absolute;
  right: 10%;
  width: 27px;
`;

export const Arrow = styled(ArrowIcon)`
  fill: ${({ theme }) => theme.colors.light};
`;
