import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const ButtonStyles = ({
  theme,
  bgColor = 'primary',
  fontSize = 'base',
  fontFamily = 'secondary',
}) => css`
  background-color: ${theme.colors[bgColor]};
  border: none;
  border-radius: 4px;
  color: ${theme.colors.info.dark};
  font: ${theme.font.size[fontSize]} ${theme.font.family[fontFamily]};
  height: 6vh;
  max-height: 40px;
  max-width: 280px;
  min-height: 35px;
  min-width: 210px;

  &:disabled {
    background-color: ${theme.colors.button.disabled};
    cursor: not-allowed;
    opacity: 1;
  }
`;

const LinkButtonStyles = css`
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none;
`;

const LinkDisabledStyled = css`
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};
`;

export const Button = styled.button`
  ${ButtonStyles}
`;

export const ExternalButton = styled.a`
  ${ButtonStyles}
  ${LinkButtonStyles}
  ${LinkDisabledStyled}
`;

export const InternalButton = styled(Link)`
  ${ButtonStyles}
  ${LinkButtonStyles}
  ${LinkDisabledStyled}
`;

export const BorderedButton = styled(Link)`
  ${({ theme, color = 'primary', size = 'small' }) => css`
    align-items: center;
    border: 2px solid ${theme.colors[color]};
    border-radius: 4px;
    color: ${theme.colors[color]};
    display: flex;
    font: ${theme.font.size[size]} ${theme.font.family.secondary};
    height: 45px;
    justify-content: center;
    max-width: 220px;
    padding: 0.5rem 1rem;
    text-decoration: none;
    width: 60%;
  `}
`;
