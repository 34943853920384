import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import DragAndDrop from '@/components/atoms/DragAndDrop';
import Loading from '@/components/atoms/Loading';
import {
  LinkTextButton,
  TextButton,
} from '@/components/atoms/Buttons/TextButton/styles';
import i18n from '@/translate/i18n';
import useUserValidations from '@/hooks/useUserValidations';
import { InternalButton } from '@/components/atoms/Buttons/Button/styles';
import { Title } from '@/components/atoms/Typography/styles';
import { createUserDocument } from '@/services/api';

import { ImagePreview, InputWrapper, PreviewButton, Wrapper } from './styles';
import TwTitle from '@/components/atoms/TwTitle';

const ACCEPTED_IMAGES = ['png', 'jpg', 'jpeg'];
const ACCEPTED_FILES = ['pdf', ...ACCEPTED_IMAGES];

function UploadSof({ setErrorMessage }) {
  const inputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const [isLoading, setIsLoading] = useState();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { getValidations } = useUserValidations();

  const fileExt = file?.type?.split('/')[1];

  useEffect(() => {
    if (file) {
      setFileUrl(URL.createObjectURL(file));
    }
  }, [file]);

  const uploadCustomerDocuments = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('verification_type', 'sof');
      await createUserDocument(formData);

      setShowSuccessMessage(true);
      getValidations();
    } catch (err) {
      const message = err?.response?.data?.message;
      setErrorMessage(
        i18n.t([
          `error.documentUpload${message}.`,
          'error.documentUpload.unspecific',
        ]),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Wrapper>
      <div>
        <TwTitle
          size="xl"
          font="arboriaBold"
          color="shadow-500"
          classList="text-center uppercase"
        >
          <Trans
            i18nKey="validations.sof.title"
            i18n={i18n}
            components={[<span />]}
          />
        </TwTitle>
        <p>{i18n.t('validations.sof.subtitle')}</p>
      </div>
      {isLoading ? (
        <>
          <Loading />
          <span />
        </>
      ) : showSuccessMessage ? (
        <>
          <div>
            <Title>{i18n.t('validations.sof.successMessage')}</Title>
            <p>{i18n.t('validations.sof.descriptionAfter')}</p>
          </div>
          <InternalButton to="/wallet">
            {i18n.t('returnButton.return')}
          </InternalButton>
        </>
      ) : (
        <>
          <InputWrapper>
            {file && (
              <>
                {ACCEPTED_IMAGES.includes(fileExt) ? (
                  <ImagePreview src={URL.createObjectURL(file)} alt="SOF" />
                ) : (
                  <>
                    <PreviewButton
                      type="button"
                      onClick={() => {
                        const pdfWindow = window.open();
                        pdfWindow.location.href = fileUrl;
                      }}
                    >
                      {i18n.t('validations.sof.showDocument')}
                    </PreviewButton>
                    <p>{file?.name}</p>
                  </>
                )}
              </>
            )}
            <DragAndDrop
              accept="image/*, application/pdf"
              hideInput={file}
              inputRef={inputRef}
              onChange={setFile}
              acceptedFiles={ACCEPTED_FILES}
            />

            {file && (
              <TextButton
                type="button"
                onClick={() => {
                  inputRef.current.click();
                }}
              >
                Trocar
              </TextButton>
            )}
            <Title size="small">
              <Trans
                i18nKey={
                  file
                    ? 'validations.sof.descriptionAfter'
                    : 'validations.sof.description'
                }
                i18n={i18n}
                components={[
                  <span />,
                  <LinkTextButton
                    className="!text-lg"
                    to="/faq"
                    margin="1.25rem 0 0 0"
                  />,
                ]}
              />
            </Title>
          </InputWrapper>
          <InternalButton
            to="#"
            onClick={
              file ? uploadCustomerDocuments : () => inputRef.current.click()
            }
          >
            {file
              ? i18n.t('validations.sof.buttonAfter')
              : i18n.t('validations.sof.button')}
          </InternalButton>
        </>
      )}
    </Wrapper>
  );
}

UploadSof.propTypes = {
  setErrorMessage: PropTypes.func,
}.isRequired;

export default UploadSof;
