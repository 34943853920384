import { QRCodeSVG } from 'qrcode.react';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.font.size.small};
  gap: 2rem;
`;

export const QrCode = styled(QRCodeSVG)`
  border: 5px solid ${({ theme }) => theme.colors.light};
  border-radius: 5px;
  height: 165px;
  width: 165px;
`;

export const CopyButton = styled.button`
  ${({ theme, bgColor = 'pending' }) => css`
    align-items: center;
    background-color: ${theme.colors[bgColor]};
    border: none;
    border-radius: 1rem;
    color: ${theme.colors.info.dark};
    cursor: pointer;
    display: flex;
    font: ${`${theme.font.size.xSmall} ${theme.font.family.secondary}`};
    gap: 3px;
    margin: 25px 0 10px;
    padding: 0.4rem 0.8rem 0.3rem;
    text-transform: uppercase;

    &:hover {
      filter: brightness(0.9);
    }
  `}
`;
