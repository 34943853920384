import React from 'react';
import i18n from '@/translate/i18n';

const LoadingComponent = () => (
  <div className="loading">
    <h2>{i18n.t('loading.loading')}</h2>
  </div>
);

export default LoadingComponent;
