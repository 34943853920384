import styled, { css } from 'styled-components';

const Container = styled.div`
  ${({
    background,
    gradient,
    gradientLight,
    justify = 'start',
    maxWidth = 'none',
    primary,
    theme,
  }) => css`
    align-items: center;
    background-color: ${background || primary
      ? theme.colors.bg.primary
      : theme.colors.dark};
    background: ${background ||
    (gradient &&
      `linear-gradient(90deg, ${theme.colors.bgGradientDark.start},${theme.colors.bgGradientDark.end} )`)};
    background: ${background ||
    (gradientLight &&
      `linear-gradient(90deg, ${theme.colors.bgGradientSettings.start} 0%, ${theme.colors.bgGradientSettings.end} 100%)`)};
    color: ${theme.colors.info.dark};
    display: flex;
    flex: 1;
    flex-direction: column;
    font: ${`${theme.font.size.xSmall} ${theme.font.family.primary}`};
    height: 100%;
    justify-content: ${justify};
    max-width: ${maxWidth || 'none'};
    overflow: hidden auto;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      background: ${`linear-gradient(90deg, ${theme.colors.bgGradientDark.start},${theme.colors.bgGradientDark.end} )`};
    }
  `}
`;

export default Container;
