import React from 'react';
import PropTypes from 'prop-types';

import SelectField from './styles';

function SelectDefault({ defaultValue, disabled, onChange, options = [] }) {
  return (
    <SelectField
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled}
    >
      {options?.map((curr) => (
        <option key={curr} value={curr}>
          {curr}
        </option>
      ))}
    </SelectField>
  );
}

SelectDefault.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  disabled: PropTypes.bool,
}.isRequired;

export default SelectDefault;
