import { useState } from 'react';

import { listUserCampaigns } from '@/services/api';
import { setupPoptinCampaigns } from '@/helpers/functions';

const useUserCampaigns = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(false);
  const [campaigns, setCampaigns] = useState(false);

  const getUserCampaigns = async () => {
    try {
      setIsFetching(true);
      const res = await listUserCampaigns();
      localStorage.setItem('campaigns', JSON.stringify(res.data));

      setupPoptinCampaigns(res.data);
      setCampaigns(res.data);
    } catch (err) {
      setError(true);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    error,
    isFetching,
    campaigns,
    getUserCampaigns,
  };
};

export default useUserCampaigns;
