import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

import Loading from '@/components/atoms/Loading';
import { Button } from '@/components/atoms/Buttons/Button/styles';
import { LinkTextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { Title } from '@/components/atoms/Typography/styles';
import { Label } from '@/components/pages/TransferApi/styles';
import TransferApiTemplate from '@/components/templates/TransferApiTemplate';
import { useWalletData } from '@/context/walletContext';
import useBalance from '@/hooks/useBalance';
import { DEFAULT_METHOD_SLUG } from '@/helpers/constants';
import { formatCurrency } from '@/helpers/stringFormat';
import useTransactionLimits from '@/hooks/useTransactionLimits';
import { createDeposit } from '@/services/api';
import i18n from '@/translate/i18n';

import { ContentWrapper, FlexWrapper, Section } from './styles';

const INPUT_NUMBER_LOCALE = 'pt-BR';
const MAX_INPUT_LENGTH = 9;
const ONLY_NUMBERS_REGEX = /[^0-9]/g;
const CURRENCY_SYMBOL = 'R$';

function DepositExternal() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { isFetching: isFetchingBalance } = useBalance();
  const {
    walletBalance,
    setWalletData,
    walletData: { selectedWallet, transactionData },
  } = useWalletData();

  const { limitsError, transactionLimits, loadingLimits } =
    useTransactionLimits(
      'deposit',
      selectedWallet,
      transactionData?.method?.slug ?? DEFAULT_METHOD_SLUG,
    );

  const { amount } = transactionData;
  const pendingTransferUuid = sessionStorage.getItem('pendingApiTransaction');

  const generateDeposit = async () => {
    try {
      setIsLoading(true);
      const payload = {
        amount: Number(transactionData.amount),
        customer_currency_iso: 'BRL',
        method: 'pix',
      };
      const res = await createDeposit(payload, null);
      const { id } = res.data;

      history.push(`/external/transactions/${id}`);
    } catch (err) {
      toast.error(i18n.t('error.deposit.validation-error'), {
        position: 'top-center',
        duration: 4000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = ({ target }) => {
    setWalletData((state) => ({
      ...state,
      transactionData: {
        ...state.transactionData,
        amount: Number(target.value.replace(ONLY_NUMBERS_REGEX, '')),
      },
    }));
  };

  useEffect(() => {
    const pendingTransferApi = JSON.parse(localStorage.getItem('transaction'));

    if (pendingTransferApi?.amount) {
      setWalletData((prevState) => ({
        ...prevState,
        transactionData: {
          ...prevState.transactionData,
          amount: pendingTransferApi.amount,
        },
      }));
    }
  }, []);

  const {
    max_amount_per_transaction: maxValue,
    min_amount_per_transaction: minValue,
  } = transactionLimits;

  const isValidValue = amount >= minValue && amount <= maxValue;
  const inputValueString = `${CURRENCY_SYMBOL} ${amount?.toLocaleString(
    INPUT_NUMBER_LOCALE,
  )}`;

  return (
    <TransferApiTemplate>
      <ContentWrapper>
        {loadingLimits ? (
          <Loading />
        ) : (
          !limitsError && (
            <Section>
              <Title>{i18n.t('depositExternal.title')}</Title>
              <FlexWrapper>
                <Label>
                  {i18n.t('depositExternal.balance')}
                  <input
                    type="text"
                    disabled
                    value={
                      isFetchingBalance
                        ? '...'
                        : formatCurrency(walletBalance, selectedWallet)
                    }
                  />
                </Label>
                <Label>
                  <p>
                    {i18n.t('depositExternal.amount')}{' '}
                    <span>
                      {amount > maxValue
                        ? i18n.t('depositExternal.maxAmount', {
                            maxAmount: formatCurrency(
                              maxValue,
                              selectedWallet,
                              true,
                            ),
                          })
                        : i18n.t('depositExternal.minAmount', {
                            minAmount: formatCurrency(
                              minValue,
                              selectedWallet,
                              true,
                            ),
                          })}
                    </span>
                  </p>
                  <input
                    type="text"
                    value={inputValueString}
                    onChange={handleChange}
                    maxLength={MAX_INPUT_LENGTH}
                  />
                </Label>
              </FlexWrapper>

              {isLoading ? (
                <Loading disableFlexGrow />
              ) : (
                <FlexWrapper>
                  <Button
                    type="button"
                    disabled={!isValidValue}
                    onClick={generateDeposit}
                  >
                    {i18n.t('depositExternal.submit')}
                  </Button>
                  <LinkTextButton
                    className="!text-lg"
                    to={`/transfer-api/${pendingTransferUuid}`}
                    color="light"
                  >
                    {i18n.t('returnButton.return')}
                  </LinkTextButton>
                </FlexWrapper>
              )}
            </Section>
          )
        )}
      </ContentWrapper>
    </TransferApiTemplate>
  );
}

export default DepositExternal;
