import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.primary};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  text-align: center;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: space-between;
    max-height: 550px;
    padding: 2.5rem 0;
  }
`;

export const FlexDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;
