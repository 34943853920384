import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useHistory } from 'react-router-dom';

import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { extractErrorSlug } from '@/helpers/functions';
import { formatCpf } from '@/helpers/stringFormat';
import { getUserAddress } from '@/services/api';
import { useUserData } from '@/context/userContext';

import {
  ActionButton,
  ButtonsWrapper,
  ContentWrapper,
  ItemWrapper,
  Span,
} from './styles';

import sofContentByLevel from './data';
import TwTitle from '@/components/atoms/TwTitle';
import TwText from '@/components/v2/atoms/TwText';

function ProfileSections() {
  const history = useHistory();
  const { isMobileLayout } = useDeviceWidth();
  const { userData, setUserData } = useUserData();
  const [address, setAddress] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { cpf, phone } = userData;

  useEffect(() => {
    const controller = new AbortController();
    const getAddress = async () => {
      try {
        const res = await getUserAddress(
          localStorage.getItem('id'),
          controller.signal,
        );

        setAddress(res.data);
        setUserData((state) => ({ ...state, address: res.data }));
      } catch (err) {
        const messages = extractErrorSlug(err?.response);
        console.error(messages?.[0]);
        setAddress({});
      } finally {
        setIsLoading(false);
      }
    };
    getAddress();

    return () => controller.abort();
  }, [setUserData]);

  const addressData = userData.address || address;

  const isPhoneValidated =
    userData?.validations?.find(({ validation }) => validation === 'phone')
      ?.status === 'validated';

  const sofValidationStatus = userData?.validations?.find(
    ({ validation }) => validation === 'sof',
  )?.status;

  const isUserUnderLevel = userData?.kyc_level < 1;

  const sofValidationContentSlug = isUserUnderLevel
    ? 'underLevel'
    : sofValidationStatus;

  return (
    <ContentWrapper id="user-basic-info">
      {!isMobileLayout && (
        <TwTitle
          text={i18n.t('profile.personalData')}
          size={isMobileLayout ? 'm_lg' : 'd_lg'}
          font="arboriaBold"
          classList="text-center"
        />
      )}
      <div>
        <ItemWrapper>
          <TwTitle
            text={i18n.t('profile.cpf')}
            size={isMobileLayout ? 'm-md' : 'd_md'}
            font="arboriaBold"
            classList="text-left uppercase"
          />
          <TwText
            inlineSize="text-lg"
            color="crem"
            font="arboriaBook"
            id="profile-user-document-number"
            text={cpf ? formatCpf(cpf) : '...'}
          />
        </ItemWrapper>

        <ItemWrapper
          onClick={
            addressData?.address && (() => history.push('/profile/address'))
          }
          showHover={Boolean(addressData?.address)}
        >
          <TwTitle
            text={i18n.t('profile.address')}
            size={isMobileLayout ? 'm-md' : 'd_md'}
            font="arboriaBold"
            classList="text-left uppercase"
          />
          <TwText
            inlineSize="text-lg"
            color="crem"
            font="arboriaBook"
            id="profile-user-document-number"
            text={
              addressData?.address ? (
                addressData?.address
              ) : isLoading ? (
                '...'
              ) : (
                <Span color="danger">{i18n.t('profile.notRegistered')}</Span>
              )
            }
          />
          <ButtonsWrapper>
            {!addressData?.address && !isLoading && (
              <ActionButton to="/profile/address">
                {i18n.t('profile.register')}
              </ActionButton>
            )}
          </ButtonsWrapper>
        </ItemWrapper>
        <ItemWrapper onClick={() => history.push('/profile/phone')} showHover>
          <TwTitle
            text={i18n.t('profile.phoneNumber')}
            size={isMobileLayout ? 'm-md' : 'd_md'}
            font="arboriaBold"
            classList="text-left uppercase"
          />
          <TwText
            inlineSize="text-lg"
            color="crem"
            font="arboriaBook"
            id="profile-user-document-number"
            text={formatPhoneNumberIntl(phone)}
          />
          <ButtonsWrapper>
            {!isPhoneValidated && (
              <ActionButton
                to="/profile/phone/validation"
                onClick={(e) => e.stopPropagation()}
              >
                {i18n.t('profile.validate')}
              </ActionButton>
            )}
          </ButtonsWrapper>
        </ItemWrapper>
        {sofValidationStatus && (
          <ItemWrapper>
            <TwTitle
              text={i18n.t('profile.sof.title')}
              size={isMobileLayout ? 'm-md' : 'd_md'}
              font="arboriaBold"
              classList="text-left uppercase"
            />
            <TwText
              inlineSize="text-lg"
              color={sofContentByLevel[sofValidationContentSlug]?.color}
              font="arboriaBook"
              id="profile-user-document-number"
              text={sofContentByLevel[sofValidationContentSlug]?.content}
            />
            {sofValidationContentSlug &&
              sofValidationContentSlug !== 'completed' && (
                <ButtonsWrapper>
                  <ActionButton
                    to="/validations/sof"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {sofContentByLevel[sofValidationContentSlug]?.buttonLabel}
                  </ActionButton>
                </ButtonsWrapper>
              )}
          </ItemWrapper>
        )}
      </div>
      <Span>{i18n.t('profile.tapToEdit')}</Span>
    </ContentWrapper>
  );
}

ProfileSections.propTypes = {
  isFetching: PropTypes.bool,
  isOpen: PropTypes.bool,
  setActiveItem: PropTypes.bool,
  validations: PropTypes.arrayOf(
    PropTypes.shape({
      validation: PropTypes.string,
      status: PropTypes.string,
    }),
  ),
}.isRequired;

export default ProfileSections;
