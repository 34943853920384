import styled, { css } from 'styled-components';
import { Form } from 'formik';

const HideAnimation = css`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    animation: none;
  }
`;

export const Wrapper = styled(Form)`
  align-items: center;
  display: flex;
  flex: 1;
  flex-flow: column;
  height: 80%;
  justify-content: space-around;
  margin: 40px 0;
  max-width: 400px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    border-radius: 20px;
    height: 100%;
    justify-content: space-between;
    margin: 0;
    max-height: 550px;
    min-height: 370px;
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  ${HideAnimation}

  align-items: center;
  animation: goLeftDefault 1s;
  color: ${({ theme }) => theme.colors.info.primary};
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: ${({ theme }) => theme.font.family.secondary};
  justify-content: center;
  text-align: center;

  p {
    max-width: 250px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0.5rem;
`;
