import styled from 'styled-components';
import { hexToRGBA } from '@/styles/colors';

export const Section = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    column-gap: 2%;
    display: grid;
    grid-template-columns: ${({ isOpen }) => (isOpen ? '47% 1% 47%' : '1fr')};
    justify-items: center;
    padding: 7vh 0;
  }
`;

export const SectionDivider = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    animation: opacityAnimationDefault 0.8s ease;
    border-left: 1px solid
      ${({ theme }) => hexToRGBA(theme.colors.info.primary, 0.2)};
    display: block;
    min-height: 80%;
  }
`;
