import styled, { css } from 'styled-components';

export const Halo = styled.div`
  ${({ isSelected, theme }) => css`
    border-radius: 50%;
    border: ${isSelected ? `4px solid ${theme.colors.blue}` : 'none'};
    height: ${isSelected ? 'fit-content' : '100%'};
    padding: ${isSelected ? '18px' : '0px'};
    transition: border 0.2ms;
    width: fit-content;
  `}
`;

export const BodyMarchanDisco = styled.div`
  ${({ isSelected }) => css`
    align-items: center;
    background-color: #32373a;
    border-radius: 50%;
    display: flex;
    height: ${isSelected ? '198px' : '136px'};
    justify-content: center;
    text-align: center;
    transition: width height 0.2ms;
    width: ${isSelected ? '198px' : '136px'};
  `}
`;

export const Image = styled.img`
  ${({ isSelected }) => css`
    height: ${isSelected ? '134px' : '76px'};
    width: ${isSelected ? '132px' : '55px'};
  `}
`;
