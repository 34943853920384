import React, { useContext, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Loading from './components/atoms/Loading';
import i18n from './translate/i18n';
import { AuthContext } from './context/authContext';

import {
  Address,
  BonusesAndPromotions,
  BonusesHistory,
  ClubVPlus,
  Conversion,
  Deposit,
  DepositDetailsExternal,
  DepositExternal,
  EmailVerification,
  Faq,
  FaqTopic,
  IncreaseLimits,
  PointsHistory,
  ProceedRegistration,
  Profile,
  RegisterAddress,
  ResetPassword,
  SelectMerchant,
  Settings,
  SignIn,
  SignInTransferApi,
  SignUp,
  SignUpTransferApi,
  Terms,
  TransactionStatus,
  Transactions,
  Transfer,
  TransferApi,
  UpdatePhoneNumber,
  ValidateEmail,
  ValidateId,
  ValidateIdentity,
  ValidatePhone,
  ValidateRegister,
  ValidateSof,
  WalletHome,
  Withdrawal,
  Cashback,
  CashbackChooseMech,
} from '@/components/pages';

import { setupPoptinCampaigns } from './helpers/functions';
import CashbackMerchantsActives from './components/pages/CashbackMerchantsActives';
import CashbackDetail from './components/pages/CashbackDetail';
import PixArea from './components/pages/PixArea';

const Page = ({ component: Component, title, redirectPath, ...rest }) => {
  const { pathname } = useLocation();
  const { isAuthenticated, isLoading } = useContext(AuthContext);

  useEffect(() => {
    document.title = title || 'Vpag';
  }, [title]);

  useEffect(() => {
    if (window.poptinStarted && window.runPoptinNow) {
      window.poptinStarted = false;
      window.runPoptinNow();
    }
  }, [pathname]);

  useEffect(() => {
    setupPoptinCampaigns();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoading ? (
          isAuthenticated && redirectPath ? (
            <Redirect
              to={{
                pathname: redirectPath,
                state: { from: props.location },
              }}
            />
          ) : (
            <Component {...props} />
          )
        ) : (
          <section className="size-full bg-black flex items-center justify-center">
            <Loading />
          </section>
        )
      }
    />
  );
};

const PrivateRoute = ({ component: Component, title, ...rest }) => {
  const { pathname } = useLocation();
  const { isAuthenticated, isLoading } = useContext(AuthContext);

  useEffect(() => {
    document.title = title || 'Vpag';
  }, [title]);

  useEffect(() => {
    setupPoptinCampaigns();
  }, []);

  useEffect(() => {
    if (window.poptinStarted && window.runPoptinNow) {
      window.poptinStarted = false;
      window.runPoptinNow();
    }
  }, [pathname]);

  const savePreviousUrlAndRedirect = (props) => {
    sessionStorage.setItem('redirectTo', pathname);
    return (
      <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />
    );
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoading ? (
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            savePreviousUrlAndRedirect(props)
          )
        ) : (
          <section className="size-full bg-black flex items-center justify-center">
            <Loading />
          </section>
        )
      }
    />
  );
};

const Routes = () => {
  useEffect(() => {
    const tokenListener = window.addEventListener('invalid_token', () => {
      localStorage.setItem('show_expired_session', true);
      window.location = '/signin';
    });

    return () => tokenListener;
  }, []);

  return (
    <Switch>
      <Page
        exact
        path="/"
        component={SignIn}
        title={i18n.t('pageTitle.signIn')}
        redirectPath="/wallet"
      />
      <Page
        exact
        path="/signin"
        component={SignIn}
        title={i18n.t('pageTitle.signIn')}
        redirectPath="/wallet"
      />
      <Page
        exact
        path="/signup"
        component={SignUp}
        title={i18n.t('pageTitle.signUp')}
        redirectPath="/wallet"
      />
      <Page
        exact
        path="/external/signup"
        component={SignUpTransferApi}
        title={i18n.t('pageTitle.signUp')}
      />
      <Page
        exact
        path="/recover-password/:token"
        component={ResetPassword}
        title={i18n.t('pageTitle.recoverPassword')}
      />
      <Page
        exact
        path="/validate-email/:token"
        component={EmailVerification}
        title={i18n.t('pageTitle.validateEmail')}
      />
      <Page
        exact
        path="/transactions/signin/:id"
        component={SignInTransferApi}
        title={i18n.t('pageTitle.signIn')}
      />
      <Page
        exact
        path="/terms/:slugTerms"
        component={Terms}
        title={i18n.t('pageTitle.termsAndConditions')}
      />
      <Route
        exact
        path="/external/validate-email"
        component={ValidateEmail}
        title={i18n.t('pageTitle.validateEmail')}
      />

      <PrivateRoute
        exact
        path="/validate-email"
        component={ValidateEmail}
        title={i18n.t('pageTitle.validateEmail')}
      />
      <PrivateRoute
        exact
        path="/external/registration"
        component={ProceedRegistration}
        title={i18n.t('pageTitle.registration')}
      />
      <PrivateRoute
        exact
        path="/registration"
        component={ProceedRegistration}
        title={i18n.t('pageTitle.registration')}
      />
      <PrivateRoute
        exact
        path="/wallet/validate-register"
        component={ValidateRegister}
        title={i18n.t('pageTitle.validateRegister')}
      />
      <PrivateRoute
        exact
        path="/external/validate-register"
        component={ValidateRegister}
        title={i18n.t('pageTitle.validateRegister')}
      />
      <PrivateRoute
        exact
        path="/wallet/validate-identity"
        component={ValidateIdentity}
        title={i18n.t('pageTitle.validateId')}
      />
      <PrivateRoute
        exact
        path="/external/validate-identity"
        component={ValidateIdentity}
        title={i18n.t('pageTitle.validateId')}
      />
      <PrivateRoute
        exact
        path="/wallet"
        component={WalletHome}
        title={i18n.t('pageTitle.walletHome')}
      />
      <PrivateRoute
        exact
        path="/external/transactions/:transactionId"
        component={DepositDetailsExternal}
        title={i18n.t('pageTitle.transactionDetails')}
      />
      <PrivateRoute
        exact
        path="/transactions/:transactionId"
        component={TransactionStatus}
        title={i18n.t('pageTitle.transactionDetails')}
      />
      <PrivateRoute
        exact
        path="/deposit"
        component={Deposit}
        title={i18n.t('pageTitle.deposit')}
      />
      <PrivateRoute
        exact
        path="/external/deposit"
        component={DepositExternal}
        title={i18n.t('pageTitle.deposit')}
      />
      <PrivateRoute
        exact
        path="/withdrawal"
        component={Withdrawal}
        title={i18n.t('pageTitle.withdrawal')}
      />
      <PrivateRoute
        exact
        path="/transfer"
        component={SelectMerchant}
        title={i18n.t('pageTitle.transfer')}
      />
      <PrivateRoute
        exact
        path="/transfer/:merchantId(\d+)"
        component={Transfer}
        title={i18n.t('pageTitle.transfer')}
      />
      <PrivateRoute
        exact
        path="/transfer/:merchantSlug([a-zA-Z0-9_-]+)"
        component={SelectMerchant}
        title={i18n.t('pageTitle.transfer')}
      />
      <PrivateRoute
        exact
        path="/transfer-api/:id"
        component={TransferApi}
        title={i18n.t('pageTitle.transfer')}
      />
      <PrivateRoute
        exact
        path="/conversion"
        component={Conversion}
        title={i18n.t('pageTitle.conversion')}
      />
      <PrivateRoute
        exact
        path="/transactions"
        component={Transactions}
        title={i18n.t('pageTitle.history')}
      />
      <PrivateRoute
        exact
        path="/points"
        component={PointsHistory}
        title={i18n.t('pageTitle.points')}
      />
      <PrivateRoute
        exact
        path="/profile"
        component={Profile}
        title={i18n.t('pageTitle.profile')}
      />
      <PrivateRoute
        exact
        path="/profile/address"
        component={Address}
        title={i18n.t('pageTitle.profile')}
      />
      <PrivateRoute
        exact
        path="/profile/phone"
        component={UpdatePhoneNumber}
        title={i18n.t('pageTitle.profile')}
      />
      <PrivateRoute
        exact
        path="/profile/phone/validation"
        component={ValidatePhone}
        title={i18n.t('pageTitle.profile')}
      />
      <PrivateRoute
        exact
        path="/settings"
        component={Settings}
        title={i18n.t('pageTitle.settings')}
      />
      <PrivateRoute
        exact
        path="/faq"
        component={Faq}
        title={i18n.t('pageTitle.faq')}
      />
      <PrivateRoute
        exact
        path="/faq/:topic"
        component={FaqTopic}
        title={i18n.t('pageTitle.faq')}
      />
      <PrivateRoute
        exact
        path="/bonuses-and-promotions"
        component={BonusesAndPromotions}
        title={i18n.t('pageTitle.bonusesAndPromotions')}
      />
      <PrivateRoute
        exact
        path="/bonuses-and-promotions/history"
        component={BonusesHistory}
        title={i18n.t('pageTitle.bonusesHistory')}
      />
      <PrivateRoute
        exact
        path="/validations"
        component={IncreaseLimits}
        title={i18n.t('pageTitle.increaseLimits')}
      />
      <PrivateRoute
        exact
        path="/validations/identity"
        component={ValidateId}
        title={i18n.t('pageTitle.increaseLimits')}
      />
      <PrivateRoute
        exact
        path="/validations/address"
        component={RegisterAddress}
        title={i18n.t('pageTitle.increaseLimits')}
      />
      <PrivateRoute
        exact
        path="/validations/sof"
        component={ValidateSof}
        title={i18n.t('pageTitle.increaseLimits')}
      />
      <PrivateRoute
        exact
        path="/club-vplus"
        component={ClubVPlus}
        title={i18n.t('pageTitle.clubVPlus')}
      />
      <PrivateRoute
        exact
        path="/cashback"
        component={Cashback}
        title={i18n.t('pageTitle.cashback')}
      />
      <PrivateRoute
        exact
        path="/cashback/merchants"
        component={CashbackChooseMech}
        title={i18n.t('pageTitle.cashback')}
      />
      <PrivateRoute
        exact
        path="/cashback/merchants-actives"
        component={CashbackMerchantsActives}
        title={i18n.t('pageTitle.cashback')}
      />
      <PrivateRoute
        exact
        path="/cashback/merchants/:idMerchant"
        component={CashbackDetail}
        title={i18n.t('pageTitle.cashback')}
      />
      <PrivateRoute
        exact
        path="/pix-area"
        component={PixArea}
        title={i18n.t('pageTitle.cashback')}
      />
      <Route path="*" component={() => <Redirect to="/wallet" />} />
    </Switch>
  );
};

Page.propTypes = {
  title: PropTypes.string,
}.isRequired;

PrivateRoute.propTypes = {
  Component: PropTypes.func,
  location: PropTypes.string,
  title: PropTypes.string,
}.isRequired;

export default Routes;
