import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';

import App from './App';
import ToasterContainer from './components/organisms/ToastContainer';
import WalletProvider from './context/walletContext';
import GlobalStyle from './styles/global';
import vpag from './styles/themes/vpag';

const replaceHashPath = () => {
  const history = createBrowserHistory();
  const { hash } = history.location;
  if (hash) {
    const path = (/#!(\/.*)$/.exec(hash) || [])[1];
    if (path) {
      history.replace(path);
    }
  }
};
replaceHashPath();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={vpag}>
        <WalletProvider>
          <GlobalStyle />
          <ToasterContainer />
          <App />
        </WalletProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
