import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { createPortal } from 'react-dom';
import TwSubmitButton from '../Buttons/TwSubmitButton';
import i18n from '@/translate/i18n';
import Modal from '@/components/molecules/Modal';
import { TextButton } from '../Buttons/TextButton/styles';
import { formatShortDate } from '@/helpers/stringFormat';
import TermsAndConditionsBonus from '@/components/molecules/Modal/Messages/TermsAndConditionsBonus';
import { getMerchant, getTermsBySlug } from '@/services/api';
import { useWalletData } from '@/context/walletContext';

function ModalContent({ bonus }) {
  const history = useHistory();
  const [showModal, setShowModal] = useState('');
  const [merchant, setMerchant] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [termsContent, setTermsContent] = useState(null);
  const { setWalletData } = useWalletData();

  const ICON_RENDER_TIME = 200;

  const getMerchantInfo = async (id) => {
    try {
      setIsFetching(true);
      await getMerchant(id).then((res) =>
        setMerchant(res?.data?.image_url || '/icons/transfer_gray.svg'),
      );
    } catch (e) {
      setMerchant('/icons/transfer_gray.svg');
      console.error(e);
    } finally {
      setIsFetching(false);
    }
  };

  const getTerms = async (slug) => {
    try {
      setIsFetching(true);
      const res = await getTermsBySlug(slug);
      setTermsContent(res.data.description);
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    let timeoutId = null;
    if (bonus?.bonus?.terms_slug) {
      getTerms(bonus.bonus.terms_slug);
    }
    if (bonus?.bonus?.merchant_ids?.length === 1) {
      getMerchantInfo(bonus?.bonus?.merchant_ids[0]);
    } else {
      timeoutId = setTimeout(() => {
        setMerchant('/icons/transfer_gray.svg');
      }, ICON_RENDER_TIME);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [bonus]);

  return (
    <div className="flex flex-col items-center p-6 gap-6 w-full">
      {isFetching ? (
        <div className="size-12 bg-shadow-600 animate-pulse" />
      ) : (
        <img className="size-12" src={merchant} alt="Merchant icon" />
      )}
      <p className="text-sand-700 font-bold text-sm">{bonus?.bonus?.title}</p>
      <p className="text-sand-700 text-sm">
        {i18n.t('bonus.modal.status')}
        <span className="font-bold">
          {i18n.t(`bonus.status.${bonus?.status}`)}
        </span>
      </p>
      {bonus?.used_at && (
        <p className="text-sand-700 text-sm">
          {i18n.t('bonus.modal.transactionId')}
          <span className="font-bold">{bonus?.transaction_id}</span>
        </p>
      )}
      {bonus?.status !== 'available' && (
        <p className="text-sand-700 text-sm">
          {i18n.t(`bonus.modal.dateLabel.${bonus?.status}`)}
          <span className="font-bold">
            {formatShortDate(
              bonus?.status === 'used' ? bonus?.used_at : bonus?.expires_at,
            )}
          </span>
        </p>
      )}

      {bonus?.status === 'available' && (
        <>
          {bonus?.bonus?.max_usages && (
            <p className="text-sand-700 text-sm">
              {i18n.t('bonus.modal.userLimit')}
              <span className="font-bold">{bonus.bonus.max_usages}</span>
            </p>
          )}

          <TwSubmitButton
            id="validate-cpf-submit-btn"
            before={i18n.t('bonus.modal.useNow')}
            after={i18n.t('bonus.modal.useNow')}
            isValid
            onClick={() => {
              setWalletData((prevState) => ({
                ...prevState,
                selectedMerchant: {},
                selectedBonus: bonus.bonus,
                filteredMerchantsByBonus: bonus.bonus.merchant_ids,
                isMerchantsFilteredByBonus: true,
              }));
              history.push('/transfer');
            }}
          />
          {termsContent && (
            <TextButton
              type="button"
              id="signup-terms-and-conditions-btn"
              onClick={() => setShowModal('termsAndConditions')}
              size="small"
              family="primary"
            >
              {i18n.t('termsAndConditions.title')}
            </TextButton>
          )}
        </>
      )}
      {createPortal(
        <Modal
          setShowModal={setShowModal}
          showModal={Boolean(showModal)}
          width="100%"
          maxWidth="900px"
          height="90vh"
          bgColor="darkSecondary"
          longInfo
          padding="4rem 0"
          maxHeigth="90vh"
        >
          <TermsAndConditionsBonus terms={termsContent} />
        </Modal>,
        document.body,
      )}
    </div>
  );
}

ModalContent.propTypes = {
  data: PropTypes.shape({}),
}.isRequired;

export default ModalContent;
