import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { IconStyled, Wrapper } from './styles';

function PointsIcon({ type, width, opacity }) {
  const [isError, setIsError] = useState(false);

  return (
    <Wrapper width={width}>
      {!isError ? (
        <IconStyled
          src={`/icons/points/${type}.svg`}
          width={width}
          height={width}
          onError={setIsError}
          opacity={opacity}
        />
      ) : (
        <IconStyled
          src="/icons/points/default.svg"
          width={width}
          height={width}
          onError={setIsError}
          opacity={opacity}
        />
      )}
    </Wrapper>
  );
}

PointsIcon.propTypes = {
  opacity: PropTypes.number,
  status: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
}.isRequired;

export default PointsIcon;
