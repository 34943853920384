import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import i18n from '@/translate/i18n';

import BankIcon from '@/components/atoms/BankIcon';
import Container from '@/components/atoms/Container/styles';
import DeleteBankAccount from '@/components/molecules/Modal/Messages/DeleteBankAccount';
import DeskContainer from '@/components/atoms/DeskContainer/styles';
import InputRadio from '@/components/atoms/Inputs/InputRadio';
import Modal from '@/components/molecules/Modal';
import NavMenuLeft from '@/components/organisms/NavMenuLeft';
import WalletHeader from '@/components/molecules/WalletHeader';
import { DeskWrapper } from '@/components/atoms/GenerateTransactionStyle/styles';
import { ReactComponent as BankIconSvg } from '@/assets/bankAccount.svg';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { accountsMock } from '@/data/mocks';
import { hideAccountNumber } from '@/helpers/stringFormat';

import {
  AccountWrapper,
  ActionButtonWrapper,
  Button,
  ButtonContainer,
  SelectButton,
  Strong,
  Text,
  TextWrapper,
  Title,
  Wrapper,
} from './styles';

function BankAccounts() {
  const history = useHistory();
  const [isSelectActive, setIsSelectActive] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!isSelectActive) {
      setSelectedAccount('');
    }
  }, [isSelectActive]);

  const isSelected = !Object.keys(selectedAccount).length;

  return (
    <DeskContainer>
      <NavMenuLeft />
      <Container>
        <WalletHeader
          title={i18n.t('bankAccounts.title')}
          returnTo="/bank-accounts/banks"
        />
        <DeskWrapper>
          {accountsMock.length > 0 && (
            <SelectButton
              type="button"
              onClick={() => setIsSelectActive(!isSelectActive)}
            >
              {isSelectActive
                ? i18n.t('bankAccounts.cancel')
                : i18n.t('bankAccounts.select')}
            </SelectButton>
          )}
          {!accountsMock.length && (
            <Wrapper>
              <BankIconSvg />
              <Text>{i18n.t('bankAccounts.emptyData')}</Text>
            </Wrapper>
          )}
          <Title>{i18n.t('bankAccounts.description')}</Title>
          {accountsMock.map((acc) => (
            <AccountWrapper key={acc.slug}>
              {isSelectActive && (
                <InputRadio
                  name="bank"
                  value={acc.slug}
                  onChange={() => setSelectedAccount(acc)}
                />
              )}

              <BankIcon slug={acc.slug} width="26px" />
              <TextWrapper>
                <Strong>{acc.bank}</Strong>
                {hideAccountNumber(`${acc.accountNumber}-${acc.dv}`)}
              </TextWrapper>
            </AccountWrapper>
          ))}
          <ButtonContainer>
            {!isSelectActive ? (
              <Button
                name={
                  !accountsMock.length
                    ? i18n.t('bankAccounts.addAccount')
                    : i18n.t('bankAccounts.newAccount')
                }
                onClick={() => history.push('/bank-accounts/banks')}
                width="200px"
              />
            ) : (
              <ActionButtonWrapper>
                <div>
                  <TextButton
                    type="button"
                    disabled={isSelected}
                    onClick={() => setShowModal(!showModal)}
                  >
                    {i18n.t('bankAccounts.delete')}
                  </TextButton>
                  <TextButton
                    type="button"
                    disabled={isSelected}
                    onClick={() => {
                      history.push({
                        pathname: '/add-bank-account',
                        state: {
                          data: selectedAccount,
                        },
                      });
                    }}
                  >
                    {i18n.t('bankAccounts.update')}
                  </TextButton>
                </div>
                <TextButton
                  type="button"
                  disabled={isSelected}
                  onClick={() => history.push('/withdrawal')}
                >
                  {i18n.t('bankAccounts.withdrawal')}
                </TextButton>
              </ActionButtonWrapper>
            )}
          </ButtonContainer>
          <Modal
            setShowModal={setShowModal}
            showModal={showModal}
            height="324px"
          >
            <DeleteBankAccount
              setShowModal={setShowModal}
              account={selectedAccount}
            />
          </Modal>
        </DeskWrapper>
      </Container>
    </DeskContainer>
  );
}

export default BankAccounts;
