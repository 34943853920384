import React from 'react';
import PropTypes from 'prop-types';

import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';
import i18n from '@/translate/i18n';

function PromotionCard({ promotion, setTermsSlug }) {
  return (
    <article className="flex flex-col items-center min-h-[160px] gap-2">
      {promotion?.image_url ? (
        <img
          src={promotion?.image_url}
          alt={promotion?.title}
          className="max-w-[180px] min-w-[160px] w-full"
        />
      ) : (
        <img
          src="/images/mock/promoExample.png"
          alt={promotion?.title}
          className="max-w-[180px] min-w-[160px] w-full"
        />
      )}
      <div className="flex flex-col items-center gap-4">
        {promotion?.terms_slug && (
          <div>
            <TextButton
              onClick={() => {
                setTermsSlug(promotion.terms_slug);
              }}
            >
              {i18n.t('bonusesAndPromotions.showTerms')}
            </TextButton>
          </div>
        )}
        {promotion?.tracker && (
          <a
            href={promotion?.tracker}
            target="_blank"
            rel="noreferrer"
            className="bg-grass-700 m-w-[140px] rounded-full px-6 py-[10px] text-shadow-900 font-arboria-bold text-base"
          >
            {promotion?.button_title ??
              i18n.t('bonusesAndPromotions.usePromotion')}
          </a>
        )}
      </div>
    </article>
  );
}

PromotionCard.propTypes = {
  promotion: PropTypes.shape({
    button_title: PropTypes.string,
    image_url: PropTypes.string,
    terms_slug: PropTypes.string,
    title: PropTypes.string,
    tracker: PropTypes.string,
  }),
  setTermsSlug: PropTypes.func,
};

export default PromotionCard;
