import i18n from '@/translate/i18n';

import { ReactComponent as Bonus } from '@/assets/MenuIcons/bonuses.svg';
import { ReactComponent as BonusActive } from '@/assets/MenuIcons/bonuses-active.svg';
import { ReactComponent as Conversion } from '@/assets/MenuIcons/conversion.svg';
import { ReactComponent as ConversionActive } from '@/assets/MenuIcons/conversion-active.svg';
import { ReactComponent as Deposit } from '@/assets/MenuIcons/deposit.svg';
import { ReactComponent as DepositActive } from '@/assets/MenuIcons/deposit-active.svg';
import { ReactComponent as Faq } from '@/assets/MenuIcons/faqfull.svg';
import { ReactComponent as FaqActive } from '@/assets/MenuIcons/faqfull-active.svg';
import { ReactComponent as Historic } from '@/assets/MenuIcons/historic.svg';
import { ReactComponent as HistoricActive } from '@/assets/MenuIcons/historic-active.svg';
import { ReactComponent as Home } from '@/assets/MenuIcons/home.svg';
import { ReactComponent as HomeActive } from '@/assets/MenuIcons/home-active.svg';
import { ReactComponent as Store } from '@/assets/MenuIcons/store.svg';
import { ReactComponent as StoreActive } from '@/assets/MenuIcons/store-active.svg';
import { ReactComponent as Transfer } from '@/assets/MenuIcons/transferIn.svg';
import { ReactComponent as TransferActive } from '@/assets/MenuIcons/transferIn-active.svg';
import { ReactComponent as VPlus } from '@/assets/MenuIcons/vPlusFull.svg';
import { ReactComponent as VPlusActive } from '@/assets/MenuIcons/vPlusFull-active.svg';
import { ReactComponent as WithDrawal } from '@/assets/MenuIcons/withdrawal.svg';
import { ReactComponent as WithDrawalActive } from '@/assets/MenuIcons/withdrawal-active.svg';
import { ReactComponent as Cashback } from '@/assets/MenuIcons/cashback.svg';
import { ReactComponent as CashbackActive } from '@/assets/MenuIcons/cashback-active.svg';

export default [
  {
    id: 'nav-btn-home',
    name: i18n.t('topLeftMenu.home'),
    path: '/wallet',
    icon: Home,
    activeIcon: HomeActive,
    hideArrows: true,
  },
  {
    id: 'nav-btn-cashback',
    name: i18n.t('topLeftMenu.cashback'),
    icon: Cashback,
    activeIcon: CashbackActive,
    path: '/cashback',
  },
  {
    id: 'nav-btn-deposit',
    name: i18n.t('topLeftMenu.deposit'),
    path: '/deposit',
    icon: Deposit,
    activeIcon: DepositActive,
  },
  {
    id: 'nav-btn-transfer-in',
    name: i18n.t('topLeftMenu.transfer'),
    path: '/transfer',
    icon: Transfer,
    activeIcon: TransferActive,
  },
  {
    id: 'nav-btn-withdrawal',
    name: i18n.t('topLeftMenu.withdrawal'),
    path: '/withdrawal',
    icon: WithDrawal,
    activeIcon: WithDrawalActive,
    validationRequired: true,
  },
  {
    id: 'nav-btn-conversion',
    name: i18n.t('topLeftMenu.conversion'),
    path: '/conversion',
    icon: Conversion,
    activeIcon: ConversionActive,
    hideBasedOnWalletNumber: true,
    validationRequired: true,
  },
  {
    id: 'nav-btn-bonus-and-promotions',
    name: i18n.t('topLeftMenu.bonusesAndPromotions'),
    path: '/bonuses-and-promotions',
    icon: Bonus,
    activeIcon: BonusActive,
  },
  {
    id: 'nav-btn-transactions',
    name: i18n.t('topLeftMenu.history'),
    path: '/transactions',
    icon: Historic,
    activeIcon: HistoricActive,
  },
  {
    id: 'nav-btn-vplus-club',
    name: i18n.t('topLeftMenu.clubVPlus'),
    path: '/club-vplus',
    icon: VPlus,
    activeIcon: VPlusActive,
  },
  {
    id: 'nav-btn-store',
    name: 'store',
    icon: Store,
    activeIcon: StoreActive,
  },
  {
    id: 'nav-btn-faq',
    name: i18n.t('topLeftMenu.faq'),
    path: '/faq',
    icon: Faq,
    activeIcon: FaqActive,
  },
];
