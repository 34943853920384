import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import HeadModal from '@/components/molecules/HeadModal';
import PlayIcon from '@/assets/playIcon.svg';
import { Container } from '../CashbackPixInfo/style';
import i18n from '@/translate/i18n';
import Row from '@/components/molecules/Row';
import { useWalletData } from '@/context/walletContext';
import { Button } from '@/components/atoms/Buttons/Button/styles';
import { createTransferIn, getMerchant } from '@/services/api';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { formatCurrency } from '@/helpers/stringFormat';

import { CashbackResumeBody, DetailContainer, Divider } from './style';
import useBalance from '@/hooks/useBalance';
import AuthorizeTransactions from '@/components/v2/Molecules/AuthorizeTransactions';
import { Title } from '@/components/atoms/Typography/styles';

const CURRENT_STEP = 3;
const FIRST_LETTER = 0;
const ZERO = 0;
const SECOND_LETTER = 1;
const EMPYT_STRING = '';
const STATUS_AFFILIATE = 'affiliated';
const GENERAL_USER_TYPE = 'general';
const VPLUS_USER_TYPE = 'vplus';

function CashbackDetail({
  handleBackStep,
  currentCurrency,
  setProcess,
  setError,
  merchantSelected,
  firstTime = false,
}) {
  const {
    walletData: {
      selectedWallet,
      transactionData: { amount },
    },
  } = useWalletData();
  const { isMobileLayout } = useDeviceWidth();
  const [merchantCurrency] = useState('BRL');
  const { getBalance } = useBalance();
  const [enable, setEnable] = useState(true);

  const [infoPointsX10, setInfoPointsX10] = useState(undefined);
  const [currentPercentCashback, setCurrentPercentCashback] = useState(0);
  const { id, slug, cashbacks } = merchantSelected;
  const username = localStorage.getItem('username');
  const isVpag =
    merchantSelected.usernames[ZERO]?.affiliate === STATUS_AFFILIATE;
  const capitalizedMerchantName =
    typeof merchantSelected.name === 'string' &&
    merchantSelected.name.length > ZERO
      ? merchantSelected.name[FIRST_LETTER].toUpperCase() +
        merchantSelected.name.slice(SECOND_LETTER)
      : EMPYT_STRING;

  const handleConfirm = async () => {
    const payload = {
      username,
      amount,
      merchant_currency_iso: currentCurrency,
      merchant_id: id,
      merchant_slug: slug,
      customer_currency_iso: merchantCurrency,
      activate_cashback: true,
      has_cashback: false,
    };
    try {
      setProcess(true);
      await createTransferIn(payload);
      getBalance();
    } catch (error) {
      setError(error.message);
    } finally {
      setProcess(false);
    }
  };

  const handleChangeEnableValue = () => {
    setEnable(!enable);
  };

  const userType = isVpag ? VPLUS_USER_TYPE : GENERAL_USER_TYPE;

  const getInfoPointsX10Merchant = async () => {
    const res = await getMerchant(merchantSelected.id);
    if (res.error) return;
    setInfoPointsX10(res.data);
  };

  useEffect(() => {
    getInfoPointsX10Merchant();
    setCurrentPercentCashback(
      cashbacks.find((item) => item.user_type === userType)?.percent,
    );
  }, []);

  return (
    <Container isMobile={isMobileLayout}>
      <HeadModal
        currentStep={CURRENT_STEP}
        currentIcon={PlayIcon}
        handleBackStep={handleBackStep}
      />
      <CashbackResumeBody
        isMobileLayout={isMobileLayout}
        className="!gap-1 md:gap-4  "
      >
        <Title size="medium" className="text-xl md:text-2xl text-white">
          {i18n.t('cashbackDetail.title')}
        </Title>
        <DetailContainer isMobileLayout={isMobileLayout}>
          <Row
            title={i18n.t('cashbackDetail.merchant')}
            value={capitalizedMerchantName}
          />
          <Row
            title={i18n.t('cashbackDetail.amountTransferred')}
            value={formatCurrency(amount, selectedWallet)}
          />
          <Row
            title={i18n.t('cashbackDetail.youWillReceive')}
            value={
              firstTime
                ? formatCurrency(amount, selectedWallet)
                : formatCurrency(
                    currentPercentCashback * Number(amount) + amount,
                    selectedWallet,
                  )
            }
          />
          <Divider />
          <Row
            title={i18n.t('cashbackDetail.pointsX10')}
            value={
              infoPointsX10
                ? Math.floor(
                    infoPointsX10.transferin_multiplier_points *
                      infoPointsX10.transferin_base_points *
                      Number(amount),
                  ).toFixed(ZERO)
                : ZERO
            }
          />
          <AuthorizeTransactions
            onClick={handleChangeEnableValue}
            merchantName={slug}
          />
          <Button
            className="w-full md:w-auto"
            onClick={handleConfirm}
            disabled={enable}
          >
            {i18n.t('cashbackDetail.confirm')}
          </Button>
        </DetailContainer>
      </CashbackResumeBody>
    </Container>
  );
}

CashbackDetail.propTypes = {
  handleBackStep: PropTypes.func,
  merchantURLCreateAccount: PropTypes.string,
  currentMerchant: PropTypes.object,
  currentCurrency: PropTypes.string,
  setProcess: PropTypes.func,
  setError: PropTypes.func,
  merchantSelected: PropTypes.object,
  firstTime: PropTypes.bool,
}.isRequired;
export default CashbackDetail;
