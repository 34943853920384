import styled from 'styled-components';
import { ReactComponent as Icon } from '@/assets/alertIcon.svg';

export const Wrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex-direction: column;
  font: ${({ theme }) =>
    `${theme.font.size.small} ${theme.font.family.primary}`} !important;
  height: 200px;
  justify-content: space-evenly;
  padding-bottom: 50px;
  text-transform: none;
`;

export const AlertIcon = styled(Icon)`
  width: 50px;

  path {
    fill: ${({ theme }) => theme.colors.info.gray};
  }
`;
