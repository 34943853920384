import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

const UserContext = React.createContext({});

const defaultState = {
  id: '',
  name: '',
  email: '',
  cpf: '',
  phone: '',
  kyc_level: 0,
  status: 0,
  token: '',
  usernames: [],
  validations: [],
  limits: {},
  points: null,
};

export default function UserProvider({ children }) {
  const [userData, setUserData] = useState(defaultState);

  const updateUserData = (data) => {
    setUserData((prev) => ({ ...prev, data }));
  };

  const resetUserData = () => {
    setUserData(defaultState);
  };

  const updateNotification = (data) => {
    setUserData((state) => ({
      ...state,
      details: {
        notification: {
          interests: state.details.notification.interests,
          types: data,
        },
      },
    }));
  };

  const updateInterests = (data) => {
    setUserData((state) => ({
      ...state,
      details: {
        notification: {
          interests: data,
          types: state.details.notification.types,
        },
      },
    }));
  };

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        updateUserData,
        resetUserData,
        updateNotification,
        updateInterests,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserData() {
  return useContext(UserContext);
}

UserProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
}.isRequired;
