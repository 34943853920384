import styled from 'styled-components';
import { Form } from 'formik';

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.info.light};
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: 600;
  margin-top: 3rem;
`;

export const FormStyled = styled(Form)`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 50px;
  width: 100%;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const ErrorText = styled.p`
  align-self: center;
  color: ${({ theme }) => theme.colors.danger};
  font-size: ${({ theme }) => theme.font.size.small};
  max-width: 350px;
  position: static;
  text-align: center;

  @media screen and (min-height: 650px) {
    bottom: 30vh;
  }
`;

export const SucessText = styled(ErrorText)`
  color: ${({ theme }) => theme.colors.sucess};
`;
