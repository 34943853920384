import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import MainTemplate from '@/components/templates/MainTemplate';
import ProfileHeader from '@/components/molecules/ProfileHeader';
import SectionDivider from '@/components/atoms/SectionDivider/styles';
import i18n from '@/translate/i18n';

import { ContentWrapper, ChildrenWrapper } from './styles';

function ProfileTemplate({ children }) {
  const history = useHistory();
  return (
    <MainTemplate
      containerProps={{ gradientLight: true }}
      headerProps={{
        title: i18n.t('profile.title'),
        returnAction: () => history.push('/profile'),
      }}
    >
      <ContentWrapper>
        <ProfileHeader hideInMobile />
        <SectionDivider breakpoint="1024px" />
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </ContentWrapper>
    </MainTemplate>
  );
}

ProfileTemplate.propTypes = {
  children: PropTypes.element,
}.isRequired;

export default ProfileTemplate;
