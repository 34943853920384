/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import Loading from '@/components/atoms/Loading';
import SectionDivider from '@/components/atoms/SectionDivider/styles';
import MainTemplate from '@/components/templates/MainTemplate';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { ReactComponent as FilterIcon } from '@/assets/filterIcon.svg';
import { Paragraph } from '@/components/atoms/Typography/styles';
import { getBonusHistory, listMerchants } from '@/services/api';

import { PaginationWrapper } from './styles';
import TwTitle from '@/components/atoms/TwTitle';
import { extractErrorSlug } from '@/helpers/functions';
import BonusHistoryResume from '@/components/molecules/BonusHistoryResume';
import Modal from '@/components/molecules/Modal';
import ModalContent from '@/components/atoms/ModalContent';
import ReturnButton from '@/components/atoms/Buttons/ReturnButton';
import useFilter from '@/hooks/useFilter';
import Filters from '@/components/organisms/Filters';

const ITEMS_PER_PAGE = 10;
const ITEMS_PER_PAGE_MOBILE = 10;
const NUM_OF_PAGES_DEFAULT = 2;

const getLastMonthRange = () => {
  const endDate = new Date();
  const startDate = new Date(endDate);
  startDate.setMonth(startDate.getMonth() - 1);
  if (endDate.getDate() < startDate.getDate()) {
    startDate.setDate(0);
  }
  return { startDate, endDate };
};
const { startDate, endDate } = getLastMonthRange();

const INITIAL_FILTERS = {
  order_by: 'id',
  order: 'desc',
  page: 1,
  type: [],
  merchant_id: [],
  status: [],
};

function BonusesHistory() {
  const { isMobileLayout } = useDeviceWidth();
  const [bonuses, setBonuses] = useState([]);
  const [pageCount, setPageCount] = useState(NUM_OF_PAGES_DEFAULT);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBonus, setSelectedBonus] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [merchantList, setMerchantList] = useState([]);
  const filterMethods = useFilter(INITIAL_FILTERS);
  const history = useHistory();

  const defaultFilters = {
    order_by: 'id',
    order: 'desc',
    per_page: isMobileLayout ? ITEMS_PER_PAGE_MOBILE : ITEMS_PER_PAGE,
  };

  const PAYLOAD = { without_pagination: true, active: true };

  const getUserBonus = async (page = 1, signal) => {
    try {
      setIsLoading(true);
      const params = {
        ...defaultFilters,
        ...filterMethods.formattedFilters,
        page,
      };

      if (!params?.created_at_from) {
        params.created_at_from = startDate.toISOString().split('T')[0];
        params.created_at_to = endDate.toISOString().split('T')[0];
      }

      if (!params.status || !params.status?.length) {
        params.status = ['available', 'used', 'expired', 'out'];
      }

      const res = await getBonusHistory(params, signal);
      setBonuses(res.data);
      setPageCount(Math.ceil(res.meta.total / ITEMS_PER_PAGE));

      const merch = await listMerchants(PAYLOAD);
      setMerchantList(merch.data);
    } catch (err) {
      const messages = extractErrorSlug(err?.response);
      toast.error(
        i18n.t([
          `error.transactionHistory.${messages}`,
          'error.transactionHistory.unspecific',
        ]),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const changePage = ({ selected }) => {
    filterMethods.setCurrentFilters((state) => ({
      ...state,
      page: selected + 1,
    }));
  };

  useEffect(() => {
    const controller = new AbortController();

    getUserBonus(controller.signal);

    return () => controller.abort();
  }, [filterMethods.currentFilters]);

  return (
    <MainTemplate
      headerProps={{
        returnTo: '/bonuses-and-promotions',
        title: i18n.t('bonusesHistory.mobileTitle'),
        returnAction: filterMethods.showFilter
          ? () => {
              filterMethods.setShowFilter(false);
            }
          : null,
        customButton: !filterMethods.showFilter && (
          <div className={`isMobileLayout ? 'pr-20' : '`}>
            <FilterIcon
              onClick={() => {
                filterMethods.setShowFilter((state) => !state);
              }}
            />
          </div>
        ),
      }}
    >
      {!isMobileLayout && (
        <ReturnButton
          top="27px"
          left="20px"
          handleClick={() => history.push('/bonuses-and-promotions')}
          color="light"
          isRollback={!isMobileLayout}
          title={i18n.t('returnButton.return')}
        />
      )}

      <div className="size-full flex justify-around py-10 px-4 sm:px-0">
        {isLoading ? (
          <div
            className={`h-[600px] max-w-[600px] 2xl:max-w-none w-full lg:w-[80%] 2xl:w-[55%] grid place-content-center ${
              !isMobileLayout && filterMethods.showFilter
                ? 'lg:mx-[5%] 2xl:mx-[10%]'
                : ''
            }`}
          >
            <Loading />
          </div>
        ) : (
          (!isMobileLayout ||
            (isMobileLayout && !filterMethods.showFilter)) && (
            <div
              className={`flex flex-col justify-between relative max-w-[600px] 2xl:max-w-none w-full lg:w-[80%] 2xl:w-[55%] ${
                filterMethods.showAnimation
                  ? filterMethods.showFilter
                    ? 'animate-left'
                    : 'animate-right'
                  : ''
              } ${
                !isMobileLayout && filterMethods.showFilter
                  ? 'lg:mx-[5%] 2xl:mx-[10%]'
                  : ''
              }`}
              hideInMobile={filterMethods.showFilter}
            >
              <div className="flex items-center justify-center mb-16">
                <TwTitle
                  text={i18n.t('bonusesHistory.title')}
                  size={isMobileLayout ? 'm_lg' : 'd_lg'}
                  font="arboriaBold"
                  classList="text-center"
                />
                {!isMobileLayout && (
                  <div className="flex items-center justify-end absolute right-2">
                    {!filterMethods.showFilter && (
                      <button
                        type="button"
                        className="flex items-center cursor-pointer h-10 ml-4"
                        onClick={() => {
                          filterMethods.setShowFilter(true);
                          filterMethods.setShowAnimation(true);
                        }}
                      >
                        <FilterIcon />
                        <TwTitle
                          text={i18n.t('filters.title')}
                          size={isMobileLayout ? 'm_md' : 'd_md'}
                          font="arboriaBold"
                          classList="text-left ml-1"
                        />
                      </button>
                    )}
                  </div>
                )}
              </div>
              <div className="flex bg-black bg-opacity-20 font-bold mb-4 items-center justify-between text-base text-crem min-h-10 w-full">
                <p className="w-[60%] ml-3 text-left">
                  {i18n.t('bonus.bonus')}
                </p>
                <p className="w-1/4 text-left">{i18n.t('bonus.received')}</p>
                {!isMobileLayout && (
                  <p className="hidden xl:block min-w-28 pl-4 text-left">
                    {i18n.t('bonus.statusTitle')}
                  </p>
                )}
                <div className="xl:hidden w-4" />
              </div>
              <div
                className={`w-full lg:max-h-[50vh] 2xl:max-h-full overflow-y-auto flex-col flex ${
                  !bonuses.length ? 'justify-center' : 'justify-start'
                } flex-grow`}
                id="transactions-list"
              >
                {bonuses.map((bonus) => (
                  <BonusHistoryResume
                    key={bonus.id}
                    data={bonus}
                    setModalContent={() => {
                      setSelectedBonus(bonus);
                      setShowModal(true);
                    }}
                  />
                ))}
                {!isLoading && !bonuses.length && (
                  <Paragraph textAlign="center" style={{ margin: '0 auto' }}>
                    {i18n.t(
                      filterMethods.isDirty
                        ? 'transactionHistory.emptyData'
                        : 'lastTransactions.noData',
                    )}
                  </Paragraph>
                )}
              </div>
              {Boolean(pageCount) && pageCount > 1 && !isLoading && (
                <PaginationWrapper>
                  <ReactPaginate
                    breakLabel=""
                    nextLabel={<IoIosArrowForward />}
                    previousLabel={<IoIosArrowBack />}
                    disableInitialCallback
                    forcePage={filterMethods.currentFilters.page - 1}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={NUM_OF_PAGES_DEFAULT}
                    activeLinkClassName="current-page"
                    disabledClassName="button-disabled"
                    pageLinkClassName="page-link"
                    previousLinkClassName="prev-button"
                    nextLinkClassName="next-button"
                  />
                </PaginationWrapper>
              )}
            </div>
          )
        )}
        {filterMethods.showFilter && (
          <>
            <SectionDivider className="h-[75%] animate-fade_in" />
            <Filters
              {...filterMethods}
              handleSubmit={getUserBonus}
              filtersType="bonusHistory"
              metaData={{
                merchant_id:
                  merchantList.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })) || [],
              }}
            />
          </>
        )}
      </div>
      <Modal
        showModal={showModal}
        setShowModal={() => {
          setShowModal(false);
          setTimeout(() => {
            setSelectedBonus(null);
          }, 200);
        }}
        height="100px"
      >
        <ModalContent bonus={selectedBonus} />
      </Modal>
    </MainTemplate>
  );
}

export default BonusesHistory;
