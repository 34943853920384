import React from 'react';
import PropTypes from 'prop-types';
import { StepContainer, ParagraphStyled } from './style';

function Step({ step, wasActivated }) {
  return (
    <StepContainer activated={wasActivated}>
      <ParagraphStyled size="medium" color="black">
        {step}
      </ParagraphStyled>
    </StepContainer>
  );
}

Step.propTypes = {
  step: PropTypes.number.isRequired,
  wasActivated: PropTypes.bool,
}.isRequired;

export default Step;
