import React from 'react';
import { MdEmail } from 'react-icons/md';
import { IoIosChatboxes } from 'react-icons/io';

import { Paragraph } from '@/components/atoms/Typography/styles';
import { CONTACT_EMAIL } from '@/helpers/constants';
import i18n from '@/translate/i18n';

import { Container } from './styled';

function Footer() {
  return (
    <Container>
      <Paragraph>
        {i18n.t('footer.allRightsReserved', { year: new Date().getFullYear() })}
      </Paragraph>
      <div>
        <Paragraph>
          <MdEmail />
          {i18n.t('footer.email', { email: CONTACT_EMAIL })}
        </Paragraph>
        <Paragraph>
          <IoIosChatboxes />
          {i18n.t('footer.support')}
        </Paragraph>
      </div>
    </Container>
  );
}

export default Footer;
