import styled, { css } from 'styled-components';

export const BannerCashbackContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  position: relative;
  top: 0;
`;

export const Image = styled.img`
  height: 2;
  position: relative;
  z-index: 1;
`;

export const BannerContent = styled.div`
  ${({ isMobileLayout }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 27px;
    justify-content: start;
    margin-top: ${isMobileLayout ? '220px' : '100px'};
    position: absolute;
    top: ${isMobileLayout ? '0' : '-100px'};
    width: 100%;
    z-index: 3;

    @media (max-width: 380px) and (max-height: 668px) {
      gap: 10px;
    }

    > img {
      margin-top: ${isMobileLayout ? 'auto' : '50px'};
    }
  `}
`;

export const BackButtonContainer = styled.div`
  width: inherit;
`;
