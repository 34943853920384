import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.colors.info.light};
    display: flex;
    flex-direction: column;
    font: ${theme.font.size.base} ${theme.font.family.secondary};
    gap: 5px;
  `}
`;

export const IconWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  gap: 1rem;
  justify-content: center;
`;
