import styled from 'styled-components';
import { Field } from 'formik';

import {
  InputSecondaryStyle,
  InputStyleDefault,
} from '@/components/atoms/Inputs/styles';

export const InputPrimary = styled(Field)`
  ${InputStyleDefault}
`;

export const InputPrimaryDefault = styled.input`
  ${InputStyleDefault}
`;

export const InputSecondary = styled(InputPrimary)`
  ${InputSecondaryStyle}
`;

export const InputSecondaryDefault = styled.input`
  ${InputSecondaryStyle}
`;

export const HiddenPasswordBtn = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  right: 15px;
  top: ${({ secondaryTheme }) => (secondaryTheme ? '29%' : '30%')};
  width: 10px;

  path {
    fill: ${({ theme, color }) => color && theme.colors[color]};
  }
`;

export const ErrorText = styled.div`
  bottom: -3px;
  color: ${({ theme }) => theme.colors.danger};
  font-size: ${({ theme }) => theme.font.size.xSmall};
  line-height: 0.8;
  position: absolute;
  text-align: center;
  width: 100%;
`;

export const IconWrapper = styled.div`
  left: 0.5rem;
  position: absolute;
  top: 31%;
`;
