import styled from 'styled-components';

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  svg {
    height: 100px;
    width: 100px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    svg {
      display: ${({ showInDesktop }) => (showInDesktop ? 'flex' : 'none')};
    }
  }
`;

export default IconWrapper;
