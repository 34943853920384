import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import RoundedButton from '@/components/atoms/Buttons/RoundedButton/styles';
import { Paragraph } from '@/components/atoms/Typography/styles';
import i18n from '@/translate/i18n';
import { useWalletData } from '@/context/walletContext';
import { formatCurrency } from '@/helpers/stringFormat';
import { listCustomerWallets } from '@/services/api';

import { Wrapper } from './styles';

function TransferApiBalance({ insufficientBalance }) {
  const history = useHistory();

  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(false);
  const {
    walletBalance,
    walletData: { selectedWallet },
    setWalletData,
  } = useWalletData();

  useEffect(() => {
    const controller = new AbortController();

    const getBalance = async (signal) => {
      try {
        setIsFetching(true);
        const userId = localStorage.getItem('id');
        const res = await listCustomerWallets(userId, signal);
        const walletInReal = res.data.filter(
          ({ currency }) => currency?.iso === 'BRL',
        );
        const userWallets = {};
        res.data.forEach((el) => {
          userWallets[el.currency.iso] = el;
        });

        setWalletData((prevState) => ({
          ...prevState,
          balance: walletInReal[0].balance,
          wallets: userWallets,
        }));
      } catch (err) {
        setError(true);
      } finally {
        setIsFetching(false);
      }
    };

    getBalance(controller.signal);

    return () => controller.abort();
  }, []);

  return (
    <Wrapper>
      {isFetching || error ? (
        <span>...</span>
      ) : (
        <Paragraph size="base">
          {i18n.t('transferApi.balance')}
          <span>{formatCurrency(walletBalance, selectedWallet)}</span>
        </Paragraph>
      )}
      <RoundedButton
        type="button"
        bordered={!insufficientBalance}
        onClick={() => history.push('/external/deposit')}
      >
        {i18n.t('transferApi.addFunds')}
      </RoundedButton>
    </Wrapper>
  );
}

TransferApiBalance.propTypes = {
  insufficientBalance: PropTypes.bool,
};

export default TransferApiBalance;
