import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CollapseClose } from '@/assets/collapse-close.svg';
import { ReactComponent as CollapseOpen } from '@/assets/collapse-open.svg';

import {
  CollapseButton,
  ItemWrapper,
  ListItem,
  Span,
  TextContent,
} from '@/components/atoms/FaqStyles/styles';

function TopicListRecursive({ data, updateCollapseState, openedTopics }) {
  const isTopicOpen = (index) => !!openedTopics?.find((el) => el === index);

  return (
    <>
      {data?.questions?.map((question) => (
        <ListItem
          key={question?.id}
          onClick={(e) => {
            e.stopPropagation();
            updateCollapseState(question?.id);
          }}
          isOpen={isTopicOpen(question?.id)}
        >
          <ItemWrapper>
            <Span isOpen={isTopicOpen(question?.id)}>{question?.question}</Span>
            <CollapseButton
              type="button"
              onClick={() => updateCollapseState(question?.id)}
              isOpen={isTopicOpen(question?.id)}
            >
              {isTopicOpen(question?.id) ? <CollapseClose /> : <CollapseOpen />}
            </CollapseButton>
          </ItemWrapper>
          {isTopicOpen(question?.id) && (
            <TextContent
              dangerouslySetInnerHTML={{
                __html: question?.answer,
              }}
            />
          )}
        </ListItem>
      ))}
      {data?.children?.map((child) => (
        <ListItem
          key={child?.id}
          onClick={(e) => {
            e.stopPropagation();
            updateCollapseState(child?.id);
          }}
          isOpen={isTopicOpen(child?.id)}
        >
          <ItemWrapper>
            <Span isOpen={openedTopics === child?.id}>
              <strong>{child?.title}</strong>
            </Span>
            <CollapseButton
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                updateCollapseState(child?.id);
              }}
              isOpen={isTopicOpen(child?.id)}
            >
              {isTopicOpen(child?.id) ? <CollapseClose /> : <CollapseOpen />}
            </CollapseButton>
          </ItemWrapper>
          <TopicListRecursive
            data={child}
            updateCollapseState={updateCollapseState}
            openedTopics={openedTopics}
            isTopicOpen={isTopicOpen}
          />
        </ListItem>
      ))}
    </>
  );
}

TopicListRecursive.propTypes = {
  data: PropTypes.shape,
  updateCollapseState: PropTypes.func,
  openedTopicss: PropTypes.string,
}.isRequired;

export default TopicListRecursive;
