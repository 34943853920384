import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: ${({ align = 'center' }) => align};
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex-direction: column;

  svg {
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  height: 110px;
  margin-bottom: 1.2rem;
  min-width: 7.5rem;
  object-fit: cover;
  width: 110px;
`;

export const TextIconWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 0.4rem;
`;
