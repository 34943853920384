import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ErrorMessage from '@/components/molecules/ErrorMessage';
import IncreaseLimitsTemplate from '@/components/templates/IncreaseLimitsTemplate';
import Loading from '@/components/atoms/Loading';
import SofContent from '@/components/organisms/SofContent';
import UploadSof from '@/components/organisms/UploadSof';
import { useUserData } from '@/context/userContext';
import useUserValidations from '@/hooks/useUserValidations';
import { getUserPendingValidations } from '@/helpers/functions';
import { getAnnualBalance } from '@/services/api';

const PERCENT_REQ_TO_VALIDATE_SOF = 0.8;

function ValidateSof() {
  const history = useHistory();
  const [limitReached, setLimitReached] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { isFetching } = useUserValidations();
  const {
    userData: { validations },
  } = useUserData();

  const [errorMessage, setErrorMessage] = useState('');

  const validationStatus = getUserPendingValidations(validations);

  if (validationStatus === 'pending_sof') {
    history.push('/validations');
  }

  const getCustomerAnnualBalance = async () => {
    try {
      setIsLoading(true);

      const res = await getAnnualBalance();
      const percentageOfAnnualLimit =
        res?.data?.eur_amount / res?.data?.eur_annual_limit;

      if (percentageOfAnnualLimit > PERCENT_REQ_TO_VALIDATE_SOF) {
        setLimitReached(true);
      }
    } catch (err) {
      console.err(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCustomerAnnualBalance();
  }, []);

  return (
    <IncreaseLimitsTemplate returnPath="/wallet">
      {isFetching || isLoading ? (
        <Loading />
      ) : errorMessage ? (
        <ErrorMessage content={errorMessage} />
      ) : limitReached ? (
        <UploadSof setErrorMessage={setErrorMessage} />
      ) : (
        <SofContent />
      )}
    </IncreaseLimitsTemplate>
  );
}

export default ValidateSof;
