import React from 'react';

import PhoneValidationForm from '@/components/organisms/PhoneValidationForm';
import ProfileTemplate from '@/components/templates/ProfileTemplate';

function ValidatePhone() {
  return (
    <ProfileTemplate>
      <PhoneValidationForm />
    </ProfileTemplate>
  );
}

export default ValidatePhone;
