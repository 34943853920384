import React from 'react';
import TwTitle from '@/components/atoms/TwTitle';
import TwText from '@/components/v2/atoms/TwText';

const ErrorStep = () => (
  <div className="w-screen h-screen md:w-none  flex flex-col justify-center">
    <TwTitle className="text-center" size="xl" text="Erro ao fazer validação" />
    <TwText
      className="text-center"
      size="lg"
      text="Tente novamente mais tarde"
    />
  </div>
);
export default ErrorStep;
