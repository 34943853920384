import styled, { css } from 'styled-components';

import { hexToRGBA } from '@/styles/colors';

const HEIGHT_BREAKPOINT = '640px';

export const Container = styled.div`
  align-items: start;
  display: flex;
  height: 100%;
  justify-content: space-around;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-items: center;
  }

  @media screen and (max-height: ${HEIGHT_BREAKPOINT}) {
    align-items: start;
    overflow: hidden auto;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-top: 0;
  }
`;

export const Section = styled.section`
  ${({ theme, hideInMobile }) => css`
    align-items: center;
    animation: goLeftDefault 0.6s;
    color: ${theme.colors.info.primary};
    display: ${hideInMobile ? 'none' : 'flex'};
    flex-direction: column;
    justify-content: start;
    width: 90%;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      display: flex;
      width: 50%;
    }
  `}
`;

export const SectionDivider = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    animation: opacityAnimationDefault 0.8s ease;
    border-left: 1px solid
      ${({ theme }) => hexToRGBA(theme.colors.info.primary, 0.2)};
    display: block;
    min-height: 80%;
  }
`;

export const ContainerSlider = styled.div`
  ${({ theme }) => css`
    display: none;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      display: flex;
      width: 80%;
    }
  `}
`;
