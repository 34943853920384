import styled, { css } from 'styled-components';

export const CardInfoComponentContainer = styled.div`
  ${({ lessBorderBottom, isActive, theme }) => css`
    align-items: center;
    background-color: ${isActive ? theme.colors.greenVpag : '#595e61'};
    border-radius: ${lessBorderBottom ? '25px 25px 0px 0px' : '25px'};
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 285px;
    justify-content: center;
    padding: 20px;
    width: ${isActive ? '400px' : '367px'};
    z-index: 20;

    > h1 {
      font-size: 18px;
      color: ${isActive ? theme.colors.sidebar.bg : 'auto'};
    }

    > p {
      font-size: 15px;
      text-align: center;
      color: ${isActive ? theme.colors.sidebar.bg : 'auto'};
    }
  `};
`;
