import styled from 'styled-components';

export const FlexWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  & > button {
    margin-top: 0.5rem;
  }
`;
