import styled, { css } from 'styled-components';

export const PaginationWrapper = styled.div`
  ${({ theme }) => css`
    background-image: ${`linear-gradient(90deg, ${theme.colors.bgGradientDark.start},${theme.colors.bgGradientDark.end} )`};
    border: none;
    display: flex;
    height: 90px;
    justify-content: center;
    width: 100%;

    ul {
      align-items: center;
      display: flex;

      .page-link {
        border-radius: 4rem;
        color: ${theme.colors.primary};
        font-size: ${theme.font.size.small};
        opacity: 0.6;
        padding: 0 0.7rem;

        &:hover {
          opacity: 1;
        }
      }

      .current-page {
        color: ${theme.colors.primary};
        font: ${theme.font.size.medium} ${theme.font.family.secondary};
        opacity: 1;
      }

      .prev-button,
      .next-button {
        align-items: center;
        color: ${theme.colors.primary};
        display: flex;
        font-size: ${theme.font.size.medium};
        justify-content: center;
        opacity: 0.6;
        padding: 0 1rem;

        &:hover {
          opacity: 1;
        }
      }

      li {
        cursor: pointer;
        list-style-type: none;
      }
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      background-image: none;
      position: static;
    }

    @media screen and (min-width: ${theme.breakpoints.md}) {
      align-items: flex-start;
      height: auto;
      justify-content: center;
      justify-self: center;
      width: 100%;

      ul {
        height: 60px;
      }
    }
  `}
`;
