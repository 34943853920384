import styled from 'styled-components';

const SelectField = styled.select`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.info.primary};
  cursor: pointer;
  font: ${({ theme }) =>
    `${theme.font.size.medium} ${theme.font.family.secondary}`};

  option {
    background-color: ${({ theme }) => theme.colors.bg.darkSecondary};
  }

  &:disabled {
    & {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      text-indent: 1px;
      text-overflow: '';
    }
  }
`;

export default SelectField;
