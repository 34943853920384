import styled, { css } from 'styled-components';

import { ReactComponent as Checked } from '@/assets/checked.svg';

export const Wrapper = styled.section`
  align-items: center;
  display: flex;
  flex: 0.8;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-width: 400px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    border-radius: 20px;
    justify-content: space-between;
    margin: 0;
    max-height: 550px;
    max-width: 260px;
    min-height: 360px;
    min-width: 250px;
  }
`;

export const CodeInput = styled.input`
  ${({ theme }) => css`
    -moz-appearance: textfield; //hide arrows in firefox
    appearance: none;
    background: repeating-linear-gradient(
        to right,
        ${theme.colors.primary} 0 1.5ch,
        transparent 0 2.44ch
      )
      bottom/100% 2px content-box no-repeat;
    border: none;
    color: ${theme.colors.info.primary};
    display: flex;
    font: ${theme.font.size.xLarge} ${theme.font.family.secondary};
    letter-spacing: 0.84em;
    padding: 0 8px;
    margin-top: 2rem;
    width: 270px;

    &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    @media screen and (min-width: ${theme.breakpoints.md}) {
      margin-left: 15px;
      width: 270px;
    }
  `}
`;

export const SuccessIcon = styled(Checked)`
  height: 80px;
  width: 80px;
`;

export const FooterFixed = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 2rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    position: static;
    padding: 0;
  }
`;

export const DescriptionWrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.info.primary};
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
    text-transform: none;

    strong {
      color: ${theme.colors.info.light};
      font-size: ${theme.font.size.small};
    }
  `}
`;
