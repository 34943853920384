import styled from 'styled-components';

export const Wrapper = styled.section`
  align-items: center;
  animation: ${({ isVisible }) => isVisible && 'goLeftDefault 0.8s'};
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 80%;
  max-width: 400px;
  width: 90%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 100%;
  }
`;

export const ItemsWrapper = styled.div`
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: 2rem;
    max-height: 230px;
    overflow-y: auto;
  }
`;

export const ListWrapper = styled.div`
  width: 100%;
`;
