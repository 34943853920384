import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useBalance from '@/hooks/useBalance';
import { useWalletData } from '@/context/walletContext';
import i18n from '@/translate/i18n';
import {
  formatCurrencyWithoutSymbol,
  getCurrencySymbol,
} from '@/helpers/stringFormat';

import {
  Amount,
  ButtonWrapper,
  CurrencyButton,
  ErrorWrapper,
  RefreshButton,
  RefreshIcon,
  Text,
  Wrapper,
} from './styles';
import { useUserData } from '@/context/userContext';
import TwText from '@/components/v2/atoms/TwText';

const MINIMUM_WALLETS_NUMBER_TO_SHOW = 2;
const ROUTES_THAT_SHOULD_REFRESH_BALANCE = [
  '/deposit',
  '/transfer',
  '/withdrawal',
  '/conversion',
  '/transfer-api',
  '/cashback/merchants',
];

function BalanceWithCurrencySwitch() {
  const { pathname } = useLocation();
  const { isFetching, error, getBalance, setError } = useBalance();
  const { userData } = useUserData();
  const {
    walletBalance,
    setWalletData,
    walletData: { selectedWallet, wallets },
  } = useWalletData();

  const balanceError = walletBalance ?? 'error';
  const shouldShowWalletSelector =
    Object.keys(wallets).length >= MINIMUM_WALLETS_NUMBER_TO_SHOW;

  const switchWallet = () => {
    const walletArray = Object.keys(wallets);
    const currIndex = walletArray.indexOf(selectedWallet);
    const newIndex = currIndex + 1;

    if (walletArray[newIndex]) {
      setWalletData((prevState) => ({
        ...prevState,
        selectedWallet: walletArray[newIndex],
      }));
    } else {
      setWalletData((prevState) => ({
        ...prevState,
        selectedWallet: walletArray[0],
      }));
    }
  };

  useEffect(() => {
    if (ROUTES_THAT_SHOULD_REFRESH_BALANCE.includes(pathname)) {
      getBalance();
    }
  }, [pathname, getBalance]);

  return (
    <Wrapper>
      <ButtonWrapper>
        {userData?.status === 5 ? (
          <Text>{i18n.t('topBar.availableBalance')}</Text>
        ) : (
          <TwText color="grass-800" font="arboriaBold" text="Status da conta" />
        )}
        {shouldShowWalletSelector &&
          Object.keys(wallets).map((item) => (
            <CurrencyButton
              key={item}
              type="button"
              active={selectedWallet === item}
              onClick={() => {
                localStorage.setItem('wallet', item);
                setWalletData((prevState) => ({
                  ...prevState,
                  selectedWallet: item,
                }));
              }}
            >
              {item}
            </CurrencyButton>
          ))}
      </ButtonWrapper>
      {isFetching && balanceError === 'error' ? (
        <Amount>
          <RefreshIcon animate={1} />
        </Amount>
      ) : !error ? (
        <Amount onClick={switchWallet} noAmount={!walletBalance}>
          {userData?.status === 5 ? (
            <>
              {getCurrencySymbol('pt-BR', selectedWallet)}
              <span>
                {formatCurrencyWithoutSymbol(
                  walletBalance || 0,
                  selectedWallet,
                  true,
                )}
              </span>
            </>
          ) : (
            <TwText inlineSize="text-3xl" text="Pendente de validação" />
          )}
        </Amount>
      ) : (
        <ErrorWrapper>
          <p>{i18n.t('error.balance.default')}</p>
          <RefreshButton
            type="button"
            disabled={isFetching}
            onClick={() => {
              setError('');
              getBalance();
            }}
          >
            <RefreshIcon />
          </RefreshButton>
        </ErrorWrapper>
      )}
    </Wrapper>
  );
}

export default BalanceWithCurrencySwitch;
