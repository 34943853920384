import styled, { css } from 'styled-components';
import { Form } from 'formik';
import { FaCheck } from 'react-icons/fa';
import { PiWarningCircle } from 'react-icons/pi';

export const FormStyled = styled(Form)`
  align-items: center;
  animation: goLeftDefault 0.8s;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  height: 100%;
  justify-content: space-evenly;
  width: 100%;
`;

export const AnimationWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-top: 4vh;
  width: 100%;
`;

export const PolityConsentWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.primary};
  display: flex;
  flex: 0.2;
  font: ${({ theme }) =>
    `${theme.font.size.small} ${theme.font.family.secondary}`};
  gap: 1.5rem;
  justify-content: center;
  margin: 2vh 0;
  width: 100%;
`;

export const ConsentText = styled.p`
  ${({ theme }) => css`
    font: ${theme.font.size.small} ${theme.font.family.primary};
    display: inline;
    text-align: left;
    text-wrap: balance;
    white-space: initial !important;

    button {
      display: inline;
    }
  `}
`;

export const Footer = styled.footer`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Flex = styled.div`
  display: flex;
  gap: 0.3rem;
  width: fit-content;
  margin: auto;
`;

export const ErrorMessage = styled.p`
  align-self: center;
  color: ${({ theme }) => theme.colors.danger};
  font-size: ${({ theme }) => theme.font.size.small};
  margin-bottom: 15px;
  max-width: 350px;
  text-align: center;
`;

export const CheckboxWrapper = styled.div`
  color: ${({ theme }) => theme.colors.info.primary};
  display: flex;
  font: ${({ theme }) =>
    `${theme.font.size.small} ${theme.font.family.secondary}`};
  flex-direction: column;
  margin: 2vh 0;
  gap: 1rem;
  width: 100%;
  max-width: 600px;
`;

export const ToggleItem = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
`;

export const GridWrapper = styled.div`
  animation: 0.6s opacityAnimationDefault;
  display: grid;
  gap: 0.3rem;
  grid-template-columns: 0.1fr 2fr;
  width: 100%;
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

export const CheckIcon = styled(FaCheck)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const WarningIcon = styled(PiWarningCircle)`
  color: ${({ theme }) => theme.colors.info.light};
`;

export const Pbox = styled.div`
  ${({ theme, hPadding = 0, vPadding = 0 }) => css`
    padding: 0 40px;
    width: 100%;
    @media screen and (min-width: ${theme.breakpoints.lg}) {
      padding: ${vPadding}px ${hPadding}px;
    }
  `}
`;

export const Loader = styled.div`
  height: ${({ isExternal }) => (isExternal ? '100%' : '85%')};
  position: absolute;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  bottom: 0;
`;
