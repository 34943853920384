import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import { getMinimumAmountRequired } from '@/helpers/functions';
import { useWalletData } from '@/context/walletContext';
import { TransactionButton } from '@/components/atoms/Buttons/TransactionButton/styles';
import { Paragraph } from '@/components/atoms/Typography/styles';

import { ContainerFormTransaction } from './styles';
import { formatCurrency } from '@/helpers/stringFormat';

function TransferButton({ isValid, showDetails, consentMessage }) {
  const {
    walletBalance,
    walletData: {
      selectedBonus,
      transactionData,
      selectedWallet,
      transactionLimits,
    },
  } = useWalletData();

  const { max_amount_per_transaction, min_amount_per_transaction } =
    transactionLimits;

  const maxValue = Math.floor(max_amount_per_transaction);
  const minValue = Math.ceil(min_amount_per_transaction);
  const generalMinAmount = getMinimumAmountRequired(minValue, selectedBonus);
  const generalMaxAMount = selectedBonus?.amount
    ? maxValue - Number(selectedBonus.amount)
    : maxValue;

  const { amount } = transactionData;

  const isFreeBonus =
    !selectedBonus?.min_amount_transaction && selectedBonus?.type === 'fixed';
  const insufficientFund = amount > walletBalance;
  const isValueBelowMinimum =
    (!isFreeBonus && !amount) ||
    (!isFreeBonus && amount < generalMinAmount && !insufficientFund) ||
    (isFreeBonus &&
      selectedBonus.amount + amount < minValue &&
      !insufficientFund);
  const isValueAboveMaximum = amount > generalMaxAMount && !insufficientFund;
  const userHasNoLimitAvailable = maxValue === 0 && !insufficientFund;
  const isValidValue = !isValueBelowMinimum && !isValueAboveMaximum;

  return (
    <ContainerFormTransaction>
      {!showDetails && (
        <div>
          {isValueAboveMaximum ? (
            <Paragraph color="primary">
              *
              {`${i18n.t('transfer.maxValue')} ${formatCurrency(
                maxValue,
                selectedWallet,
                true,
              )}`}
            </Paragraph>
          ) : (
            <Paragraph color="primary">
              *{i18n.t('transfer.minValue')}
              {formatCurrency(generalMinAmount, selectedWallet, true)}
            </Paragraph>
          )}
        </div>
      )}
      <TransactionButton
        type="submit"
        disabled={!isValidValue || !isValid || insufficientFund}
        id={showDetails && !consentMessage ? 'transfer-button' : 'next-button'}
      >
        {consentMessage
          ? i18n.t('transfer.consentActivate')
          : isFreeBonus && !showDetails
          ? i18n.t('transfer.useBonus')
          : !isValid
          ? i18n.t('transfer.fillIn')
          : isValueBelowMinimum
          ? `${i18n.t('transfer.next')}`
          : userHasNoLimitAvailable
          ? i18n.t('error.userLimits.limitUnvailable')
          : insufficientFund
          ? i18n.t('withdrawal.unavailableValue')
          : showDetails
          ? i18n.t('transfer.confirm')
          : i18n.t('transfer.next')}
      </TransactionButton>
    </ContainerFormTransaction>
  );
}

TransferButton.propTypes = {
  isValid: PropTypes.bool,
  showDetails: PropTypes.func,
}.isRequired;

export default TransferButton;
