import styled, { css } from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
`;

export const TabWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 530px;
  width: 100%;
`;

export const Tab = styled.span`
  ${({ theme, selected }) => css`
    border-bottom: ${selected ? `2px solid ${theme.colors.primary}` : ''};
    color: ${selected ? theme.colors.info.light : theme.colors.info.inactive};
    cursor: pointer;
    font: ${theme.font.size.xSmall} ${theme.font.family.secondary};
    padding-bottom: 0.3rem;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.3s ease;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      font-size: ${theme.font.size.small};
    }
  `}
`;

export const ContentWrapper = styled.article`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.colors.info.primary};
    display: flex;
    flex-direction: column;
    font: ${theme.font.size.xSmall} ${theme.font.family.secondary};
    max-height: 90%;
    overflow-x: hidden;
  `}
`;

export const TableWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 80%;
  width: 100%;
  height: fit-content;
`;

export const Table = styled.table`
  margin-top: 2rem;
  width: 100%;

  tr {
    border: 1px solid black;
  }

  td {
    border: 1px solid ${({ theme }) => theme.colors.info.primary};
    padding: 2rem 0;

    &:first-child {
      max-width: 200px;
    }

    p {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0 1rem;
      width: 100%;
    }
  }
`;
