import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';

import 'tippy.js/dist/tippy.css';

function Tooltip({ children, ...rest }) {
  return <Tippy {...rest}>{children}</Tippy>;
}

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Tooltip;
