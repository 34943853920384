import React from 'react';
import PropTypes from 'prop-types';

import vpag from '@/styles/themes/vpag';
import Button from './styles';

function WalletButton({ selected, content, onClick, icon: Icon, transparent }) {
  return (
    <Button
      type="button"
      selected={selected}
      onClick={onClick}
      transparent={transparent}
      id="mobile-fixed-bottom-button"
    >
      <Icon fill={selected ? vpag.colors.dark : vpag.colors.light} />
      {content}
    </Button>
  );
}

WalletButton.propTypes = {
  selected: PropTypes.bool,
  content: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func,
}.isRequired;

export default WalletButton;
