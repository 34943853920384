import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.dark};
  border-radius: 10px;
  padding: 0.5rem;
`;

export const ImageWrapper = styled.div`
  max-height: 80vh;
  max-width: 85vw;
  overflow: hidden auto;

  img {
    max-width: 80vw;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    img {
      max-width: 900px;
    }
  }
`;
