import React from 'react';
import PropTypes from 'prop-types';

import BonusIcon from '@/components/atoms/BonusIcon';
import i18n from '@/translate/i18n';
import Wrapper from './styles';

const ICON_SIZE = 16;

function BonusTitle({ bonus }) {
  const isFreeBonus = !bonus?.min_amount_transaction && bonus?.type === 'fixed';

  return (
    <Wrapper type={isFreeBonus ? 'free' : bonus?.type}>
      <BonusIcon bonus={bonus} size={ICON_SIZE} onlyIcon />
      <p>
        {i18n.t([
          `bonusTitle.${isFreeBonus ? 'free' : bonus?.type}`,
          'bonus.bonus',
        ])}
      </p>
    </Wrapper>
  );
}

BonusTitle.propTypes = {
  bonus: PropTypes.shape({
    type: PropTypes.string,
    amount: PropTypes.number,
    title: PropTypes.string,
  }),
}.isRequired;

export default BonusTitle;
