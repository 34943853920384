import React from 'react';

import i18n from '@/translate/i18n';
import {
  Content,
  InfoWrapper,
  Text,
  TextWrapper,
  Title,
} from '@/components/molecules/Modal/Messages/InstructionStyles/styles';

function DepositInstructions() {
  return (
    <Content>
      <Title>{i18n.t('depositIntructions.title')}</Title>
      <InfoWrapper>
        <TextWrapper>
          {i18n.t('depositIntructions.transfer')}
          <Text>{i18n.t('depositIntructions.transferDescription')}</Text>
        </TextWrapper>
      </InfoWrapper>

      <InfoWrapper>
        <TextWrapper>
          {i18n.t('depositIntructions.wait')}
          <Text>{i18n.t('depositIntructions.waitDescription')}</Text>
        </TextWrapper>
      </InfoWrapper>

      <InfoWrapper>
        <TextWrapper>{i18n.t('depositIntructions.disclaimer')}</TextWrapper>
      </InfoWrapper>
    </Content>
  );
}

export default DepositInstructions;
