import React from 'react';
import PropTypes from 'prop-types';
import Arrow from '@/assets/backArrow.svg';
import { LinkStyled } from './styles';

const SIZE_IMG = 25;
function BackButton({ to, sizeIcon = SIZE_IMG, text, onClick }) {
  return (
    <LinkStyled to={to} onClick={onClick}>
      <img src={Arrow} alt="back" width={sizeIcon} height={sizeIcon} />
      {text}
    </LinkStyled>
  );
}

BackButton.propTypes = {
  to: PropTypes.string,
  sizeIcon: PropTypes.number,
  text: PropTypes.string,
  onClick: PropTypes.func,
}.isRequired;

export default BackButton;
