import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import i18n from '@/translate/i18n';
import SimpleCashbackBlueIcon from '@/assets/simpleCashbackBlueIcon.svg';
import SimpleCashbackWhiteIcon from '@/assets/simpleCashbackWhiteIcon.svg';
import VpagIconWhite from '@/assets/vpagIconWhite.svg';
import VpagIconGreen from '@/assets/vpagIconGreen.svg';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';
import HeadModal from '@/components/molecules/HeadModal';
import useDeviceWidth from '@/hooks/useDeviceWidth';

import {
  Container,
  BannerCashbackBody,
  BannerCashbackInfoContainer,
  IconsContainer,
  ImageContainer,
  SimpleImage,
  VerticalDivider,
  InfoContainer,
  ResumeInfo,
  ButtonStyled,
  TitleWhite,
  SpanStyled,
} from './style';

const GENERAL_USER_TYPE = 'general';
const VPLUS_USER_TYPE = 'vplus';
const ONE_HUNDRED = 100;
const ZERO = 0;
const PARAGRAPH_SIZE = '12px';

function CashbackPixInfo({
  handleBackStep,
  vpagSelected,
  merchantSelected,
  handleNextStep,
  setVpagSelected,
}) {
  const { isMobileLayout } = useDeviceWidth();

  const cashback = merchantSelected.cashbacks.find((item) =>
    vpagSelected
      ? item.user_type === VPLUS_USER_TYPE
      : item.user_type === GENERAL_USER_TYPE,
  );

  const { percent = ZERO, limit = ZERO } = cashback || {};
  return (
    <Container isMobile={isMobileLayout}>
      <HeadModal handleBackStep={handleBackStep} />
      <BannerCashbackBody isMobileLayout={isMobileLayout}>
        <Title size="medium" className="text-xl md:text-2xl text-white">
          <Trans i18nKey="cashbackModalInfo.title" />
          {merchantSelected.name}
        </Title>
        <div>
          <BannerCashbackInfoContainer vpag={vpagSelected}>
            {vpagSelected ? (
              <IconsContainer>
                <ImageContainer onClick={() => setVpagSelected(false)}>
                  <SimpleImage
                    src={SimpleCashbackWhiteIcon}
                    alt="icon cashback"
                  />
                </ImageContainer>
                <VerticalDivider />
                <ImageContainer active vpagSelected>
                  <SimpleImage src={VpagIconGreen} alt="icon cashback" />
                </ImageContainer>
              </IconsContainer>
            ) : (
              <IconsContainer>
                <ImageContainer active>
                  <SimpleImage
                    src={SimpleCashbackBlueIcon}
                    alt="icon cashback"
                  />
                </ImageContainer>
                <VerticalDivider />
                <ImageContainer onClick={() => setVpagSelected(true)}>
                  <SimpleImage src={VpagIconWhite} alt="icon cashback" />
                </ImageContainer>
              </IconsContainer>
            )}
            {vpagSelected ? (
              <>
                <TitleWhite size="medium">
                  {i18n.t('cashbackModalInfo.titleCashbackVpag')}
                </TitleWhite>
                <InfoContainer>
                  {percent > ZERO && limit > ZERO && (
                    <ul>
                      <li>
                        <Paragraph size="medium">
                          <Trans
                            i18n={i18n}
                            i18nKey="cashbackModalInfo.simpleAdvantagesOne"
                            values={{
                              percent: `${percent * ONE_HUNDRED}`,
                              limit,
                            }}
                          />
                        </Paragraph>
                      </li>
                    </ul>
                  )}
                </InfoContainer>
                <ResumeInfo vpag>
                  <Paragraph size={PARAGRAPH_SIZE}>
                    {i18n.t('cashbackModalInfo.resumeVpagPartOne')}
                    <span>{merchantSelected.name}</span>
                    {i18n.t('cashbackModalInfo.resumeVpagPartTwo')}
                    <SpanStyled to="/club-vplus">Sócio V+</SpanStyled>
                  </Paragraph>
                </ResumeInfo>
              </>
            ) : (
              <>
                <TitleWhite size="medium">
                  {i18n.t('cashbackModalInfo.titleCashbackSimple')}
                </TitleWhite>
                <InfoContainer>
                  {percent > ZERO && limit > ZERO && (
                    <ul>
                      <li>
                        <Paragraph size="medium">
                          <Trans
                            i18n={i18n}
                            i18nKey="cashbackModalInfo.simpleAdvantagesOne"
                            values={{
                              percent: `${percent * ONE_HUNDRED}`,
                              limit,
                            }}
                          />
                        </Paragraph>
                      </li>
                    </ul>
                  )}
                </InfoContainer>
                <ResumeInfo>
                  <Paragraph size={PARAGRAPH_SIZE}>
                    <Trans
                      i18n={i18n}
                      i18nKey="cashbackModalInfo.simpleAdvantagesThird"
                    />
                  </Paragraph>
                </ResumeInfo>
              </>
            )}
          </BannerCashbackInfoContainer>
        </div>
        <ButtonStyled className="w-full md:w-auto" onClick={handleNextStep}>
          {i18n.t('cashbackModalInfo.next')}
        </ButtonStyled>
      </BannerCashbackBody>
    </Container>
  );
}

CashbackPixInfo.propTypes = {
  handleBackStep: PropTypes.func,
  handleNextStep: PropTypes.func,
  vpagSelected: PropTypes.bool,
  merchantSelected: PropTypes.object,
}.isRequired;
export default CashbackPixInfo;
