import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import i18n from '@/translate/i18n';
import MainTemplate from '@/components/templates/MainTemplate';
import { ContainerContent } from '../Cashback/styles';
import { Title } from '@/components/molecules/WalletHeader/style';
import CarouselMech from '@/components/organisms/CarouselMech';
import { getListMerchantsCashback, getTrackerByMerchant } from '@/services/api';
import BackButton from '@/components/atoms/BackButton';
import Modal from '@/components/molecules/Modal';
import CashbackPix from '@/components/molecules/Modal/Messages/CashbackPix';
import SkeletonCarouselMech from '@/components/organisms/CarouselMech/SkeletonCarouselMech';
import CashbackPixInfo from '@/components/molecules/Modal/Messages/CashbackPixInfo';
import OptionCashback from '@/components/atoms/OptionCashback';
import SimpleCashback from '@/assets/simpleCashback.svg';
import VpagCashback from '@/assets/vpagPlus.svg';
import { CashbackPixCreateAccount } from '@/components/molecules/Modal/Messages/CashbackPixCreateAccount';
import CashInsertUsername from '@/components/molecules/Modal/Messages/CashInsertUsername';
import { CashInsertValueTransation } from '@/components/molecules/Modal/Messages/CashInsertValueTransation';
import CashbackDetail from '@/components/molecules/Modal/Messages/CashbackDetail';
import CashbackFinished from '@/components/molecules/Modal/Messages/CashbackFinished';
import Loading from '@/components/atoms/Loading';

import useDeviceWidth from '@/hooks/useDeviceWidth';
import CurrentBalance from '@/components/molecules/CurrentBalance';
import { LOCAL_STORAGE_KEY_USERNAME } from '@/helpers/constants';
import { ActiveMechButton, BackButtonContent, CenterContainer } from './styles';
import { TwModal } from '@/components/v2/atoms/TwModal';
import ErrorStep from '@/components/molecules/Modal/Messages/ErrorStep';

export const CashbackModalStep = {
  Initial: 0,
  InfoPix: 1,
  CreateAccount: 2,
  InserUsername: 3,
  InserValueTransation: 4,
  Resume: 5,
  Process: 6,
  Finished: 7,
  ErrorProcess: 8,
};

const FIRST_ELEMENT = 0;
const ZERO = 0;
const WAIT_TIME = 500;

function CashbackChooseMech() {
  const [isSmallHeight, setIsSmallHeight] = useState(false);
  const [merchantsAvaible, setMerchantsAvaible] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState({});
  const [cashbackModalStep, setCashbackModalStep] = useState(
    CashbackModalStep.Initial,
  );
  const [currency, setCurrency] = useState('BRL');
  const [vpagSelected, setVpagSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [errorProcess, setErrorProcess] = useState('');
  const { isMobileLayout } = useDeviceWidth();
  const [process, setProcess] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isloading, setIsLoading] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);

  const merchantName = useMemo(
    () => (selectedMerchant ? selectedMerchant.name : ''),
    [selectedMerchant],
  );

  const getTrackers = async (item) => {
    const res = await getTrackerByMerchant(item.id);
    return res.data;
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const skipHaveUsername = () => {
    if (selectedMerchant.usernames[ZERO]?.username) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY_USERNAME,
        selectedMerchant.usernames[ZERO].username,
      );
      setCashbackModalStep(CashbackModalStep.InserValueTransation);
    } else {
      setCashbackModalStep(CashbackModalStep.CreateAccount);
    }
    scrollToTop();
  };

  const skipHaveUsernameBack = () => {
    if (selectedMerchant.usernames[ZERO]?.username) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY_USERNAME,
        selectedMerchant.usernames[ZERO].username,
      );
      setCashbackModalStep(CashbackModalStep.InfoPix);
    } else {
      setCashbackModalStep(CashbackModalStep.InserUsername);
    }
  };

  async function updateItemsWithTrackers(data) {
    try {
      const updatedItems = await Promise.all(
        data.map(async (item) => {
          const trackers = await getTrackers(item);
          if (trackers && trackers.length > ZERO) {
            return { ...item, url: trackers[ZERO].url };
          }
          return item;
        }),
      );
      return updatedItems;
    } catch {
      setError(i18n.t('cashback.errorLoadMerchants'));
      return [];
    }
  }

  const handleCloseModalTutorial = () => {
    setShowVideoTutorial(false);
  };

  const getMerchants = async () => {
    try {
      setIsLoading(true);
      const res = await getListMerchantsCashback();
      const dt = res.data.filter(
        (item) =>
          item.usernames.length === ZERO ||
          item.usernames[ZERO].cashback_active_by_customer === false,
      );
      const updatedItems = await updateItemsWithTrackers(dt);
      setMerchantsAvaible(updatedItems);
      setSelectedMerchant(updatedItems[FIRST_ELEMENT]);
    } catch {
      setError(i18n.t('cashback.errorLoadMerchants'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setVpagSelected(false);
    setErrorProcess('');
    setTimeout(
      () => setCashbackModalStep(CashbackModalStep.Initial),
      WAIT_TIME,
    );
  };

  const CurrentModalStep = {
    [CashbackModalStep.Initial]: (
      <CashbackPix
        merchantName={merchantName}
        handleCloseModal={handleCloseModal}
        handleOpenModalTutorial={() => setShowVideoTutorial(true)}
      >
        <OptionCashback
          textBody={i18n.t('cashbackOptions.simple')}
          srcIcon={SimpleCashback}
          onClick={() => {
            setVpagSelected(false);
            setCashbackModalStep(CashbackModalStep.InfoPix);
            scrollToTop();
          }}
        />
        <OptionCashback
          isVpag
          textBody={i18n.t('cashbackOptions.vpag')}
          srcIcon={VpagCashback}
          onClick={() => {
            setVpagSelected(true);
            setCashbackModalStep(CashbackModalStep.InfoPix);
            scrollToTop();
          }}
        />
      </CashbackPix>
    ),
    [CashbackModalStep.InfoPix]: (
      <CashbackPixInfo
        handleBackStep={() => setCashbackModalStep(CashbackModalStep.Initial)}
        handleNextStep={() => {
          skipHaveUsername();
          scrollToTop();
        }}
        vpagSelected={vpagSelected}
        merchantSelected={selectedMerchant}
        setVpagSelected={setVpagSelected}
      />
    ),
    [CashbackModalStep.CreateAccount]: (
      <CashbackPixCreateAccount
        handleBackStep={() => {
          setCashbackModalStep(CashbackModalStep.InfoPix);
          scrollToTop();
        }}
        merchantSelected={selectedMerchant}
        handleNextStep={() => {
          setCashbackModalStep(CashbackModalStep.InserUsername);
          scrollToTop();
        }}
      />
    ),
    [CashbackModalStep.InserUsername]: (
      <CashInsertUsername
        handleBackStep={() => {
          setCashbackModalStep(CashbackModalStep.CreateAccount);
          scrollToTop();
        }}
        merchantName={merchantName}
        merchantURLCreateAccount={selectedMerchant ? selectedMerchant.url : ''}
        handleNextStep={() => {
          setCashbackModalStep(CashbackModalStep.InserValueTransation);
          scrollToTop();
        }}
      />
    ),
    [CashbackModalStep.InserValueTransation]: (
      <CashInsertValueTransation
        merchantName={merchantName}
        merchantSelected={selectedMerchant}
        handleBackStep={skipHaveUsernameBack}
        handleNextStep={() => {
          setCashbackModalStep(CashbackModalStep.Resume);
          scrollToTop();
        }}
        setCurrency={setCurrency}
      />
    ),
    [CashbackModalStep.Resume]: (
      <CashbackDetail
        handleBackStep={() => {
          setCashbackModalStep(CashbackModalStep.InserValueTransation);
        }}
        merchantName={merchantName}
        currentMerchant={selectedMerchant}
        isVpag={vpagSelected}
        currentCurrency={currency}
        setProcess={setProcess}
        setError={setErrorProcess}
        merchantSelected={selectedMerchant}
        firstTime
      />
    ),
    [CashbackModalStep.Process]: (
      <div className="w-screen h-screen flex justify-center">
        <Loading />
      </div>
    ),

    [CashbackModalStep.Finished]: (
      <CashbackFinished
        merchantSelected={selectedMerchant}
        onClose={handleCloseModal}
      />
    ),
    [CashbackModalStep.ErrorProcess]: <ErrorStep />,
  };

  useEffect(() => {
    getMerchants();
    setCashbackModalStep(CashbackModalStep.Initial);
  }, []);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    if (process) {
      setCashbackModalStep(CashbackModalStep.Process);
    } else if (errorProcess === '') {
      setCashbackModalStep(CashbackModalStep.Finished);
    } else {
      setCashbackModalStep(CashbackModalStep.ErrorProcess);
    }
  }, [process]);

  useEffect(() => {
    const mediaQuery = window.matchMedia(
      '(max-height: 570px) and (max-width: 480px)',
    );

    const handleMediaQueryChange = (event) => {
      setIsSmallHeight(event.matches);
    };

    mediaQuery.addEventListener('change', handleMediaQueryChange);

    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  return (
    <MainTemplate
      hideHeader={isMobileLayout}
      containerProps={{ gradient: true }}
      headerProps={{ mobileFullVersion: false }}
    >
      <div className="w-full py-5 md:px-10 hidden md:block">
        <BackButtonContent>
          <BackButton to="/cashback" text="Voltar" />
        </BackButtonContent>
      </div>
      <ContainerContent>
        <header className="w-full flex items-center mt-10 md:mt-5  px-5 relative">
          <div className="w-full absolute md:relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Title fontSize={isMobileLayout ? '18px' : '32px'}>
              {i18n.t('cashback.title')}
            </Title>
          </div>
          <div className={`block md:hidden ${showModal ? 'z-0' : 'z-10'}`}>
            <BackButton to="/cashback" />
          </div>
        </header>
        {isMobileLayout && <CurrentBalance />}
        <p className="font-bold text-xl md:text-2xl text-white w-1/2 md:w-auto">
          {i18n.t('cashback.chooseMerchant')}
        </p>
        <CenterContainer>
          {isloading ? (
            <SkeletonCarouselMech />
          ) : error ? (
            <p>{error}</p>
          ) : (
            <CarouselMech
              data={merchantsAvaible}
              setSelectedMerchant={setSelectedMerchant}
              handleClickItem={() => setShowModal(true)}
            />
          )}
        </CenterContainer>
        <div className="pb-10 md:pb-10">
          <ActiveMechButton
            loading={isloading}
            onClick={() => {
              setShowModal(true);
            }}
            className="!font-extrabold"
            disabled={selectedMerchant.usernames?.[ZERO]?.cashback_blocked}
          >
            {i18n.t('cashback.activeMerchant')}
            {selectedMerchant ? selectedMerchant.name : ''}
          </ActiveMechButton>
        </div>
        {!isSmallHeight ? (
          <Modal
            centralized
            closeButtonWhite
            handleClose={handleCloseModal}
            showModal={showModal}
            height={isMobileLayout ? '100vh' : '621px'}
            width={isMobileLayout ? '100vw' : '451px'}
            hiddenCloseButton={isMobileLayout}
          >
            {CurrentModalStep[cashbackModalStep]}
          </Modal>
        ) : (
          showModal &&
          createPortal(
            <dialog
              open={showModal}
              className="absolute top-0 left-0 z-50 bg-shadow-800 "
            >
              {CurrentModalStep[cashbackModalStep]}
            </dialog>,
            document.body,
          )
        )}

        <TwModal
          isOpen={showVideoTutorial}
          onClose={handleCloseModalTutorial}
          classList="md:!h-fit md:!w-fit bg-transparent "
          classListContainer="z-[100] "
          classListChildren="md:mr-10 md:mb-5"
          closeButtonWhite
        >
          {isMobileLayout ? (
            <iframe
              className="w-full h-64 md:w-[697px] md:h-[400px] rounded-lg shadow-lg"
              src="https://www.youtube.com/embed/7e7y4Ks3Kvc?si=Pz0_3RkEi8DiKrXl"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          ) : (
            <iframe
              className="w-full h-64 md:w-[697px] md:h-[400px] rounded-lg shadow-lg"
              src="https://www.youtube.com/embed/8U0H7kZMEnE?si=fksdUzFpy9p4Q86R"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          )}
        </TwModal>
      </ContainerContent>
    </MainTemplate>
  );
}

export default CashbackChooseMech;
