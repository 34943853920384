import i18n from '@/translate/i18n';

export default {
  default: {
    title: i18n.t('validations.pending.title'),
    subtitle: i18n.t('validations.pending.subtitle'),
    description: i18n.t('validations.pending.description'),
    button: i18n.t('validations.pending.button'),
    path: '/wallet',
  },
  pending: {
    title: i18n.t('validations.pending.title'),
    subtitle: i18n.t('validations.pending.subtitle'),
    description: i18n.t('validations.pending.description'),
    button: i18n.t('validations.pending.button'),
    path: '/wallet',
  },
  pending_sof: {
    title: i18n.t('validations.pending_sof.title'),
    subtitle: i18n.t('validations.pending_sof.subtitle'),
    description: i18n.t('validations.pending_sof.description'),
    button: i18n.t('validations.pending_sof.button'),
    path: '/wallet',
  },
  rejected_sof: {
    title: i18n.t('validations.rejected_sof.title'),
    subtitle: i18n.t('validations.rejected_sof.subtitle'),
    description: i18n.t('validations.rejected_sof.description'),
    button: i18n.t('validations.rejected_sof.button'),
    path: '/validations/sof',
  },
  completed: {
    title: i18n.t('validations.completed.title'),
    subtitle: i18n.t('validations.completed.subtitle'),
    description: i18n.t('validations.completed.description'),
    button: i18n.t('validations.completed.button'),
    path: '/wallet',
  },
  ocr: {
    title: i18n.t('validations.ocr.title'),
    subtitle: i18n.t('validations.ocr.subtitle'),
    description: i18n.t('validations.ocr.description'),
    button: i18n.t('validations.ocr.button'),
    path: '/validations/identity',
  },
  ocr_or_facematch_rejected: {
    title: i18n.t('validations.ocr_or_facematch_rejected.title'),
    subtitle: i18n.t('validations.ocr_or_facematch_rejected.subtitle'),
    description: i18n.t('validations.ocr_or_facematch_rejected.description'),
    button: i18n.t('validations.ocr_or_facematch_rejected.button'),
    path: '/validations/identity',
  },
  address: {
    title: i18n.t('validations.address.title'),
    subtitle: i18n.t('validations.address.subtitle'),
    description: i18n.t('validations.address.description'),
    button: i18n.t('validations.address.button'),
    path: '/validations/address',
  },
};
