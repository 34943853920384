import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import Article from '@/components/organisms/Article';

import {
  Container,
  ContentWrapper,
  Tab,
  TabWrapper,
  Table,
  TableWrapper,
} from './styles';

import {
  privacyPolicyContent,
  tableFields,
  termsAndConditionContent,
} from './data';

const TABS = ['termsAndConditions', 'privacyPolicy'];
const POSITION_OF_PRIVACI_POLICY_IN_TAB = 1;

function TermsAndConditionsAndPrivacyPolicy({ selectedTopic }) {
  const [selectedTab, setSelectedTab] = useState(selectedTopic);

  useEffect(() => {
    setSelectedTab(selectedTopic);
  }, [selectedTopic]);

  return (
    <Container>
      <TabWrapper>
        {TABS.map((tabTitle) => (
          <Tab
            key={tabTitle}
            selected={selectedTab === tabTitle}
            onClick={() => setSelectedTab(tabTitle)}
          >
            {i18n.t(`${tabTitle}.title`)}
          </Tab>
        ))}
      </TabWrapper>
      <ContentWrapper>
        <Article
          textContent={
            selectedTab === 'termsAndConditions'
              ? termsAndConditionContent
              : privacyPolicyContent
          }
        />
        {selectedTab === TABS[POSITION_OF_PRIVACI_POLICY_IN_TAB] && (
          <TableWrapper>
            <h1>{i18n.t('privacyPolicy.table.title')}</h1>
            <Table>
              {tableFields.map(({ description, content }) => (
                <tr key={description}>
                  <td>
                    <p>{description}</p>
                  </td>
                  <td>
                    <p>{content}</p>
                  </td>
                </tr>
              ))}
            </Table>
          </TableWrapper>
        )}
      </ContentWrapper>
    </Container>
  );
}

TermsAndConditionsAndPrivacyPolicy.propTypes = {
  selectedTopic: PropTypes.string,
};

export default TermsAndConditionsAndPrivacyPolicy;
