import { useState, useEffect, useCallback } from 'react';

import { listCustomerWallets } from '@/services/api';
import { useWalletData } from '@/context/walletContext';

const useBalance = () => {
  const [error, setError] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  const { setWalletData, walletData } = useWalletData();

  const getBalance = useCallback(
    async (signal) => {
      try {
        setIsFetching(true);
        const userId = localStorage.getItem('id');
        const res = await listCustomerWallets(userId, signal);
        const walletInReal = res.data.filter(
          ({ currency }) => currency?.iso === 'BRL',
        );

        const userWallets = {};
        res.data.forEach((el) => {
          userWallets[el.currency.iso] = el;
        });

        setWalletData((prevState) => ({
          ...prevState,
          balance: walletInReal[0].balance,
          wallets: userWallets,
        }));

        setError('');
      } catch (err) {
        const message = err?.response?.data?.message;
        const data = err?.response?.data?.data;

        if (data?.owner_id?.includes('validation.exists')) {
          setWalletData((prevState) => ({
            ...prevState,
            balance: 0,
            wallets: { BRL: { balance: 0 } },
          }));
          setError('');
        } else {
          setWalletData((prevState) => ({
            ...prevState,
            balance: 0,
            wallets: {},
          }));
          setError(message);
        }
      } finally {
        setIsFetching(false);
      }
    },
    [setWalletData],
  );

  useEffect(() => {
    const controller = new AbortController();

    if (!Object.keys(walletData?.wallets).length && !error) {
      getBalance(controller.signal);
    }

    return () => controller.abort();
  }, []);

  return { isFetching, error, getBalance, setError };
};

export default useBalance;
