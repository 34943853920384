import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import ErrorMessage from '@/components/molecules/ErrorMessage';
import IdwallSdk from '@/components/organisms/IdwallSdk';
import IncreaseLimitsTemplate from '@/components/templates/IncreaseLimitsTemplate';
import { useUserData } from '@/context/userContext';
import useUserValidations from '@/hooks/useUserValidations';
import Loading from '@/components/atoms/Loading';
import { getUserPendingValidations } from '@/helpers/functions';

const identityValidationSlugs = ['ocr', 'ocr_or_facematch_rejected'];

function ValidateId() {
  const history = useHistory();

  const { isFetching } = useUserValidations();
  const {
    userData: { validations },
  } = useUserData();

  const validationStatus = getUserPendingValidations(validations);

  if (!identityValidationSlugs.includes(validationStatus)) {
    history.push('/validations');
  }

  const [errorMessage, setErrorMessage] = useState('');

  return (
    <IncreaseLimitsTemplate returnPath="/validations">
      {isFetching ? (
        <Loading />
      ) : errorMessage ? (
        <ErrorMessage content={errorMessage} />
      ) : (
        <IdwallSdk setErrorMessage={setErrorMessage} />
      )}
    </IncreaseLimitsTemplate>
  );
}

export default ValidateId;
