import { useState } from 'react';

import { getExchangeRate } from '@/services/api';
import { useWalletData } from '@/context/walletContext';

const useExchangeRate = (fromCurrency, toCurrency) => {
  const { setWalletData } = useWalletData();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoadingExchangeRate, setLoadingExchangeRate] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(null);

  const getExchangeData = async () => {
    try {
      setLoadingExchangeRate(true);
      const payload = {
        from_currency_iso: fromCurrency,
        to_currency_iso: toCurrency,
      };
      const res = await getExchangeRate(payload);
      const calculatedRate = res.data.dividend_rate / res.data.divisor_rate;
      const multiplierRate =
        res?.data?.divisor_iso === fromCurrency
          ? calculatedRate
          : 1 / calculatedRate;

      setWalletData((prevState) => ({
        ...prevState,
        transactionData: {
          ...prevState?.transactionData,
          exchangeRates: {
            ...prevState?.transactionData?.exchangeRates,
            [`BRL-${toCurrency}`]: multiplierRate,
          },
        },
      }));
      setExchangeRate(multiplierRate);
    } catch (err) {
      const message = err?.response?.data?.message;
      setErrorMessage(message);
    } finally {
      setLoadingExchangeRate(false);
    }
  };
  return { exchangeRate, getExchangeData, isLoadingExchangeRate, errorMessage };
};

export default useExchangeRate;
