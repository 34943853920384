import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.primary};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  text-align: center;
  width: 80%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: space-between;
    max-height: 550px;
  }
`;

export const InputWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ImagePreview = styled.img`
  max-height: 180px;
  max-width: 80%;
`;

export const PreviewButton = styled.button`
  background-color: transparent;
  border: 2px dashed ${({ theme }) => theme.colors.bg.secondary};
  color: ${({ theme }) => theme.colors.info.primary};
  margin-bottom: 1rem;
  padding: 1rem 2rem;
  text-decoration: underline;
`;
