import React, { useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import Input from '@/components/atoms/Inputs/Input';
import SubmitButton from '@/components/atoms/Buttons/SubmitButton';
import i18n from '@/translate/i18n';
import { Button } from '@/components/atoms/Buttons/Button/styles';
import { Paragraph } from '@/components/atoms/Typography/styles';
import { recoverPassword } from '@/services/api';
import { RECOVER_PASSWORD_SCHEMA } from '@/helpers/schemas';

import {
  ButtonWrapper,
  EmailImage,
  ErrorMessage,
  ModalWrapper,
  StyledForm,
  SuccessWrapper,
} from './style';

function RecoverPassword({ setShowModal }) {
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [errorMessage, setErrormessage] = useState('');

  return (
    <ModalWrapper>
      {!submitted && (
        <>
          <Paragraph color="light">
            {i18n.t('signin.recoverPassDescription')}
          </Paragraph>
          <Formik
            validationSchema={RECOVER_PASSWORD_SCHEMA}
            initialValues={{
              newEmail: '',
            }}
            initialErrors={{
              newEmail: 'Required',
            }}
            onSubmit={async ({ newEmail }) => {
              try {
                await recoverPassword({ email: newEmail });

                setSubmitted(true);
              } catch (err) {
                const message = err?.response?.data?.message;
                setSubmitError(true);
                setErrormessage(
                  i18n.t([
                    `error.resetPassword.${message}`,
                    'error.resetPassword.unspecific',
                  ]),
                );
              }
            }}
          >
            {({ values, isValid, isSubmitting }) => (
              <StyledForm
                onChange={() => {
                  setSubmitError(false);
                }}
              >
                <Input
                  id="newEmail"
                  name="newEmail"
                  type="text"
                  placeholder={i18n.t('signin.emailPlaceholder')}
                  value={values.newEmail}
                  color="light"
                  secondaryTheme
                  disableAutoComplete
                />
                {submitError && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <ButtonWrapper>
                  <SubmitButton
                    before={i18n.t('signin.resetPasswordBtnLabel')}
                    after={i18n.t('signin.resetPasswordBtnLabel')}
                    submitText={i18n.t('signin.loadingButtonLabel')}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                  />
                </ButtonWrapper>
              </StyledForm>
            )}
          </Formik>
        </>
      )}

      {submitted && (
        <>
          <Paragraph color="light">
            {i18n.t('signin.followInstructions')}
          </Paragraph>

          <SuccessWrapper>
            <EmailImage />
            <Button
              onClick={() => {
                setShowModal(false);
              }}
            >
              {i18n.t('signin.iAgree')}
            </Button>
          </SuccessWrapper>
        </>
      )}
    </ModalWrapper>
  );
}

RecoverPassword.propTypes = {
  setShowModal: PropTypes.func,
}.isRequired;

export default RecoverPassword;
