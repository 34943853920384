import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

const WalletContext = React.createContext({});

const defaultState = {
  balance: 0,
  merchants: [],
  minimalTransactions: [],
  minimalTransfers: [],
  selectedWallet: localStorage.getItem('wallet') || 'BRL',
  selectedMerchant: {},
  transactionData: {
    amount: 0,
    method: {
      id: 1,
      name: 'Pix',
      slug: 'pix',
    },
  },
  selectedBonus: null,
  wallets: {},
  shouldRenderFirstDepositBtn: false,
  promotions: [],
  transactionLimits: {
    min_amount_per_transaction: 0,
    max_amount_per_transaction: 0,
  },
};

export default function WalletProvider({ children }) {
  const [walletData, setWalletData] = useState(defaultState);
  const [isLoading, setIsLoading] = useState(false);

  const updatWalletData = (data) => {
    setWalletData((prev) => ({ ...prev, data }));
  };

  const resetWalletData = () => {
    setWalletData({
      ...defaultState,
      selectedWallet: localStorage.getItem('wallet') || 'BRL',
    });
  };

  const { wallets, selectedWallet } = walletData;

  const walletBalance = wallets[selectedWallet]?.balance;

  return (
    <WalletContext.Provider
      value={{
        walletData,
        setWalletData,
        updatWalletData,
        resetWalletData,
        walletBalance,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
}

export function useWalletData() {
  return useContext(WalletContext);
}

WalletProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
}.isRequired;
