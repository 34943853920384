import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getUserInfo } from '@/services/api';
import { useUserData } from '@/context/userContext';

const ROUTES_THAT_SHOULD_REFRESH = [
  '/deposit',
  '/transfer',
  '/withdrawal',
  '/conversion',
  '/transfer-api',
];

const useUpdateUser = () => {
  const { pathname } = useLocation();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(false);

  const { setUserData } = useUserData();

  const updateUserData = async (data = {}) => {
    try {
      setIsFetching(true);
      const res = await getUserInfo();
      setUserData((state) => ({ ...state, ...res?.data, ...data }));
    } catch (err) {
      setError(true);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    if (ROUTES_THAT_SHOULD_REFRESH.includes(pathname) && !isFetching) {
      updateUserData();
    }

    return () => controller.abort();
  }, []);

  return { isFetching, error, updateUserData };
};

export default useUpdateUser;
