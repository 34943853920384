import styled from 'styled-components';

export const ContainerFormTransaction = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  div {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    text-align: center;
  }

  p {
    max-width: 300px;
  }
`;
