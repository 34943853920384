import styled from 'styled-components';

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  overflow-x: hidden;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: center;
    animation: none;
    display: grid;
    grid-template-columns: ${({ isLoading }) =>
      isLoading ? '1fr' : '47% 1% 47%'};
    padding: 4vh 0;
  }
`;

export const ChildrenWrapper = styled.section`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
