import i18n from '@/translate/i18n';

export default {
  default: {
    title: i18n.t('requestCard.validationMissing.title'),
    description: i18n.t('requestCard.validationMissing.description'),
    button: i18n.t('requestCard.validationMissing.button'),
    path: '/request-card',
  },
  validationMissing: {
    title: i18n.t('requestCard.validationMissing.title'),
    description: i18n.t('requestCard.validationMissing.description'),
    button: i18n.t('requestCard.validationMissing.button'),
    path: '/validations',
  },
  requestAvailable: {
    title: i18n.t('requestCard.requestAvailable.title'),
    description: i18n.t('requestCard.requestAvailable.description'),
    button: i18n.t('requestCard.requestAvailable.button'),
    path: '/request-card',
  },
};
