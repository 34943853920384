import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';

import IconWrapper from './styles';
import TwTitle from '@/components/atoms/TwTitle';
import useDeviceWidth from '@/hooks/useDeviceWidth';

function BonusesAndPromotionsIcon({ type, title, showInDesktop }) {
  const { isMobileLayout } = useDeviceWidth();

  return (
    <IconWrapper showInDesktop={showInDesktop}>
      <TwTitle
        text={title ?? i18n.t(`bonusesAndPromotions.${type}`)}
        size={isMobileLayout ? 'm_lg' : 'd_lg'}
        font="arboriaBold"
        classList="text-center"
      />
    </IconWrapper>
  );
}

BonusesAndPromotionsIcon.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  showInDesktop: PropTypes.bool,
}.isRequired;

export default BonusesAndPromotionsIcon;
