import styled, { css } from 'styled-components';

import { Button } from '@/components/atoms/Buttons/Button/styles';

export const ConversionButton = styled(Button)`
  ${({ theme, isValid, isValidatedIdentity }) => css`
    animation: opacitySubmitButton 1s;
    margin-top: ${isValidatedIdentity ? '60px' : '15px'};

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      align-self: center;
      animation: goUpDefault 1s;
      justify-self: flex-end;
      margin-bottom: 0px;
      width: 26vw;
    }

    @media screen and (min-width: ${theme.breakpoints.md}) {
      align-self: flex-end;
      margin-right: 35px;
      width: 20vw;
    }
  `}
`;

export const MobileContent = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;
