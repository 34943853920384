import React from 'react';
import PropTypes from 'prop-types';

import { Label, CheckBoxInput } from './styles';

function Checkbox({ id, label, disabled, ...rest }) {
  return (
    <Label htmlFor={id} disabled={disabled} className="!text-left">
      <input id={id} type="checkbox" disabled={disabled} {...rest} />
      <CheckBoxInput disabled={disabled} />
      {label}
    </Label>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
}.isRequired;

export default Checkbox;
