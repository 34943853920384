import styled, { css } from 'styled-components';
import { Form } from 'formik';
import { hexToRGBA } from '@/styles/colors';

export const FormStyled = styled(Form)`
  align-items: center;
  animation: goUpForm 0.4s;
  background-color: transparent;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  width: 100%;
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.danger};
  font-size: ${({ theme }) => theme.font.size.small};
  margin-bottom: 15px;
  text-align: center;
  top: 220px;
`;

export const ButtonWrapper = styled.div`
  margin: 3vh 0;
  width: 100%;
`;

export const InputAnimationWrapper = styled.div`
  align-items: center;
  animation: ${({ animate }) =>
    animate ? 'opacityAnimationDefault 1.5s' : 'none'};
  display: flex;
  flex: 0.5;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const Footer = styled.footer`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 3vh 0 2vh;
  width: 100%;
`;

export const SummaryWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    border-bottom: 1px solid ${hexToRGBA(theme.colors.info.primary, 0.3)};
    color: ${theme.colors.info.light};
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    margin: 2rem 0;
    padding-bottom: 10vh;
    width: 80%;

    div {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  `}
`;
