import styled from 'styled-components';

import { Button as ButtonStyles } from '@/components/atoms/Buttons/Button/styles';
import { ReactComponent as Success } from '@/assets/sucessFull.svg';

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-self: center;
    padding-bottom: 12vh;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 95%;
  }
`;

export const Button = styled(ButtonStyles)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.info.dark};
  margin: 15px 0;
  width: 250px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    animation: goUpDefault 1s;
    bottom: 20px;
    position: absolute;
    right: auto;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    right: 40px;
  }
`;

export const AmountWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.font.size.small};
  grid-column: 2;
  justify-content: center;
  text-align: center;

  span {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 5vh;
  }
`;

export const AmountValue = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font: ${({ theme }) =>
    `${theme.font.size.xxxLarge} ${theme.font.family.secondary}`};
  margin: 8px 0;
`;

export const SuccessIcon = styled(Success)`
  width: 90px;
`;

export const Section = styled.section`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex: 0.6;
  flex-direction: column;
  font-weight: 600;
  gap: 1rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column: 2;
    grid-row: 1;
  }
`;
