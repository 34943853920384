import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';

import DepositButton from '@/components/atoms/Buttons/DepositButton';
import InputTransactionValue from '@/components/atoms/Inputs/InputTransactionValue';
import MinMaxAmountMessage from '@/components/atoms/MinMaxAmountMessage';
import LimitInstructions from '@/components/molecules/Modal/Messages/LimitInstructions';
import PaymentCarousel from '@/components/molecules/PaymentCarousel';
import MethodIcon from '@/components/atoms/MethodIcon';
import Modal from '@/components/molecules/Modal';
import WarningMessage from '@/components/molecules/WarningMessage';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { createDeposit } from '@/services/api';
import { registrationStatus } from '@/helpers/constants';
import { useUserData } from '@/context/userContext';
import { useWalletData } from '@/context/walletContext';
import {
  LinkTextButton,
  TextButton,
} from '@/components/atoms/Buttons/TextButton/styles';

import ContentWrapper, { TextButtonWrapper } from './styles';
import TwTitle from '@/components/atoms/TwTitle';

function DepositForm({ setShowDetails, showDetails, isExternalFlow }) {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const { isMobileLayout } = useDeviceWidth();
  const [showModal, setShowModal] = useState(false);
  const { userData } = useUserData();

  const {
    isLoading,
    setIsLoading,
    setWalletData,
    walletData: { transactionSubmitError, transactionData },
  } = useWalletData();

  const userHasPendingValidations = !userData?.kyc_level;
  const identityValidationPending =
    userData?.status === registrationStatus.validateId;

  const generateDeposit = async () => {
    setIsLoading(true);
    const payload = {
      amount: Number(transactionData.amount),
      customer_currency_iso: 'BRL',
      method: transactionData.method?.slug,
    };

    try {
      const res = await createDeposit(payload, null);
      const { id } = res.data;

      setWalletData((state) => ({
        ...state,
        shouldRenderFirstDepositBtn: false,
      }));

      window.dataLayer.push({
        event: process.env.REACT_APP_ENV
          ? `deposit_pending_${process.env.REACT_APP_ENV}`
          : 'deposit_pending',
        tx_id: id,
        type: 'deposit',
        status: 'pending',
        value: Number(transactionData.amount),
        currency: 'BRL',
      });

      history.push(
        isExternalFlow ? `/external/transactions/${id}` : `/transactions/${id}`,
      );
    } catch (err) {
      const message = err?.response?.data?.message;

      setErrorMessage(message);
      setWalletData((prevState) => ({
        ...prevState,
        transactionSubmitError: true,
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    setWalletData((state) => ({
      ...state,
      transactionData: {
        ...state.transactionData,
        type: 'deposit',
      },
    }));

    if (showDetails) {
      generateDeposit();
    } else {
      setShowDetails(true);
    }
  };

  const hideDetailsExternal =
    isExternalFlow && showDetails && !transactionSubmitError;

  return (
    <ContentWrapper
      showDetails={showDetails && !transactionSubmitError}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      {(!(showDetails && isMobileLayout) || transactionSubmitError) &&
        !hideDetailsExternal && (
          <>
            {transactionSubmitError ? (
              <TwTitle
                size={isMobileLayout ? 'm_lg' : 'd_lg'}
                font="arboriaBold"
                classList="text-center"
              >
                <Trans
                  i18nKey={i18n.t('deposit.errorTitle')}
                  i18n={i18n}
                  components={[<strong />]}
                />
              </TwTitle>
            ) : (
              <TwTitle
                size={isMobileLayout ? 'm_lg' : 'd_lg'}
                font="arboriaBold"
                classList="text-center"
              >
                {i18n.t('deposit.title')}{' '}
                <span className="text-grass-800 uppercase">
                  {transactionData?.method?.name}
                </span>
              </TwTitle>
            )}
            {isMobileLayout || isExternalFlow ? (
              <PaymentCarousel
                hide={showDetails}
                showDetails={showDetails}
                minimalVersion
              />
            ) : (
              <MethodIcon method={transactionData?.method?.slug} width="50px" />
            )}
            {transactionSubmitError && (
              <p id="error-message">
                {i18n.t([
                  `error.deposit.${errorMessage}`,
                  `error.deposit.unspecific`,
                ])}
              </p>
            )}
          </>
        )}
      {!(showDetails && isMobileLayout) && !hideDetailsExternal && (
        <InputTransactionValue
          content={
            transactionSubmitError
              ? i18n.t('deposit.messageOnError')
              : transactionData?.amount
              ? i18n.t('deposit.youWillDeposit')
              : i18n.t('deposit.enterValue')
          }
          currency="BRL"
          disabled={showDetails}
          isError={transactionSubmitError}
          transactionType="deposit"
        />
      )}
      {userHasPendingValidations ? (
        <>
          <WarningMessage
            message={
              identityValidationPending
                ? i18n.t('validationAlert.identityValidationPendingWithdrawal')
                : i18n.t('validationAlert.waitConfirmation')
            }
            linkPath={identityValidationPending && '/wallet/validate-identity'}
            linkLabel={
              identityValidationPending &&
              i18n.t('validationAlert.validateIdentity')
            }
          />
        </>
      ) : (
        <>
          {transactionSubmitError ? (
            <TextButtonWrapper>
              <LinkTextButton
                className="!text-lg"
                to={isExternalFlow ? '/external/deposit/' : '/deposit'}
                onClick={() => {
                  setWalletData((state) => ({
                    ...state,
                    transactionSubmitError: false,
                  }));
                  setShowDetails(false);
                }}
                id="try-again-btn"
              >
                {i18n.t('transactionError.tryAgain')}
              </LinkTextButton>
              <TextButton onClick={setShowModal}>
                {i18n.t('transactionError.increaseLimit')}
              </TextButton>
            </TextButtonWrapper>
          ) : (
            <>
              {!showDetails && <MinMaxAmountMessage />}
              <DepositButton
                handleSubmit={handleSubmit}
                showDetails={showDetails}
                disabled={isLoading}
              />
            </>
          )}
        </>
      )}
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        height="290px"
        button={i18n.t('modal.understood')}
      >
        <LimitInstructions />
      </Modal>
    </ContentWrapper>
  );
}

DepositForm.propTypes = {
  depositValue: PropTypes.number,
  isValidValue: PropTypes.bool,
  maxValue: PropTypes.number,
  method: PropTypes.string,
  minValue: PropTypes.number,
  minimumAmount: PropTypes.number,
  setDepositValue: PropTypes.func,
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  kycLevel: PropTypes.objectOf(PropTypes.any),
}.isRequired;

export default DepositForm;
