import { css } from 'styled-components';

import { hexToRGBA } from '@/styles/colors';

export const HoverEffect = css`
  &:hover {
    background: radial-gradient(
      circle,
      ${({ theme }) => hexToRGBA(theme.colors.info.gray, 0.05)} 0%,
      ${({ theme }) => hexToRGBA(theme.colors.info.gray, 0.5)} 0%,
      ${({ theme }) => hexToRGBA(theme.colors.info.gray, 0.05)} 100%
    );
  }
`;
