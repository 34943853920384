import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  ${({ theme }) => css`
    display: none;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      align-items: center;
      animation: goLeftDefault 0.5s;
      color: ${theme.colors.info.primary};
      display: flex;
      flex-direction: column;
      gap: 5vh;
      height: 100%;
      justify-content: center;
      max-height: 550px;
      width: 300px;

      span {
        color: ${theme.colors.primary};
        font-family: ${theme.font.family.secondary};
      }
    }
  `}
`;

export const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  font-size: ${({ theme }) => theme.font.size.small};
  gap: 2rem;
  justify-content: space-between;
  padding: 0.8rem 0;
  width: 100%;

  p {
    max-width: 240px;
  }
`;
