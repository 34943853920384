import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import { ReactComponent as ErrorIcon } from '@/assets/errorIcon.svg';
import { ReactComponent as SuccessIcon } from '@/assets/checked.svg';
import { Button } from '@/components/atoms/Buttons/Button/styles';
import { Paragraph, Title } from '@/components/atoms/Typography/styles';
import { formatCurrency } from '@/helpers/stringFormat';
import i18n from '@/translate/i18n';

import { ButtonsWrapper, ContentWrapper } from './styles';

const TIME_TO_REDIRECT = 5_000;
const ICON_SIZE = ' 70px';

function TransferAPIStatus({ error, data }) {
  const [showRedirectText, setShowRedirectText] = useState(false);

  const failUrl = data?.start_request?.redirect_fail;
  const successUrl = data?.start_request?.redirect_success;
  const shouldRedirect = (failUrl && error) || (successUrl && !error);

  const redirectBack = () => {
    localStorage.removeItem('pendingApiTransaction');
    if (failUrl && error) {
      window.location = failUrl;
      return;
    }
    window.location = successUrl;
  };

  useEffect(() => {
    let timeout = null;

    if (shouldRedirect) {
      setShowRedirectText(true);
      timeout = setTimeout(() => {
        redirectBack();
      }, TIME_TO_REDIRECT);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <ContentWrapper>
      <Title size="medium">
        <Trans
          i18n={i18n}
          i18nKey={`transferApiStatus.${
            error
              ? 'failMessage'
              : `successMessage.${data?.type ?? 'transfer-in'}`
          }`}
          components={[<span />, <strong />]}
        />
      </Title>
      {error ? (
        <ErrorIcon height={ICON_SIZE} width={ICON_SIZE} />
      ) : (
        <SuccessIcon height={ICON_SIZE} width={ICON_SIZE} />
      )}

      {error ? (
        <Paragraph color="light" size="base" textAlign="center">
          {i18n.t('transferApiStatus.errorMsg')}
        </Paragraph>
      ) : (
        <>
          <Paragraph highlightColor="light" size="medium" textAlign="center">
            <Trans
              i18n={i18n}
              i18nKey={`transferApiStatus.completedMessage.${
                data?.type ?? 'transfer-in'
              }`}
              components={[<strong />]}
              tOptions={{
                amount: formatCurrency(data?.amount),
              }}
            />
          </Paragraph>
          {data?.type === 'transfer-out' && (
            <Paragraph textAlign="center" size="xSmall">
              {i18n.t('transferApiStatus.transferOutDescription')}
            </Paragraph>
          )}
        </>
      )}
      {showRedirectText && (
        <ButtonsWrapper>
          <Button type="button" onClick={redirectBack}>
            {i18n.t('transferApiStatus.returnToSite')}
          </Button>
          <Paragraph size="small" color="light" textAlign="center">
            {i18n.t('transferApiStatus.redirectMessage')}
          </Paragraph>
        </ButtonsWrapper>
      )}
    </ContentWrapper>
  );
}

TransferAPIStatus.propTypes = {
  error: PropTypes.bool,
  data: PropTypes.shape({
    request_currency: PropTypes.string,
    merchant_username: PropTypes.string,
    amount: PropTypes.number,
    type: PropTypes.string,
    merchant: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
    start_request: PropTypes.shape({
      redirect_fail: PropTypes.string,
      redirect_success: PropTypes.string,
    }),
  }),
};

export default TransferAPIStatus;
