import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
  align-items: start;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 90%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 0.1fr 1fr;
    height: 100%;
    justify-items: center;
    padding-top: 8vh;
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 4vh 0;
  }
`;

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 2rem;
`;

export const SectionWrapper = styled.section`
  ${({ theme, isOpen }) => css`
    animation: goRightDefault 0.6s;
    border-bottom: 1px solid ${theme.colors.info.gray};
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    padding: 1.4rem 0;
    position: relative;
    width: 80vw;

    & > h1 {
      display: flex;
      gap: 2rem;
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      max-width: 390px;
      width: 100%;
    }

    @media screen and (min-width: ${theme.breakpoints.md}) {
      p {
        color: ${isOpen && theme.colors.bgGradientLight.start};
      }
    }

    @media screen and (min-width: ${theme.breakpoints.lg}) {
      width: 450px;
    }
  `}
`;

export const ModalButton = styled.button`
  ${({ theme, hideInDesktop }) => css`
    background-color: transparent;
    border: none;
    color: ${theme.colors.primary};
    font: ${`${theme.font.size.small} ${theme.font.family.secondary}`};
    padding: 1px 5px;
    position: absolute;
    right: 0;
    text-decoration: underline;
    top: 1.3rem;

    &:disabled {
      color: ${theme.colors.bgGradientLight.start};
    }

    @media screen and (min-width: ${theme.breakpoints.md}) {
      display: ${hideInDesktop ? 'none' : 'block'};
    }
  `}
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: start;
  max-height: 600px;
  padding: 0 2rem;
  transition: 0.4s margin ease;
`;

export const DetailsWrapper = styled.div`
  animation: goLeftDefault 0.6s;
  display: flex;
  flex: 1;
  height: 100%;
  justify-self: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-items: center;
    max-height: 600px;
  }
`;

export const GridWrapper = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 0.7fr 1fr;
  margin-top: 0.5rem;
`;
