import { useEffect, useState } from 'react';

import { getTermsBySlug } from '@/services/api';

function useTerms(slugTerms) {
  const [termsContent, setTermsContent] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getContent = async () => {
      try {
        setIsFetching(true);
        const res = await getTermsBySlug(slugTerms);

        setTermsContent(res.data.description);
      } catch (err) {
        setError(true);
      } finally {
        setIsFetching(false);
      }
    };

    if (slugTerms) {
      getContent();
    }
  }, [slugTerms]);

  return { isFetching, termsContent, error };
}

export default useTerms;
