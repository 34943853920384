import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.colors.info.primary};
    display: flex;
    flex-direction: column;
    font: ${theme.font.size.xSmall} ${theme.font.family.secondary};
    max-width: 80%;
  `}
`;

export const Section = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 100%;

  li {
    list-style-position: inside;
    margin-top: 1rem;
    padding-left: 1rem;
  }

  a {
    color: ${({ theme }) => theme.colors.info.secondary};
  }
`;

export const TextContent = styled.p`
  margin-left: ${({ indent }) => (indent ? '1rem' : 0)};
  text-align: justify;
`;
