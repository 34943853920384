import styled from 'styled-components';

import { ReactComponent as SearchIcon } from '@/assets/searchIcon.svg';

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.light};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.font.size.medium};
  position: relative;

  div {
    position: relative;
  }
`;

export const InputBar = styled.input`
  background-color: ${({ theme }) => theme.colors.bg.darkSecondary};
  border: none;
  border-radius: 50px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.08);
  color: ${({ theme }) => theme.colors.info.light};
  font-size: ${({ theme }) => theme.font.size.small};
  max-width: 250px;
  padding: 10px 0 10px 60px;
  width: 80vw;

  &::placeholder {
    color: ${({ theme }) => theme.colors.info.primary};
    font-size: ${({ theme }) => theme.font.size.small};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: 200px;
  }
`;

export const Icon = styled(SearchIcon)`
  left: 13px;
  position: absolute;
  top: 6px;
  width: 20px;
`;
