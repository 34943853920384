import React from 'react';
import { Trans } from 'react-i18next';

import i18n from '@/translate/i18n';

import { Wrapper, TextWrapper } from './styles';

const CONTENT = [
  {
    key: 'getStartWithPoints.chooseBetting',
    path: '/icons/arrow-right.svg',
    alt: 'arrow',
  },
  {
    key: 'getStartWithPoints.earnPoints',
    path: '/icons/plus-icon.svg',
    reverse: true,
    alt: 'plus',
  },
  {
    key: 'getStartWithPoints.enjoyBetting',
    path: '/icons/vpag.svg',
    alt: 'vpag',
  },
];

function GetStartWithPoints() {
  return (
    <Wrapper id="get-start-with-points">
      {CONTENT.map(({ key, path, alt, reverse }) => (
        <TextWrapper key={key} reverse={reverse}>
          <img src={path} alt={alt} />
          <p>
            <Trans i18nKey={key} i18n={i18n} components={[<span />]} />
          </p>
        </TextWrapper>
      ))}
    </Wrapper>
  );
}

export default GetStartWithPoints;
