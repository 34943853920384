import React, { cloneElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from '@/assets/closeBtn.svg';
import { ReactComponent as CloseIconWhite } from '@/assets/closeBtnWhite.svg';
import { ModalContainer, ModalWrapper, CloseButton, Button } from './styles';

function Modal({
  bgColor,
  button,
  children,
  handleButton,
  handleClose,
  height,
  hideInDesktop,
  setShowModal,
  showModal,
  width,
  maxWidth,
  closeButtonWhite = false,
  centralized = false,
  hiddenCloseButton = false,
  padding,
  maxHeigth,
}) {
  const [hideAnimation, setHideAnimation] = useState(true);
  const [modalButton, setModalButton] = useState({});

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHideAnimation(false);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <ModalContainer
      showModal={showModal}
      hideAnimation={hideAnimation}
      hideInDesktop={hideInDesktop}
      onClick={handleClose || (() => setShowModal(false))}
      centralized={centralized}
    >
      <ModalWrapper
        showModal={showModal}
        hideAnimation={hideAnimation}
        height={height}
        bgColor={bgColor}
        width={width}
        bonusTerms
        maxWidth={maxWidth}
        onClick={(e) => e.stopPropagation()}
        button={Boolean(button)}
        padding={padding}
        maxHeigth={maxHeigth}
      >
        {!hiddenCloseButton && (
          <CloseButton
            type="button"
            onClick={handleClose || (() => setShowModal(false))}
            id="modal-close-button"
            closeButtonWhite={closeButtonWhite}
          >
            {closeButtonWhite ? <CloseIconWhite /> : <CloseIcon />}
          </CloseButton>
        )}
        {cloneElement(children, { setModalButton })}
        {(modalButton?.action || button) && (
          <Button
            type="button"
            onClick={
              modalButton?.action || handleButton || (() => setShowModal(false))
            }
          >
            {modalButton?.label || button}
          </Button>
        )}
      </ModalWrapper>
    </ModalContainer>
  );
}

Modal.propTypes = {
  bgColor: PropTypes.string,
  button: PropTypes.string,
  children: PropTypes.element,
  handleButton: PropTypes.func,
  handleClose: PropTypes.func,
  height: PropTypes.string,
  hideInDesktop: PropTypes.bool,
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  with: PropTypes.string,
  centralized: PropTypes.bool,
  closeButtonWhite: PropTypes.bool,
  padding: PropTypes.string,
  maxHeigth: PropTypes.string,
}.isRequired;

export default Modal;
