import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import ErrorMessage from '@/components/molecules/ErrorMessage';
import Loading from '@/components/atoms/Loading';
import SubmitButton from '@/components/atoms/Buttons/SubmitButton';
import i18n from '@/translate/i18n';
import { createPhoneValidation, validatePhoneNumber } from '@/services/api';
import { Paragraph } from '@/components/atoms/Typography/styles';
import { useUserData } from '@/context/userContext';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';

import {
  CodeInput,
  DescriptionWrapper,
  FooterFixed,
  SuccessIcon,
  Wrapper,
} from './styles';
import TwTitle from '@/components/atoms/TwTitle';

const CODE_LENGTH = 6;
const ONE_MINUTE = 60;
const TEN_SECONDS = 10;
const TIME_TO_REDIRECT = 4000;

function PhoneValidationForm() {
  const history = useHistory();
  const { setUserData, userData } = useUserData();

  const [validationCode, setValidationCode] = useState('');
  const [counter, setCounter] = useState(ONE_MINUTE);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [sendingCode, setSendingCode] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [submitErrorMsg, setSubmitErrorMsg] = useState('');

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    const redirect = () => {
      history.push('/profile');
    };

    const timeToRedirect = setTimeout(
      () => showSuccessMessage && redirect(),
      TIME_TO_REDIRECT,
    );
    return () => {
      clearTimeout(timeToRedirect);
    };
  }, [showSuccessMessage, history]);

  const sendValidationCode = async (signal) => {
    try {
      setSendingCode(true);
      const userId = localStorage.getItem('id');
      await createPhoneValidation(userId, signal);
    } catch (err) {
      const message = err?.response?.data?.message;
      setErrorMsg(
        i18n.t([
          `error.phoneValidation.${message}`,
          'error.phoneValidation.unspecific',
        ]),
      );
    } finally {
      setSendingCode(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    sendValidationCode(controller.signal);

    return () => controller.abort();
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;
    if (value.toString().length <= CODE_LENGTH) {
      setValidationCode(value);
    }

    if (value.toString().length === CODE_LENGTH) {
      e.target.blur();
    }
  };

  const handleSubmit = async () => {
    if (!counter) {
      sendValidationCode();
      setCounter(ONE_MINUTE);
    } else {
      try {
        const userId = localStorage.getItem('id');
        await validatePhoneNumber(userId, { code: validationCode });

        setShowSuccessMessage(true);
        setUserData((state) => ({
          ...state,
          validations: state?.validations?.map((item) => {
            if (item?.validation === 'phone') {
              return {
                ...item,
                status: 'validated',
              };
            }
            return item;
          }),
        }));
      } catch (err) {
        const message = err?.response?.data?.message;
        setSubmitErrorMsg(
          i18n.t([
            `error.phoneValidation.${message}`,
            'error.phoneValidation.unspecific',
          ]),
        );
        setValidationError(true);
      }
    }
  };

  return (
    <Wrapper id="validate-phone-number">
      <TwTitle
        size="xl"
        font="arboriaBold"
        color="shadow-500"
        classList="text-center"
      >
        {' '}
        <Trans
          i18n={i18n}
          i18nKey={
            showSuccessMessage
              ? 'validatePhone.phoneValidated'
              : 'validatePhone.title'
          }
          components={<span />}
        />
      </TwTitle>
      {!validationError ? (
        <>
          {!showSuccessMessage && (
            <DescriptionWrapper>
              {userData?.phone && (
                <strong>{formatPhoneNumberIntl(userData.phone)}</strong>
              )}
              <p>{i18n.t('validatePhone.description')}</p>
            </DescriptionWrapper>
          )}
          {showSuccessMessage ? (
            <>
              <SuccessIcon />
              <span />
            </>
          ) : (
            <>
              {sendingCode ? (
                <Loading content="Enviando código ..." />
              ) : !errorMsg ? (
                <>
                  <CodeInput
                    type="number"
                    onChange={handleChange}
                    value={validationCode}
                    autoFocus
                    id="phone-number-validation-code-input"
                  />
                  <Paragraph
                    color="light"
                    size="xSmall"
                    textTransform="uppercase"
                  >
                    {`${i18n.t('validatePhone.timerText')}: 0:${
                      counter < TEN_SECONDS ? `0${counter}` : counter
                    }`}
                  </Paragraph>
                </>
              ) : (
                <Paragraph textAlign="center" family="secondary" color="danger">
                  {errorMsg}
                </Paragraph>
              )}
              <FooterFixed>
                <SubmitButton
                  before={
                    errorMsg
                      ? `${i18n.t('validatePhone.timerText')}: 0:${
                          counter < TEN_SECONDS ? `0${counter}` : counter
                        }`
                      : i18n.t('validatePhone.insertCode')
                  }
                  after={
                    !counter
                      ? i18n.t('validatePhone.resendCode')
                      : i18n.t('validatePhone.validate')
                  }
                  isValid={
                    validationCode?.toString().length >= CODE_LENGTH || !counter
                  }
                  handleClick={handleSubmit}
                  width="80%"
                />
                <TextButton
                  type="button"
                  onClick={() =>
                    history.push(errorMsg ? '/profile/phone' : '/profile')
                  }
                >
                  {errorMsg
                    ? i18n.t('validatePhone.changePhoneNumber')
                    : i18n.t('addressForm.cancel')}
                </TextButton>
              </FooterFixed>
            </>
          )}
        </>
      ) : (
        <>
          <ErrorMessage content={submitErrorMsg} />
          <FooterFixed>
            <SubmitButton
              after={i18n.t('validatePhone.tryAgain')}
              isValid
              handleClick={() => {
                setValidationCode('');
                setValidationError(false);
                setCounter(ONE_MINUTE);
              }}
              width="60%"
            />
          </FooterFixed>
        </>
      )}
    </Wrapper>
  );
}

export default PhoneValidationForm;
