/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import DrugsIcon from '@/assets/pharmacy.svg';
import { BodyMarchanDisco, Halo, Image } from './style';

function MerchanDisc({ src, isSelected = false, onClick, isBlocked }) {
  return (
    <div
      onClick={onClick}
      isBlocked={isBlocked}
      className={`items-center flex flex-col gap-[1.875rem] justify-center ${
        isBlocked ? 'opacity-20' : 'opacity-100'
      }`}
    >
      <Halo isSelected={isSelected}>
        <BodyMarchanDisco isSelected={isSelected}>
          <Image
            src={src || DrugsIcon}
            alt="Icon marchan"
            isSelected={isSelected}
          />
        </BodyMarchanDisco>
      </Halo>
    </div>
  );
}

MerchanDisc.propTypes = {
  src: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  isBlocked: PropTypes.bool,
}.isRequired;

export default MerchanDisc;
