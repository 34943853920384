import styled from 'styled-components';

export const DesktopLinks = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    & {
      align-items: flex-end;
      animation: goLeftDefault 1s;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      justify-content: space-evenly;
    }
  }
`;

export const ValueWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  font: ${({ theme }) =>
    `${theme.font.size.xLarge} ${theme.font.family.secondary}`};
  margin-top: 70px;
`;
