import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';

import { useWalletData } from '@/context/walletContext';
import { getAvailableMerchants } from '@/services/api';
import i18n from '@/translate/i18n';

const ITEMS_PER_PAGE = 9;
const SECOND_PAGE = 2;
const PAYLOAD = {
  per_page: ITEMS_PER_PAGE,
  active: 1,
  transfer_in_vsi_integration: 1,
};

const useMerchants = (page, setPageCount, filters = {}) => {
  const {
    setWalletData,
    walletData: { filteredMerchantsByBonus },
  } = useWalletData();

  const [loadingMerchants, setLoadingMerchants] = useState(false);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [merchantsError, setMerchantsError] = useState(false);
  const [metaData, setMetaData] = useState({});
  const [newPage, setNewPage] = useState(SECOND_PAGE);
  useEffect(() => {
    const controller = new AbortController();

    const getMerchants = async () => {
      try {
        setLoadingMerchants(true);

        const params = { ...PAYLOAD, page, ...filters };
        const res = await getAvailableMerchants(
          localStorage.getItem('id'),
          params,
          controller.signal,
        );
        const { data, meta } = res;

        setWalletData((prevState) => ({ ...prevState, merchants: data }));
        setPageCount(Math.ceil(meta.total / ITEMS_PER_PAGE));
        setMetaData(meta);
      } catch (err) {
        setMerchantsError(true);
      } finally {
        setLoadingMerchants(false);
      }
    };
    getMerchants();

    return () => controller.abort();
  }, [page, filteredMerchantsByBonus]);

  const getMoreData = async () => {
    if (newPage <= metaData?.last_page && !loadingMoreData) {
      try {
        setLoadingMoreData(true);
        const params = { ...PAYLOAD, page: newPage };
        const res = await getAvailableMerchants(
          localStorage.getItem('id'),
          params,
        );
        const { data, meta } = res;
        const filtered = data.filter(({ active }) => active);

        setWalletData((prevState) => ({
          ...prevState,
          merchants: [...prevState.merchants, ...filtered],
        }));

        setPageCount(Math.ceil(meta.total / ITEMS_PER_PAGE));
        setNewPage((old) => old + 1);
        setMetaData(meta);
      } catch (err) {
        toast.error(i18n.t('error.unspecific '));
      } finally {
        setLoadingMoreData(false);
      }
    }
  };

  return { getMoreData, loadingMerchants, loadingMoreData, merchantsError };
};

export default useMerchants;
