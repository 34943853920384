import styled from 'styled-components';

export const Wrapper = styled.div`
  cursor: pointer;
  height: ${({ width }) => `${width + 4}px`};
  width: ${({ width }) => `${width + 4}px`};
`;

export const Logo = styled.img`
  min-height: ${({ height }) => height};
  opacity: ${({ opacity = 1 }) => opacity};
  width: ${({ width }) => width};

  &:hover {
    opacity: 1;
  }
`;
