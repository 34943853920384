import styled, { css } from 'styled-components';
import { Title } from '@/components/atoms/Typography/styles';

export const TitleInsertUsernameContainer = styled.div`
  font-weight: 100;
  text-align: center;
`;

export const SubtitleText = styled(Title)`
  ${({ theme, fontSize = 'auto', opacity }) => css`
    color: ${theme.colors.info.subtitle};
    font-size: ${fontSize};
    opacity: ${opacity} !important;
  `}
`;
