import styled, { css } from 'styled-components';

const DEFAULT_SIZE = 30;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto 0;
`;

export const Loader = styled.span`
  ${({ theme, size = DEFAULT_SIZE }) => css`
    animation: rotation 1s linear infinite;
    border: 3px solid ${theme.colors.primary};
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    height: ${size}px;
    margin: 40% 0;
    width: ${size}px;
  `}
`;
