import React from 'react';
import PropTypes from 'prop-types';

import { Title } from '@/components/atoms/Typography/styles';

import { AlertIcon, Wrapper } from './styles';

function Warning({ message, title }) {
  return (
    <Wrapper>
      <AlertIcon />
      <Title>{title}</Title>
      <p>{message}</p>
    </Wrapper>
  );
}

Warning.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
}.isRequired;

export default Warning;
