import React from 'react';
import PropTypes from 'prop-types';
import i18n from '@/translate/i18n';
import TwToggleSwitch from '@/components/v2/atoms/TwToggleSwitch';

const AuthorizeTransactions = ({ onClick, check, merchantName }) => (
  <div className="h-auto flex gap-1 items-start">
    <TwToggleSwitch onClick={onClick} checked={check} />
    <p className="text-white font-arboria-light text-justify">
      {i18n.t('cashbackDetail.confirmAuthorizationTransaction1')}
      {merchantName}
      {i18n.t('cashbackDetail.confirmAuthorizationTransaction2')}
      <span className="font-arboria-bold text-justify">
        {i18n.t('cashbackDetail.confirmAuthorizationTransaction3')}
      </span>
    </p>
  </div>
);
AuthorizeTransactions.propTypes = {
  onClick: PropTypes.func,
  check: PropTypes.bool,
  merchantName: PropTypes.string,
};
export default AuthorizeTransactions;
