import React from 'react';
import PropTypes from 'prop-types';

import { Trans } from 'react-i18next';
import MethodIcon from '@/components/atoms/MethodIcon';
import i18n from '@/translate/i18n';

import { CopyButton, QrCode } from '../DepositDetailsSummary/styles';
import { Paragraph } from '@/components/atoms/Typography/styles';
import { FlexWrapper } from './styles';

const LOGO_SIZE = 24;

function BinanceQrCode({ data }) {
  const shouldRenderQrCode =
    data?.status === 'pending' &&
    data?.payment_method_slug === 'binance' &&
    data?.details?.psp_payment_info?.checkout_url;

  return (
    <>
      {shouldRenderQrCode ? (
        <QrCode
          value={data.details.psp_payment_info.checkout_url}
          alt="QR Code"
          id="deposit-details-qr-code"
          imageSettings={{
            src: '/images/methods/binance.svg',
            height: LOGO_SIZE,
            width: LOGO_SIZE,
            excavate: true,
          }}
        />
      ) : (
        data?.payment_method_slug && (
          <MethodIcon
            method={data?.payment_method_slug}
            width="70px"
            flat
            id="deposit-details-method-icon"
          />
        )
      )}
      <FlexWrapper>
        {data?.status === 'pending' && (
          <Paragraph color="light" textAlign="center" highlightColor="light">
            <Trans
              i18n={i18n}
              i18nKey="transactionDetailsSummary.binanceDescription"
              components={[<strong />]}
            />
          </Paragraph>
        )}
        {shouldRenderQrCode && (
          <CopyButton
            id="deposit-details-copy-binance-key-btn"
            bgColor="pending"
            onClick={() =>
              window.open(data.details.psp_payment_info.checkout_url)
            }
          >
            {i18n.t('transactionDetailsSummary.continueBinance')}
          </CopyButton>
        )}
      </FlexWrapper>
    </>
  );
}

BinanceQrCode.propTypes = {
  data: PropTypes.shape({
    customer_bonus: PropTypes.number,
    customer_currency_amount: PropTypes.number,
    customer_currency_iso: PropTypes.string,
    id: PropTypes.number,
    payment_method_name: PropTypes.string,
    payment_method_slug: PropTypes.string,
    status: PropTypes.string,
    details: PropTypes.shape({
      psp_payment_info: PropTypes.shape({
        qr_code: PropTypes.string,
        checkout_url: PropTypes.string,
      }),
    }),
  }),
};

export default BinanceQrCode;
