import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import LoginRegisterSelector from '@/components/molecules/LoginRegisterSelector';
import TransferApiTemplate from '@/components/templates/TransferApiTemplate';
import SignUpForm from '@/components/organisms/SignUpForm';

function SignUpTransferApi() {
  const { id } = useParams();
  const [isEmailValid, setIsEmailValid] = React.useState(false);
  const [stateProgressBar, setStateProgressBar] = useState('signUp');
  if (id) {
    sessionStorage.setItem('pendingApiTransaction', id);
  }

  return (
    <TransferApiTemplate>
      <LoginRegisterSelector
        isExternalLogin
        origin="signUp"
        isCreateAccount
        isEmailValid={isEmailValid}
        stateProgressBar={stateProgressBar}
      />
      <SignUpForm
        setEmailValid={setIsEmailValid}
        setStateProgressBar={setStateProgressBar}
      />
    </TransferApiTemplate>
  );
}

export default SignUpTransferApi;
