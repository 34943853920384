import i18n from '@/translate/i18n';

const i18nOptions = { returnObjects: true };

export const privacyPolicyContent = i18n.t(
  'privacyPolicy.sections',
  i18nOptions,
);

export const termsAndConditionContent = i18n.t(
  'termsAndConditions.sections',
  i18nOptions,
);

export const tableFields = i18n.t('privacyPolicy.table.fields', i18nOptions);
