import styled from 'styled-components';

export const Logo = styled.img`
  border-radius: 50%;
  min-height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

export const DefaultIcon = styled.div`
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.colors.bgGradientTransfer.start},${theme.colors.bgGradientTransfer.end} )`};
  border-radius: 50%;
  min-height: ${({ height }) => height};
  width: ${({ width }) => width};
`;
