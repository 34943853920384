import React from 'react';
import Input from '@/components/atoms/Inputs/Input';

import { ReactComponent as FlagIcon } from '@/assets/br-flag-icon.svg';

import { Wrapper, IconWrapper } from './styles';

const COUNTRY_CODE = '+55';

function PhoneInput({ ...props }) {
  return (
    <Wrapper>
      <IconWrapper>
        <FlagIcon />
        <span>{COUNTRY_CODE}</span>
      </IconWrapper>
      <Input {...props} />
    </Wrapper>
  );
}

export default PhoneInput;
