import { useState, useEffect } from 'react';

import { getUserValidation } from '@/services/api';
import { useUserData } from '@/context/userContext';

const useUserValidations = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(false);
  const { userData, setUserData } = useUserData();

  const getValidations = async (signal) => {
    try {
      setIsFetching(true);
      const res = await getUserValidation(localStorage.getItem('id'), signal);

      setUserData((state) => ({ ...state, validations: res.data }));
    } catch (err) {
      setError(true);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    if (!userData.validations?.length && !isFetching) {
      getValidations(controller.signal);
    }

    return () => controller.abort();
  }, [userData.validations?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  return { isFetching, error, getValidations };
};

export default useUserValidations;
