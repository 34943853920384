import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';

function TwSubmitButton({
  after,
  before,
  handleClick,
  isSubmitting,
  isValid,
  submitText,
  type,
  width,
  classList,
  ...rest
}) {
  return (
    <button
      type="submit"
      {...rest}
      handleClick={handleClick}
      disabled={!isValid || isSubmitting}
      className={`w-full h-11 rounded-sm text-shadow-900 ${
        isValid ? 'cursor-pointer' : 'cursor-not-allowed'
      } ${
        isValid ? 'bg-grass-800' : 'bg-ashes-700'
      } text-sm font-arboria-regular ${classList}`}
    >
      {!isValid
        ? before
        : isSubmitting
        ? submitText || i18n.t('submitButton.validating')
        : after}
    </button>
  );
}

TwSubmitButton.propTypes = {
  after: PropTypes.string,
  before: PropTypes.string,
  handleClick: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  submitText: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
  classList: PropTypes.string,
};

export default TwSubmitButton;
