import styled from 'styled-components';

import { ReactComponent as LogoHorizontal } from '@/assets/vpagLogoHorizontal.svg';

export const Wrapper = styled.div`
  align-items: center;
  display: ${({ showInMobile }) => (showInMobile ? 'flex' : 'none')};
  gap: 16px;
  margin: 2rem 0;
  max-width: 768px;
  width: 90%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const Logo = styled(LogoHorizontal)`
  fill: ${({ theme }) => theme.colors.primary};
  height: 18px;
  margin-right: auto;
  width: 70px;
`;
