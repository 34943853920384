import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import toast from 'react-hot-toast';

import { extractErrorSlug } from '@/helpers/functions';
import { RESET_PASSWORD_SCHEMA } from '@/helpers/schemas';
import i18n from '@/translate/i18n';
import { updatePassword } from '@/services/api';

import { FormStyled, Title } from './styles';
import PasswordFormCheck from '../PasswordFormCheck';
import TwSubmitButton from '@/components/atoms/Buttons/TwSubmitButton';

const TIME_OUT = 3000;

function ResetPasswordForm({ token }) {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const firstRenderPasswordControl = useRef(true);
  const firstRenderPasswordConfirmControl = useRef(true);

  return (
    <>
      <Title>{i18n.t('resetPassword.formTitle')}</Title>
      <Formik
        validationSchema={RESET_PASSWORD_SCHEMA}
        initialValues={{
          password: '',
          password_confirmation: '',
        }}
        initialErrors={{
          password: 'required',
          password_confirmation: 'required',
        }}
        onSubmit={async ({ password, password_confirmation }) => {
          try {
            const payload = {
              token,
              password,
              password_confirmation,
            };

            await updatePassword(payload);
            toast.success(i18n.t('resetPassword.passwordUpdated'));
            setTimeout(() => {
              history.push('/signin');
            }, TIME_OUT);
          } catch (err) {
            const messages = extractErrorSlug(err?.response);

            toast.error(
              i18n.t([
                `error.updatePassword.${messages?.[0]}`,
                'error.updatePassword.unspecific',
              ]),
            );
          }
        }}
      >
        {({
          errors,
          values,
          isSubmitting,
          isValid,
          touched,
          setFieldTouched,
        }) => (
          <FormStyled
            onChange={() => {
              setErrorMessage('');
              if (!values.password) {
                setFieldTouched('password', false);
              }
              if (!values.confirmPassword) {
                setFieldTouched('password_confirmation', false);
              }
            }}
          >
            <div className="space-y-3 w-full px-10">
              <PasswordFormCheck
                errors={errors}
                touched={touched}
                values={values}
                setFieldTouched={setFieldTouched}
                firstRenderPasswordControl={firstRenderPasswordControl}
                firstRenderPasswordConfirmControl={
                  firstRenderPasswordConfirmControl
                }
              />
            </div>
            <div className="px-10 w-full">
              <TwSubmitButton
                id="signup-submit-btn"
                data-testid="signup-submit-btn"
                isValid={isValid}
                isSubmitting={isSubmitting}
                classList="!text-xl"
                before={i18n.t('resetPassword.insertPass')}
                after={i18n.t('resetPassword.update')}
                submitError={!!errorMessage}
              />
            </div>
          </FormStyled>
        )}
      </Formik>
    </>
  );
}

ResetPasswordForm.propTypes = {
  token: PropTypes.string,
}.isRequired;

export default ResetPasswordForm;
