import { QRCodeSVG } from 'qrcode.react';
import PropTypes from 'prop-types';

import React from 'react';

function TwQrCode({ url }) {
  return (
    <div className="flex justify-center">
      <div className="border-[3px] border-grass-800 rounded-md overflow-hidden">
        <QRCodeSVG
          level="L"
          className="border-[5px] border-white size-52"
          value={url}
        />
      </div>
    </div>
  );
}

TwQrCode.propTypes = {
  url: PropTypes.string,
}.isRequired;

export default TwQrCode;
