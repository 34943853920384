import { useState, useEffect } from 'react';

import { listPromotions } from '@/services/api';
import { useWalletData } from '@/context/walletContext';

const CASINO_CATEGORIES = ['poker', 'bingo'];

const usePromotions = (filters) => {
  const { setWalletData, walletData } = useWalletData();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const controller = new AbortController();

    const getPromotions = async () => {
      try {
        setIsFetching(true);
        const params = { active: 1, ...filters };
        const res = await listPromotions(params);
        const filteredCategories = res?.data?.map((promo) => {
          if (CASINO_CATEGORIES.includes(promo?.category)) {
            return {
              ...promo,
              category: 'casino',
            };
          }
          return promo;
        });

        setWalletData((state) => ({
          ...state,
          promotions: filteredCategories,
        }));
      } catch (err) {
        setError(true);
      } finally {
        setIsFetching(false);
      }
    };

    if (!walletData.promotions.length && !isFetching) {
      getPromotions();
    }

    return () => controller.abort();
  }, []);

  return { isFetching, error };
};

export default usePromotions;
