import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex-direction: column;
  gap: 5vh;
  overflow-x: hidden;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 100%;
    justify-content: space-between;
  }
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: center;
  overflow-x: scroll;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    position: absolute;
    top: 6rem;
    width: auto;
  }
`;

export const NavItem = styled.span`
  ${({ theme, selected }) => css`
    border-bottom: ${selected ? `2px solid ${theme.colors.primary}` : 'none'};
    color: ${selected ? theme.colors.primary : theme.colors.info.title};
    cursor: pointer;
    font: ${theme.font.size.medium} ${theme.font.family.secondary};
    margin: 0 0.5rem;
    transition: background-color 0.25s ease;
  `}
`;

export const PromotionsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  gap: 2rem 1rem;
  justify-content: center;
  max-width: 80%;
  overflow: auto;
  padding-bottom: 10rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: center;
    margin-top: 2rem;
    max-height: 50vh;
    min-height: 280px;
    padding-bottom: 2rem;
  }
`;
