import styled, { css } from 'styled-components';

const BonusButtonWrapper = styled.div`
  ${({ theme, divider }) => css`
    align-items: center;
    animation: opacityAnimationDefault 0.6s;
    display: flex;

    button {
      background-color: transparent;
      border: none;
      color: ${theme.colors.info.secondary};
      font: ${`${theme.font.size.small} ${theme.font.family.secondary}`};
      text-align: center;
      text-decoration: underline;

      &:before {
        color: ${theme.colors.info.light};
        content: '|';
        display: ${divider ? 'inline' : 'none'};
        margin: 0 0.3rem;
      }
    }
  `}
`;

export default BonusButtonWrapper;
