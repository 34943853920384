import styled, { css } from 'styled-components';
import { Form } from 'formik';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';

const ContainerStyles = css`
  align-items: center;
  animation: goUpForm 0.4s;
  background-color: transparent;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  width: 100%;
`;

export const FormStyled = styled(Form)`
  ${ContainerStyles}
`;

export const Container = styled.div`
  ${ContainerStyles}
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.danger};
  font-size: ${({ theme }) => theme.font.size.small};
  margin-bottom: 15px;
  text-align: center;
  top: 220px;
`;

export const InputWrapper = styled.div`
  margin-top: ${({ isValid }) => (isValid ? '10vh' : 0)};
  position: relative;
  width: 100%;
`;

export const CheckCpfWrapper = styled.div`
  width: 100%;
  margin-top: 5vh;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 332px;
  }
`;

export const LogoutButton = styled(TextButton)`
  margin: 2vh auto 0;
  width: 100%;
`;
