import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  svg {
    border-radius: 2px;
    width: 30px;
  }

  @media screen and (min-width: 250px) {
    input {
      padding-left: 2rem !important;
    }
  }
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    display: none;

    @media screen and (min-width: 250px) {
      color: ${theme.colors.info.primary};
      display: flex;
      font-size: ${theme.font.size.mediumCalc};
      gap: 3px;
      left: 5px;
      opacity: 0.7;
      position: absolute;
      top: 13px;
      z-index: 3;

      span {
        display: none;
        margin-top: 1px;
      }
    }

    @media screen and (min-width: ${theme.breakpoints.xs}) {
      span {
        display: inline;
      }
    }
  `}
`;
