import React from 'react';
import PropTypes from 'prop-types';

import { ErrorIcon, Text, Wrapper } from './styles';

function ErrorMessage({ content }) {
  return (
    <Wrapper>
      <ErrorIcon />
      <Text>{content}</Text>
    </Wrapper>
  );
}

ErrorMessage.propTypes = {
  content: PropTypes.string,
}.isRequired;

export default ErrorMessage;
