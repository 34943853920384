import styled from 'styled-components';
import { hexToRGBA } from '@/styles/colors';

export const ItemWrapper = styled.div`
  align-items: center;
  align-self: center;
  border-bottom: ${({ theme }) =>
    `1px solid ${hexToRGBA(theme.colors.info.primary, 0.1)}`};
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  width: 92%;
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
