import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ShowPass } from '@/assets/showPass.svg';
import { ReactComponent as HidePass } from '@/assets/hidePass.svg';

function TwInput({
  error,
  handleBlur,
  name,
  numericKeypad,
  placeholder,
  id,
  classList,
  customSize,
  value,
  disableAutoComplete,
  handleChange,
  options,
  type = 'text',
  complement,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={!customSize && 'w-full'}>
      <div className="relative w-full flex gap-1">
        {complement}

        <input
          {...rest}
          name={name}
          id={id}
          autoComplete={disableAutoComplete && 'new-password'}
          onBlur={() => handleBlur && handleBlur()}
          placeholder={placeholder}
          type={showPassword ? 'text' : type}
          inputMode={numericKeypad ? 'numeric' : type}
          value={value}
          className={`border bg-shadow-700 autofill:bg-yellow-200 placeholder:text-ashes-800 h-14 rounded-lg w-full px-4 text-white font-arboria-regular text-base ${
            error ? 'border-cherryRed' : 'border-transparent'
          }`}
          {...(handleChange ? { onChange: handleChange } : {})}
        />

        {type === 'password' && (
          <div className="absolute top-1/2 right-0 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer z-40">
            {showPassword ? (
              <ShowPass
                className="w-5"
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <HidePass className="w-5" onClick={() => setShowPassword(true)} />
            )}
          </div>
        )}
      </div>
      {error && (
        <span className="text-cherryRed font-arboria-regular text-base text-left block">
          *{error}
        </span>
      )}
    </div>
  );
}

TwInput.propTypes = {
  color: PropTypes.string,
  dataTestId: PropTypes.string,
  disableAutoComplete: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  handleBlur: PropTypes.func,
  icon: PropTypes.element,
  id: PropTypes.string,
  isvalidandlocked: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  secondaryTheme: PropTypes.bool,
  togglePassowordButton: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,
}.isRequired;

export default TwInput;
