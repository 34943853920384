import React from 'react';
import PropTypes from 'prop-types';
import useDeviceWidth from '@/hooks/useDeviceWidth';

function TwBonusIndicator({ multiplier, children }) {
  const { isMobileLayout } = useDeviceWidth();

  return (
    <div
      className={`flex items-center ${
        isMobileLayout ? 'justify-between ml-auto' : 'justify-center ml-0'
      } w-[80px] font-arboria-bold text-ashes-500`}
    >
      {children}
      {multiplier > 0 && (
        <p className="!ml-0 !mb-0 text-sm">
          {multiplier}
          <span>x pts</span>
        </p>
      )}
    </div>
  );
}

TwBonusIndicator.propTypes = {
  multiplier: PropTypes.number,
  children: PropTypes.node,
};

export default TwBonusIndicator;
