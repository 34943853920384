import styled from 'styled-components';
import { HoverScrollBar } from '@/components/atoms/ScrollBar/styles';

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  justify-content: flex-start;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: space-between;
    height: 90%;
  }
`;

export const ListWrapper = styled.div`
  ${HoverScrollBar};

  display: flex;
  flex-direction: column;
  max-width: 470px;
  overflow-y: auto;
  width: 90%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 1rem 0;
    max-height: 60vh;
    width: 80%;
  }
`;

export const ListItem = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.6fr 0.6fr;
  padding: 0.6rem 0.5rem;
  width: 100%;

  &:not(:last-child) {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.info.gray}`};
  }
`;
