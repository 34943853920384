import React from 'react';

import ProfileTemplate from '@/components/templates/ProfileTemplate';
import AddressForm from '@/components/organisms/AddressForm';

function Address() {
  return (
    <ProfileTemplate>
      <AddressForm />
    </ProfileTemplate>
  );
}

export default Address;
