import React from 'react';
import PropTypes from 'prop-types';

import VpagIconBlack from '@/assets/vpag-icon-black.svg';
import { IconContainer } from './style';

const IconVplus = ({ white }) => (
  <IconContainer isBackgroundWhite={white}>
    <img src={VpagIconBlack} alt="V+" />
  </IconContainer>
);

IconVplus.propTypes = {
  white: PropTypes.bool,
};

export default IconVplus;
