import styled from 'styled-components';

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 1rem;
  width: 100%;
`;

export const Section = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  justify-content: space-between;
  width: 70%;
`;

export const FlexDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
