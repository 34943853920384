import React from 'react';
import { Trans } from 'react-i18next';

import i18n from '@/translate/i18n';
import { LinkText, Text, Title } from './styles';

function LimitInstructions() {
  return (
    <>
      <Title>{i18n.t('limitInstructions.title')}</Title>
      <Text>
        <Trans
          i18n={i18n}
          i18nKey="limitInstructions.content"
          components={<LinkText to="/validations" />}
        />
      </Text>
    </>
  );
}

export default LimitInstructions;
