import styled from 'styled-components';

export const ContentWrapper = styled.div`
  align-items: center;
  background-image: ${({ theme, isExternalFlow }) =>
    isExternalFlow ? 'none' : `black`};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  text-align: center;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    background-image: none;
    width: auto;
  }
`;

export const ContentSection = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Section = styled.section`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.primary};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.font.size.small};
  line-height: 28px;
  text-align: center;
  width: 100%;

  p > a {
    display: inline;
  }
`;

export const ParagraphDesk = styled.p`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: inline;
    width: 450px;
  }
`;

export const MobileDescription = styled.p`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;
