import styled from 'styled-components';

import { hexToRGBA } from '@/styles/colors';
import { Title } from '@/components/atoms/Typography/styles';

export const Container = styled.div`
  align-items: center;
  animation: ${({ showModal }) =>
    showModal
      ? 'opacityAnimationDefault forwards '
      : 'opacityOneToZero forwards'};
  animation-duration: ${({ hideAnimation }) => (hideAnimation ? '0' : '0.3s')};
  background-color: ${({ removeBlur }) => !removeBlur && 'rgba(0, 0, 0, 0.5)'};
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 3;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  animation: ${({ showModal }) =>
    showModal ? 'mobileModalUp forwards ' : 'mobileModalDown forwards'};
  animation-duration: ${({ hideAnimation }) => (hideAnimation ? '0' : '0.8s')};
  background-color: transparent;
  bottom: 0;
  position: fixed;
  width: 90vw;
  z-index: 9;
`;

export const ButtonWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.darkSecondary};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.bg.darkSecondary};
  border: none;
  color: ${({ theme }) => theme.colors.light};
  font: ${({ theme }) =>
    `${theme.font.size.medium} ${theme.font.family.primary}`};
  height: 60px;
  width: 100%;

  &:not(:last-child) {
    background: ${({ theme }) =>
      `linear-gradient(${hexToRGBA(
        theme.colors.info.primary,
        0.2,
      )}, ${hexToRGBA(theme.colors.info.primary, 0.2)}) bottom no-repeat`};
    background-size: 90% 1px;
  }
`;

export const CancelButton = styled(Button)`
  border-radius: 10px;
  margin-top: 20px;
`;

export const MenuTitle = styled(Title)`
  align-items: center;
  border-bottom: 1px solid
    ${({ theme }) => hexToRGBA(theme.colors.info.primary, 0.2)};
  display: flex;
  gap: 1ch;
  height: 60px;
  justify-content: center;
  text-transform: uppercase;
  width: 100%;
`;
