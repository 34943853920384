import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import MethodIcon from '@/components/atoms/MethodIcon';
import PixKey from '@/components/atoms/PixKey';
import SectionDivider from '@/components/atoms/SectionDivider/styles';
import TransactionDetailsSummary from '@/components/molecules/TransactionDetailsSummary';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { DeskWrapper } from '@/components/atoms/GenerateTransactionStyle/styles';
import { LinkTextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { ValueWrapper } from '@/components/atoms/TransactionStatusStyles/styles';
import { formatCurrency, formatShortDate } from '@/helpers/stringFormat';
import { twTitleColorByStatus } from '@/helpers/constants';

import { ContentWrapper, MethodWrapper, Section } from './styles';
import TwTitle from '@/components/atoms/TwTitle';

function WithdrawalDetails({ data }) {
  const { isMobileLayout } = useDeviceWidth();
  const formattedAmount = formatCurrency(
    data?.customer_amount,
    data?.customer_currency_iso,
  );

  const detailsData = {
    method: data?.payment_method_name ?? data?.payment_method_slug ?? '',
    id: data?.id,
    date: data?.created_at ? formatShortDate(data.created_at) : '',
    amount: formatCurrency(
      data?.customer_amount ?? 0,
      data?.customer_currency_iso,
    ),

    [`youReceive.${data?.status}`]: formatCurrency(
      data?.customer_amount ?? 0,
      data?.customer_currency_iso,
    ),
  };

  return (
    <DeskWrapper>
      <ContentWrapper>
        <Section>
          {isMobileLayout && (
            <TwTitle
              size={isMobileLayout ? 'm_lg' : 'd_lg'}
              font="arboriaBold"
              classList="text-center"
            >
              <Trans
                i18nKey={i18n.t(
                  `transactionDetails.title.withdrawal.${data?.status}`,
                )}
                i18n={i18n}
                components={[
                  <strong
                    className={`${
                      twTitleColorByStatus[data?.status ?? 'default']
                    }`}
                  />,
                ]}
              />
            </TwTitle>
          )}
          <TransactionDetailsSummary data={detailsData} status={data?.status} />
          <LinkTextButton className="!text-lg" to="/transactions">
            {i18n.t('transactionDetails.goToHistory')}
          </LinkTextButton>
        </Section>
        <SectionDivider />
        <Section hideInMobile>
          <TwTitle
            size={isMobileLayout ? 'm_lg' : 'd_lg'}
            font="arboriaBold"
            classList="text-center"
          >
            <Trans
              i18nKey={i18n.t(
                `transactionDetails.title.withdrawal.${data?.status}`,
              )}
              i18n={i18n}
              components={[
                <strong
                  className={`${
                    twTitleColorByStatus[data?.status ?? 'default']
                  }`}
                />,
              ]}
            />
          </TwTitle>
          <MethodWrapper>
            <MethodIcon method={data?.payment_method_slug} flat width="60px" />
            <PixKey />
          </MethodWrapper>
          <ValueWrapper>
            <TwTitle
              size={isMobileLayout ? 'm_md' : 'd_md'}
              font="arboriaBold"
              classList="text-center"
            >
              {i18n.t(
                `transactionDetails.amountResume.withdrawal.${data?.status}`,
              )}
            </TwTitle>
            <p id="withdrawal-details-amount">{formattedAmount}</p>
          </ValueWrapper>
          <span />
        </Section>
      </ContentWrapper>
    </DeskWrapper>
  );
}

WithdrawalDetails.propTypes = {
  data: PropTypes.shape({
    updated_at: PropTypes.string,
    id: PropTypes.number,
    status: PropTypes.string,
    details: PropTypes.shape({
      payment_method_slug: PropTypes.string,
      receiver_data: {
        pix_code: PropTypes.string,
      },
    }),
  }),
}.isRequired;

export default WithdrawalDetails;
