import React from 'react';
import PropTypes from 'prop-types';

import HeadModal from '@/components/molecules/HeadModal';
import i18n from '@/translate/i18n';
import PersonPlus from '@/assets/personPlus.svg';
import { Container } from '../CashbackPixInfo/style';
import useDeviceWidth from '@/hooks/useDeviceWidth';

import {
  ButtonHoverStyled,
  CreateAccountBody,
  SpanStyled,
  TitleContainer,
} from './style';
import { Title } from '@/components/atoms/Typography/styles';

const SECOND_STEP = 2;

export const CashbackPixCreateAccount = ({
  handleBackStep,
  handleNextStep,
  merchantSelected,
}) => {
  const { isMobileLayout } = useDeviceWidth();

  return (
    <Container isMobile={isMobileLayout}>
      <HeadModal
        currentStep={SECOND_STEP}
        handleBackStep={handleBackStep}
        currentIcon={PersonPlus}
      />

      <CreateAccountBody
        className="px-10 md:px-14"
        isMobileLayout={isMobileLayout}
      >
        <TitleContainer>
          <Title size="medium" className="text-xl md:text-2xl text-white">
            {i18n.t('cashbackModalCreateAccount.titleOne')}
            {merchantSelected.name}
            {i18n.t('cashbackModalCreateAccount.titleTwo')}
          </Title>
        </TitleContainer>
        <ButtonHoverStyled
          className="w-full"
          onClick={() => {
            window.open(merchantSelected.url, '_blank');
          }}
        >
          {i18n.t('cashbackModalCreateAccount.createAccountNow')}
        </ButtonHoverStyled>
        <TitleContainer>
          <p className="!font-extralight text-white text-xl">
            {i18n.t('cashbackModalCreateAccount.accountExist')}
            <span className="font-bold">{merchantSelected.name}</span>
            {i18n.t('cashbackModalCreateAccount.clickHere')}
            <SpanStyled
              className="font-bold text-grass-800"
              onClick={() => handleNextStep()}
            >
              {i18n.t('cashbackModalCreateAccount.Here')}
            </SpanStyled>
          </p>
        </TitleContainer>
      </CreateAccountBody>
    </Container>
  );
};

CashbackPixCreateAccount.propTypes = {
  handleBackStep: PropTypes.func,
  handleNextStep: PropTypes.func,
  merchantSelected: PropTypes.object,
}.isRequired;
