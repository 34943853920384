import styled from 'styled-components';

const Wrapper = styled.label`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.light};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: ${({ hideInput }) => (hideInput ? 0 : 'auto')};
  text-align: center;
  text-transform: uppercase;
  width: 200px;

  input {
    display: none;
  }
`;

export default Wrapper;
