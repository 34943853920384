import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import MethodIcon from '@/components/atoms/MethodIcon';
import i18n from '@/translate/i18n';
import { Paragraph } from '@/components/atoms/Typography/styles';
import { useWalletData } from '@/context/walletContext';

import { IconWrapper, SwiperWrapper, MethodWrapper } from './styles';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import TwTitle from '@/components/atoms/TwTitle';
import useDeviceWidth from '@/hooks/useDeviceWidth';

SwiperCore.use([Navigation, Pagination]);

function PaymentCarousel({ customMessage, description, hide, minimalVersion }) {
  const { pathname } = useLocation();
  const { isMobileLayout } = useDeviceWidth();
  const {
    walletData: { methods },
    setWalletData,
  } = useWalletData();

  useEffect(() => {
    if (methods?.length) {
      setWalletData((state) => ({
        ...state,
        transactionData: { ...state.transactionData, method: methods[0] },
      }));
    }
  }, [methods, setWalletData]);

  const handleChange = (method) => {
    setWalletData((state) => ({
      ...state,
      transactionData: { ...state.transactionData, method },
    }));
  };

  const methodList =
    pathname === '/withdrawal'
      ? methods?.filter((m) => m?.slug === 'pix')
      : methods;

  const moreThanOneMethod = methodList?.length > 1;

  return (
    <>
      {methodList?.length ? (
        <SwiperWrapper
          hide={hide}
          id="payment-selector"
          minimalVersion={minimalVersion}
        >
          {description && (
            <TwTitle
              text={description}
              size={isMobileLayout ? 'm_lg' : 'd_lg'}
              font="arboriaBold"
              classList="text-center"
            />
          )}
          <Swiper
            initialSlide={0}
            loop
            loopFillGroupWithBlank
            navigation={moreThanOneMethod}
            modules={[Pagination, Navigation]}
            allowSlidePrev={moreThanOneMethod}
            allowSlideNext={moreThanOneMethod}
            onSlideChange={(e) => {
              handleChange(methodList[e.realIndex]);
            }}
          >
            {methodList?.map(({ slug, name }) => (
              <SwiperSlide key={slug} value={slug}>
                <MethodWrapper minimalVersion={minimalVersion}>
                  <IconWrapper minimalVersion={minimalVersion}>
                    <MethodIcon method={slug} width="50px" height="50px" />
                  </IconWrapper>
                  <Paragraph color="light" size="medium">
                    {name}
                  </Paragraph>
                </MethodWrapper>
              </SwiperSlide>
            ))}
          </Swiper>
          {customMessage ? (
            <TwTitle
              size="xxs"
              font="arboriaBold"
              color="shadow-500"
              classList="text-center"
            >
              <Trans
                i18nKey={customMessage}
                i18n={i18n}
                components={[<span className="text-grass-800" />]}
              />
            </TwTitle>
          ) : (
            <span />
          )}
        </SwiperWrapper>
      ) : null}
    </>
  );
}

PaymentCarousel.propTypes = {
  customMessage: PropTypes.string,
  description: PropTypes.string,
  hide: PropTypes.bool,
  disableBonusSelection: PropTypes.bool,
  showDetails: PropTypes.bool,
}.isRequired;

export default PaymentCarousel;
