import React from 'react';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import { SubmitWrapper, SubmitPrimary, SubmitSecondary } from './styles';

function SubmitButton({
  after,
  before,
  color,
  handleClick,
  isSubmitting,
  isValid,
  secondaryTheme,
  submitError,
  submitText,
  type,
  width,
  disabledMaxWidth,
  ...rest
}) {
  const Button = secondaryTheme ? SubmitSecondary : SubmitPrimary;
  return (
    <SubmitWrapper>
      <Button
        type={type ?? 'submit'}
        opac={!isValid || submitError}
        disabled={!isValid || isSubmitting}
        onClick={handleClick}
        color={color}
        width={width}
        disabledMaxWidth={disabledMaxWidth}
        {...rest}
      >
        {!isValid
          ? before
          : isSubmitting
          ? submitText || i18n.t('submitButton.validating')
          : after}
      </Button>
    </SubmitWrapper>
  );
}

SubmitButton.propTypes = {
  disabledMaxWidth: PropTypes.bool,
  after: PropTypes.string,
  before: PropTypes.string,
  color: PropTypes.string,
  handleClick: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  secondaryTheme: PropTypes.bool,
  submitError: PropTypes.bool,
  submitText: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
};

export default SubmitButton;
