import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  p {
    display: flex;
    flex-direction: column;
  }

  span {
    font-family: ${({ theme }) => theme.font.family.secondary};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
