import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { InternalButton } from '@/components/atoms/Buttons/Button/styles';
import { DELAYS_IN_SECONDS } from '@/helpers/constants';

import 'swiper/css';
import 'swiper/css/pagination';

const SLIDER_CLASSES = {
  left: 'max-w-35 text-left',
  center: 'max-w-45 text-center',
};

function CarouselOnboarding({ data = [] }) {
  return (
    <div className="onboarding-carousel">
      <Swiper
        className="w-full h-full"
        pagination
        modules={[Pagination, Autoplay]}
        autoplay={{ delay: DELAYS_IN_SECONDS.long }}
        loopAdditionalSlides={1}
        observer
        observeParents
        loop
        slidesPerView={1}
      >
        {data.map(
          ({
            buttonLabel,
            background,
            sliderType,
            imgPosition,
            textColor,
            buttonId,
            buttonPath,
            externalLink,
            handleClick,
            id,
            logoAlt,
            logoPath,
            imageAlt,
            imagePath,
            filter,
            title,
            subtitle,
          }) => (
            <SwiperSlide key={id}>
              <div
                className={`${background} !size-full bg-cover flex flex-col items-center`}
              >
                <img
                  className={`${imgPosition}-0 bottom-0 max-h-full absolute`}
                  src={imagePath}
                  alt={imageAlt}
                />
                <div
                  className={` ${sliderType === 'left' ? 'left-[10%]' : ''} ${
                    SLIDER_CLASSES[sliderType]
                  } absolute top-[8%] flex flex-col justify-between items-${sliderType}`}
                >
                  <img className="w-24 mb-14" src={logoPath} alt={logoAlt} />
                  <h1
                    className={`text-${textColor} text-4xl font-arboria-regular mb-4`}
                  >
                    {title}
                  </h1>
                  <h2
                    className={`text-${textColor} text-lg font-arboria-regular mb-4`}
                  >
                    {subtitle}
                  </h2>
                </div>
                {filter && (
                  <img
                    className="absolute bottom-0 w-full"
                    src="\images\carousel\onboarding\filter.png"
                    alt="filter"
                  />
                )}
                <div className="absolute bottom-[20%] left-[10%] w-fit">
                  {buttonLabel && (buttonPath || handleClick) && (
                    <InternalButton
                      id={buttonId}
                      to={
                        externalLink
                          ? { pathname: buttonPath ?? '#' }
                          : buttonPath ?? '#'
                      }
                      bgColor="primaryDark"
                      {...(externalLink ? { target: '_blank' } : {})}
                      onClick={handleClick}
                    >
                      {buttonLabel}
                    </InternalButton>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ),
        )}
      </Swiper>
    </div>
  );
}

CarouselOnboarding.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      buttonLabel: PropTypes.string,
      buttonPath: PropTypes.string,
      customHeight: PropTypes.string,
      customMarginTop: PropTypes.string,
      externalLink: PropTypes.bool,
      handleClick: PropTypes.func,
      id: PropTypes.string,
      imageAlt: PropTypes.string,
      imagePath: PropTypes.string,
      textContent: PropTypes.string,
    }),
  ),
};

export default CarouselOnboarding;
