/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import Painel from '@/components/atoms/Panel';
import SlideContentOneApresentation from '@/components/molecules/SlideOneApresentation';
import SlideContentTwoApresentation from '@/components/molecules/SlideContentTwoApresentation';
import PanelButtonContainer from '@/components/molecules/PanelButtonContainer';
import 'swiper/swiper-bundle.css';
import SlideContentThreeApresentation from '@/components/molecules/SlideContentThreeApresentation';

const SLIDES_PER_VIEW = 1;
const THREE_SECONDS_TIME = 3000;

const SystemPresentationPanel = () => (
  <Painel classList="flex flex-col top-[82px] bg-black bg-gradient-to-t from-black to-transparent !h-[calc(100%-82px)]">
    <div className="z-50 h-full flex flex-col justify-between">
      <Swiper
        slidesPerView={SLIDES_PER_VIEW}
        allowTouchMove
        modules={[Autoplay]}
        autoplay={{ delay: THREE_SECONDS_TIME }}
        pagination={{
          clickable: false,
          el: '.custom-pagination',
          bulletClass:
            'swiper-pagination-bullet size-2 bg-gray-black rounded-full',
          bulletActiveClass: 'swiper-pagination-bullet-active bg-blue-500',
        }}
        className="!size-full px-auto p-auto"
      >
        <SwiperSlide className="flex flex-col justify-end items-center w-1/2 h-full">
          <>
            <img
              src="/images/carousel/onboarding/backgroundMobile.svg"
              alt="background"
              className="inset-0 absolute w-full h-screen object-cover -z-20"
            />
            <SlideContentOneApresentation />
            <PanelButtonContainer />
          </>
        </SwiperSlide>
        <SwiperSlide className="overflow-visible flex flex-col w-screen bg-gradient-to-t from-black to-transparent">
          <>
            <img
              src="/images/carousel/onboarding/bgFans.png"
              alt="fan background"
              className="absolute w-full h-screen left-0 -bottom-[1px] -z-20 object-cover iphone-5:bottom-16"
            />
            <SlideContentTwoApresentation />
            <PanelButtonContainer />
          </>
        </SwiperSlide>
        <SwiperSlide className="flex flex-col justify-end !items-center">
          <img
            src="/images/carousel/onboarding/devices.svg"
            alt="devices background"
            className="absolute max-h-810:!bottom-16  bottom-[8.5rem] -z-20 mx-auto"
          />
          <div className="absolute max-h-810:!bottom-16 bottom-[8.5rem] bg-gradient-to-t from-black to-transparent z-20 !w-full !h-1/3 mx-auto" />
          <SlideContentThreeApresentation />
          <PanelButtonContainer />
        </SwiperSlide>
      </Swiper>
      <div className="custom-pagination absolute w-fit max-h-780:!bottom-[9rem] !bottom-52 !left-1/2 transform -translate-x-1/2 z-50 flex gap-[0.875rem] justify-center" />
    </div>
  </Painel>
);
export default SystemPresentationPanel;
