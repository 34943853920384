import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const FlexWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  button {
    margin: 1rem 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-items: start;
    width: ${({ width = 'unset' }) => width};
  }
`;
