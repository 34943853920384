import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import DeskContainer from '@/components/atoms/DeskContainer/styles';
import Container from '@/components/atoms/Container/styles';
import Footer from '@/components/organisms/Footer';
import { AppLayout } from '@/components/templates/MainTemplate/styles';

import { carouselData } from './data';
import { RegisterLayout, SliderWrapper } from './styles';

import CarouselOnboarding from '@/components/organisms/CarouselOnboarding';

function RegisterTemplate({ children }) {
  const { pathname } = useLocation();

  const isExternalFlow =
    pathname?.includes('/transactions/signin') ||
    pathname?.includes('/external');

  return (
    <DeskContainer>
      <AppLayout>
        <RegisterLayout>
          {!isExternalFlow && (
            <SliderWrapper>
              <CarouselOnboarding hideButtons data={carouselData} />
            </SliderWrapper>
          )}
          <Container
            gradient
            maxWidth={isExternalFlow ? 'auto' : '750px'}
            justify="center"
          >
            {children}
          </Container>
        </RegisterLayout>
        {!isExternalFlow && <Footer />}
      </AppLayout>
    </DeskContainer>
  );
}

RegisterTemplate.propTypes = {
  children: PropTypes.element,
}.isRequired;

export default RegisterTemplate;
