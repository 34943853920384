import styled from 'styled-components';
import { Button } from '@/components/atoms/Buttons/Button/styles';
import { TitleContainer } from '../Cashback/styles';

export const ActiveMechButton = styled(Button)`
  font-size: 1.25rem;
  max-height: 3.75rem;
  max-width: 20rem;
  min-height: 3.75rem;
  min-width: 20rem;
  padding: 0.625rem;
  transition: opacity 0.2s;
  visibility: ${({ loading }) => (loading ? 'hidden' : 'auto')};
  margin-bottom: 1.875rem;

  @media (min-width: 1440px) {
    margin-bottom: 0rem;
  }
  :hover {
    opacity: 0.8;
  }
`;

export const TitleContainerChooseCash = styled(TitleContainer)`
  width: 50%;
`;

export const BackButtonContainer = styled.div`
  padding: 0.625rem 0rem 0.625rem 3.75rem;
  width: 100%;

  @media (min-width: 1440px) {
    margin: 1.875rem 0 -5.4375rem;
  }
`;

export const BackButtonContent = styled.div`
  width: 'fit-content';
`;
export const CenterContainer = styled.div`
  margin-top: -1.5625rem;
  width: 43.125rem;
`;
