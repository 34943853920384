import React from 'react';
import PropTypes from 'prop-types';
import i18n from '@/translate/i18n';

import { formatShortDate } from '@/helpers/stringFormat';
import useDeviceWidth from '@/hooks/useDeviceWidth';

const BACKGROUND_POINTS = {
  available: 'bg-grass-800',
  out: 'bg-blue-secondary',
  used: 'bg-sand-800',
  expired: 'bg-cherryRed',
};

function BonusHistoryResume({ data, setModalContent }) {
  const { isMobileLayout } = useDeviceWidth();

  return (
    <button
      type="button"
      className="list-row cursor-pointer flex items-center justify-between text-white text-base w-full min-h-[50px] last:border-b-0 border-b border-shadow-500"
      onClick={setModalContent}
    >
      <span className="w-[60%] text-left ml-3">{data?.bonus?.title}</span>
      <span className="w-1/4 text-left  text-xs">
        {formatShortDate(data?.created_at, false)}
      </span>
      <div className="flex items-center">
        <div
          className={`mr-3 sm:mr-0 w-2 h-2 ml-2 rounded-full ${
            BACKGROUND_POINTS[data?.status]
          }`}
        />
        {!isMobileLayout && (
          <span className="hidden xl:block ml-2 min-w-20 text-left">
            {i18n.t(`bonus.status.${data.status}`)}
          </span>
        )}
      </div>
    </button>
  );
}

BonusHistoryResume.propTypes = {
  data: PropTypes.shape({}),
}.isRequired;

export default BonusHistoryResume;
