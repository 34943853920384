import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.colors.info.light};
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-around;
    min-height: 400px;
    padding: 2rem 1rem;

    h1 {
      font: ${theme.font.size.large} ${theme.font.family.secondary};
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      max-width: 90%;
    }
  `}
`;

export const TextWrapper = styled.div`
  overflow: hidden auto;
  height: 100%;
  max-height: 60vh;
`;

export const TextContent = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.info.light};
    display: flex;
    flex: 1;
    flex-direction: column;
    font: ${`${theme.font.size.small} ${theme.font.family.primary}`};
    padding: 0.5rem 0.3rem 2rem 0.3rem;
    width: 100%;

    a {
      color: ${theme.colors.highlight};

      &:hover {
        color: ${theme.colors.primary};
      }
    }
  `}
`;
