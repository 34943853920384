import styled, { css } from 'styled-components';
import { Form } from 'formik';
import { LinkTextButton } from '@/components/atoms/Buttons/TextButton/styles';

export const FormStyled = styled(Form)`
  align-items: center;
  animation: ${({ $invertAnimation }) =>
    $invertAnimation ? 'goRightDefault 0.8s' : 'goLeftDefault 0.7s'};
  background-color: transparent;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  width: 100%;
`;

export const AnimationWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 0.7;
  flex-direction: column;
  gap: ${({ gap }) => gap || 'none'};
  justify-content: center;
  margin: 5vh 0;
  width: 100%;
`;

export const Footer = styled.footer`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  & > div {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.colors.info.light};
  font-size: ${({ theme }) => theme.font.size.xSmall};
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.danger};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: 600;
  margin-bottom: 15px;
  max-width: 300px;
  text-align: center;
`;

export const ResetPassBtn = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.primaryDark};
  font: ${({ theme }) =>
    `${theme.font.size.xSmall} ${theme.font.family.secondary}`};
`;

export const SignUpButton = styled(LinkTextButton)`
  ${({ $isExternalLogin, theme }) => css`
    align-items: center;
    border: ${$isExternalLogin
      ? `2px solid ${theme.colors.primaryDark}`
      : 'none'};
    border-radius: 4px;
    color: ${theme.colors.primaryDark};
    display: flex;
    height: ${$isExternalLogin ? '45px' : 'auto'};
    justify-content: center;
    max-width: 140px;
    padding: ${$isExternalLogin ? '0.5rem 1rem' : 0};
    text-decoration: ${$isExternalLogin ? 'none' : 'underline'};
    width: ${$isExternalLogin ? '60%' : 'inherit'};

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      width: ${$isExternalLogin ? '44%' : 'inherit'};
    }
  `}
`;
