import React from 'react';
import PropTypes from 'prop-types';
import { AiFillShopping } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import i18n from '@/translate/i18n';
import { useUserData } from '@/context/userContext';

import Wrapper from './styles';
import useUserPoints from '@/hooks/useUserPoints';

function PointsSummary({ hideInMobile }) {
  const history = useHistory();
  const { userData } = useUserData();

  const { isFetching, error } = useUserPoints();

  return (
    <Wrapper
      hideInMobile={hideInMobile}
      onClick={() => history.push('/points')}
    >
      <p>
        <AiFillShopping />
        {i18n.t('pointsSummary.title')}
      </p>
      <div>
        <strong>
          {isFetching ? '...' : error ? '0' : userData?.points ?? '0'}
        </strong>
      </div>
    </Wrapper>
  );
}

PointsSummary.propTypes = {
  hideInMobile: PropTypes.bool.isRequired,
};

export default PointsSummary;
