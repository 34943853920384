import styled from 'styled-components';

const ContentWrapper = styled.form`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.primary};
  display: flex;
  flex-direction: column;
  height: ${({ showDetails }) => (showDetails ? 'auto' : '100%')};
  justify-content: space-around;
  padding-bottom: 2rem;
  text-align: center;
  width: 50%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 100%;
    justify-content: space-between;
    padding-bottom: 0;
  }
`;

export const TextButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default ContentWrapper;
