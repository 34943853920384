import { useState, useEffect } from 'react';

import { getConversionData } from '@/services/api';
import { useWalletData } from '@/context/walletContext';

const useConversion = (fromCurrency, toCurrency, type) => {
  const {
    walletData: { selectedWallet },
  } = useWalletData();

  const [exchangeData, setExchangeData] = useState({});
  const [exchangeError, setExchangeError] = useState(false);

  useEffect(() => {
    const controller = new AbortController();

    const payload = {
      owner_id: localStorage.getItem('id'),
      owner_type: 'customer',
      from_currency: fromCurrency || selectedWallet,
      to_currency: toCurrency || 'BRL',
      from_amount: 1,
    };

    const getData = async () => {
      try {
        const res = await getConversionData(payload, controller.signal);
        setExchangeData(res.data);
      } catch (error) {
        setExchangeError(true);
      }
    };

    if (toCurrency && (selectedWallet !== 'BRL' || type === 'conversion')) {
      getData();
    }

    return () => controller.abort();
  }, [fromCurrency, selectedWallet, toCurrency, type]);

  return { exchangeData, exchangeError };
};

export default useConversion;
