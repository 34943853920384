import styled from 'styled-components';

export const ContentWrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 90%;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: grid;
    grid-template-columns: 47% 1% 47%;
    height: 100%;
    justify-items: center;
    min-height: 550px;
    overflow: hidden;
    padding: 4vh 0;
  }
`;

export const Section = styled.section`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.primary};
  display: ${({ hideInMobile }) => (hideInMobile ? 'none' : 'flex')};
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-height: 550px;
  text-align: center;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
  }
`;
