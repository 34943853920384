import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { ReactComponent as CopyIcon } from '@/assets/icon-pix-key.svg';
import { ReactComponent as CopyIconActive } from '@/assets/icon-pix-key-active.svg';
import TwText from '../TwText';
import { ReactComponent as CopyInput } from '@/assets/copyInput.svg';
import { ReactComponent as CopyInputWhite } from '@/assets/copyInputWhite.svg';
import i18n from '@/translate/i18n';

const TIME_ANIMATION = 2000;
const TYPE_KEYS = {
  evp: i18n.t('pixArea.keyRandom'),
  email: i18n.t('pixArea.keyEmail'),
  cpf: i18n.t('pixArea.keyCPF'),
  phone: i18n.t('pixArea.keyPhone'),
};

function TwCopyPixKey({ typeKey, keyPix }) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(keyPix);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, TIME_ANIMATION);
  };

  return (
    <div className="flex justify-between py-8 pl-3 pr-7 border-y-2 border-[#444444] h-[143px] w-[375px] md:max-w-3xl md:w-[809px] bg-transparent hover:bg-[#111213] transition-colors duration-300">
      <div className="flex justify-center items-center  gap-8">
        {copied ? (
          <>
            <CopyIconActive className="w-[55px] h-[53px]" />
            <div className="w-full  text-left">
              <TwText
                size="lg"
                font="arboriaBold"
                text={i18n.t('pixArea.keyCopy')}
                classList="!text-lg"
              />
            </div>
          </>
        ) : (
          <>
            <CopyIcon className="w-12 h-14" />
            <div className="w-full text-left">
              <TwText
                size="lg"
                font="arboriaBold"
                text={TYPE_KEYS[typeKey]}
                classList="!text-lg"
              />
              <TwText size="sm" text={keyPix} />
            </div>
          </>
        )}
      </div>

      <button onClick={copyToClipboard} type="button" className="text-white">
        {copied ? <CopyInput /> : <CopyInputWhite />}
      </button>
    </div>
  );
}

TwCopyPixKey.propTypes = {
  keyPix: PropTypes.string.isRequired,
  typeKey: PropTypes.string.isRequired,
};

export default TwCopyPixKey;
