import styled, { css } from 'styled-components';

const PRIMARY_COLOR = 'primary';

export const Title = styled.h1`
  ${({ highlightUpper, theme, uppercase }) => css`
    text-transform: ${uppercase ? 'uppercase' : 'none'};

    span {
      text-transform: ${highlightUpper ? 'uppercase' : 'none'};
    }

    strong {
      color: ${theme.colors.danger};
      font-weight: 500;
    }
  `}
`;

export const Paragraph = styled.p`
  ${({
    color = PRIMARY_COLOR,
    family = PRIMARY_COLOR,
    highlightColor = PRIMARY_COLOR,
    size = 'small',
    textAlign = 'unset',
    lineHeight = 'normal',
    textTransform = 'none',
    theme,
    whiteSpace = 'pre-line',
  }) => css`
    color: ${theme.colors.info[color]};
    font: ${`${theme.font.size[size]} ${theme.font.family[family]}`};
    line-height: ${lineHeight !== 'normal'
      ? theme.font.size[lineHeight]
      : lineHeight};
    text-align: ${textAlign};
    text-transform: ${textTransform};
    white-space: ${whiteSpace};

    span {
      color: ${theme.colors[highlightColor]};
      font: ${`${theme.font.size[size]} ${theme.font.family[family]}`};
    }

    strong {
      color: ${theme.colors[highlightColor]};
    }
  `}
`;

export const TextOverflowEllipsis = styled(Paragraph)`
  max-width: ${({ maxWidth = 'unset' }) => maxWidth};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
