import React from 'react';
import { Trans } from 'react-i18next';

import CardsTemplate from '@/components/templates/CardsTemplate';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { InternalButton } from '@/components/atoms/Buttons/Button/styles';
import { useUserData } from '@/context/userContext';

import content from './data';
import { FlexDiv, Wrapper } from './styles';
import TwTitle from '@/components/atoms/TwTitle';

const KYC_LEVEL_REQUIRED = 3;

function RequestNewCard() {
  const { isMobileLayout } = useDeviceWidth();
  const { userData } = useUserData();

  const instructionType =
    userData?.kyc_level >= KYC_LEVEL_REQUIRED
      ? 'transferRequired'
      : 'requestAvailable';

  return (
    <CardsTemplate>
      <Wrapper>
        {!isMobileLayout && (
          <TwTitle
            size="xl"
            font="arboriaBold"
            color="shadow-500"
            classList="text-center"
          >
            <Trans
              i18n={i18n}
              i18nKey="cards.requestCard"
              components={[<span />]}
            />
          </TwTitle>
        )}
        <FlexDiv>
          <TwTitle size="xxs" font="arboriaBold" color="shadow-500">
            <Trans
              i18nKey={content[instructionType]?.description}
              i18n={i18n}
              components={[<span />]}
            />
          </TwTitle>
        </FlexDiv>
        <FlexDiv>
          <InternalButton to={content[instructionType]?.path}>
            {content[instructionType]?.button}
          </InternalButton>
          <TextButton type="button">
            {i18n.t('requestCard.termsAndConditions')}
          </TextButton>
        </FlexDiv>
      </Wrapper>
    </CardsTemplate>
  );
}

export default RequestNewCard;
