import styled, { css } from 'styled-components';
import Subtitle from '@/components/atoms/Subtitle';

import SubmitButton from '@/components/atoms/Buttons/SubmitButton';
import { BannerCashbackBody } from '../CashbackPixInfo/style';

export const CashInsertValueTransationBody = styled(BannerCashbackBody)`
  ${({ isMobileLayout }) => css`
    gap: ${isMobileLayout ? '50px' : '10px'};
    padding: 0 15px;

    /* @media (max-width: 380px) and (max-height: 668px) {
      gap: 10px;
    } */
    @media (min-width: 720px) {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  `};
`;

export const SubtitleStyled = styled(Subtitle)`
  opacity: 0.8;
`;

export const SubmitButtonStyledVT = styled(SubmitButton)`
  margin: 0 auto;
  width: 100%;
`;

export const TransactionContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;
