import React from 'react';

import { Key, Wrapper } from './styles';

function PixKey() {
  return (
    <Wrapper>
      <Key />
      <span className="text-ashes-500">CPF</span>
    </Wrapper>
  );
}

export default PixKey;
