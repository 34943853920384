import React from 'react';
import PropTypes from 'prop-types';

const Panel = ({ classList, children }) => (
  <div className={`block md:hidden z-50 absolute w-full ${classList}`}>
    {children}
  </div>
);

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  classList: PropTypes.string,
};

export default Panel;
