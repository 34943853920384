import styled, { css } from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 75vh;
  min-height: 100%;
  padding: 2rem 1rem;
  width: 100%;
`;

export const ContentWrapper = styled.section`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.colors.info.primary};
    display: flex;
    flex-direction: column;
    font: ${theme.font.size.xSmall} ${theme.font.family.secondary};
    overflow: hidden auto;
    padding: 0 1rem;
    text-align: justify;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      max-width: 90%;
    }
  `}
`;

export const TextContent = styled.article`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 100%;
  width: 100%;

  li {
    list-style-position: inside;
    margin-top: 1rem;
    padding-left: 1rem;
  }

  a {
    color: ${({ theme }) => theme.colors.info.secondary};
  }
`;

export const ErrorWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;

  & > p {
    max-width: 500px;
  }
`;
