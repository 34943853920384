import { useState, useEffect } from 'react';
import { isAfter } from 'date-fns';

import { getUserBonuses } from '@/services/api';
import { useUserData } from '@/context/userContext';

const useUserBonuses = () => {
  const { userData, setUserData } = useUserData();

  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(false);

  const getBonuses = async (signal) => {
    try {
      const res = await getUserBonuses(
        userData.id,
        'status[]=available ',
        signal,
      );
      const activeBonuses = res.data?.filter(
        ({ active, transaction_type, min_amount_transaction }) => {
          if (transaction_type === 'deposit') {
            return active && Boolean(min_amount_transaction);
          }
          return active;
        },
      );
      const startedBonuses = activeBonuses?.filter(({ user_bonuses }) => {
        if (user_bonuses?.[0]?.start_at) {
          const startAt = `${user_bonuses[0].start_at.replace(
            ' ',
            'T',
          )}.000000Z`;
          return isAfter(new Date(), new Date(startAt));
        }
        return true;
      });

      setUserData((prevState) => ({ ...prevState, bonuses: startedBonuses }));
    } catch (err) {
      setError(true);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    if (!userData?.bonuses) {
      getBonuses(controller.signal);
    }

    return () => controller.abort();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { isFetching, error, getBonuses };
};

export default useUserBonuses;
