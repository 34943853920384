import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Formik } from 'formik';

import SubmitButton from '@/components/atoms/Buttons/SubmitButton';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';
import PhoneInput from '@/components/atoms/Inputs/PhoneInput';
import ErrorMessage from '@/components/molecules/ErrorMessage';
import { useUserData } from '@/context/userContext';
import { BR_PHONE_CODE } from '@/helpers/constants';
import { extractErrorSlug } from '@/helpers/functions';
import { PHONE_SCHEMA } from '@/helpers/schemas';
import { formatOnlyNumber, formatPhoneMask } from '@/helpers/stringFormat';
import { updatePhoneNumber } from '@/services/api';
import i18n from '@/translate/i18n';

import { ButtonWrapper, InputWrapper, Wrapper } from './styles';
import TwTitle from '@/components/atoms/TwTitle';

const BREAKPOINT = 1024;

function PhoneNumberForm() {
  const history = useHistory();
  const { userData, setUserData } = useUserData();

  const [errorMessage, setErrorMessage] = useState('');
  const [phoneUpdated, setPhoneUpdated] = useState(false);

  const userInitialPhone = userData?.phone?.replaceAll(BR_PHONE_CODE, '');

  return (
    <Formik
      validationSchema={PHONE_SCHEMA}
      initialValues={{
        phone: userData?.phone?.replaceAll(BR_PHONE_CODE, '') ?? '',
      }}
      onSubmit={async (values) => {
        try {
          const userId = localStorage.getItem('id');
          const phoneOnlyNumbers = formatOnlyNumber(values.phone);
          const payload = {
            phone: `${BR_PHONE_CODE}${phoneOnlyNumbers}`,
          };

          const res = await updatePhoneNumber(userId, payload);
          const { phone } = res.data;
          setUserData({ ...userData, phone });

          setUserData((state) => ({
            ...state,
            validations: state?.validations?.map((item) => {
              if (item?.validation === 'phone') {
                return {
                  ...item,
                  status: 'completed',
                };
              }
              return item;
            }),
          }));

          setPhoneUpdated(true);
        } catch (err) {
          const messages = extractErrorSlug(err?.response);
          setErrorMessage(
            i18n.t([
              `error.phoneUpdate.${messages?.[0]}`,
              'error.phoneUpdate.unspecific',
            ]),
          );
        }
      }}
    >
      {({ setFieldTouched, values, setFieldValue, submitForm, isValid }) => (
        <Wrapper id="update-phone-number-form">
          <TwTitle
            size="xl"
            font="arboriaBold"
            color="shadow-500"
            classList="text-center"
          >
            <Trans
              i18n={i18n}
              i18nKey="editPhone.title"
              components={<span />}
            />
          </TwTitle>
          {!errorMessage ? (
            <>
              <InputWrapper>
                {phoneUpdated ? (
                  <TwTitle size="xxs" font="arboriaBold" color="shadow-500">
                    {i18n.t('editPhone.successMessage')}
                    <span>{values?.phone}</span>
                  </TwTitle>
                ) : (
                  <>
                    <PhoneInput
                      id="phone"
                      name="phone"
                      type="text"
                      placeholder={i18n.t('registration.phoneNumber')}
                      value={values.phone}
                      dataTestId="registration-input-phone-number"
                      secondaryTheme
                      handleChange={({ target: t }) => {
                        setFieldTouched('phone', true);
                        if (t.value.length > values.phone.length) {
                          setFieldValue('phone', formatPhoneMask(t.value));
                        } else {
                          setFieldValue('phone', t.value);
                        }
                      }}
                    />
                    <p>{i18n.t('editPhone.description')}</p>
                  </>
                )}
              </InputWrapper>
              <SubmitButton
                type="button"
                before={i18n.t('editPhone.confirm')}
                after={
                  phoneUpdated
                    ? i18n.t('editPhone.validate')
                    : i18n.t('editPhone.confirm')
                }
                isValid={
                  phoneUpdated ||
                  (isValid &&
                    userInitialPhone !== formatOnlyNumber(values.phone))
                }
                handleClick={
                  phoneUpdated
                    ? () => history.push('/profile/phone/validation')
                    : submitForm
                }
                width={window.innerWidth > BREAKPOINT ? '220px' : '100%'}
              />

              <ButtonWrapper>
                {window.innerWidth > BREAKPOINT && (
                  <TextButton
                    type="button"
                    onClick={() => history.push('/profile')}
                  >
                    {phoneUpdated
                      ? i18n.t('returnButton.return')
                      : i18n.t('addressForm.cancel')}
                  </TextButton>
                )}
              </ButtonWrapper>
            </>
          ) : (
            <>
              <div>
                <ErrorMessage content={errorMessage} />
              </div>
              <TextButton type="button" onClick={() => setErrorMessage('')}>
                {i18n.t('editPhone.tryAgain')}
              </TextButton>
            </>
          )}
        </Wrapper>
      )}
    </Formik>
  );
}

PhoneNumberForm.propTypes = {
  setActiveItem: PropTypes.func,
}.isRequired;

export default PhoneNumberForm;
