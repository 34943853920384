import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import Step from '@/components/atoms/Step';
import { Row, StepContent, StepperContainer } from './style';

const ONE = 1;

function Stepper({ quantity, currentStep }) {
  return (
    <StepperContainer>
      {Array.from({ length: quantity }).map((_, index) => (
        <StepContent key={uuidv4()}>
          <Step step={index + ONE} wasActivated={index + ONE === currentStep} />
          {index + ONE !== quantity && <Row />}
        </StepContent>
      ))}
    </StepperContainer>
  );
}

Stepper.propTypes = {
  quantity: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default Stepper;
