import i18n from '@/translate/i18n';

export const filterFields = {
  transactionHistory: [
    {
      name: 'type',
      label: 'transaction',
      type: 'multiselect',
      placeholder: i18n.t('filters.all_f'),
      options: [
        { label: i18n.t('filters.deposit'), value: 'deposit' },
        { label: i18n.t('filters.transfer-in'), value: 'transfer-in' },
        { label: i18n.t('filters.transfer-out'), value: 'transfer-out' },
        { label: i18n.t('filters.withdrawal'), value: 'withdrawal' },
      ],
    },
    {
      name: 'status',
      type: 'multiselect',
      placeholder: i18n.t('filters.all'),
      options: [
        { label: i18n.t('filters.pending'), value: 'pending' },
        { label: i18n.t('filters.completed'), value: 'completed' },
        { label: i18n.t('filters.cancelled'), value: 'cancelled' },
        { label: i18n.t('filters.expired'), value: 'expired' },
      ],
    },
    {
      name: 'merchant_id',
      label: 'site',
      isDisabled: (filters) =>
        filters?.type?.some(
          (type) => type.value === 'deposit' || type.value === 'withdrawal',
        ),
      type: 'multiselect',
      placeholder: i18n.t('filters.all'),
      options: null,
    },
    {
      name: 'date',
      label: 'period',
      type: 'dateRange',
    },
  ],

  bonusHistory: [
    {
      name: 'type',
      type: 'multiselect',
      placeholder: i18n.t('filters.all'),
      options: [
        { label: i18n.t('filters.free'), value: 'free' },
        { label: i18n.t('filters.fixed'), value: 'fixed' },
        { label: i18n.t('filters.percent'), value: 'percentage' },
      ],
    },
    {
      name: 'merchant_id',
      label: 'site',
      isDisabled: (filters) =>
        filters?.type?.some(
          (type) => type.value === 'deposit' || type.value === 'withdrawal',
        ),
      type: 'multiselect',
      placeholder: i18n.t('filters.all'),
      options: null,
    },
    {
      name: 'status',
      type: 'multiselect',
      placeholder: i18n.t('filters.all'),
      options: [
        { label: i18n.t('filters.available'), value: 'available' },
        { label: i18n.t('filters.used'), value: 'used' },
        { label: i18n.t('filters.expired'), value: 'expired' },
        { label: i18n.t('filters.out'), value: 'out' },
      ],
    },
    {
      name: 'date',
      label: 'dateRange',
      type: 'dateRange',
    },
  ],
};
