import styled from 'styled-components';

import { hexToRGBA } from '@/styles/colors';

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  padding-bottom: 100px;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: grid;
    grid-template-columns: 50% 1% 50%;
    justify-items: center;
    padding: 0;
  }
`;

export const SwitchButtonWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.dark};
  bottom: 0;
  display: flex;
  min-width: 65%;
  position: fixed;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const Section = styled.section`
  align-items: center;
  animation: ${({ showDetails, animationReverse }) =>
    showDetails
      ? animationReverse
        ? 'goLeftAndAppear forwards'
        : 'goLeftAndDisappear forwards'
      : animationReverse
      ? 'goRightAndDisapppear forwards'
      : 'goRightAndAppear forwards'};
  animation-duration: ${({ hideAnimation }) => (hideAnimation ? '0' : '0.7s')};
  display: flex;
  flex-direction: column;
  gap: 5vh;
  height: 100%;
  width: 100%;
  justify-content: flex-start;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-self: ${({ align = 'unset' }) => align};
    animation: none;
    padding-top: 5vh;
  }
`;

export const SectionDivider = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    animation: opacityAnimationDefault 0.8s ease;
    border-left: 1px solid
      ${({ theme }) => hexToRGBA(theme.colors.info.primary, 0.2)};
    display: block;
    min-height: 90%;
  }
`;
