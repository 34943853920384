import React, { useEffect, useState } from 'react';

import DepositForm from '@/components/organisms/DepositForm';
import Loading from '@/components/atoms/Loading';
import MainTemplate from '@/components/templates/MainTemplate';
import PaymentCarousel from '@/components/molecules/PaymentCarousel';
import TransactionError from '@/components/molecules/TransactionError';
import TransactionPreview from '@/components/molecules/TransactionPreview';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import useMethods from '@/hooks/useMethods';
import { DEFAULT_METHOD_SLUG } from '@/helpers/constants';
import useTransactionLimits from '@/hooks/useTransactionLimits';
import { useWalletData } from '@/context/walletContext';

import { Section, SectionDivider } from './styles';

function Deposit() {
  const { isMobileLayout } = useDeviceWidth();
  const { loadingMethods, methodsError } = useMethods();
  const [showDetails, setShowDetails] = useState(false);

  const {
    setWalletData,
    walletData: { transactionSubmitError, selectedWallet, transactionData },
  } = useWalletData();

  const { limitsError } = useTransactionLimits(
    'deposit',
    selectedWallet,
    transactionData?.method?.slug ?? DEFAULT_METHOD_SLUG,
  );
  useEffect(
    () => () => {
      setWalletData((prevState) => ({
        ...prevState,
        transactionData: { amount: null },
        selectedBonus: null,
        transactionSubmitError: false,
      }));
    },
    [setWalletData],
  );

  const returnAction = () => {
    if (showDetails) {
      setShowDetails(false);
      setWalletData((prevState) => ({
        ...prevState,
        transactionSubmitError: false,
      }));
    }
  };

  return (
    <MainTemplate
      headerProps={{
        returnAction: showDetails ? returnAction : null,
        returnTo: '/wallet',
        showAvailableBalance: true,
        title: i18n.t('deposit.title'),
      }}
    >
      {loadingMethods ? (
        <div className="size-full grid place-content-center">
          <Loading />
        </div>
      ) : (
        <Section isOpen>
          <PaymentCarousel
            hide={
              (showDetails && !isMobileLayout) ||
              isMobileLayout ||
              limitsError ||
              methodsError
            }
            showDetails={showDetails}
            description={i18n.t('paymentSelector.title')}
          />

          {showDetails && (!transactionSubmitError || !isMobileLayout) && (
            <TransactionPreview
              setShowDetails={setShowDetails}
              type="deposit"
              redirectTo="/deposit"
            />
          )}
          {!limitsError && !methodsError && (
            <>
              <SectionDivider />
              <DepositForm
                setShowDetails={setShowDetails}
                showDetails={showDetails}
              />
            </>
          )}
        </Section>
      )}
      {(methodsError || limitsError) && (
        <TransactionError
          content={i18n.t('error.deposit.limitsOrMethodError')}
          height="80%"
          showInstructions
        />
      )}
    </MainTemplate>
  );
}

export default Deposit;
