import styled from 'styled-components';

export const RightWrapper = styled.div`
  overflow: hidden;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.shadow};
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;
