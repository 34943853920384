import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import BonusTitle from '@/components/atoms/BonusTitle';
import i18n from '@/translate/i18n';
import { useUserData } from '@/context/userContext';
import { useWalletData } from '@/context/walletContext';
import { checkIfMerchantHasBonuses } from '@/helpers/functions';

import BonusButtonWrapper from './styles';

function ChangeBonusButton({ type, customAction }) {
  const history = useHistory();
  const {
    setWalletData,
    walletData: { selectedBonus, selectedMerchant },
  } = useWalletData();

  const {
    userData: { bonuses },
  } = useUserData();

  const selectedMerchantHasBonus = checkIfMerchantHasBonuses(
    bonuses,
    selectedMerchant?.id,
  );

  const depositBonuses = bonuses?.filter(
    ({ transaction_type }) => transaction_type === 'deposit',
  );

  const isFreeBonus =
    !selectedBonus?.min_amount_transaction && selectedBonus?.type === 'fixed';

  const bonusType = isFreeBonus ? 'free' : selectedBonus?.type;

  const isBonusAvailableByType = {
    'transfer-in': selectedMerchantHasBonus,
    deposit: Boolean(depositBonuses?.length),
  };

  const elementId = `bonus-${type}-${bonusType}`;

  const handleClick = () => {
    setWalletData((prevState) => ({
      ...prevState,
      selectedBonus: null,
    }));

    const queryParams = new URLSearchParams(history.location.search);

    if (queryParams.get('b')) {
      history.replace({ search: '' });
    }
  };

  return (
    <BonusButtonWrapper
      onClick={customAction || handleClick}
      type={bonusType}
      divider={Boolean(selectedBonus?.title)}
    >
      {selectedBonus?.title && <BonusTitle bonus={selectedBonus} />}
      <button type="button" id={elementId}>
        {isBonusAvailableByType[type] && selectedBonus === 'default'
          ? i18n.t('transfer.selectBonus')
          : i18n.t('transfer.changeBonus')}
      </button>
    </BonusButtonWrapper>
  );
}

ChangeBonusButton.propTypes = {
  type: PropTypes.string,
  customAction: PropTypes.func,
};

export default ChangeBonusButton;
