import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 290px;
  justify-content: space-between;
  padding: 2rem 0;
  text-align: center;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
